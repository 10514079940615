
import { PRODUCTS_FETCH } from '@/store/constants';
import {
  DynamicFulfillmentState,
  Product,
  IConfig,
  SourceSystem,
  ProductMappingAttribute,
} from '@/store/dynamicfulfillment/types';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DataTable from '../common/DataTable.vue';

@Component({ components: { DataTable } })
export default class ProductConfigModal extends Vue {
  @Prop({ required: true }) private sourceSystem!: SourceSystem;
  @Prop({ required: true }) private id: string;
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;
  private configs: IConfig[] = null;
  private fields: any[] = [];
  private isLoading: boolean = false;

  private async mounted() {
    let products: any[] = [];
    this.isLoading = true;
    let sourceSystemConfig = this.profile.sourceSystemConfigs.find(s => s.id === this.sourceSystem.toString());

    products = await this.$store.dispatch(PRODUCTS_FETCH, {
      sourceSystem: this.sourceSystem.toString(),
    });


    this.fields = [
      {
        key: 'id',
        thStyle: { width: '275px' },
        sortable: true,
      },
      {
        key: 'clientId',
        sortable: true,
      },
      {
        key: 'name',
        sortable: true,
      },
      {
        key: 'description',
        sortable: true,
      },
    ];

    if (sourceSystemConfig.productMappingAttributes.length > 0) {
      this.fields.push(
        ...sourceSystemConfig.productMappingAttributes.map((x: any) => ({
          key: x.name,
          thStyle: null as any,
          sortable: true,
          ...(x.type === 'Contains' && {
            formatter: (x: ProductMappingAttribute) => {
              return x instanceof Array ? x.join(', ') : '';
            },
          }),
        })),
      );
    }
    this.configs = products.filter((config: IConfig) => config.id === this.id);
    this.isLoading = false;
  }
}

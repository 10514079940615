
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ServiceNetworkFulfillmentOptionTypeConfigCreateEdit from '@/components/configuration/ServiceNetworkFulfillmentOptionTypeConfigCreateEdit.vue';
import {
  FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG,
  UPDATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG
} from '@/store/dynamicfulfillment/constants';
import { Action } from 'vuex-class';
import { ServiceNetworkFulfillmentOptionTypeConfig, AllowedAttribute } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: { ConfigEditComponent, ServiceNetworkFulfillmentOptionTypeConfigCreateEdit },
})
export default class ServiceNetworkFulfillmentOptionTypeConfigEdit extends Vue {
  @Prop() private id!: string;
  @Action(FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG, { namespace })
  private fetchServiceNetworkFulfillmentOptionTypeConfig: (id: string) => Promise<ServiceNetworkFulfillmentOptionTypeConfig>;
  config: ServiceNetworkFulfillmentOptionTypeConfig = {
    _etag: null,
    id: null,
    allowedAttributes: [] as AllowedAttribute[],
    fulfillmentOption: null,
  };
  isValid: boolean = false;

  get fetchAction() {
    return namespace + '/' + FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG;
  }

  get saveAction() {
    return namespace + '/' + UPDATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG;
  }

  async mounted() {
    const serviceNetworkFulfillmentOptionTypeConfig = await this.fetchServiceNetworkFulfillmentOptionTypeConfig(this.id);
    if (serviceNetworkFulfillmentOptionTypeConfig) {
      this.config = serviceNetworkFulfillmentOptionTypeConfig;
    }
  }

  onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.config = request.config;
    }
  }
}

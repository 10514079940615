import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, VendorTicketStatusUpdateRequest, FulfillmentTicket, RepairCatalog } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const VendorApiActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.SEND_VENDOR_TICKET_STATUS](context, request: VendorTicketStatusUpdateRequest) {
    await authorizationApiProvider.sendVendorTicketStatus(request);
  },

  async [apis.GET_SVS_ORDER_BY_ID](context, id: string) {
    return await authorizationApiProvider.getSvsOrderById(id);
  },

  async [apis.DELETE_SERVICECENTRAL_CACHE](context) {
    return await authorizationApiProvider.deleteServiceCentralCache();
  },

  async [apis.SERVICECENTRAL_GET_CLAIM](context, id: string) {
    return await authorizationApiProvider.getServiceCentralClaimById(id);
  },

  async [apis.DELETE_SERVICEPOWER_SERVICECENTERS_CACHE](context) {
    return await authorizationApiProvider.deleteServicePowerServiceCentersCache();
  },

  async [apis.CANCEL_VENDOR_OFFER](context, ticket: FulfillmentTicket) {
    await authorizationApiProvider.cancelVendorOffer(ticket);
  },
  
  async [apis.FETCH_REPAIR_CATALOGS](context) {
    let repairCatalogs = context.state.repairCatalogs;
    if (repairCatalogs.length > 0) {
      return repairCatalogs;
    }

    repairCatalogs = await authorizationApiProvider.getRepairCatalogs();
    context.commit(apis.SET_REPAIR_CATALOGS, repairCatalogs);
    return repairCatalogs;
  },
  
  async [apis.FETCH_REPAIR_CATALOG](context, id: string) {
    const repairCatalog = await authorizationApiProvider.getRepairCatalogById(id);
    return repairCatalog;
  },

  async [apis.FETCH_REPAIR_CATALOG_ITEMS](context, repairCatalogId: string) {
    let repairCatalogItems = context.state.repairCatalogItems[repairCatalogId] || [];
    if (repairCatalogItems.length > 0) {
      return repairCatalogItems;
    }
    
    repairCatalogItems = await authorizationApiProvider.getRepairCatalogItemDefinitions(repairCatalogId);
    context.commit(apis.SET_REPAIR_CATALOG_ITEMS, { repairCatalogId, repairCatalogItems });
    return repairCatalogItems;
  },

  async [apis.FETCH_REPAIR_CATALOG_ITEM](context, request: { repairCatalogId: string, itemDefinitionId: string }) {
    const repairCatalogItemDefinition = await authorizationApiProvider.getRepairCatalogItemDefinition(request.repairCatalogId, request.itemDefinitionId);
    return repairCatalogItemDefinition;
  },

  async [apis.CREATE_REPAIR_CATALOG](context, request: { payload: RepairCatalog; }) {
    const repairCatalog = await authorizationApiProvider.createRepairCatalog(request.payload);
    context.commit(apis.RESET_REPAIR_CATALOGS);
    await context.dispatch(apis.FETCH_REPAIR_CATALOGS);
    return repairCatalog;
  },

  async [apis.UPLOAD_REPAIR_CATALOG_ITEMS](context, request: { payload: any; repairCatalogId: string, vendor: string }) {
    return new Promise<void>(async (resolve, reject) => {
      await authorizationApiProvider
        .uploadRepairCatalogItems(request.payload, request.repairCatalogId, request.vendor)
        .then(async () => {
          resolve();
        })
        .catch((error) => {
          if (error.response && error.response.status === 413) {
            const message = request.payload.name.endsWith('.xlsx')
              ? 'The excel file is too large. Try uploading in csv format.'
              : 'The file is too large.';
            reject(message);
          }
          else {
            reject(error);
          }
        });
    });
  },

  async [apis.FETCH_REPAIR_CATALOG_INVENTORY_ITEM](context, request: { repairCatalogId: string, itemId: string }) { 
    return await authorizationApiProvider.getRepairCatalogInventoryItem(request.repairCatalogId, request.itemId);
  },

  async [apis.FETCH_REPAIR_CATALOG_PARTS_MAPPING](context, request: { catalogId: string }) { 
    return await authorizationApiProvider.getRepairCatalogPartsMapping(request.catalogId);
  },
};

export default VendorApiActions;

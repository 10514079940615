
import { ServiceCenterLocationConfig } from '@/store/globalmodules/types';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ServiceAreaComponent from './ServiceAreaComponent.vue';

@Component({
  components: { ServiceAreaComponent }
})
export default class ServiceAreaModal extends Vue {
    @Prop({required: true}) private config: ServiceCenterLocationConfig;
}


import { Component } from 'vue-property-decorator';
import RemovedConfigIndexComponent from '@/components/configuration/RemovedConfigIndexComponent.vue';
import { DELETE_PROGRAM } from '@/store/constants';
import ProgramConfigIndex from '@/views/configuration/Programs/ProgramConfigIndex.vue';
import ProductConfigModal from '@/components/configuration/ProductConfigModal.vue';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import PriceListConfigModal from '@/components/configuration/PriceListConfigModal.vue';
import { ProgramConfig, RoutingAction } from '@/store/dynamicfulfillment/types';
import { authorizationApiProvider } from '@/common/df.authorization.api';
@Component({
  components: {
    RemovedConfigIndexComponent,
    ProductConfigModal: ProductConfigModal,
    VueJSONEditor,
    PriceListConfigModal,
  },
})
export default class RemovedProgramConfigIndex extends ProgramConfigIndex {
  private configItems: ProgramConfig[] = null;

  async mounted() {
    await authorizationApiProvider.getProgramConfigs(this.getSourceSystem(), true)
        .then((configs) => this.configItems = configs);
  }

  get removedRouteActions() {
    return [
     { name: RoutingAction.Delete, action: DELETE_PROGRAM, requiredPermission: 'Global-HardDelete', params: { hardDelete: true } },
    ];
  }
}

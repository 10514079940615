
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ProductsCreateEdit from '@/components/configuration/ProductsCreateEdit.vue';
import { PRODUCTS_BY_ID_FETCH, PRODUCTS_SAVE } from '@/store/constants';
import {
  DynamicFulfillmentState,
  SourceSystemConfig,
} from '@/store/dynamicfulfillment/types';
import { Product } from '@/store/dynamicfulfillment/types';

@Component({ components: { ConfigEditComponent, ProductsCreateEdit } })
export default class ProductsCreate extends Vue {
  @Prop() id!: string;
  @Prop() sourceSystem!: string;
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;
  sourceSystemConfig: SourceSystemConfig;
  isValidState: boolean = false;

  created() {
    this.defaultConfig.sourceSystem = this.sourceSystem;
    this.sourceSystemConfig = this.getSourceSystemConfig;
  }

  get getSourceSystemConfig() {
    return this.sourceSystemConfig !== undefined
      ? this.sourceSystemConfig
      : this.profile.sourceSystemConfigs.find(
          (config) => config.sourceSystem === this.sourceSystem);
  }

  defaultConfig: Product = {
    id: null,
    name: '',
    description: '',
    clientId: '',
    _etag: null,
    sourceSystem: '',
  };

  get fetchAction() {
    return PRODUCTS_BY_ID_FETCH;
  }

  get saveAction() {
    return PRODUCTS_SAVE;
  }

  get fetchParams() {
    return {
      sourceSystem: this.sourceSystem,
      id: this.id,
    };
  }
  
  isValidState_Changed(isValidState: boolean) {
    this.$emit('isValidState_Changed', isValidState);
    this.isValidState = isValidState;
  }
}

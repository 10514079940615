
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FulfillmentTicketOrchestration, FulfillmentTicketOrchestrationDetail, HistoryEvent, OrchestrationTimeLine, OrchestrationRuntimeStatus } from '@/store/dynamicfulfillment/types';
import { FETCH_FULFILLMENT_TICKET_ORCHESTRATION } from '@/store/dynamicfulfillment/constants';
import OrchestrationHistory from '@/components/FulfillmentAuthorization/OrchestrationHistory.vue';
import { Action } from 'vuex-class';
import TimeLine from '../customControls/timeLine/TimeLine.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { TimeLine }})
export default class OrchestrationHistoryDetail extends Vue {
  @Prop() private summary: FulfillmentTicketOrchestration;
  @Action(FETCH_FULFILLMENT_TICKET_ORCHESTRATION, { namespace }) private fetchFulfillmentTicketOrchestration: any;

  private orchTimeLine: OrchestrationTimeLine[] = [];
  private subOrchsTimeLine: {
    orchTimeLine: OrchestrationTimeLine[],
  }[] = [];

  private orchestrationDetail: FulfillmentTicketOrchestrationDetail = null;
  private subOrchestrationDetails: FulfillmentTicketOrchestrationDetail[] = null;
  private historyEventFields: string[] = ['Timestamp', 'FunctionName', 'EventType', 'Result', 'Details'];
  private infoModal: any = {
          id: 'info-modal',
          title: '',
          content: '',
        };

  private uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
   });
  }

  private async mounted() {
    if (this.summary && this.summary.instanceId) {
      this.orchTimeLine = await this.bindDetails(this.summary.instanceId);
    }
    if (this.summary.children && this.summary.children.length > 0) {
      this.summary.children.forEach( async (instance: any) => {
        const subOrchTimeLine = await this.bindDetails(instance.instanceId);
        this.subOrchsTimeLine = this.subOrchsTimeLine.concat({orchTimeLine: subOrchTimeLine});
      });
    }
  }

  private async bindDetails(instanceId: string): Promise<OrchestrationTimeLine[]> {
    let orchTimeLines: OrchestrationTimeLine[] = [];
    const orchDetail = await this.fetchFulfillmentTicketOrchestration(instanceId);
    const orchHistory = this.getOrchHistoryItems(orchDetail);
    if (orchDetail.customStatus && orchDetail.customStatus.Message && orchDetail.customStatus.Events) {
      const waitingOrch: OrchestrationTimeLine = {
        id: this.uuid(),
        timeStamp : orchDetail.lastUpdatedTime,
        title : `${orchDetail.customStatus.Message}-${orchDetail.customStatus.Events.join(', ')}`,
        status : 'Waiting',
        input: null,
        result: null,
        icon_status: 'primary',
        controls: [],
        details: null,
      };
      orchTimeLines = orchTimeLines.concat(waitingOrch);
    }
    const runningOrch: OrchestrationTimeLine = {
      id: this.uuid(),
      timeStamp : orchDetail.lastUpdatedTime,
      title : orchDetail.name,
      status : this.getRuntimeStatus(orchDetail.runtimeStatus),
      input: orchDetail.input,
      result: null,
      icon_status: 'info',
      controls: [
        {
          id: this.uuid(),
          method: 'copyInput',
          icon_class: 'fa-clipboard',
          text: 'Copy Input',
        },
        {
          id: this.uuid(),
          method: 'copyResult',
          icon_class: 'fa-clipboard',
          text: 'Copy Result',
        },
        {
          id: this.uuid(),
          method: 'copyDetails',
          icon_class: 'fa-clipboard',
          text: 'Copy Details',
        },
      ],
      details: orchDetail.details,
    };
    orchTimeLines = orchTimeLines.concat(runningOrch);
    if (orchHistory && orchHistory.length > 0) {
      orchHistory.filter( (h: any) => h.FunctionName !== undefined || h.name !== undefined).forEach( (o: any) => {
          const orch: OrchestrationTimeLine = {
              id: this.uuid(),
              timeStamp : o.Timestamp,
              title : (o.FunctionName) ? o.FunctionName : o.Name,
              status : o.EventType,
              input: null,
              result: o.Result,
              icon_status: 'info',
              controls: [
                {
                  id: this.uuid(),
                  method: 'copyInput',
                  icon_class: 'fa-clipboard',
                  text: 'Copy Input',
                },
                {
                  id: this.uuid(),
                  method: 'copyResult',
                  icon_class: 'fa-clipboard',
                  text: 'Copy Result',
                },
                {
                  id: this.uuid(),
                  method: 'copyDetails',
                  icon_class: 'fa-clipboard',
                  text: 'Copy Details',
                },
              ],
              details: o.Details,
          };
          orchTimeLines = orchTimeLines.concat(orch);
      });
    }
    return orchTimeLines;
  }

  private isSuborchestration(eventType: string): boolean {
    return 'SubOrchestrationInstanceCompleted' === eventType;
  }

  private getSubOrchestration(historyEvent: HistoryEvent): FulfillmentTicketOrchestration {
    return this.summary.children.find((c) => c.name === historyEvent.FunctionName);
  }

  private info(item: any, index: any, button: any) {
        this.infoModal.title = `Event Data`;
        this.infoModal.content = JSON.stringify(item, null, 4);
        this.$root.$emit('bv::show::modal', this.infoModal.id, button);
  }

  private resetInfoModal() {
        this.infoModal.title = '';
        this.infoModal.content = '';
  }

  private getRuntimeStatus(status: any) {
    return isNaN(status) ? status : OrchestrationRuntimeStatus[status];
  }

  private getOrchHistoryItems(orchDetail: any) {
    return Object.hasOwn(orchDetail, 'historyEvents') ? orchDetail.historyEvents : orchDetail.history;
  }
}

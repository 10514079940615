
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class TimeLineControl extends Vue {
@Prop() private control: any;
@Prop({default: false}) private disabled: boolean;
private handleClick() {
    switch (this.control.method) {
        case 'copyInput':
            this.$emit('copyInput');
            break;
        case 'copyResult':
            this.$emit('copyResult');
            break;
        case 'copyDetails':
            this.$emit('copyDetails');
            break;
    }
}
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_CONFIG_HISTORY, FETCH_VENDOR_CONFIGS, VENDOR_CONFIG_DELETE, VENDOR_CONFIG_SAVE } from '@/store/constants';
import { humanizeString } from '@/common/utilities';
import { BookedRepairVolumeFilterConfig, ConfigType, RoutingAction, Enums, ServiceProviderConfig, AncillaryServiceConfig, ServiceProviderConfigExtended, } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';
@Component({ components: { ConfigIndexComponent } })
export default class VendorConfigIndex extends Vue {
  configType: ConfigType = ConfigType.Vendors;
  fields: any[] = [
    {
      key: 'id',
      thStyle: { width: '275px' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'defaultWarrantyPeriod',
      sortable: true,
      filterable: true,
      label: 'Default Warranty Period (Days)',
    },
    {
      key: 'statusMapperStrategy',
    },
    {
      key: 'serviceProviderDetails',
      label: 'Service Network Details',
    },
  ];

  serviceProviderConfigFields: any[] = [
    {
      key: 'name',
      sortable: false,
    },
    {
      key: 'serviceCenterAdministration',
      sortable: false,
      label: 'Service Center Administration',
      formatter: (value: any, key: any, item: any) => {
        return item.serviceCenterAdministration ? 'Yes' : 'No';
      },
    },
    {
      key: 'warrantyPeriod',
      sortable: false,
      label: 'Warranty Period (Days)',
    },
    {
      key: 'returnPeriod(Days)',
      sortable: false,
      formatter: (value: any, key: any, item: any) => {
        return item.loanerProgramConfig.returnPeriodInDays;
      },
      label: 'Return Period (Days)',
    },
    {
      key: 'hasLoanerProgram',
      sortable: false,
      formatter: (value: any, key: any, item: any) => {
        return item.loanerProgramConfig.hasLoanerProgram ? 'Yes' : 'No';
      },
    },
    {
      key: 'bookedRepairLimit',
      sortable: false,
      formatter: (value: any, key: any, item: any) => {
        if (item.bookedRepairVolumeFilterConfig != null) {
          return item.bookedRepairVolumeFilterConfig.bookedRepairLimit;
        } else {
          item.bookedRepairVolumeFilterConfig = this.defaultBookedRepairVolumeFilterConfig;
          return item.bookedRepairVolumeFilterConfig.bookedRepairLimit;
        }
      },
      label: 'Booked Repair Limit',
    },
    {
      key: 'lookBackWindowHours',
      sortable: false,
      formatter: (value: any, key: any, item: any) => {
        if (item.bookedRepairVolumeFilterConfig != null) {
          return item.bookedRepairVolumeFilterConfig.lookBackWindowHours;
        } else {
          item.bookedRepairVolumeFilterConfig = this.defaultBookedRepairVolumeFilterConfig;
          return item.bookedRepairVolumeFilterConfig.lookBackWindowHours;
        }
      },
      label: 'Booked Look Back Window Hours',
    },
    {
      key: 'inventoryFilteringEnabled',
      formatter: (value: any, key: any, item: any) => {
        if (item.inventoryConfig != null) {
          return item.inventoryConfig.inventoryFilteringEnabled;
        }
      },
    },
    {
      key: 'minimumInventoryThreshold',
      formatter: (value: any, key: any, item: any) => {
        if (item.inventoryConfig != null) {
          return item.inventoryConfig.minimumInventoryThreshold;
        }
      },
    },
    {
      key: 'vendorManagedInventory',
      formatter: (value: any, key: any, item: any) => {
        if (item.inventoryConfig != null) {
          return item.inventoryConfig.vendorManagedInventory;
        }
      },
    },
    {
      key: 'billingVendorStrategy',
      formatter: (value: any, key: any, item: any) => {
        if (item.billingVendorStrategy != null) {
          return item.billingVendorStrategy;
        }
      },
    },
    {
      key: 'billingVendorId',
      formatter: (value: any, key: any, item: any) => {
        if (item.billingVendorId != null) {
          return item.billingVendorId;
        }
      },
    },
  ];

  private serviceProviderConfigAncillaryFields: any[] = [
    {
      key: 'name',
      sortable: false,
    },
    {
      key: 'allowedServices',
      sortable: false,
      formatter: (value: AncillaryServiceConfig[], key: string, item: ServiceProviderConfig) => {
        if (item.allowedServices.length > 0) {
          var serviceTypes = item.allowedServices.map((service: AncillaryServiceConfig) => {
            return service.serviceType;
          });
          return serviceTypes;
        }
      },
      label: 'Service Type',
    },
    {
      key: 'billingVendorStrategy',
      sortable: false,
    },
    {
      key: 'billingVendorId',
      sortable: false,
    },
  ];

  vendorFulfillmentOptionConfigsFields: any[] = [
    {
      key: 'fulfillmentOption',
    },
    {
      key: 'attributes',
      formatter: (value: any, key: any, item: any) => {
        if (value) {
          return Object.entries(value).map(([key, value]) => `${humanizeString(key)}: ${value}`).join('<br />');
        }
      },
    },
  ];

  configFields: any[] = ['name', 'serviceCenterAdministration', 'warrantyPeriod', 'returnPeriod(Days)', 'hasLoanerProgram', 'bookedRepairLimit', 'lookBackWindowHours'];

  historyFields = [
    {
        key: 'userAudit.current.name',
        label: 'Name',
    },
    {
        key: 'userAudit.current.modifiedOnUtc',
        label: 'Modified On',
    },
    'action',
  ];

  get fetchAction() {
    return FETCH_VENDOR_CONFIGS;
  }

  get removeAction() {
    return VENDOR_CONFIG_DELETE;
  }

  get createRoute() {
    return 'VendorConfig-Create';
  }

  get editRoute() {
    return 'VendorConfig-Edit';
  }

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'VendorConfig-Edit', requiredPermission: 'Configuration-Vendors-Edit' },
      { name: RoutingAction.Create, route: 'VendorConfig-Create', requiredPermission: 'Configuration-Vendors-Create' },
      { name: RoutingAction.Fetch, action: FETCH_VENDOR_CONFIGS, requiredPermission: 'Configuration-Vendors-View' },
      { name: RoutingAction.Remove, action: VENDOR_CONFIG_DELETE, requiredPermission: 'Configuration-Vendors-Delete' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-Vendors-View' },
      { name: RoutingAction.Revert, action: VENDOR_CONFIG_SAVE, requiredPermission: 'Configuration-Vendors-Edit' },
      { name: RoutingAction.RemovedItems, route: 'RemovedVendorConfig-Index', requiredPermission: 'Global-HardDelete' },
    ];
  }

  get environment() {
    return process.env.VUE_APP_ENVIRONMENT;
  }

  get defaultBookedRepairVolumeFilterConfig() {
    const bookedRepairVolumeFilterConfig: BookedRepairVolumeFilterConfig = {
      lookBackWindowHours: null,
      bookedRepairLimit: null,
    };
    return bookedRepairVolumeFilterConfig;
  }

  private getServiceProviderConfigsByCategory(serviceProviderConfigs: ServiceProviderConfig[], category: string) {
    serviceProviderConfigs.forEach((sp: ServiceProviderConfig) => {
      const extendedSp = sp as ServiceProviderConfigExtended;
      extendedSp._showDetails = true;
    });

    return serviceProviderConfigs.filter((sp: ServiceProviderConfig) => sp.category === category);
  }

  private hasAncillaryCategory(serviceProviderConfigs: ServiceProviderConfig[]) {
    return serviceProviderConfigs.some((sp: ServiceProviderConfig) => sp.category === Enums.OrderCategory.Ancillary);
  }

  private hasPrimaryCategory(serviceProviderConfigs: ServiceProviderConfig[]) {
    return serviceProviderConfigs.some((sp: ServiceProviderConfig) => sp.category === Enums.OrderCategory.Primary);
  }

  private hasServiceProviderConfigs(serviceProviderConfigs: ServiceProviderConfig[]) {
    return serviceProviderConfigs !== undefined && serviceProviderConfigs.length > 0;
  }
}

import { RouteConfig } from 'vue-router';
import FulfillmentOptionConfigIndex from '@/views/configuration/FulfillmentOptions/FulfillmentOptionConfigIndex.vue';
import FulfillmentOptionConfigDetail from '@/views/configuration/FulfillmentOptions/FulfillmentOptionConfigDetail.vue';
import FulfillmentOptionConfigCreate from '@/views/configuration/FulfillmentOptions/FulfillmentOptionConfigCreate.vue';
import FulfillmentOptionConfigEdit from '@/views/configuration/FulfillmentOptions/FulfillmentOptionConfigEdit.vue';

const routes = [
  {
    path: 'fulfillment-options',
    name: 'FulfillmentOptions',
    meta: {
      title: 'Fulfillment Option Config',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-FulfillmentOptions-View'],
    },
    component: FulfillmentOptionConfigIndex,
  },
  {
    path: 'fulfillment-options/create',
    name: 'FulfillmentOptionConfig-Create',
    props: true,
    meta: {
      title: 'Fulfillment Option Config Create',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-FulfillmentOptions-Create'],
    },
    component: FulfillmentOptionConfigCreate,
  },
  {
    path: 'fulfillment-options/:id',
    name: 'FulfillmentOptionConfig-Detail',
    props: true,
    meta: {
      title: 'Fulfillment Option Config Detail',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-FulfillmentOptions-View'],
    },
    component: FulfillmentOptionConfigDetail,
  },
  {
    path: 'fulfillment-options/:id/edit',
    name: 'FulfillmentOptionConfig-Edit',
    props: true,
    meta: {
      title: 'Fulfillment Option Config Edit',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-FulfillmentOptions-Edit'],
    },
    component: FulfillmentOptionConfigEdit,
  },
] as RouteConfig[];

export default routes;

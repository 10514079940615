
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import EquipmentExclusionConfigCreateEdit from '@/components/configuration/EquipmentExclusionConfigCreateEdit.vue';
import { FETCH_EQUIPMENT_EXCLUSION_CONFIG, CREATE_EQUIPMENT_EXCLUSION_CONFIG, FETCH_PROGRAM_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { EquipmentExclusionConfig } from '@/store/dynamicfulfillment/types';
import { Action } from 'vuex-class';
import { Guid } from '@/common/utilities';

@Component({ components: { ConfigEditComponent, EquipmentExclusionConfigCreateEdit } })
export default class EquipmentExclusionConfigCreate extends Vue {
  @Action('dynamicfulfillmentmodule/' + FETCH_PROGRAM_CONFIGS) fetchProgramConfigs: any;
  editedConfig: any = {};
  isValid: boolean = false;
  defaultConfig: EquipmentExclusionConfig = {
    name: null,
    fulfillmentOption: null,
    programIds: [],
    items: [],
    id: Guid.MakeNew().ToString(),
    _etag: null,
  };

  private mounted() {
    this.fetchProgramConfigs();
  }

  get fetchAction() {
    return 'dynamicfulfillmentmodule/' + FETCH_EQUIPMENT_EXCLUSION_CONFIG;
  }

  get saveAction() {
    return 'dynamicfulfillmentmodule/' + CREATE_EQUIPMENT_EXCLUSION_CONFIG;
  }

  onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.editedConfig = request.config;
    }
  }
}


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import RolesCreateEdit from '@/components/configuration/RolesCreateEdit.vue';
import { RoleConfig } from '@/store/dynamicfulfillment/types';
import { FETCH_ROLE, CREATE_ROLE } from '@/store/dynamicfulfillment/constants';

@Component({ components: { ConfigEditComponent, RolesCreateEdit } })
export default class RolesCreate extends Vue {
  defaultConfig: RoleConfig = {
    id: null,
    description: null,
    _etag: null,
    permissions: [],
  };

  get fetchAction() {
    return 'dynamicfulfillmentmodule/' + FETCH_ROLE;
  }

  get saveAction() {
    return 'dynamicfulfillmentmodule/' + CREATE_ROLE;
  }

  get indexRoute() {
    return 'Roles-Index';
  }
}


  import Vue from 'vue';
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import { Action } from 'vuex-class';
  import {
    FulfillmentOption,
    ServiceNetworkFulfillmentOptionTypeConfig,
    ServiceProviderConfig,
    VendorConfig,
    VendorFulfillmentOptionConfig,
  } from '@/store/dynamicfulfillment/types';
  import CustomAttributesCreateEditComponent from './CustomAttributesCreateEditComponent.vue';
  import { FETCH_VENDOR_CONFIGS } from '@/store/dynamicfulfillment/constants';
  import { AllowedAttribute } from '@/store/globalmodules/types';
  const namespace: string = 'dynamicfulfillmentmodule';
  
  @Component({ components: { CustomAttributesCreateEditComponent } })
  export default class ServiceNetworkFulfillmentOptionTypeConfigCreateEdit extends Vue {
    @Prop() private config: ServiceNetworkFulfillmentOptionTypeConfig;
    @Action(FETCH_VENDOR_CONFIGS, { namespace }) fetchVendorConfigs: () => VendorConfig[]; vendorConfigs: VendorConfig[] = [];
    attributeState: boolean = false;
    
    async mounted() {
      this.vendorConfigs = await this.fetchVendorConfigs();
    }

    private get isNew() {
      return this.config._etag === null;
    }
  
    get serviceNetworkFulfillmentOptionTypeIdState() {
        return this.config.fulfillmentOption != null && this.config.fulfillmentOption != FulfillmentOption.None;
    }

    get allowedFulfillmentOptions() {
        return Object.entries(FulfillmentOption)
            .filter((e: any) => !isNaN(e[0] as any))
            .map((e: any) => e[0]);
    }

    fulfillmentOptionLabel(option: number) {
        return FulfillmentOption[option];
    }
  
    async created() {
      this.config.isEdit = this.isNew ? false : true;
    }  
  
    customAttributesChanged(content: {
      config: ServiceNetworkFulfillmentOptionTypeConfig | null;
      isValid: boolean;
    }) {
      this.attributeState = content.isValid;
      this.validateConfig();
    }

    validateConfig() {
        this.$emit('onConfigChange', {
            config: this.config,
            isValid: this.attributeState && this.serviceNetworkFulfillmentOptionTypeIdState
        });
    }

    deleteValidationFunc(item: AllowedAttribute) {
      let usedConfig: ServiceProviderConfig | null = null;

      this.vendorConfigs.some(vendorConfig => 
        vendorConfig.serviceProviderConfigs.some(serviceProviderConfig => 
          serviceProviderConfig.fulfillmentOptionConfigs.some(fulfillmentOptionConfig => {
            if (fulfillmentOptionConfig.attributes) {
              const foundAttribute = Object.keys(fulfillmentOptionConfig.attributes).includes(item.key);
              if (foundAttribute) {
                usedConfig = serviceProviderConfig;
              }
            }
          })
        )
      );

      if (usedConfig) {
              const h = this.$createElement;
              const messageVNode = 
              h('div', [
                  h('p', [
                    'Key: ',
                    h('strong', `${item.key} `),
                    'is currently in use by ServiceProviderConfig: ',
                    h('strong', `${usedConfig.name} `),
                    'and cannot be deleted.'
                  ])
              ]);
        return { 
          isValid: false,
          message: [messageVNode]
        };
      }

      return { isValid: true };
    }

    @Watch('config.fulfillmentOption', { immediate: false, deep: true })
    private fulfillmentOptionChanged() {
        this.config.id = FulfillmentOption[this.config.fulfillmentOption];
        this.validateConfig();
    }
  }
  

import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { UPDATE_APPCONFIG } from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({})
export default class BrandMappingConfigEdit extends Vue {
  @Prop({ required: true }) brandMappingConfig: any;
  @Action(UPDATE_APPCONFIG, { namespace }) private updateAppConfig: any;

  private isLoading: boolean = false;

  private displayErrors(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit(
      'e',
      `There was a problem in processing the request. ${message}`,
    );
  }

  private get configState() {
    return (
      this.brandMappingConfig.servicePowerCode != null ||
      this.brandMappingConfig.servicePowerCode === ''
    );
  }

  private checkFormValidity(): boolean {
    this.isLoading = true;
    return this.configState;
  }

  public async handleSubmit() {
    if (!this.checkFormValidity()) {
      return;
    }
    const sentinelKey = 'ServicePowerApi:Sentinel';
    this.updateAppConfig({
      key: this.brandMappingConfig.key,
      value: this.brandMappingConfig.servicePowerCode,
    })
      .then((response: any) => {
        this.updateAppConfig({
          key: sentinelKey,
          value: Date.now(),
        });
        this.isLoading = false;
        EventBus.$emit(
          's',
          'Service Power Brand Mapping updated successfully.',
        );
        this.$router.push({ name: 'ServicePowerBrandMapping-Index' });
      })
      .catch((error: any) => {
        const message = buildErrorMessage(error);
        EventBus.$emit('e', message);
        this.isLoading = false;
        return;
      });
  }
}

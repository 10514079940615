import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, InventoryState } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const InventoryApiActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_INVENTORY_STATES](context) {
    let inventoryStatePage = context.state.inventoryStatePage;
    if (inventoryStatePage && inventoryStatePage.pagingToken) {
      const inventoryStatePageResponse = await authorizationApiProvider.getInventoryStates(inventoryStatePage.pagingToken);
      inventoryStatePage.inventoryStates.push(...inventoryStatePageResponse.inventoryStates);
      inventoryStatePage.pagingToken = inventoryStatePageResponse.pagingToken;
    } else if (inventoryStatePage.inventoryStates && inventoryStatePage.inventoryStates.length > 0) {
      return inventoryStatePage.inventoryStates; // reached end of the page. Do not make the api call.
    } else {
      inventoryStatePage = await authorizationApiProvider.getInventoryStates(null);
    }
    context.commit(apis.SET_INVENTORY_STATES_PAGING_DATA, inventoryStatePage);
    return inventoryStatePage.inventoryStates;
  },

  async [apis.REFRESH_INVENTORY_STATES](context) {
    context.commit(apis.RESET_INVENTORY_STATES);
  },

  async [apis.FETCH_INVENTORY_STATE](context, id: string) {
    let inventoryState = context.state.inventoryStatePage?.inventoryStates?.find((c) => c.id === id);
    if (!inventoryState) {
      inventoryState = await authorizationApiProvider.getInventoryState(id);
    }
    return inventoryState;
  },

  async [apis.SAVE_INVENTORY_STATE](context, inventoryState: InventoryState) {
    await authorizationApiProvider.upsertInventoryState(inventoryState).then(() => context.commit(apis.ADD_INVENTORY_STATE, inventoryState));
  },

  async [apis.UPDATE_INVENTORY_STATE](context, inventoryState: InventoryState) {
    await authorizationApiProvider.upsertInventoryState(inventoryState).then(() => context.commit(apis.UPDATE_INVENTORY_STATE, inventoryState));
  },

  async [apis.SET_INVENTORY_STATE](context, inventoryState: InventoryState) {
    context.commit(apis.SET_INVENTORY_STATE, inventoryState);
  },

  async [apis.DELETE_INVENTORY_STATE](context, params: { inventory: InventoryState }) {
    await authorizationApiProvider.deleteInventoryState(params.inventory.id).then(() => context.commit(apis.DELETE_INVENTORY_STATE, params.inventory.id));
  },

  async [apis.FETCH_INVENTORY_PARTS_MAPPING_STATE](context) {
    return await authorizationApiProvider.getInventoryPartsStatesMapping();
  },

  async [apis.FETCH_JUSTINTIME_INVENTORY_PARTS_MAPPING_STATE](context) {
    return await authorizationApiProvider.getJustInTimeInventoryPartsStatesMapping();
  },
};

export default InventoryApiActions;

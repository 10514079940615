
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue';
import {
  FETCH_SERVICE_MANUFACTURER,
  UPDATE_SERVICE_MANUFACTURER,
} from '@/store/globalmodules/constants';
import { ServiceManufacturer } from '@/store/globalmodules/types';
const namespace: string = 'globalmodule';

@Component({ components: { ViewContent, PerLineTextAreaControl } })
export default class ServiceManufacturersEdit extends Vue {
  @Prop() private id!: string;
  @Action(FETCH_SERVICE_MANUFACTURER, { namespace })
  private fetchServiceManufacturer: any;
  @Action(UPDATE_SERVICE_MANUFACTURER, { namespace })
  private updateServiceManufacturer: any;

  private config: ServiceManufacturer = null;
  private isLoading: boolean = true;
  private newAllowedValues: string[] = [];

  private async mounted() {
    const fetchedServiceManufacturer = await this.fetchServiceManufacturer(
      this.id,
    );
    if (fetchedServiceManufacturer) {
      this.config = fetchedServiceManufacturer;
    }
    this.isLoading = false;
  }

  private updateAllowedValues(allowedValues: string[]) {
    this.config.allowedValues = allowedValues;
  }

  private updateNewAllowedValues(newValues: string[]) {
    this.newAllowedValues = [];
    const intersection = this.config.allowedValues.filter((val: any) =>
      newValues.includes(val),
    );
    if (intersection.length > 0) {
      EventBus.$emit(
        'e',
        `<h4>Validation error(s) occured.</h4> Remove duplicate(s): ${intersection}`,
      );
    } else {
      this.newAllowedValues.push(...newValues);
    }
  }

  private get pageTitle() {
    return this.config.id;
  }
  private get isNew() {
    return this.config.eTag === null;
  }

  private formatter(value: string) {
    return value.replace(',', '').replace("'", '');
  }

  private get displayNameState() {
    return this.config.displayName != null || this.config.displayName === '';
  }

  private get configState() {
    return this.displayNameState;
  }

  private checkFormValidity(): boolean {
    this.isLoading = true;
    return this.configState;
  }

  public async handleSubmit() {
    if (!this.checkFormValidity()) {
      return;
    }
    const configCopy = JSON.parse(JSON.stringify(this.config));
    configCopy.allowedValues.push(...this.newAllowedValues);

    this.updateServiceManufacturer(configCopy)
      .then((response: any) => {
        this.isLoading = false;
        EventBus.$emit('s', 'Service Manufacturer updated successfully.');
        this.$router.push({ name: 'ServiceManufacturers-Index' });
      })
      .catch((error: any) => {
        const message = buildErrorMessage(error);
        EventBus.$emit('e', message);
        this.isLoading = false;
        return;
      });
  }
}


import ViewContent from '@/components/ViewContent.vue';
import { Component } from 'vue-property-decorator';
import RemovedConfigIndexComponent from '@/components/configuration/RemovedConfigIndexComponent.vue';
import { locationManagementApiProvider } from '@/common/locationManagement.api';
import { ServiceCenterLocationConfig } from '@/store/globalmodules/types';
import DataTable from '@/components/common/DataTable.vue';
import ServiceAreaModal from '@/components/ServiceCenters/ServiceAreaModal.vue';
import ViewServiceCenterLocations from './ViewServiceCenterLocations.vue';
import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';

@Component({
  components: { ViewContent, RemovedConfigIndexComponent, DataTable, ServiceAreaModal },
})
export default class RemovedServiceCenterLocations extends ViewServiceCenterLocations {
  private configItems: ServiceCenterLocationConfig[] = null;
  private removedTitle: string = 'Removed Service Center Locations';
  private indexRoute: string = 'ViewServiceCenterLocations';

  async created() {
    await this.getLocations();
  }

  async getLocations() {
    var locations = await locationManagementApiProvider.getServiceCenterLocations(true);
    if (this.isQueryParamExist()) {
      this.configItems = locations.filter((l) => l.serviceNetwork === this.$route.query.serviceNetwork);
    } else {
      this.configItems = locations;
    }
  }

  private async deleteLocation(item: any) {
    this.$bvModal
      .msgBoxConfirm('Are you sure? This cannot be undone.', {
        title: `Delete ${item.serviceNetwork}-${item.storeId}`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          const request = { serviceNetwork: item.serviceNetwork, storeId: item.storeId };
          await locationManagementApiProvider
            .deleteServiceCenter(request, true)
            .then(async (response: any) => {
              EventBus.$emit('s', `${request.serviceNetwork}-${request.storeId} deleted successfully`);
              await this.getLocations();
            })
            .catch((error: any) => {
              this.displayError(error);
            });
        }
      })
      .catch((err) => {
        this.displayError(err);
      });
  }
}

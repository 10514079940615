import { RouteConfig } from 'vue-router';
import PriceListConfigIndex from '@/views/configuration/PriceLists/PriceListConfigIndex.vue';
import PriceListConfigCreateEdit from '@/views/configuration/PriceLists/PriceListConfigCreateEdit.vue';
import PriceListConfigItemsUpload from '@/views/configuration/PriceLists/PriceListConfigItemsUpload.vue';
import PriceListConfigItemsIndex from '@/views/configuration/PriceLists/PriceListConfigItemsIndex.vue';
const routes = [
  {
    path: 'price-lists',
    name: 'PriceListConfig-Index',
    meta: {
      title: 'Price Lists',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-View'],
    },
    component: PriceListConfigIndex,
  },
  {
    path: 'price-lists/items/upload',
    name: 'PriceListConfig-Items-Upload',
    props: true,
    meta: {
      title: 'Price Lists Items Upload',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-UploadItems'],
    },
    component: PriceListConfigItemsUpload,
  },
  {
    path: '/price-lists/:id/edit',
    name: 'PriceListConfig-Edit',
    props: true,
    meta: {
      title: 'Price List Config Edit',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-Edit'],
    },
    component: PriceListConfigCreateEdit,
  },
  {
    path: 'price-lists/create',
    name: 'PriceListConfig-Create',
    props: true,
    meta: {
      title: 'Price List Config Create',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-Create'],
    },
    component: PriceListConfigCreateEdit,
  },
  {
    path: '/price-lists/:id/items',
    name: 'PriceListConfig-Items-Index',
    props: true,
    meta: {
      title: 'Price List Items',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-View'],
    },
    component: PriceListConfigItemsIndex,
  },
] as RouteConfig[];

export default routes;


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  FETCH_AUTH_TRACES,
  FETCH_AUTH_REQUESTS,
} from '@/store/dynamicfulfillment/constants';
import DataTable from '@/components/common/DataTable.vue';
import { Action } from 'vuex-class';
import { DateTime } from 'luxon';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable } })
export default class FulfillmentAuthorizationRequestHistory extends Vue {
  @Prop() private authorizationId!: string;
  @Action(FETCH_AUTH_REQUESTS, { namespace }) private fetchAuthRequests: any;
  private fields = [
    {
      key: 'timestamp',
      sortable: true,
      thStyle: { width: '150px' },
    },
    {
      key: 'success',
      sortable: true,
    },
    {
      key: 'resultCode',
      sortable: true,
    },
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'url',
      sortable: true,
    },
    {
      key: 'duration',
      sortable: true,
    },
  ];
  private items: any[] = null;

  public async mounted() {
    this.items = await this.fetchAuthRequests(this.authorizationId);
  }

  private badgeVariant(success: boolean) {
    return success === true ? 'success' : 'danger';
  }

  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}

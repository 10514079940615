var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ViewContent',{attrs:{"page-title":"Legacy Claims"},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Legacy Claims")]),(_vm.claimId)?_c('li',{staticClass:"breadcrumb-item active"},[_c('strong',[_vm._v(_vm._s(_vm.claimId))])]):_vm._e()]},proxy:true},{key:"content",fn:function(){return [(_vm.claimData)?_c('section',[_c('b-table',{attrs:{"items":_vm.legacyClaimData,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(claimNumber)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(authorizationNumber)",fn:function(data){return [_c('router-link',{attrs:{"to":{
              name: 'LegacyClaim-Details',
              params: {
                claimNumber: _vm.claimId,
                claimAuthorizationNumber: data.value,
              },
            }}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(repairStatus)",fn:function(data){return [_c('LegacyClaimStatusLabel',{attrs:{"status":data.value}})]}},{key:"cell(authorizationDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDateTime(data.value))+" ")]}}],null,false,935324276)})],1):_c('loading')]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
import { distinct } from "./functions.helpers";

export const numberValidator = (value: any, field: any, model: any) => { 
  if (value === null || value === undefined) {
    return [];
  }
  if (/\r|\n/.exec(value)){
    var invalidValues = value.split('\n').filter( (v: any) => isNaN(v));
    return invalidValues && invalidValues.length > 0 ? ['Invalid number']: [];
  } else if (Array.isArray(value) && value.length > 0) {
    let invalidValues = value.filter( (v: any) => isNaN(v));
    return invalidValues && invalidValues.length > 0 ? ['Invalid number']: [];
  } 
  return [];
}

export const uniqueValueValidator = (value: any, field: any, model: any) => {
  if (Array.isArray(value) && value.length > 0) {
    const duplicateValues = value.filter(distinct);
    return value.filter(distinct).length !== value.length ? ['Contains duplicates'] : [];
  } else if (!Array.isArray(value) && value.includes('\n') && value.split('\n').length > 0) {
    const values = value.split('\n');
    return values.filter(distinct).length !== values.length ? ['Contains duplicates'] : [];
  }
  return [];
}


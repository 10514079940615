
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import DataTable from '@/components/common/DataTable.vue';
import { Action } from 'vuex-class';
import { FETCH_AI_EXCEPTIONS } from '@/store/dynamicfulfillment/constants';
import SeverityLevel from '@/components/SeverityLevel.vue';
import { DateTime } from 'luxon';

const namespace: string = 'dynamicfulfillmentmodule';
@Component({components: {DataTable, SeverityLevel}})
export default class AppInsightsExceptions extends Vue {
    @Prop({default: false}) private isReload: boolean;
    @Prop() private isFullScreen: boolean;
    private pageSize: number = 5;
    @Action(FETCH_AI_EXCEPTIONS, { namespace }) private fetchExceptions: any;
    private fields = [
        {
            key: 'timeStamp',
            sortable: true,
            thStyle: { width: '150px' },
        },
        {
            key: 'severityLevel',
            sortable: true,
        },
        {
            key: 'message',
            sortable: true,
        },
        {
            key: 'exceptionType',
            sortable: true,
        },
        {
            key: 'innermostType',
            sortable: true,
            label: 'Inner Most Type',
        },
        {
            key: 'innermostMessage',
            sortable: true,
            label: 'Inner Most Message',
        },
        {
            key: 'details',
            label: 'Details',
            formatter: (value: any, key: any, item: any) => {
                if (item && item.customDimensions) {
                    return item.customDimensions;
                }
            },
        },
        'action',
    ];
    private exceptions: any = null;
    private kvExceptions: { [key: string]: string } = {};
    private infoModal: any = {
        id: 'info-modal',
        title: '',
        content: '',
    };

    private info(item: any) {
        const url = `https://portal.azure.com/#blade/AppInsightsExtension/DetailsV2Blade/DataModel/%7B%22eventId%22:%22${item.id}%22,%22timestamp%22:%22${item.timeStamp}%22%7D/ComponentId/%7B%22Name%22:%22${process.env.VUE_APP_APPINSIGHTS_NAME}%22,%22ResourceGroup%22:%22${process.env.VUE_APP_RESOURCE_GROUP}%22,%22SubscriptionId%22:%22${process.env.VUE_APP_SUBSCRIPTION_ID}%22%7D`;
        window.open(url, '_blank');
    }

    private resetInfoModal() {
        this.infoModal.title = '';
        this.infoModal.content = '';
    }

    private mounted() {
        this.getExceptions();
    }

    @Watch('isReload', {immediate: false})
    private refresh() {
        this.getExceptions();
    }

    @Watch('isFullScreen', {immediate: false})
    private onFullScreen() {
        this.pageSize = (this.isFullScreen) ? 20 : 5;
    }

    private async getExceptions() {
        this.exceptions = await this.fetchExceptions(500);
        this.$emit('onReloadCompleted');
    }

    private formatDateTime(value: any) {
        return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
    }
}

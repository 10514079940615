
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import axios from 'axios';
import EventBus from '@/common/event.bus';
import {
  FETCH_MIGRATION_MATRIX_CLIENTS,
} from '@/store/globalmodules/constants';
import { GlobalModuleState, MigrationMatrixSettingData } from '@/store/globalmodules/types';
import { Action, State } from 'vuex-class';
import { DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
const namespace: string = 'globalmodule';

@Component
export default class MigrationMatrixUploadForm extends Vue {
    private file: File = null;
    private selectedClient: string = '';
    private clientNameValid: boolean = true;
    private selectedClientName: string = '';
    private eventData: any  = {};
    @Action(FETCH_MIGRATION_MATRIX_CLIENTS, { namespace }) private fetchMigrationMatrixClients: any;
    @State(namespace) private profile!: GlobalModuleState;
    @State('dynamicfulfillmentmodule') private dfProfile!: DynamicFulfillmentState;
    private async created() {
        await this.fetchMigrationMatrixClients();
        const clientElement = this.$refs.migrationMatrixClient as HTMLSelectElement;
        clientElement.focus();
    }

    private formatToastMessage(error: string) {
        return `<div><strong>${error}</strong><br/>`;
    }

    private formatFileName(files: File[]) {
        const fileName = files[0].name;
        return fileName.length > 50 ? [fileName.substring(0, 50), '...'].join() : fileName;
    }

    private get migrationMatrixClients() {
        return this.profile.migrationMatrixClients;
    }

    private get invalidClientNameFeedback() {
        return 'File name should begin with ' + this.selectedClient;
    }

    private async handleSubmit() {
        if (!this.selectedClient || !this.clientNameValid) {
            return;
        }

        await this.upload();
        this.resetForm();
    }

    private validation() {
        if (this.selectedClient && this.file) {
            this.clientNameValid = this.file.name.split('_')[0].toLowerCase() === this.selectedClient.toLowerCase();
        }
        return this.clientNameValid;
    }

    private resetForm() {
        this.selectedClient = '';
        this.file = null;
    }

    private async upload() {
        this.eventData = {
            clientId: this.migrationMatrixClients.filter((client: MigrationMatrixSettingData) =>
                client.clientName === this.selectedClient)[0].clientId,
            file: this.file,
            modifiedBy: this.dfProfile.userClaims.name };
        this.$emit('process-upload', this.eventData);
    }
}


import Vue from 'vue';
import { Action, State } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { SourceSystemClaimData, SourceSystemIdentifier, SourceSystem, DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import { FETCH_SOURCE_SYSTEM_MOCK_CLAIM_DATA, UPDATE_SOURCE_SYSTEM_MOCK_CLAIM_DATA, DELETE_SOURCE_SYSTEM_MOCK_CLAIM_DATA } from '@/store/dynamicfulfillment/constants';
import { jsonToText } from '@/common/functions.helpers';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { ValidationError, ValidationSeverity } from 'vanilla-jsoneditor';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import EventBus from '@/common/event.bus';
import vueJsonCompare from 'vue-json-compare';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { VueJSONEditor, vueJsonCompare } })
export default class MockClaimDataComponent extends Vue {
  @Prop() sourceSystemIdentifier: SourceSystemIdentifier;
  @Prop() sourceSystemClaimData: any;
  @State(namespace) private profile: DynamicFulfillmentState;
  @Action(FETCH_SOURCE_SYSTEM_MOCK_CLAIM_DATA, { namespace }) private fetchMockSourceSystemClaim: (sourceSystemIdentifier: SourceSystemIdentifier) => Promise<SourceSystemClaimData>;
  @Action(UPDATE_SOURCE_SYSTEM_MOCK_CLAIM_DATA, { namespace }) private updateMockSourceSystemClaim: (sourceSystemClaimData: SourceSystemClaimData) => Promise<SourceSystemClaimData>;
  @Action(DELETE_SOURCE_SYSTEM_MOCK_CLAIM_DATA, { namespace }) private deleteMockSourceSystemClaim: (sourceSystemIdentifier: SourceSystemIdentifier) => Promise<void>;

  title: string = 'Mock Source System Claim Data';
  jsonEditorText: any = jsonToText;
  mockSourceSystemClaimData: any = null;
  validationErrors: ValidationError[] = [];
  editorKey: number = 0;
  userAuthorizationProvider: UserAuthorizationProvider;

  async created() {
    await this.getClaimData();
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
  }

  get validMockData(): boolean {
    return this.validationErrors.length === 0;
  }

  contentToString(content: any): string {
    return JSON.stringify(content, null, 2);
  }

  async getClaimData() {
    try {
      const mockClaim = await this.fetchMockSourceSystemClaim(this.sourceSystemIdentifier);
      if (mockClaim) {
        this.mockSourceSystemClaimData = JSON.parse(mockClaim.data);
        this.editorKey++;
      }
    } catch (error: any) {
      EventBus.$emit('e', `Fetch Mock Source System Claim failed. ${error}`);
    }
  }

  onDataChanged(event: any) {
    if (event.text === null || event.text.trim() === '') {
      this.mockSourceSystemClaimData = null;
      return;
    }

    this.validateAndParseData(event.text);
    if (this.validMockData) {
      this.mockSourceSystemClaimData = JSON.parse(event.text);
    }
  }

  validateAndParseData(data: any): ValidationError[] {
    this.validationErrors = [];
    let value = typeof data !== 'string' ? JSON.stringify(data) : data;

    try {
      JSON.parse(value);
    } catch (error) {
      this.validationErrors.push({
        message: 'Invalid JSON format',
        severity: ValidationSeverity.error,
        path: [],
      });
    }

    return this.validationErrors;
  }

  compare() {
    this.$bvModal.show('compareClaimData');
  }

  save() {
    if (this.mockSourceSystemClaimData) {
      const sourceSystemClaimData: SourceSystemClaimData = {
        claimDateTime: new Date(),
        sourceSystem: SourceSystem[this.sourceSystemIdentifier.sourceSystem as keyof typeof SourceSystem],
        claimNumber: this.sourceSystemIdentifier.claimNumber,
        data: JSON.stringify(this.mockSourceSystemClaimData),
      };
      this.updateMockSourceSystemClaim(sourceSystemClaimData)
        .then(() => {
          EventBus.$emit('s', `Mock claim data saved successfully.`);
        })
        .catch((error: any) => {
          EventBus.$emit('e', `Error saving mock claim data. ${error}`);
        });
    } else {
      this.deleteMockClaimData();
    }
  }

  deleteMockClaimData() {
    this.$bvModal
      .msgBoxConfirm('Are you sure? This cannot be undone.', {
        title: ``,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        console.log(value);
        if (value) {
          this.deleteMockSourceSystemClaim(this.sourceSystemIdentifier)
            .then(() => {
              this.mockSourceSystemClaimData = null;
              this.editorKey++;
              EventBus.$emit('s', `Mock claim data deleted successfully.`);
            })
            .catch((error: any) => {
              EventBus.$emit('e', `Error deleting mock claim data. ${error}`);
            });
        }
      })
      .catch((err) => {
        EventBus.$emit('e', `Error deleting mock claim data. ${err}`);
      });
  }

  hasPermission(features: string[]) {
    if (!this.userAuthorizationProvider) {
      this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    }
    return this.userAuthorizationProvider.hasPermission(features);
  }
}

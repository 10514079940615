
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DynamicFulfillmentState, SourceSystemConfig, DamageType, Peril, EquipmentType } from '@/store/dynamicfulfillment/types';
import { FETCH_SOURCE_SYSTEM_CONFIG } from '@/store/dynamicfulfillment/constants';
import SourceSystemConfigDetailsItemsCreateEdit from '@/views/configuration/SourceSystems/SourceSystemConfigDetailsItemsCreateEdit.vue';
import { Action, State } from 'vuex-class';
import { humanizeString } from '@/common/utilities';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { SourceSystemConfigDetailsItemsCreateEdit } })
export default class SourceSystemConfigDetailsCreateEdit extends Vue {
  @Prop() private id!: string;
  @Prop() private sourceSystem!: string;
  @Prop() private items: any;
  @Action(FETCH_SOURCE_SYSTEM_CONFIG, { namespace })
  private fetchSourceSystemConfig: any;
  @State(namespace) private profile!: DynamicFulfillmentState;
  private sourceSystemConfig: SourceSystemConfig = null;
  private configItems: any = null;
  private isStateChanged: boolean = false;

  private async created() {
    if (this.items) {
      this.configItems = this.items;
    } else {
      this.sourceSystemConfig = await this.fetchSourceSystemConfig(this.sourceSystem);

      let tabData: {
        key: string;
        label: string;
        fields?: any[];
        value: any;
      }[] = [];
      if (this.sourceSystemConfig) {
        const sourceSystemConfig = JSON.parse(JSON.stringify(this.sourceSystemConfig));

        for (const key in sourceSystemConfig) {
          if (sourceSystemConfig.hasOwnProperty(key)) {
            const value = (sourceSystemConfig as any)[key];

            if (typeof value != 'string' && typeof value === 'object' && value !== null) {
              if (value instanceof Array && value.length > 0) {
                tabData.push({
                  key: key,
                  label: humanizeString(key),
                  value: value,
                });
              } else {
                const keyValuePairArray: { key: string; value: string }[] = [];
                for (const subKey in value) {
                  if (value.hasOwnProperty(subKey)) {
                    let val = value[subKey];

                    switch (key) {
                      case 'damageTypes':
                        val = DamageType[value[subKey]];
                        break;
                      case 'perils':
                        val = Peril[value[subKey]];
                        break;
                      case 'equipmentTypes':
                        val = EquipmentType[value[subKey]];
                        break;
                      default:
                        break;
                    }

                    keyValuePairArray.push({ key: subKey, value: val });
                  }
                }

                tabData.push({
                  key: key,
                  label: humanizeString(key),
                  fields: ['key', 'value'],
                  value: keyValuePairArray,
                });
              }
            }
          }
        }
      }

      this.configItems = tabData.find((i) => i.key === this.id);
    }
  }

  private get indexRoute() {
    return {
      name: 'SourceSystemConfig-Details',
      params: {
        id: this.sourceSystem,
      },
    };
  }

  private stateUpdated() {
    this.isStateChanged = true;
  }
}

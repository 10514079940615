import { RouteConfig } from 'vue-router';
import InventoryStatesIndex from '@/views/configuration/Inventory/InventoryStatesIndex.vue';
import InventoryStatesEdit from '@/views/configuration/Inventory/InventoryStatesEdit.vue';
import InventoryStatesCreate from '@/views/configuration/Inventory/InventoryStatesCreate.vue';
import InventoryStatesUpload from '@/views/configuration/Inventory/InventoryStatesUpload.vue';
import InventoryMapIndex from '@/views/configuration/Inventory/Map/InventoryMapIndex.vue';
import InventoryMapUpload from '@/views/configuration/Inventory/Map/InventoryMapUpload.vue';
import JustInTimeInventoryMapIndex from '@/views/configuration/Inventory/JustInTime/JustInTimeInventoryMapIndex.vue';
import JustInTimeInventoryMapUpload from '@/views/configuration/Inventory/JustInTime/JustInTimeInventoryMapUpload.vue';

const routes = [
  {
    path: '/inventoryStates',
    name: 'InventoryStates-Index',
    props: true,
    component: InventoryStatesIndex,
    meta: {
      title: 'Inventory States',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-InventoryStates-View'],
    },
  },
  {
    path: '/inventoryStates/:id/edit',
    name: 'InventoryStates-Edit',
    props: true,
    component: InventoryStatesEdit,
    meta: {
      title: 'Inventory States Edit',
      requiresAuth: true,
      validateRoleMembership: true,
      authorizedPermissions: ['Configuration-InventoryStates-Edit'],
    },
  },
  {
    path: '/inventoryStatesCreate',
    name: 'InventoryStates-Create',
    props: true,
    component: InventoryStatesCreate,
    meta: {
      title: 'Inventory States Create',
      requiresAuth: true,
      validateRoleMembership: true,
      authorizedPermissions: ['Configuration-InventoryStates-Create'],
    },
  },
  {
    path: '/inventoryStatesUpload',
    name: 'InventoryStates-Upload',
    props: true,
    component: InventoryStatesUpload,
    meta: {
      title: 'Inventory States Upload',
      requiresAuth: true,
      validateRoleMembership: true,
      authorizedPermissions: ['Configuration-InventoryStates-Upload'],
    },
  },
  {
    path: '/inventoryPartsStatesMapping',
    name: 'InventoryPartsStatesMapping-Index',
    props: true,
    component: InventoryMapIndex,
    meta: {
      title: 'Inventory Parts States',
      requiresAuth: true,
      validateRoleMembership: true,
      authorizedPermissions: ['Configuration-InventoryPartsMapping-View'],
    },
  },
  {
    path: '/inventoryPartsStatesMappingUpload',
    name: 'InventoryPartsMappingStates-Upload',
    props: true,
    component: InventoryMapUpload,
    meta: {
      title: 'Inventory Parts States Upload',
      requiresAuth: true,
      validateRoleMembership: true,
      authorizedPermissions: ['Configuration-InventoryPartsMapping-Upload'],
    },
  },
  {
    path: '/justInTimeInventoryPartsStatesMapping',
    name: 'JustInTimeInventoryPartsStatesMapping-Index',
    props: true,
    component: JustInTimeInventoryMapIndex,
    meta: {
      title: 'JustInTime Inventory Parts States',
      requiresAuth: true,
      validateRoleMembership: true,
      authorizedPermissions: ['Configuration-JustInTimeInventoryPartsMapping-View'],
    },
  },
  {
    path: '/justInTimeInventoryPartsStatesMappingUpload',
    name: 'JustInTimeInventoryPartsMappingStates-Upload',
    props: true,
    component: JustInTimeInventoryMapUpload,
    meta: {
      title: 'JustInTime Inventory Parts States Upload',
      requiresAuth: true,
      validateRoleMembership: true,
      authorizedPermissions: ['Configuration-JustInTimeInventoryPartsMapping-Upload'],
    },
  },
] as RouteConfig[];

export default routes;


import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import UploadComponent from '@/components/common/UploadComponent.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { CLEAR_CACHE } from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';
@Component({
    components: { ViewContent, UploadComponent },
})
export default class VendorTicketStatusMappingUpload extends Vue {
    @Prop() apiRoute: string;
    @Action(CLEAR_CACHE, { namespace }) clearCacheForAllKeys: () => void;

    resetForm: boolean = false;    

    get pageTitle(){
        switch(this.apiRoute) {
            case 'vendorticketstatus':
                return 'Upload Vendor Ticket Status Mappings';
            case 'orderstatus':
                return 'Upload Order Status Mappings';
        }
    }
    
    get label() {
        switch(this.apiRoute) {
            case 'vendorticketstatus':
                return 'Vendor Ticket Status Mapping';
            case 'orderstatus':
                return 'Order Status Mapping';
        }
    }

    get uploadLabel() {
        switch(this.apiRoute) {
            case 'vendorticketstatus':
                return 'Vendor Ticket Status Mapping Upload:';
            case 'orderstatus':
                return 'Order Status Mapping Upload:';
            default:
                return '';
        }
    }

    get indexRoute() {
        let route = '';
        let detailsRoute = '';
        switch(this.apiRoute) {
            case 'vendorticketstatus': 
                route = 'vendorticketstatusmapping';
                detailsRoute = 'VendorTicketStatusMapping-Details';
                break;
            case 'orderstatus':
                route = 'orderstatusmapping';
                detailsRoute = 'OrderStatusMapping-Details';
                break;
        }
        if (!this.$route.query.fulfillmentOption && !this.$route.query.vendor) {
            return {name: route};
        } 
        return {name: detailsRoute, query: {fulfillmentOption: this.$route.query.fulfillmentOption, vendor: this.$route.query.vendor} };
    }
    
    validation(file: File) {
        if (file) {
            return file.name.split('.').pop() === 'csv' || file.name.split('.').pop() === 'xlsx';
        }
        return true;
    }

    formatFileName(files: File[]) {
        const fileName = files[0].name;
        return fileName.length > 50 ? [fileName.substring(0, 50), '...'].join() : fileName;
    }

    invalidFeedBack() {
        return 'Invalid file. Select .csv or .xlsx file';
    }

    async upload(eventData: any) {
        await authorizationApiProvider.uploadStatusMapping(this.apiRoute,eventData.file).then( (response: any) => {
            EventBus.$emit('s', `${eventData.file.name} uploaded successfully.`);
            this.resetForm = true;
            this.clearCacheForAllKeys();
            EventBus.$emit('s', 'Cache has been successfully cleared.');
            return;
        }).catch( (error: any) => {
            EventBus.$emit('e', `<h3>Upload failed.</h3>${this.formatToastMessage(error)}`);
        });        
    }

    formatToastMessage(error: any) {
        if (error && error.response && error.response.data && error.response.data.errors) {
            const errors = error.response.data.errors;
            return `<div><strong>${errors[Object.keys(error.response.data.errors)[0]]}</strong><br/>`;
        }
        if (error && error.response && error.response.data && error.response.data.message) {
            return `<div><strong>${error.response.data.message}</strong><br/>`;
        }
        return `<div><strong>Upload Failed</strong><br/>`;
    }
}


import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  DynamicFulfillmentState,
  FulfillmentOption,
  FulfillmentOptionConfig,
  ProgramConfig,
  ServiceCentralServiceNetworkConfig,
} from '@/store/dynamicfulfillment/types';
import {
  CREATE_APPCONFIG,
  FETCH_APPCONFIG_BY_KEY,
  FETCH_FULFILLMENT_OPTION_CONFIGS,
  FETCH_PROGRAM_CONFIGS,
  UPDATE_APPCONFIG,
} from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({})
export default class ServiceNetworkConfigCreate extends Vue {
  @Prop() private serviceNetworkConfig: ServiceCentralServiceNetworkConfig;
  @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace })
  private fetchFulfillmentOptionConfigs: any;
  @Action(FETCH_PROGRAM_CONFIGS, { namespace })
  private fetchProgramConfigs: any;
  @Action(CREATE_APPCONFIG, { namespace }) private createAppConfig: any;
  @Action(FETCH_APPCONFIG_BY_KEY, { namespace })
  private fetchAppConfigByKey: any;
  @State(namespace) private profile!: DynamicFulfillmentState;
  @Action(UPDATE_APPCONFIG, { namespace }) private updateAppConfig: any;

  private programConfigs: ProgramConfig[] = [];
  private fulfillmentOptionConfigs: FulfillmentOptionConfig[] = [];
  private selectedFulfillmentOption: FulfillmentOption = FulfillmentOption.None;
  private selectedProgram: string = null;
  private isLoading: boolean = false;
  private sentinelKey: string = 'ServiceCentralApi:Sentinel';
  private forceUpdate: boolean = true;

  private async mounted() {
    await this.fetchProgramConfigs();
    await this.fetchFulfillmentOptionConfigs();
    this.programConfigs = this.profile.programConfigs;
    this.fulfillmentOptionConfigs = this.profile.fulfillmentOptionConfigs;
  }

  private get allowedFulfillmentOptions() {
    return Object.keys(FulfillmentOption)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key: any) => FulfillmentOption[key])
      .sort((a, b) => {
        return a < b ? -1 : 1;
      });
  }

  private fulfillmentOptionLabel(option: any) {
    if (isNaN(option)) {
      return option;
    } else {
      return FulfillmentOption[option];
    }
  }

  private get fulfillmentOptionState() {
    return (
      this.selectedFulfillmentOption != null &&
      Number.parseInt(
        this.selectedFulfillmentOption.valueOf().toString(),
        10,
      ) !== Number.parseInt(FulfillmentOption.None.valueOf().toString(), 10)
    );
  }

  private get programState() {
    return this.selectedProgram != null;
  }

  private get serviceProviderState() {
    return (
      this.serviceNetworkConfig.serviceProvider != null ||
      this.serviceNetworkConfig.serviceProvider === ''
    );
  }

  private get serviceNetworkState() {
    return (
      this.serviceNetworkConfig.serviceNetwork != null ||
      this.serviceNetworkConfig.serviceNetwork === ''
    );
  }

  private get configState() {
    return (
      this.fulfillmentOptionState &&
      this.programState &&
      this.serviceNetworkState
    );
  }

  private checkFormValidity(): boolean {
    this.isLoading = true;
    return this.configState;
  }

  private displayErrors(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit(
      'e',
      `There was a problem in processing the request. ${message}`,
    );
  }

  public async handleSubmit() {
    if (!this.checkFormValidity()) {
      return;
    }
    const key: string = this.serviceProviderState
      ? `${this.selectedFulfillmentOption}:${this.selectedProgram}_${this.serviceNetworkConfig.serviceProvider}`
      : `${this.selectedFulfillmentOption}:${this.selectedProgram}`;

    this.fetchAppConfigByKey(key)
      .then((response: any) => {
        if (response.length > 0 && response[0].key === key) {
          EventBus.$emit(
            'e',
            `Service Network key named ${key} already exist.`,
          );
        } else {
          this.createAppConfig({
            key: key,
            value: this.serviceNetworkConfig.serviceNetwork,
          })
            .then((response: any) => {
              if (this.forceUpdate) {
                this.updateAppConfig({
                  key: this.sentinelKey,
                  value: Date.now(),
                });
              }
              this.isLoading = false;
              EventBus.$emit('s', 'Service Network created successfully.');
              this.$router.push({
                name: 'ServiceCentralNetworkManagement-Index',
              });
            })
            .catch((error: any) => {
              const message = buildErrorMessage(error);
              EventBus.$emit('e', message);
              this.isLoading = false;
              return;
            });
        }
        this.isLoading = false;
      })
      .catch((error: any) => {
        const message = buildErrorMessage(error);
        EventBus.$emit('e', message);
        this.isLoading = false;
      });
  }
}


import { copyToClipboard } from '@/common/utilities';
import { FETCH_PROGRAM_CONFIGS } from '@/store/constants';
import { FETCH_FULFILLMENT_OPTION_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { DynamicFulfillmentState, FilterDefinition, FilterType, FulfillmentOption, FulfillmentOptionConfig, ProgramConfig, ProgramConfigVersion, ProgramFulfillmentOptionConfig } from '@/store/dynamicfulfillment/types';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DataTable from '../common/DataTable.vue';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import ProductConfigModal from './ProductConfigModal.vue';
import {jsonToText} from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({components: {DataTable, VueJSONEditor, ProductConfigModal: ProductConfigModal}})
export default class ProgramConfigModal extends Vue {
    @Prop({required: true}) private id: string;
    @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace }) private fetchFulfillmentOptionConfigs: any;
    @Action(FETCH_PROGRAM_CONFIGS) private fetchProgramConfigs: any;
    @State(namespace) private profile!: DynamicFulfillmentState;
    private isLoading: boolean = false;
    private programConfigs: ProgramConfig[] = [];
    private fulfillmentOptionConfigs: FulfillmentOptionConfig[] = [];
    private fields: any[] = [
    {
      key: 'id',
      thStyle: { width: '275px' },
      sortable: true,
    },
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'productIds',
      thStyle: {
        width: '600px',
      },
    },
    {
      key: 'fulfillmentOptionServiceProviders',
      formatter: (value: any, key: any, item: any) => {
        const fulfillmentOptionServiceProviders: {
          fulfillmentOption: FulfillmentOption,
          serviceProviders: string[],
        }[] = [];
        const currentVersion = item.programConfigVersions.filter( (configVersion: ProgramConfigVersion) => item.activeVersion === configVersion.version)[0];
        currentVersion.fulfillmentOptions.forEach( (fo: ProgramFulfillmentOptionConfig) => fulfillmentOptionServiceProviders.push(...[{fulfillmentOption: fo.fulfillmentOption, serviceProviders: fo.serviceProviders}]));
        return fulfillmentOptionServiceProviders;
      },
    },
    {
        key: 'fulfillmentOptionFilterDefinitions',
        formatter: (value: any, key: any, item: any) => {
          const fulfillmentOptionFilterDefinitions: {
            fulfillmentOption: FulfillmentOption,
            filterType: number,
            filterName: string,
            filterDescription: string,
          }[] = [];
          const currentVersion = item.programConfigVersions.filter( (configVersion: ProgramConfigVersion) => item.activeVersion === configVersion.version)[0];
          currentVersion.fulfillmentOptions.forEach( (fo: ProgramFulfillmentOptionConfig) => fo.filterDefinitions.forEach( (fd: FilterDefinition) => {
                fulfillmentOptionFilterDefinitions.push(...[{fulfillmentOption: fo.fulfillmentOption, filterType: fd.type, filterName: fd.name, filterDescription: fd.description}]); } ));
          return fulfillmentOptionFilterDefinitions;
        },
    },
    {
        key: 'programFilterDefinitions',
        formatter: (value: any, key: any, item: any) => {
          const programFilterDefinitions: {
            filterType: number,
            filterName: string,
            filterDescription: string,
          }[] = [];
          const currentVersion = item.programConfigVersions.filter( (configVersion: ProgramConfigVersion) => item.activeVersion === configVersion.version)[0];
          if (currentVersion && currentVersion.filterDefinitions) {
            currentVersion.filterDefinitions.forEach( (fd: FilterDefinition) => programFilterDefinitions.push(...[{ filterType: fd.type, filterName: fd.name, filterDescription: fd.description}]));
          }
          return programFilterDefinitions;
        },
    },
    'activeVersion',
    'versionCount',
    {key: 'details', label: 'View Program Config'},
  ];
  private fulfillmentOptionServiceProvidersFields: any[] = [
    {
      key: 'fulfillmentOption',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
    },
    {
      key: 'serviceProviders',
    },
  ];

  private fulfillmentOptionFilterDefinitionFields: any[] = [
    {
      key: 'fulfillmentOption',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
    },
    {
      key: 'filterType',
      formatter: (value: any, key: any, item: any) => {
        return FilterType[value];
      },
    },
    {
      key: 'filterName',
    },
    {
      key: 'filterDescription',
    },
  ];

  private programFilterDefinitionFields: any[] = [
    {
      key: 'filterType',
      formatter: (value: any, key: any, item: any) => {
        return FilterType[value];
      },
    },
    {
      key: 'filterName',
    },
    {
      key: 'filterDescription',
    },
  ];
  private configModal: any = {
    id: '',
    title: '',
    content: '',
  };
  private jsonEditorText: any = jsonToText;

  private async mounted() {
        await this.fetchProgramConfigs();
        await this.fetchFulfillmentOptionConfigs();
        this.programConfigs = this.profile.programConfigs.filter( (pc: ProgramConfig) => pc.id === this.id);
        this.fulfillmentOptionConfigs = this.profile.fulfillmentOptionConfigs;
    }

  private config(item: any, index: any, button: any) {
    this.configModal.id = item.id;
    this.configModal.title = `Program Config`;
    this.configModal.content = item;
    this.$root.$emit('bv::show::modal', this.configModal.id, button);
  }

  private resetConfigModal() {
    this.configModal.title = '';
    this.configModal.content = '';
  }

  private copyPayload(payload: any) {
    copyToClipboard(payload);
  }
}


import Vue from 'vue';
import { Watch, Component, Prop } from 'vue-property-decorator';
import { AppointmentConfig } from '@/store/dynamicfulfillment/types';

@Component({})
export default class AppointmentConfigCreateEdit extends Vue {
  @Prop({ required: true }) private index!: number;
  @Prop({ required: true }) private appointmentConfig!: AppointmentConfig;
  private daysUntilAutoCancelError: string = null;

  @Watch('appointmentConfig.autoCancelMissedAppointments')
  onAutoCancelMissedAppointmentsChange() {
    this.daysUntilAutoCancelError = null;
    if (!this.appointmentConfig.autoCancelMissedAppointments) {
      this.appointmentConfig.daysUntilAutoCancel = null;
    }
  }

  get validation(): boolean {
    if (this.appointmentConfig.autoCancelMissedAppointments && (this.appointmentConfig.daysUntilAutoCancel <= 0 || this.appointmentConfig.daysUntilAutoCancel == null)) {
      this.daysUntilAutoCancelError = 'This must be greater than 0 when Auto Cancel Missed Appointments is selected.';
      return false;
    } else {
      this.daysUntilAutoCancelError = null;
      return true;
    }
  }

  emitOnChange(content: { config: AppointmentConfig | null; isValid: boolean }) {
    this.$emit('onConfigChange', content);
  }

  @Watch('appointmentConfig', { immediate: false, deep: true })
  configChange() {
    this.emitOnChange({ config: this.appointmentConfig, isValid: this.validation });
  }
}


import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import {
  FulfillmentOptionConfig,
  OfferType,
  OrchestrationMode,
} from '@/store/dynamicfulfillment/types';
import {
  GET_FULFILLMENT_OPTION_CONFIG,
  FETCH_FULFILLMENT_OPTION_CONFIGS,
} from '@/store/dynamicfulfillment/constants';
import ViewContent from '@/components/ViewContent.vue';
import { Action, State } from 'vuex-class';
import { FulfillmentOption } from '@/store/dynamicfulfillment/types';
import DataTable from '@/components/common/DataTable.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, DataTable } })
export default class FulfillmentOptionConfigDetail extends Vue {
  @Prop({ required: true, type: String }) id!: string;
  @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace })
  fetchFulfillmentOptionConfigs: any;
  config: FulfillmentOptionConfig = null;
  vendorFields: any[] = [
    'name',
    { key: 'offerType', label: 'Offer Type' },
    { key: 'orchestrationMode', label: 'Orchestration Mode' },
    { key: 'orchestrationName', label: 'Orchestration Name' },
    { key: 'serviceProviders', label: 'Service Networks' },
    { key: 'requiresPriceList', label: 'Requires Price List' },
    { key: 'invoiceStrategy', label: 'Invoice Strategy' },
    { key: 'actions', label: 'Actions' },
  ];
  vendorAdditionalFields: any[] = [
    { key: 'minimumInventoryLimit', label: 'Minimum Inventory Limit' },
    { key: 'operationsStrategy', label: 'Operations Strategy' },
  ];
  flowTypeFields: any[] = [
    { key: 'flowType', label: 'Flow Type' },
    {
      key: 'requiresPriceList',
      label: 'Requires Price List',
      formatter: (value: boolean) => (value ? 'Yes' : 'No'),
    },
  ];

  async mounted() {
    const configs =
      (await this.fetchFulfillmentOptionConfigs()) as FulfillmentOptionConfig[];
    this.config = configs.find((c) => c.id === this.id);
  }

  get title() {
    return this.config ? this.config.id : '';
  }

  get vendorsCount() {
    return this.config ? this.config.vendors.length : 0;
  }

  offerTypeLabel(offerType: number) {
    return OfferType[offerType];
  }

  orchestrationModeLabel(mode: number) {
    return OrchestrationMode[mode];
  }
  getFulfillmentOptionName(fulfillmentOption: number) {
    return FulfillmentOption[Number(fulfillmentOption)];
  }

  getOperationsStrategy(operationsStrategy: any) {
    return operationsStrategy == null
      ? operationsStrategy
      : operationsStrategy.name;
  }
}

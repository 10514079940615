import axios, { AxiosRequestConfig } from 'axios';
import {
  FulfillmentAuthorization,
  FulfillmentTicketOrchestrationDetail,
  SourceSystemConfig,
  FulfillmentOptionConfig,
  FulfillmentTicket,
  VendorStatusEvent,
  UpdateTicketStatusModel,
  SourceSystemIdentifier,
  ProgramConfig,
  Product,
  VendorConfig,
  IPriceListConfig,
  InventoryState,
  InventoryPartsMappingState,
  FulfillmentAuthorizationWidget,
  InventoryStatePage,
  FulfillmentAuthorizationSearchPageResult,
  FulfillmentAuthorizationFilterLog,
  JustInTimeInventoryPartsMappingState,
  AppConfig,
  LocaleConfig,
  Invoice,
  SourceSystemClaimData,
  UserRolesPermissions,
  TicketAuthorizationAmountUpdateRequest,
  RoleConfig,
  PermissionsConfig,
  UserConfig,
  EquipmentExclusionConfig,
  EquipmentExclusionItem,
  StatusMapping,
  VendorTicketStatusUpdateRequest,
  IConfig,
  AncillaryServiceTypeConfig,
  ProgramFeesConfig,
  FeeType,
  CountryConfig,
  ServiceNetworkFulfillmentOptionTypeConfig,
  AccessRequest,
  SearchCriteria,
  RepairCatalogItemDefinition,
  RepairCatalog,
  InventoryItem,
} from '@/store/dynamicfulfillment/types';
import Vue from 'vue';
import { UserClaims } from './auth.types';
import { INVENTORYSTATES_PAGING_COUNT } from '@/store/dynamicfulfillment/constants';

declare var process: any;
declare var NProgress: any;
const region = process.env.VUE_APP_REGION;

const instance = axios.create({
  baseURL: process.env.VUE_APP_APIM_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

const multipartFormInstance = axios.create({
  baseURL: process.env.VUE_APP_APIM_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*'
  },
});

instance.interceptors.request.use(
  (config: any) => {
    NProgress.start();
    return config;
  },
  (error: any) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (config: any) => {
    NProgress.done();
    return config;
  },
  (error: any) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

export class AuthorizationApiProvider {
  public async getAuthorization(id: string): Promise<FulfillmentAuthorization> {
    return await this.get<FulfillmentAuthorization>('/authorization/' + id);
  }

  // tslint:disable-next-line:max-line-length
  public async getAuthorizationBySourceIdentifiers(sourceSystem: string, claimNumber: string): Promise<FulfillmentAuthorization> {
    return await this.get<FulfillmentAuthorization>('/authorization', {
      params: {
        sourceSystem,
        claimNumber,
      },
    });
  }

  public async getAuthorizationByTicketId(fulfillmentTicketId: string): Promise<FulfillmentAuthorization> {
    return await this.get<FulfillmentAuthorization>('/authorization', {
      params: {
        fulfillmentTicketId,
      },
    });
  }

  public async getRecentAuthorizations(count: number): Promise<FulfillmentAuthorizationWidget[]> {
    return await this.get<FulfillmentAuthorizationWidget[]>('/authorization/diagnostics/recentauthorizations/' + count);
  }

  public async getAuthorizationsByPhoneNumber(count: number, phone: string, pagingToken: string): Promise<FulfillmentAuthorizationSearchPageResult> {
    if (pagingToken) {
      return await this.get<FulfillmentAuthorizationSearchPageResult>(`/authorization/diagnostics/authorizationsByPhone/${count}?phone=${phone}&pagingToken=${pagingToken}`);
    } else {
      return await this.get<FulfillmentAuthorizationSearchPageResult>(`/authorization/diagnostics/authorizationsByPhone/${count}?phone=${phone}`);
    }
  }

  public async getAuthorizationsByKeyValue(count: number, collection: string, key: string, value: string, pagingToken: string): Promise<FulfillmentAuthorizationSearchPageResult> {
    if (pagingToken) {
      return await this.get<FulfillmentAuthorizationSearchPageResult>(`/authorization/searchByKeyValue/${count}?collection=${collection}&key=${key}&value=${value}&pagingToken=${pagingToken}`);
    } else {
      return await this.get<FulfillmentAuthorizationSearchPageResult>(`/authorization/searchByKeyValue/${count}?collection=${collection}&key=${key}&value=${value}`);
    }
  }

  public async getFulfillmentTicketsByBookedOfferConfirmationId(bookedOfferConfirmationId: string): Promise<FulfillmentTicket[]> {
    return await this.get<FulfillmentTicket[]>(`fulfillmentTicket?BookedOfferConfirmationId=${bookedOfferConfirmationId}`);
  }

  public async updateTicketStatus(request: UpdateTicketStatusModel) {
    const currentUser = await this.getUserId();
    await this.post(`/authorization/${request.authorizationId}/fulfillmentTicket/${request.fulfillmentTicketId}/status`, {
      ticketStatus: request.ticketStatus,
      statusReason: request.statusReason,
      modifiedBy: currentUser,
    });
  }

  public async overrideTicketStatus(request: UpdateTicketStatusModel) {
    const currentUser = await this.getUserId();
    await this.post(`/authorization/${request.authorizationId}/fulfillmentTicket/${request.fulfillmentTicketId}/status-override`, {
      ticketStatus: request.ticketStatus,
      statusReason: request.statusReason,
      modifiedBy: currentUser,
    });
  }

  public async sendVendorTicketStatus(request: VendorTicketStatusUpdateRequest) {
    await this.post(`/vendor-status/ticket`, request);
  }

  public async updateTicketAuthorizationAmountAsync(request: TicketAuthorizationAmountUpdateRequest) {
    // const currentUser = await this.getUserId();
    await this.put(`/authorization/${request.authorizationId}/fulfillmentTicket/${request.fulfillmentTicketId}/updateAuthorizationAmount`, {
      priceListCostDatas: request.priceListCostDatas,
    });
  }

  public async getEvents(authorizationId: string, fulfillmentTicketId?: string): Promise<any[]> {
    const params = {
      fulfillmentAuthorizationId: authorizationId,
    } as any;
    if (fulfillmentTicketId) {
      params.fulfillmentTicketId = fulfillmentTicketId;
    }
    const data = await this.get<any>('/events', { params });
    return data.Documents as any[];
  }

  public async getVendorStatusEvents(correlationId: string, eventType: string): Promise<VendorStatusEvent[]> {
    const params = { correlationId, eventType };
    const data = await this.get<any>('/vendor-status-updates', { params });
    return data.Documents as VendorStatusEvent[];
  }

  public async getOfferSet(authorizationId: string, offerSetId: string) {
    return await this.get<any>(`/authorization/${authorizationId}/offersets/${offerSetId}`);
  }

  public async getOrchestrationInfo(orchestrationId: string) {
    return await this.get<FulfillmentTicketOrchestrationDetail>(`/orchestrations/fulfillment/instances/${orchestrationId}`, {
      params: {
        showHistory: true,
        showInput: true,
        showHistoryOutput: true,
      },
    });
  }

  public async restartFulfillmentTicketOrchestration(fulfillmentTicketId: string) {
    return await this.post(`/orchestrations/${fulfillmentTicketId}/restart`, {});
  }

  public async getSourceSystemConfigs(): Promise<SourceSystemConfig[]> {
    return await this.get<SourceSystemConfig[]>('/config/sourcesystems');
  }

  public async getVendorConfigs(onlyDeleted?: boolean): Promise<VendorConfig[]> {
    return await this.get<VendorConfig[]>('/config/vendors', { params: { onlyDeleted } });
  }

  public async updateSourceSystemConfig(config: SourceSystemConfig): Promise<SourceSystemConfig> {
    return await this.put<SourceSystemConfig>(`/config/sourcesystems/${config.id}`, config);
  }

  public async getFulfillmentOptionConfigs(): Promise<FulfillmentOptionConfig[]> {
    return await this.get<FulfillmentOptionConfig[]>('/config/fulfillmentoptions');
  }

  public async updateFulfillmentOptionConfig(config: FulfillmentOptionConfig): Promise<void> {
    if (config._etag === null) {
      await this.post<FulfillmentOptionConfig>(`/config/fulfillmentoptions`, config);
    } else {
      await this.put<FulfillmentOptionConfig>(`/config/fulfillmentoptions/${config.id}`, config);
    }
  }

  public async updateVendorConfig(config: VendorConfig): Promise<void> {
    if (config._etag === null) {
      await this.post<VendorConfig>(`/config/vendors`, config);
    } else {
      await this.put<VendorConfig>(`/config/vendors/${config.id}`, config);
    }
  }

  public async createProgramConfig(config: ProgramConfig): Promise<ProgramConfig> {
    return await this.post<ProgramConfig>(`/config/Programs/`, config);
  }

  public async updateProgramConfig(config: ProgramConfig): Promise<void> {
    if (config.id === null || config.id === undefined) {
      await this.post<ProgramConfig>(`/config/programs`, config);
    } else {
      await this.put<ProgramConfig>(`/config/programs/${config.id}`, config);
    }
  }

  public async getProgramConfigs(sourceSystem?: string, onlyDeleted?: boolean): Promise<ProgramConfig[]> {
    return await this.get<ProgramConfig[]>('/config/programs', { params: { sourceSystem, onlyDeleted } });
  }

  public async deleteProgram(config: any, hardDelete?: boolean): Promise<void> {
    await this.delete(`/config/programs/${config.id}`, {}, { params: { hardDelete } });
  }

  public async removeVendorConfig(config: any, hardDelete?: boolean): Promise<void> {
    await this.delete(`/config/vendors/${config.id}`, {}, { params: { hardDelete } });
  }

  public async insertPriceListConfig<T>(config: IPriceListConfig<T>, route: string): Promise<IPriceListConfig<T>> {
    return await this.post<IPriceListConfig<T>>(`/config/${route}`, config);
  }

  public async updatePriceListConfig<T>(config: IPriceListConfig<T>, route: string): Promise<IPriceListConfig<T>> {
    return await this.put<IPriceListConfig<T>>(`/config/${route}/${config.id}`, config);
  }

  public async getPriceListConfigs<T>(route: string): Promise<IPriceListConfig<T>[]> {
    return await this.get<IPriceListConfig<T>[]>(`/config/${route}`);
  }

  public async getPriceListConfig<T>(id: string, route: string): Promise<IPriceListConfig<T>> {
    return await this.get<IPriceListConfig<T>>(`/config/${route}/${id}`);
  }

  public async deletePriceListConfig<T>(config: IPriceListConfig<T>): Promise<void> {
    await this.delete(`/config/pricelists/${config.id}`, config);
  }

  public async getProducts(sourceSystem: string, onlyDeleted?: boolean): Promise<Product[]> {
    return await this.get<Product[]>('/config/products', { params: { sourceSystem, onlyDeleted } });
  }

  public async getProduct(sourceSystem: string, id: string): Promise<Product> {
    return await this.get<Product>(`/config/products/${id}`);
  }

  public async createProduct(product: Product): Promise<Product> {
    return await this.post<Product>(`/config/products`, product);
  }

  public async editProduct(product: Product): Promise<Product> {
    return await this.put<Product>(`/config/products/${product.id}`, product);
  }

  public async deleteProduct(product: Product, hardDelete?: boolean): Promise<void> {
    await this.delete(`/config/products/${product.id}`, {}, { params: { hardDelete } });
  }

  public async cancelVendorOffer(ticket: FulfillmentTicket) {
    await this.delete(`/servicecentralticketservice/api/Ticket/${ticket.bookedOfferConfirmationId}`, {
      data: {
        claim: {
          id: ticket.claimAuthorizationNumber,
          action: 'Cancel',
        },
      },
      params: {
        fulfillmentOption: 'WalkInRepair',
      },
    });
  }

  public async getStatusMapping<T>(type: string, onlyDeleted?: boolean): Promise<StatusMapping<T>[]> {
    const items = await this.get<StatusMapping<T>[]>(`/config/${type}`, { params: { onlyDeleted } });
    return items.sort((i1, i2) => i1.sourceStateId.localeCompare(i2.sourceStateId));
  }

  public async createStatusMapping(apiRoute: string, config: any): Promise<void> {
    await this.post<any>(`/config/${apiRoute}`, config);
  }

  public async updateStatusMapping(apiRoute: string, config: any): Promise<void> {
    await this.put<any>(`/config/${apiRoute}/${config.id}`, config);
  }

  public async deleteStatusMapping(apiRoute: string, config: any, hardDelete?: boolean): Promise<void> {
    await this.delete(`/config/${apiRoute}/${config.id}`, {}, { params: { hardDelete } });
  }

  public async uploadStatusMapping(apiRoute: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.upload(`/config/upload/${apiRoute}`, formData);
  }

  public async getCommunicationsByAuthId(authorizationId: string) {
    return await this.get<any[]>(`/authorization/${authorizationId}/communications`);
  }

  public async getClaimData(sourceSystemIdentifier: SourceSystemIdentifier) {
    const sourceSystemPathVariable = this.sourceSystemPathVariable(sourceSystemIdentifier);

    return await this.get<any>(`/claims/${sourceSystemPathVariable}/${sourceSystemIdentifier.claimNumber}`);
  }

  public async getDiagnosticTraces(authorizationId: string): Promise<any[]> {
    return await this.get<any[]>(`/authorization/${authorizationId}/diagnostics/traces`);
  }

  public async getDiagnosticRequests(authorizationId: string): Promise<any[]> {
    return await this.get<any[]>(`/authorization/${authorizationId}/diagnostics/requests`);
  }

  public async getAllSuccessFulAuthDiagnosticRequests(): Promise<any[]> {
    return await this.get<any[]>(`/authorization/diagnostics/allsuccessfulauthrequests`);
  }

  public async getDiagnosticExceptions(count: number): Promise<any[]> {
    return await this.get<any[]>(`/authorization/diagnostics/exceptions/${count}`);
  }

  public async clearCache() {
    await this.delete('/cache');
  }

  public async clearCacheByKey(key: string) {
    await this.delete(`/cache/${key}`);
  }

  public async getInventoryStates(pagingToken: string) {
    if (pagingToken) {
      return await this.get<InventoryStatePage>(`/inventoryState/Paging?count=${INVENTORYSTATES_PAGING_COUNT}&pagingToken=${pagingToken}`);
    } else {
      return await this.get<InventoryStatePage>(`/inventoryState/Paging?count=${INVENTORYSTATES_PAGING_COUNT}`);
    }
  }

  public async getInventoryState(id: string) {
    return await this.get<InventoryState>(`/inventoryState/${id}`);
  }

  public async upsertInventoryState(inventoryState: InventoryState) {
    return await this.post<InventoryState>(`/inventorystate/upsert`, inventoryState);
  }

  public async deleteInventoryState(id: string) {
    return await this.delete(`/inventorystate/${id}`);
  }

  public async uploadInventoryStates(file: File, vendor: string) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.post(`/inventoryState/upload/${vendor}`, formData);
  }

  public async getInventoryPartsStatesMapping() {
    return await this.get<InventoryPartsMappingState[]>(`/inventorypartsmappingstate`);
  }

  public async uploadInventoryPartsStatesMapping(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.upload(`/inventorypartsmappingstate/upload`, formData);
  }

  public async getJustInTimeInventoryPartsStatesMapping() {
    return await this.get<JustInTimeInventoryPartsMappingState[]>(`/justintimeinventorypartsmappingstate`);
  }

  public async uploadJustInTimeInventoryPartsStatesMapping(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.upload(`/justintimeinventorypartsmappingstate/upload`, formData);
  }

  public async getFulfillmentAuthorizationFilterLogs(authorizationId: string): Promise<FulfillmentAuthorizationFilterLog[]> {
    return await this.get<FulfillmentAuthorizationFilterLog[]>(`/authorization/trace/filters`, { params: { authorizationId } });
  }

  public async getAppConfigByKey(key: string): Promise<AppConfig[]> {
    return await this.get<AppConfig[]>(`/config/appconfig/${key}`);
  }

  public async createAppConfig(request: { key: string; value: string }): Promise<any> {
    return await this.post(`/config/appconfig`, request);
  }

  public async updateAppConfig(request: { key: string; value: string }): Promise<any> {
    return await this.put(`/config/appconfig`, request);
  }

  public async deleteAppConfig(key: string): Promise<any> {
    return await this.delete(`/config/appconfig/${key}`);
  }

  public async getSvsOrderById(id: string) {
    return await this.get<any>(`/vendor-api/Svs/orders/${id}`);
  }

  public async getServiceCentralClaimById(id: string) {
    return await this.get<any>(`/vendor-api/ServiceCentral/Claims/${id}`);
  }

  public async deleteServiceCentralCache() {
    if (this.isInAuthorizedServiceCentralRegion()) {
      return await this.delete(`/vendor-api/ServiceCentral/Cache`);
    }
  }

  public async deleteServicePowerServiceCentersCache() {
    if (this.isInAuthorizedServicePowerRegion()) {
      return await this.delete(`/vendor-api/ServicePower/Cache/serviceCenters`);
    }
  }

  public async getConfigHistory(config: string, id: string): Promise<IConfig[]> {
    return await this.get<IConfig[]>(`/config/${config}/${id}/history`);
  }

  public get instance() {
    return instance;
  }

  public async getLocaleConfigs(): Promise<LocaleConfig[]> {
    const items = await this.get<LocaleConfig[]>(`/config/locales`);
    return items.sort((i1, i2) => i1.id.localeCompare(i2.id));
  }

  public async getLocaleConfig(id: string): Promise<LocaleConfig> {
    return await this.get<LocaleConfig>(`/config/locales/${id}`);
  }

  public async createLocaleConfig(localeConfig: LocaleConfig): Promise<void> {
    await this.post<LocaleConfig>(`/config/locales`, localeConfig);
  }

  public async updateLocaleConfig(localeConfig: LocaleConfig): Promise<void> {
    await this.put<LocaleConfig>(`/config/locales/${localeConfig.id}`, localeConfig);
  }

  public async deleteLocaleConfig(id: string): Promise<void> {
    await this.delete(`/config/locales/${id}`);
  }

  public async fetchFulfillmentTicketInvoices(fulfillmentTicketId: string): Promise<Invoice[]> {
    return await this.get<Invoice[]>(`/invoices?fulfillmentTicketId=${fulfillmentTicketId}`);
  }
  public async getSourceSystemClaimData(authorizationId: string): Promise<SourceSystemClaimData[]> {
    return await this.get<any[]>(`/authorization/${authorizationId}/sourceSystemClaimData`);
  }

  public async updateSourceSystemConfigPartial(id: string, configType: string, items: []): Promise<void> {
    await this.post<any>(`/config/SourceSystems/${id}/${configType}`, items);
  }

  public async getUserRolesPermissions(id: string) {
    return await this.get<UserRolesPermissions>(`/config/users/${id}`);
  }

  public async getRoles(onlyDeleted?: boolean) {
    return await this.get<RoleConfig[]>(`/config/roles`, { params: { onlyDeleted } });
  }

  public async getRole(id: string) {
    return await this.get<RoleConfig>(`/config/roles/${id}`);
  }

  public async updateRole(config: RoleConfig): Promise<void> {
    await this.put<RoleConfig>(`/config/roles/${config.id}`, config);
  }

  public async createRole(config: RoleConfig): Promise<void> {
    await this.post<RoleConfig>(`/config/roles`, config);
  }

  public async deleteRole(role: RoleConfig, hardDelete?: boolean): Promise<void> {
    await this.delete(`/config/roles/${role.id}`, {}, { params: { hardDelete } });
  }

  public async getPermissions(): Promise<PermissionsConfig[]> {
    return await this.get<PermissionsConfig[]>('/config/permissions');
  }

  public async getUserConfigs(onlyDeleted?: boolean) {
    return await this.get<UserConfig[]>(`/config/users`, { params: { onlyDeleted } });
  }

  public async getUserById(id: string) {
    return await this.get<UserConfig>(`/config/users/${id}`);
  }

  public async createUser(config: UserConfig) {
    await this.post<UserConfig>('/config/users', config);
  }

  public async editUser(config: UserConfig) {
    await this.put<UserConfig>(`/config/users/${config.id}`, config);
  }

  public async deleteUser(config: UserConfig, hardDelete?: boolean) {
    await this.delete(`/config/users/${config.id}`, {}, { params: { hardDelete } });
  }

  public async uploadUserConfig(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.upload(`/config/upload/users`, formData);
  }

  public async getEquipmentExclusionConfigById(id: string) {
    return await this.get<EquipmentExclusionConfig>(`/config/equipmentExclusionConfig/${id}`);
  }

  public async createEquipmentExclusionConfig(config: EquipmentExclusionConfig) {
    await this.post<EquipmentExclusionConfig>('/config/equipmentExclusionConfig', config);
  }

  public async editEquipmentExclusionConfig(config: EquipmentExclusionConfig) {
    await this.put<EquipmentExclusionConfig>(`/config/equipmentExclusionConfig/${config.id}`, config);
  }

  public async getEquipmentExclusionConfigs(onlyDeleted?: boolean): Promise<EquipmentExclusionConfig[]> {
    return await this.get<EquipmentExclusionConfig[]>('/config/equipmentExclusionConfig', { params: { onlyDeleted } });
  }

  public async deleteEquipmentExclusionConfig(config: any, hardDelete?: boolean): Promise<void> {
    await this.delete(`/config/equipmentExclusionConfig/${config.id}`, {}, { params: { hardDelete } });
  }

  public async getEquipmentExclusionItems(id: string): Promise<EquipmentExclusionItem[]> {
    return await this.get<EquipmentExclusionItem[]>(`/config/equipmentExclusionConfig/${id}/items`);
  }

  public async createEquipmentExclusionItem(config: EquipmentExclusionItem) {
    return await this.post<EquipmentExclusionItem>(`/config/equipmentExclusionConfig/${config.equipmentExclusionConfigId}/items`, config);
  }

  public async getEquipmentExclusionItem(configId: string, id: string) {
    return await this.get<EquipmentExclusionItem>(`/config/equipmentExclusionConfig/${configId}/items/${id}`);
  }

  public async editEquipmentExclusionItem(config: EquipmentExclusionItem) {
    await this.put<EquipmentExclusionItem>(`/config/equipmentExclusionConfig/${config.equipmentExclusionConfigId}/items/${config.id}`, config);
  }

  public async deleteEquipmentExclusionItem(config: EquipmentExclusionItem) {
    await this.delete(`/config/equipmentExclusionConfig/${config.equipmentExclusionConfigId}/items/${config.id}`);
  }

  public async uploadEquipmentExclusionItems(file: File, configId: string) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.upload(`/config/equipmentExclusionConfig/${configId}/items/upload`, formData);
  }

  public async getAncillaryServiceTypeConfigs(): Promise<AncillaryServiceTypeConfig[]> {
    return await this.get<AncillaryServiceTypeConfig[]>('/config/ancillaryservicetypes');
  }

  public async getAncillaryServiceTypeById(id: string): Promise<AncillaryServiceTypeConfig> {
    return await this.get<AncillaryServiceTypeConfig>(`/config/ancillaryservicetypes/${id}`);
  }

  public async createAncillaryServiceTypeConfig(config: AncillaryServiceTypeConfig): Promise<void> {
    await this.post<AncillaryServiceTypeConfig>('/config/ancillaryservicetypes', config);
  }

  public async updateAncillaryServiceTypeConfig(config: AncillaryServiceTypeConfig): Promise<void> {
    await this.put<AncillaryServiceTypeConfig>(`/config/ancillaryservicetypes/${config.id}`, config);
  }

  public async getServiceNetworkFulfillmentOptionTypeConfigs(onlyDeleted?: boolean): Promise<ServiceNetworkFulfillmentOptionTypeConfig[]> {
    return await this.get<ServiceNetworkFulfillmentOptionTypeConfig[]>('/config/servicenetworkfulfillmentoptiontypes', { params: { onlyDeleted } });
  }

  public async getServiceNetworkFulfillmentOptionTypeById(id: string): Promise<ServiceNetworkFulfillmentOptionTypeConfig> {
    return await this.get<ServiceNetworkFulfillmentOptionTypeConfig>(`/config/servicenetworkfulfillmentoptiontypes/${id}`);
  }

  public async createServiceNetworkFulfillmentOptionTypeConfig(config: ServiceNetworkFulfillmentOptionTypeConfig): Promise<void> {
    await this.post<ServiceNetworkFulfillmentOptionTypeConfig>('/config/servicenetworkfulfillmentoptiontypes', config);
  }

  public async updateServiceNetworkFulfillmentOptionTypeConfig(config: ServiceNetworkFulfillmentOptionTypeConfig): Promise<void> {
    await this.put<ServiceNetworkFulfillmentOptionTypeConfig>(`/config/servicenetworkfulfillmentoptiontypes/${config.id}`, config);
  }

  public async deleteServiceNetworkFulfillmentOptionTypeConfig(config: ServiceNetworkFulfillmentOptionTypeConfig, hardDelete?: boolean) {
    await this.delete(`/config/servicenetworkfulfillmentoptiontypes/${config.id}`, {}, { params: { hardDelete } });
  }

  public async getProgramFeesConfigs(onlyDeleted?: boolean) {
    return await this.get<ProgramFeesConfig[]>(`/config/programfees`, { params: { onlyDeleted } });
  }

  public async getProgramFeesConfig(id: string) {
    return await this.get<ProgramFeesConfig>(`/config/programfees/${id}`);
  }

  public async createProgramFeesConfig(config: ProgramFeesConfig) {
    await this.post<ProgramFeesConfig>('/config/programfees', config);
  }

  public async editProgramFeesConfig(config: ProgramFeesConfig) {
    await this.put<ProgramFeesConfig>(`/config/programfees/${config.id}`, config);
  }

  public async deleteProgramFeesConfig(config: ProgramFeesConfig, hardDelete?: boolean) {
    await this.delete(`/config/programfees/${config.id}`, {}, { params: { hardDelete } });
  }

  public async getFeeTypes() {
    return await this.get<FeeType[]>(`/config/feetypes`);
  }

  public async getCountryConfigs(): Promise<CountryConfig[]> {
    return await this.get<CountryConfig[]>('/config/countries');
  }

  public async sendAccessRequest(accessRequest: AccessRequest): Promise<void> {
    await this.post<AccessRequest>('/accessRequest/', accessRequest);
  }

  public async uploadPriceListItems(file: File, configId: string) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.upload(`/config/pricelists/${configId}/items`, formData);
  }

  public async getPriceListItems<T>(id: string, route: string): Promise<T> {
    return await this.get<T>(`/config/${route}/${id}/items`);
  }

  public async getPriceListItemsKeys<T>(id: string): Promise<T> {
    return await this.get<T>(`/config/pricelists/${id}/items/keys`);
  }

  public async getPriceListItemsSearch<T>(id: string, searchCriteria: SearchCriteria[]): Promise<T> {
    return await this.post<T>(`/config/pricelists/${id}/items/search`, searchCriteria);
  }

  public async fetchMockClaimData(sourceSystemIdentifier: SourceSystemIdentifier): Promise<SourceSystemClaimData> {
    return await this.get<SourceSystemClaimData>(`/mockSourceSystemClaimData`, { params: sourceSystemIdentifier });
  }

  public async updateMockClaimData(sourceSystemClaimData: SourceSystemClaimData): Promise<SourceSystemClaimData> {
    return await this.post(`/mockSourceSystemClaimData`, sourceSystemClaimData);
  }

  public async deleteMockClaimData(sourceSystemIdentifier: SourceSystemIdentifier): Promise<void> {
    await this.delete(`/mockSourceSystemClaimData`, {}, { params: sourceSystemIdentifier });
  }

  public async getRepairCatalogs(): Promise<RepairCatalog[]> {
    return await this.get<RepairCatalog[]>('/repaircatalog');
  }

  public async getRepairCatalogById(id: string): Promise<RepairCatalog> {
    return await this.get<RepairCatalog>(`/repaircatalog/${id}`);
  }

  public async getRepairCatalogItemDefinitions(repairCatalogId: string): Promise<RepairCatalogItemDefinition[]> {
    return await this.get<RepairCatalogItemDefinition[]>(`/repairCatalog/${repairCatalogId}/itemDefinitions`);
  }

  public async getRepairCatalogItemDefinition(repairCatalogId: string, itemDefinitionId: string): Promise<RepairCatalogItemDefinition> {
    return await this.get<RepairCatalogItemDefinition>(`/repairCatalog/${repairCatalogId}/items${itemDefinitionId}`);
  }

  public async getRepairCatalogInventoryItem(repairCatalogId: string, itemId: string): Promise<InventoryItem> {
    return await this.get<InventoryItem>(`/repairCatalog/${repairCatalogId}/items/${itemId}`);
  }
  public async getRepairCatalogPartsMapping(repairCatalogId: string): Promise<InventoryItem[]> {
    return await this.get<InventoryItem[]>(`/repairCatalog/${repairCatalogId}/items`);
  }

  public async uploadRepairCatalogItems(file: File, repairCatalogId: string, vendor: string) {
    console.log('uploadRepairCatalogItems', file, repairCatalogId, vendor);
    const formData = new FormData();
    formData.append('file', file);
    return await this.upload(`/vendor-api/${vendor}/repairCatalog/${repairCatalogId}/item-definitions`, formData);
  }

  public async createRepairCatalog(catalog: RepairCatalog): Promise<RepairCatalog> {
    return await this.post<RepairCatalog>(`/repaircatalog`, catalog);
  }

  //#region Private Methods
  private sourceSystemPathVariable(sourceSystemIdentifier: SourceSystemIdentifier): string {
    switch (sourceSystemIdentifier.sourceSystem) {
      case '1':
        return 'Eprism';
      case '2':
        return 'Elita';
      case '3':
        return 'Huxley';
      case '5':
        return 'Fixt';
      case '6':
        return 'Lori';
      case '7':
        return 'EIP';
      case '8':
        return 'Verizon';
      default:
        throw Error('Invalid source system');
    }
  }

  private isInAuthorizedServicePowerRegion(): boolean {
    if (region === 'US' || region === 'Local') {
      return true;
    }
    return false;
  }

  private isInAuthorizedServiceCentralRegion(): boolean {
    if (region === 'US' || region === 'UK' || region === 'Local') {
      return true;
    }
    return false;
  }

  private async get<TReturn>(uri: string, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const response = await instance.get<TReturn>(uri, config);
    return response.data;
  }

  private async getBaseResponse<TReturn>(uri: string, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const response = await instance.get<TReturn>(uri, config);
    return response;
  }

  private async post<TReturn>(uri: string, body?: any, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const { data } = await instance.post<TReturn>(uri, body, config);
    return data;
  }

  private async put<TReturn>(uri: string, body?: any, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const { data } = await instance.put<TReturn>(uri, body, config);
    return data;
  }

  private async delete(uri: string, body?: any, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    config = { ...{ data: body }, ...config };
    return await instance.delete(uri, config);
  }

  private async upload<TReturn>(uri: string, body?: any, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const { data } = await multipartFormInstance.post<TReturn>(uri, body, config);
    return data;
  }

  private async addAuthHeader(config?: any) {
    config = { ...{ headers: {} }, ...config };
    const accessToken = await Vue.prototype.$auth.getAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  }

  private async getUserId() {
    const user = (await Vue.prototype.$auth.getUser()) as UserClaims;
    return user.preferred_username.toLowerCase();
  }
  //#endregion Private Methods
}

export const authorizationApiProvider = new AuthorizationApiProvider();


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FETCH_OFFER_SET, SEARCH_AUTH_BY_ID } from '@/store/dynamicfulfillment/constants';
import ListItem from '@/components/common/ListItem.vue';
import { FulfillmentOption, UserTier } from '@/store/dynamicfulfillment/types';
import ViewContent from '@/components/ViewContent.vue';
import { Action } from 'vuex-class';
import FulfillmentAuthorizationFilterLogs from '@/components/FulfillmentAuthorization/FulfillmentAuthorizationFilterLogs.vue';
import FulfillmentAuthorizationTraces from '@/components/FulfillmentAuthorization/FulfillmentAuthorizationTraces.vue';
import ClaimedEquipment from '@/components/FulfillmentAuthorization/ClaimedEquipment.vue'
const namespace: string = 'dynamicfulfillmentmodule';
import DataTable from '@/components/common/DataTable.vue';

@Component({ components: {ListItem, ViewContent, FulfillmentAuthorizationFilterLogs, FulfillmentAuthorizationTraces, ClaimedEquipment, DataTable }})
export default class OfferSetDetail extends Vue {
  @Prop() private authorizationId!: string;
  @Prop() private offerSetId!: string;
  @Prop() private userTier!: number;
  @Prop({default:false}) private forceFetch: boolean;
  @Action(FETCH_OFFER_SET, { namespace }) private fetchOfferSet: any;
  @Action(SEARCH_AUTH_BY_ID, { namespace }) private searchAuthById: any;
  private  displayServiceAddress: any = null;
  private  displayUserTier: number = 0;
  private offerSet: any = null;
  private infoModal: any = {
          id: 'offer-set-info-modal',
          title: '',
          content: '',
        };

  public async mounted() {
    await this.fetch();
  }

  private async fetch() {
    const query = { authorizationId: this.authorizationId, offerSetId: this.offerSetId };
    this.offerSet = await this.fetchOfferSet(query);

    this.displayServiceAddress = this.offerSet.serviceAddress;
    this.displayUserTier = this.userTier;
    
    if(!this.offerSet.serviceAddress || !this.userTier) {
      const authorization = await this.searchAuthById({ authorizationId: this.authorizationId, forceFetch: this.forceFetch });

      if (!this.offerSet.serviceAddress) {
        this.displayServiceAddress = authorization.serviceAddress;
      }

      if (!this.userTier) {
        this.displayUserTier = authorization.offerHistory.historyItems.filter((x: any) => x.offerSetId == this.offerSetId)[0].userTier;
      }
    }
  }

  private get fields() {
    return [
      'id',
      'fulfillmentOptionCategory',
      {
        key: 'fulfillmentOption',
        label: 'Fulfillment Option',
        formatter: (value: any, key: any, item: any) => {
            return FulfillmentOption[value];
        },
      },
      'vendorId',
      'deductibleAmount',
      'isLegacy',
      'details',
    ];
  }
  private info(item: any, index: any, button: any) {
        this.infoModal.title = `Offer Id ${item.id}`;
        this.infoModal.content = JSON.stringify(item, null, 4);
        this.$root.$emit('bv::show::modal', this.infoModal.id, button);
  }

  private resetInfoModal() {
        this.infoModal.title = '';
        this.infoModal.content = '';
  }

  private getUserTier(val: any) {
    return UserTier[val];
  }
}

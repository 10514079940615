import { FulfillmentOption } from '@/store/dynamicfulfillment/types';
import FulfillmentOptionVendorConfigCreateEdit from '@/components/configuration/FulfillmentOptionVendorConfigs/FulfillmentOptionVendorConfigCreateEdit.vue';
import CustomerLocationRepairFOVendorConfigCreateEdit from '@/components/configuration/FulfillmentOptionVendorConfigs/CustomerLocationRepairFOVendorConfigCreateEdit.vue';
import RepairFOVendorConfigCreateEdit from '@/components/configuration/FulfillmentOptionVendorConfigs/RepairFOVendorConfigCreateEdit.vue';


export class FulfillmentOptionVendorConfigFactory {
    public GetFulfillmentOptionVendorConfigComponent(fulfillmentOption: FulfillmentOption): any {
        if (!fulfillmentOption)
            return FulfillmentOptionVendorConfigCreateEdit;
        let vendorConfigComponentKey = this.getFulfillmentOptionText(fulfillmentOption);
        var component = ComponentList[vendorConfigComponentKey];
        return component ? component : FulfillmentOptionVendorConfigCreateEdit;
    }

    private get getFulfillmentOptionText() {
        return (val: number) => {
            return FulfillmentOption[val];
        };
    }
}

export const fulfillmentOptionVendorConfigFactory = new FulfillmentOptionVendorConfigFactory();

const ComponentList: { [key: string]: object } = {
    "InHomeAppointment": CustomerLocationRepairFOVendorConfigCreateEdit,
    "PickUpAndDelivery": CustomerLocationRepairFOVendorConfigCreateEdit,
    "DirectedRepair": RepairFOVendorConfigCreateEdit,
    "WalkInRepair": RepairFOVendorConfigCreateEdit,
};




import Vue from 'vue';
import { FulfillmentTicketStatus, UpdateTicketStatusModel } from '@/store/dynamicfulfillment/types';
import { Component, Prop } from 'vue-property-decorator';
import { OVERRIDE_TICKET_STATUS } from '@/store/dynamicfulfillment/constants';
import { Action } from 'vuex-class';
const namespace: string = 'dynamicfulfillmentmodule';

@Component
export default class UpdateTicketStatus extends Vue {
  @Prop() private authorizationId!: string;
  @Prop() private fulfillmentTicketId!: string;
  @Action(OVERRIDE_TICKET_STATUS, { namespace }) private overrideTicketStatus: any;
  private ticketStatuses: any[] = this.ticketStatusOptions();
  private targetTicketStatus: number = null;
  private targetStatusReason: string = null;
  private ticketStatusState: boolean = null;
  private errorMessage: string = null;

  private created() {
    this.$root.$on('open-update-ticket-status', () => {
      this.$bvModal.show('update-ticket-status-modal');
    });
  }

  private ticketStatusOptions() {
    const statuses = [{value: null as number, text: 'Please select a ticket status'}];
    for (const status in FulfillmentTicketStatus) {
      if (Number(status)) {
        statuses.push({ value: Number.parseInt(status, 10), text: FulfillmentTicketStatus[status] });
      }
    }
    return statuses;
  }

  private checkFormValidity() {
    this.ticketStatusState = this.targetTicketStatus && this.targetTicketStatus > 0
      ? true
      : false;
    return this.ticketStatusState;
  }

  private resetModal() {
    this.targetTicketStatus = null;
    this.targetStatusReason = null;
    this.ticketStatusState = null;
    this.errorMessage = null;
  }

  private handleOk(bvModalEvt: any) {
    // Prevent modal from closing
    bvModalEvt.preventDefault();

    // Trigger submit handler
    this.handleSubmit();
  }

  private async handleSubmit() {
    this.errorMessage = null;

    // Exit when the form isn't valid
    if (!this.checkFormValidity()) {
      return;
    }

    try {
      await this.overrideTicketStatus({
        authorizationId: this.authorizationId,
        fulfillmentTicketId: this.fulfillmentTicketId,
        ticketStatus: this.targetTicketStatus,
        statusReason: this.targetStatusReason,
      } as UpdateTicketStatusModel);

      this.$emit('ticket-status-updated');
      this.$bvModal.hide('update-ticket-status-modal');
    } catch (error) {
      const err: any = error;
      this.errorMessage = err.response.data.message;
    }
  }
}

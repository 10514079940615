import { ActionTree } from 'vuex';
import { RootState,  } from '@/store/types';
import { GlobalModuleState, ServiceLocationSearchCriteria, ServiceCenterLocationConfig, ServiceNetworkConfig } from '../types';
import * as apis from '../constants';
import { locationManagementApiProvider } from '@/common/locationManagement.api';
import { DateTime } from 'luxon';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const ServiceCenterApisActions: ActionTree<GlobalModuleState, RootState> = {
  async [apis.FETCH_SERVICECENTER_STATE](context, id: string) {
    // Fetch service location and format open/end times
    const serviceLocation = await locationManagementApiProvider.getServiceLocationById(id);
    serviceLocation.openHours.forEach((o) => {
      o.startTimeFormatted = DateTime.fromFormat(o.startTime, 't').toFormat('HH:mm');
      o.endTimeFormatted = DateTime.fromFormat(o.endTime, 't').toFormat('HH:mm');
    });
    context.commit(apis.SET_SERVICECENTER_LOCATION_STATE, serviceLocation);
    return serviceLocation;
  },

  async [apis.FETCH_SERVICE_CENTERS](context) {
    let serviceCentersPage = context.state.serviceCentersPage;
    if (serviceCentersPage && serviceCentersPage.pagingToken) {
      const response = await locationManagementApiProvider.getServiceLocations(serviceCentersPage.pagingToken);
      serviceCentersPage.serviceCenters.push(...response.serviceCenters);
      serviceCentersPage.pagingToken = response.pagingToken;
    } else if (serviceCentersPage.serviceCenters && serviceCentersPage.serviceCenters.length > 0) {
      return serviceCentersPage.serviceCenters; // reached end of the page. Do not make the api call.
    } else {
      serviceCentersPage = await locationManagementApiProvider.getServiceLocations(null);
    }
    context.commit(apis.SET_SERVICECENTERS_PAGING_DATA, serviceCentersPage);
    return serviceCentersPage.serviceCenters;
  },

  async [apis.FETCH_SERVICE_CENTERS_ADVANCED_SEARCH](context, searchCriteria: ServiceLocationSearchCriteria) {
    return await locationManagementApiProvider.searchServiceLocations(searchCriteria);
  },

  async [apis.CLEAR_SERVICECENTERS_PAGING_DATA](context) {
    context.commit(apis.CLEAR_SERVICECENTERS_PAGING_DATA);
  },

  async [apis.ADD_SERVICECENTERS_LOCATION_DATA](context, serviceCenterLocation: ServiceCenterLocationConfig) {
    context.commit(apis.ADD_SERVICECENTERS_LOCATION_DATA, serviceCenterLocation);
  },

  async [apis.EDIT_SERVICECENTERS_LOCATION_DATA](context, serviceCenterLocation: ServiceCenterLocationConfig) {
    context.commit(apis.EDIT_SERVICECENTERS_LOCATION_DATA, serviceCenterLocation);
  },

  async [apis.FETCH_SERVICENETWORK_CONFIGS](context) {
    let serviceNetworkConfigs = await locationManagementApiProvider.getServiceNetworkConfigs();
    context.commit(apis.SET_SERVICENETWORK_CONFIGS, serviceNetworkConfigs);
    return serviceNetworkConfigs;
  },

  async [apis.FETCH_SERVICENETWORK_CONFIG](context, serviceNetwork: string) {
    return await locationManagementApiProvider.getServiceNetworkConfig(serviceNetwork);
  },

  async [apis.SET_SERVICENETWORK_CONFIGS](context, serviceNetwork: ServiceNetworkConfig) {
    if (serviceNetwork.isEdit) {
      return await locationManagementApiProvider.updateServiceNetworkConfig(serviceNetwork);
    } else {
      return await locationManagementApiProvider.addServiceNetworkConfig(serviceNetwork);
    }
  },

  async [apis.DELETE_SERVICE_CENTER](context, request: { serviceNetwork: string; storeId: string }) {
    await locationManagementApiProvider.deleteServiceCenter(request);
    context.commit(apis.DELETE_SERVICE_CENTER, request);
  },

  async [apis.DELETE_SERVICECENTERS_CACHE](context) {
    return await locationManagementApiProvider.deleteServiceCentersCache();
  },

  async [apis.SAVE_SERVICECENTER_LOCATION](context, config: ServiceCenterLocationConfig) {
    if (config.isEdit) {
      await locationManagementApiProvider.updateServiceLocation(config);
      // Update view page:
      /* To update/add userAudit to state.serviceLocationData data for newly added or edited location 
        so it can be shown on the locations view page without refreshing the page. */
      var locationId = config.storeId + ':' + config.serviceNetwork;
      const serviceLocation = await locationManagementApiProvider.getServiceLocationById(locationId);
      context.commit(apis.EDIT_SERVICECENTERS_LOCATION_DATA, serviceLocation);
    } else {
      await locationManagementApiProvider.addServiceLocation(config);
      var locationId = config.storeId + ':' + config.serviceNetwork;
      const serviceLocation = await locationManagementApiProvider.getServiceLocationById(locationId);
      context.commit(apis.ADD_SERVICECENTERS_LOCATION_DATA, serviceLocation);
    }
    // Clear service central and service power cache, which include service centers:
    await authorizationApiProvider.deleteServiceCentralCache();
    await authorizationApiProvider.deleteServicePowerServiceCentersCache();
    return config;
  },

  async [apis.FETCH_SERVICE_MANUFACTURERS](context) {
    let serviceManufacturers = context.state.serviceManufacturers;
    if (serviceManufacturers.length > 0) {
      return serviceManufacturers;
    }
    serviceManufacturers = await locationManagementApiProvider.getServiceManufacturers();
    context.commit(apis.SET_SERVICE_MANUFACTURERS, serviceManufacturers);
    return serviceManufacturers;
  },
};

export default ServiceCenterApisActions;


import { Component, Vue, Prop } from 'vue-property-decorator';
import { Enums } from '@/store/dynamicfulfillment/types';
import Badge from '@/common/badge';

@Component
export default class OrderStatusLabel extends Vue {
  @Prop({required: true, type: Number}) private status!: Enums.OrderStatus;

  get badge(): Badge {
    switch (this.status) {
      case Enums.OrderStatus.None:
        return new Badge('None', 'light');
      case Enums.OrderStatus.Pending:
        return new Badge('Pending', 'info');
      case Enums.OrderStatus.InProgress:
        return new Badge('InProgress', 'secondary');
      case Enums.OrderStatus.Closed:
        return new Badge('Closed', 'primary');
      case Enums.OrderStatus.Cancelled:
        return new Badge('Cancelled', 'warning');
      case Enums.OrderStatus.Rejected:
        return new Badge('Rejected', 'danger');
      case Enums.OrderStatus.Failed:
        return new Badge('Failed', 'danger');
      case Enums.OrderStatus.Created:
        return new Badge('Created', 'success');
      default:
        throw new Error(`Unmapped status type: ${this.status}`);
    }
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import EventBus from '@/common/event.bus';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import StatusMappingCreateEdit from '@/components/configuration/StatusMappingCreateEdit.vue';
import { FETCH_STATUS_MAPPING_BY_ID, SAVE_STATUS_MAPPING } from '@/store/constants';
import { CLEAR_CACHE } from '@/store/dynamicfulfillment/constants';
import { RoutingAction } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: {ConfigEditComponent, StatusMappingCreateEdit } })
export default class StatusMappingEdit extends Vue {    
    @Prop() id!: string;
    @Prop() indexRoute!: string | { name: string, query?: any };
    @Action(CLEAR_CACHE, { namespace }) private clearCacheForAllKeys: () => void;
    @Prop() statusType!: string;
    private localIndexRoute: any = null;
    private localStatusType: string = '';

    beforeMount(){
        this.localIndexRoute = this.indexRoute;
        this.localStatusType = this.statusType;
        if (!this.localStatusType) {            
            var mapping = this.$route.name.split('-')[0].toLocaleLowerCase();
            this.localIndexRoute = mapping;
            this.localStatusType = mapping.replace('mapping', '');
        }
    }

    get label() {
        switch(this.localStatusType) {
            case 'vendorticketstatus':
                return 'Vendor Ticket Status Mapping';
            case 'orderstatus':
                return 'Order Status Mapping';
        }
    }

    get title() {
        switch(this.localStatusType) {
            case 'vendorticketstatus':
                return 'Vendor Ticket';
            case 'orderstatus':
                return 'Order';
        }
    }

    get fetchAction() {
        return FETCH_STATUS_MAPPING_BY_ID;
    }

    get saveAction() {
        return SAVE_STATUS_MAPPING;
    }

    async stateSaved() {
      this.successMessage();
      this.clearCacheForAllKeys();
    }

    successMessage() {
        return EventBus.$emit('s', 'Cache has been successfully cleared.');
    }

    get routeActions() {
        switch(this.localStatusType) {
            case 'vendorticketstatus': 
                return [
                    { name: RoutingAction.Create, action: SAVE_STATUS_MAPPING, params: {apiRoute: 'vendorticketstatus'}},        
                    { name: RoutingAction.Fetch, action: FETCH_STATUS_MAPPING_BY_ID, params: {apiRoute: 'vendorticketstatus'} },        
                ];
            case 'orderstatus':
                return [
                    { name: RoutingAction.Create, action: SAVE_STATUS_MAPPING, params: {apiRoute: 'orderstatus'}},        
                    { name: RoutingAction.Fetch, action: FETCH_STATUS_MAPPING_BY_ID, params: {apiRoute: 'orderstatus'} },        
                ];
        }        
    }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { InventoryPartsMappingState, RoutingAction } from '@/store/dynamicfulfillment/types';
import { FETCH_INVENTORY_PARTS_MAPPING_STATE } from '@/store/constants';
import XLSX from 'xlsx';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ConfigIndexComponent } })
export default class InventoryMapIndex extends Vue {
  private configs: InventoryPartsMappingState[] = null;
  private fields: any[] = [
    {
      key: 'serviceProvider',
      label: 'Service Network',
      filterable: true,
    },
    {
      key: 'partSku',
      filterable: true,
    },
    {
      key: 'partName',
      filterable: true,
    },
    {
      key: 'partType',
      filterable: true,
    },
    {
      key: 'deviceTypeId',
      filterable: true,
    },
    {
      key: 'repairTypeId',
      filterable: true,
    },
    {
      key: 'color',
      filterable: true,
    },
    {
      key: 'deviceSkus',
      filterable: true,
    },
    'lastUpdatedDate',
  ];
  private exportFields: any[] = ['ServiceProvider', 'PartSku', 'PartName', 'PartType', 'DeviceTypeId', 'RepairTypeId', 'Color', 'Skus'];
  private downloadFileName = 'InventoryPartsMapping';
  private mappedStates: {
    ServiceProvider: string;
    PartSku: string;
    PartName: string;
    PartType: string;
    DeviceTypeId: string;
    RepairTypeId: string;
    Color: string;
    Skus: string[];
  }[] = [];

  get routeActions() {
    return [
      { name: RoutingAction.Upload, route: 'InventoryPartsMappingStates-Upload', requiredPermission: 'Configuration-InventoryPartsMapping-Upload' },
      { name: RoutingAction.Fetch, action: FETCH_INVENTORY_PARTS_MAPPING_STATE, requiredPermission: 'Configuration-InventoryPartsMapping-View' },
      { name: RoutingAction.Download, requiredPermission: 'Configuration-InventoryPartsMapping-Download' },
    ];
  }

  private pageLoad(configs: InventoryPartsMappingState[]) {
    this.configs = configs;
  }

  private mapStates(states: any[]) {
    this.mappedStates = states.map((item: any) => ({
      ServiceProvider: item.serviceProvider,
      PartSku: item.partSku,
      PartName: item.partName,
      PartType: item.partType,
      DeviceTypeId: item.deviceTypeId,
      RepairTypeId: item.repairTypeId,
      Color: item.color,
      Skus: item.deviceSkus.join(','),
    })) as [];
  }

  private get exportFileName() {
    return `${this.downloadFileName}.xlsx`;
  }
}

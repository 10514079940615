var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('config-index-component',{attrs:{"title":"Source Systems","fields":_vm.fields,"routeActions":_vm.routeActions,"initialSort":"Id","configType":_vm.configType},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Configuration")]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Source Systems")])]},proxy:true},{key:"cell(id)",fn:function(data){return [_c('router-link',{attrs:{"to":{
        name: 'SourceSystemConfig-Details',
        params: { id: data.value },
      },"title":"Details"}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(uiVersion)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(sourceSystem)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(searchCriteria.criteria)",fn:function(data){return [(data.item)?_c('b-button',{staticClass:"mr-2",attrs:{"size":"xs","variant":"primary"},on:{"click":data.toggleDetails}},[_vm._v(_vm._s(data.detailsShowing ? 'Hide' : 'Show')+" Details")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('router-link',{attrs:{"to":{
        name: 'SourceSystemConfig-Edit',
        params: { id: data.item.id },
      },"title":"Edit"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-pen-to-square","size":"lg"}})],1)]}},{key:"row-details",fn:function(data){return [_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"10","md":"8","lg":"6","xl":"4"}},[_c('b-card',{attrs:{"bg-variant":"light","title":"Search Criteria"}},[(_vm.hasSearchCriteria(data))?_c('data-table',{attrs:{"showFilter":false,"showPagination":false,"items":data.item.searchCriteria.criteria,"fields":_vm.criteriaFields}}):_vm._e()],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, UserConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const AppConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_APPCONFIG_BY_KEY](context, key: string) {
    return await authorizationApiProvider.getAppConfigByKey(key);
  },

  async [apis.CREATE_APPCONFIG](context, request: { key: string; value: string }) {
    return await authorizationApiProvider.createAppConfig(request);
  },

  async [apis.UPDATE_APPCONFIG](context, request: { key: string; value: string }) {
    return await authorizationApiProvider.updateAppConfig(request);
  },

  async [apis.DELETE_APPCONFIG](context, key: string) {
    return await authorizationApiProvider.deleteAppConfig(key);
  },
};

export default AppConfigActions;

import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import dynamicfulfillmentmodule from './dynamicfulfillment/index';
import globalmodule from './globalmodules/index';

Vue.use(Vuex);

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    dynamicfulfillmentmodule,
    globalmodule,
  },
};

const store = new Vuex.Store<RootState>(storeOptions);
export default store;


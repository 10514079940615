
import { Component } from 'vue-property-decorator';
import FulfillmentTicketStatusLabel from '@/components/FulfillmentAuthorization/FulfillmentTicketStatusLabel.vue';
import RemovedConfigIndexComponent from '@/components/configuration/RemovedConfigIndexComponent.vue';
import { DELETE_STATUS_MAPPING } from '@/store/constants';
import { RoutingAction, } from '@/store/dynamicfulfillment/types';
import StatusMappingIndex from './StatusMappingIndex.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import OrderStatusLabel from '@/components/FulfillmentAuthorization/OrderStatusLabel.vue';


@Component({
  components: { FulfillmentTicketStatusLabel, OrderStatusLabel, RemovedConfigIndexComponent },
})
export default class RemovedStatusMappingIndex extends StatusMappingIndex {
  private configItems: any[]  = null;

  async mounted() {
    switch(this.statusType) {
            case 'vendorticketstatus':
                await authorizationApiProvider.getStatusMapping('vendorticketstatus', true).then((configs) => (this.configItems = configs));
                this.configItems.map(s=> s.documentType = 'VendorTicketStatusMapping');
                break;
            case 'orderstatus':
                await authorizationApiProvider.getStatusMapping('orderstatus', true).then((configs) => (this.configItems = configs));
                this.configItems.map(s=> s.documentType = 'OrderStatusMapping');
                break;
            default:
                break;
    }
  }

  get removedRouteActions() {
    switch(this.statusType) {
            case 'vendorticketstatus':
                return [
                    { name: RoutingAction.Delete, action: DELETE_STATUS_MAPPING, requiredPermission: 'Global-HardDelete' , params: {apiRoute: 'vendorticketstatus', hardDelete: true}},
                ];
            case 'orderstatus':
                return [
                    { name: RoutingAction.Delete, action: DELETE_STATUS_MAPPING, requiredPermission: 'Global-HardDelete' , params: {apiRoute: 'orderstatus', hardDelete: true}},
                ];
            default:
                return [];
    }  
  }
}


import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class PaginationPageSizeControl extends Vue {
  @Prop() private pageSize!: number;
  @Prop() private maxPageSize!: number;
  pageSizeOptions: any = [10, 25, 50, 100, 'All'];
  localPageSize: any = 10;

  mounted() {
    this.localPageSize = this.getNextHighestPage(this.pageSize);
  }

  getNextHighestPage(pageSize: number) {
    const options = this.pageSizeOptions.filter((opt: number | string) => typeof opt === 'number');
    const sortedOptions = options.sort((a: any, b: any) => a - b);
    const nextHighest = sortedOptions.find((opt: any) => opt >= pageSize);

    return nextHighest ?? this.pageSize;
  }

  @Watch('localPageSize', { immediate: false, deep: true })
  private pageSizeHasChanged() {
    this.$emit('pageSizeChanged', typeof this.localPageSize === 'number' ? this.localPageSize : this.maxPageSize);
  }
}


import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import {
  FETCH_APPCONFIG_BY_KEY,
  CREATE_APPCONFIG,
  UPDATE_APPCONFIG,
} from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({})
export default class BrandMappingConfigCreate extends Vue {
  @Prop() private brandMappingConfig: any;
  @Action(FETCH_APPCONFIG_BY_KEY, { namespace })
  private fetchAppConfigByKey: any;
  @Action(CREATE_APPCONFIG, { namespace }) 
  private createAppConfig: any;
  @Action(UPDATE_APPCONFIG, { namespace })
  private updateAppConfig: any;

  private isLoading: boolean = false;

  private displayErrors(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit('e', message);
  }

  private get configState() {
    return (
      (this.brandMappingConfig.brand != null ||
        this.brandMappingConfig.brand === '') &&
      (this.brandMappingConfig.servicePowerCode != null ||
        this.brandMappingConfig.servicePowerCode === '')
    );
  }

  private checkFormValidity(): boolean {
    this.isLoading = true;
    return this.configState;
  }

  public async handleSubmit() {
    if (!this.checkFormValidity()) {
      return;
    }
    const keyPrefix = 'ServicePowerApi';
    const brandName = this.brandMappingConfig.brand
      .replace(/[^0-9a-zA-Z]/gi, '')
      .trim()
      .toLowerCase();
    const key: string = `${keyPrefix}:EquipmentMakeAliasMapping:${brandName}`;

    this.fetchAppConfigByKey(key)
      .then((response: any) => {
        if (response.length > 0 && response[0].key === key) {
          EventBus.$emit(
            'e',
            `Service Power Brand Mapping key named ${key} already exist.`,
          );
        } else {
          this.createAppConfig({
            key: key,
            value: this.brandMappingConfig.servicePowerCode.trim(),
          })
            .then((response: any) => {
              this.updateAppConfig({
                key: `${keyPrefix}:Sentinel`,
                value: Date.now(),
              });
              this.isLoading = false;
              EventBus.$emit(
                's',
                'Service Power Brand Mapping created successfully.',
              );
              this.$router.push({
                name: 'ServicePowerBrandMapping-Index',
              });
            })
            .catch((error: any) => {
              this.displayErrors(error);
              this.isLoading = false;
              return;
            });
        }
        this.isLoading = false;
      })
      .catch((error: any) => {
        this.displayErrors(error);
        this.isLoading = false;
      });
  }
}


import { Component, Vue, Prop } from 'vue-property-decorator';
import { FulfillmentOption, FulfillmentOptionConfig } from '@/store/dynamicfulfillment/types';
import { GET_FULFILLMENT_OPTION_CONFIG } from '@/store/dynamicfulfillment/constants';
import { State, Action, Getter } from 'vuex-class';
const namespace: string = 'dynamicfulfillmentmodule';

@Component
export default class FulfillmentOptionLabel extends Vue {
  @Prop({required: true, type: Number}) private fulfillmentOption!: FulfillmentOption;

  @Getter(GET_FULFILLMENT_OPTION_CONFIG, { namespace }) private getFulfillmentOptionConfig: any;

  private get fulfillmentOptionalLabel(): string {
    const fo = this.getFulfillmentOptionConfig(this.fulfillmentOption) as FulfillmentOptionConfig;
    if (fo != null && fo.userInterfaceSettings != null ) {
      return fo.userInterfaceSettings.label;
    }
    return '';
  }
}

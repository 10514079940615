
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { FETCH_ALL_SUCCESSFUL_AUTH_REQUESTS } from '@/store/dynamicfulfillment/constants';
import { Action } from 'vuex-class';
import AuthChart from '@/components/common/BarChart.vue';
import _ from 'lodash';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { AuthChart } })
export default class FulfillmentAuthRequestsChart extends Vue {
  @Action(FETCH_ALL_SUCCESSFUL_AUTH_REQUESTS, { namespace })
  private fetchAllAuthRequests: any;

  private items: any[] = null;
  private chartDataValue: ChartData = { xData: null, yData: null };
  private xLabel: string = 'Hours';
  private yLabel: string = 'Count of Auths';
  private groupByFieldName: string = 'timeIn24HourFormat';

  public async mounted() {
    this.items = await this.fetchAllAuthRequests();
    this.chartDataValue = await this.GetData(this.items);
  }

  private async GetData(responseData: any[]): Promise<ChartData> {
    const groupedByHours = _.groupBy(responseData, this.groupByFieldName);
    const groupedByHoursItems = Object.keys(groupedByHours).sort();
    const xDataValue: any[] = new Array();
    const yDataValue: any[] = new Array();

    groupedByHoursItems.forEach((key) => {
      if (key !== 'undefined') {
        xDataValue.push(key.toString() + ':00');
        yDataValue.push(groupedByHours[key].length);
        const callsInGroup = groupedByHours[key].length;
      }
    });
    const chartData = {
      xData: xDataValue,
      yData: yDataValue,
    } as ChartData;

    return chartData;
  }
}

export interface ChartData {
  xData: string[];
  yData: string[];
}

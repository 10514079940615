
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DynamicFulfillmentState, LocaleConfig } from '@/store/dynamicfulfillment/types';
import { State } from 'vuex-class';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { ValidationError } from 'vanilla-jsoneditor';

const namespace: string = 'dynamicfulfillmentmodule';

interface ContentErrors {
  validationErrors?: ValidationError[]
}

@Component({ components: {VueJSONEditor} })
export default class LocaleConfigCreateEdit extends Vue {
    @Prop() private config: LocaleConfig;
    @State(namespace) private profile!: DynamicFulfillmentState;

    private mounted() {
        this.emitOnChange({ 
            config: this.config, 
            isValid: false
        });
    }

    private initializeJSON() {
        return JSON.stringify(this.config, null, 2);
    }

    private data() {
        return {
            content: {
                json: undefined as any,
                text: this.initializeJSON(),
            },
            mode: 'text',
            onChange: (content: any, previousContent: any, changeStatus: { contentErrors: ContentErrors, patchResult: any | null }) => {
                if (changeStatus.contentErrors.validationErrors && changeStatus.contentErrors.validationErrors.length === 0) {
                    this.$data.content = content;
                    this.emitOnChange({
                        config : this.$data.mode == 'tree' ? this.$data.content.json : JSON.parse(content.text),
                        isValid : true
                    });
                } else {
                    this.emitOnChange({
                        config: null,
                        isValid: false
                    })
                }
            },
            onChangeMode: (mode: string) => {
                this.$data.mode = mode;
            },
        };
    };

    private emitOnChange(content: {config: LocaleConfig | null, isValid: boolean}) {
        this.$emit('onConfigChange', content);
    }
}


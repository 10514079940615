
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { DynamicFulfillmentState, SourceSystemConfig, SourceSystem, SourceSystemCriteria, SourceSystemSearchCriteria } from '@/store/dynamicfulfillment/types';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import { FETCH_SOURCE_SYSTEM_CONFIGS } from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';
import { isNullOrEmpty } from '@/common/utilities';

@Component({ components: {} })
export default class SearchCriteria extends Vue {
  @Action(FETCH_SOURCE_SYSTEM_CONFIGS, { namespace }) private fetchSourceSystemConfigs: any;
  @State('dynamicfulfillmentmodule') private profile: DynamicFulfillmentState;
  sourceSystem: string = '';
  searchCriteria: SourceSystemSearchCriteria = null;
  selectedValues: { [key: string]: string } = {};
  claimId: string = '';
  defaultSearchCriteria: SourceSystemSearchCriteria = {
    criteria: [
      {
        name: 'Claim Id',
        values: null,
      },
    ],
    delimiter: null,
  };

  async created() {
    await this.fetchSourceSystemConfigs();
    this.sourceSystem = this.sourceSystems && this.sourceSystems.length >= 1 ? this.sourceSystems[0] : this.sourceSystem;
    this.setSourceSystem();
  }

  get selectedValuesString(): string {
    const selectedValues = this.searchCriteria.criteria.map((criteria) => this.selectedValues[criteria.name]).filter(Boolean);

    if (selectedValues.length === 0) {
      return '';
    }

    if (selectedValues.length === 1) {
      return selectedValues[0];
    }

    return selectedValues.join(this.searchCriteria.delimiter);
  }

  get sourceSystems(): string[] {
    if (!this.profile.sourceSystemConfigs) {
      return [];
    }

    return this.profile.sourceSystemConfigs.map((s: SourceSystemConfig) => s.sourceSystem).filter((sourceSystem: string) => this.hasPermission([`SourceSystem-${sourceSystem}`]));
  }

  get searchCriteriaState(): boolean {
    return !isNullOrEmpty(this.claimId) && !isNullOrEmpty(this.sourceSystem);
  }

  hasPermission(features: string[]) {
    return userAuthorizationProviderFactory(this.profile).hasPermission(features);
  }

  setSourceSystem() {
    const sourceSystemConfig = this.profile.sourceSystemConfigs.find((s) => s.sourceSystem === this.sourceSystem);
    if (sourceSystemConfig.searchCriteria && sourceSystemConfig.searchCriteria.criteria && sourceSystemConfig.searchCriteria.criteria.length > 0) {
      this.searchCriteria = sourceSystemConfig.searchCriteria;
      this.setDefaults();
    } else {
      this.searchCriteria = this.defaultSearchCriteria;
    }
  }

  setDefaults() {
    if (this.searchCriteria && this.searchCriteria.criteria) {
      this.searchCriteria.criteria.forEach((criteria: SourceSystemCriteria) => {
        if (criteria.values) {
          if (criteria.values.length === 1) {
            this.$set(this.selectedValues, criteria.name, criteria.values[0]);
          } else if (criteria.defaultValue && criteria.values.includes(criteria.defaultValue)) {
            this.$set(this.selectedValues, criteria.name, criteria.defaultValue);
          }
        }
      });
    }
  }

  addOption(criteriaValues: string[], value: string) {
    if (value) {
      if (!criteriaValues.includes(value)) {
        criteriaValues.push(value);
      }
    }
  }

  emitOnChange() {
    const content = {
      sourceSystem: this.sourceSystem,
      claimId: this.claimId,
      searchCriteriaState: this.searchCriteriaState,
    };
    this.$emit('onConfigChange', content);
  }

  emitSearch() {
    if (this.searchCriteriaState) {
      this.$emit('search');
    }
  }

  @Watch('sourceSystem', { immediate: false })
  async sourceSystemChanged() {
    this.claimId = '';
    this.selectedValues = {};
    this.setSourceSystem();
    this.setDefaults();
    this.emitOnChange();
  }

  @Watch('selectedValues', { immediate: false, deep: true })
  selectedValuesChanged(newVal: any, oldVal: any) {
    // Check that the count of keys with values is equal to the count of keys in searchCriteria.criteria
    const selectedValues = Object.keys(newVal).filter((key) => newVal[key]);
    const isValid = selectedValues.length === this.searchCriteria.criteria.length;
    this.claimId = isValid ? this.selectedValuesString : '';
    this.emitOnChange();
  }

  @Watch('claimId', { immediate: false })
  claimIdChanged(newVal: string, oldVal: string) {
    this.emitOnChange();
  }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import { RepairCatalog, VendorConfig } from '@/store/dynamicfulfillment/types';
import { FETCH_REPAIR_CATALOGS, CREATE_REPAIR_CATALOG, FETCH_VENDOR_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { Action, State } from 'vuex-class';
import { buildErrorMessage } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';
import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue';
import { arraysEqual } from '@/common/utilities';

@Component({
  components: { ViewContent, PerLineTextAreaControl },
})
export default class RepairCatalogCreateEdit extends Vue {
  @Action(FETCH_VENDOR_CONFIGS, { namespace }) fetchVendorConfigs: () => VendorConfig[];
  @Action(FETCH_REPAIR_CATALOGS, { namespace }) fetchRepairCatalogs: () => Promise<RepairCatalog[]>;
  @Action(CREATE_REPAIR_CATALOG, { namespace })
  private createRepairCatalog: (request: { payload: RepairCatalog }) => Promise<RepairCatalog>;

  private repairCatalog: RepairCatalog = {
    id: null,
    vendor: null,
    serviceNetworks: [],
  };
  private repairCatalogList: RepairCatalog[] = [];
  private isLoading: boolean = false;
  private vendorConfigs: VendorConfig[] = null;
  private configuredVendors: string[] = ['ServiceCentral'];

  public async mounted() {
    this.vendorConfigs = await this.fetchVendorConfigs();
    this.repairCatalogList = await this.fetchRepairCatalogs();
  }

  private get isNew() {
    return this.repairCatalog !== null;
  }

  private get pageTitle(): string {
    return this.repairCatalog && !this.isNew ? `${this.repairCatalogName}` : 'Create Repair Catalog';
  }

  private get repairCatalogName(): string {
    return this.repairCatalog ? `${this.repairCatalog.vendor}` : '';
  }

  private updateServiceNetworks(updatedNetworks: any) {
    this.repairCatalog.serviceNetworks = updatedNetworks;
  }

  getServiceNetworks(item: string[]) {
    var sns = [] as string[];
    if (item) {
      item.forEach((x: string) => {
        sns.push(x);
      });
    }
    return sns;
  }

  private get vendorState() {
    return this.repairCatalog.vendor !== null;
  }

  private get serviceNetworksState() {
    return this.repairCatalog.serviceNetworks && this.repairCatalog.serviceNetworks.length >= 1 && !this.repairCatalog.serviceNetworks.some((network) => network.trim() === '');
  }

  private get repairCatalogState() {
    return this.checkFormValidity();
  }

  private get configState() {
    return this.vendorState && this.serviceNetworksState && this.repairCatalogState;
  }

  private get invalidRepairCatalogFeedback() {
    if (this.repairCatalog.vendor && this.repairCatalog.serviceNetworks) {
      return `There is already a repair catalog configured with the provided options`;
    }
  }

  public async handleSubmit() {
    if (!this.checkFormValidity()) {
      return;
    }

    const requestObject: RepairCatalog = {
      id: this.repairCatalog.id,
      vendor: this.repairCatalog.vendor,
      serviceNetworks: this.repairCatalog.serviceNetworks && this.repairCatalog.serviceNetworks.length >= 1 ? this.repairCatalog.serviceNetworks : [],
    };

    this.isLoading = true;

    if (this.isNew) {
      await this.createRepairCatalog({
        payload: requestObject,
      })
        .then(async (response: RepairCatalog) => {
          this.isLoading = false;
          EventBus.$emit('s', 'Repair Catalog saved successfully.');
          this.$router.push({ name: 'RepairCatalogs-Index' });
        })
        .catch((error: any) => {
          this.isLoading = false;
          const message = buildErrorMessage(error);
          EventBus.$emit('e', message);
        });
    }
  }

  private checkFormValidity(): boolean {
    if (this.vendorState) {
      let existingRepairCatalogs: RepairCatalog[] = null;
      const lowerCaseServiceNetworks = this.repairCatalog.serviceNetworks.map(network => network.toLowerCase());

      if (this.isNew) {
        existingRepairCatalogs = this.repairCatalogList.filter((rcl) => {
          const rclServiceNetworks = rcl.serviceNetworks.map(network => network.toLowerCase());
          return rcl.vendor === this.repairCatalog.vendor && 
            (arraysEqual(rclServiceNetworks, lowerCaseServiceNetworks) || 
            rclServiceNetworks.filter((provider) => lowerCaseServiceNetworks.includes(provider)).length >= 1);
        });
      } else {
        existingRepairCatalogs = this.repairCatalogList.filter((rcl) => {
          const rclServiceNetworks = rcl.serviceNetworks.map(network => network.toLowerCase());
          return rcl.vendor === this.repairCatalog.vendor && 
            rcl.id !== this.repairCatalog.id && 
            (arraysEqual(rclServiceNetworks, lowerCaseServiceNetworks) || 
            rclServiceNetworks.filter((provider) => lowerCaseServiceNetworks.includes(provider)).length >= 1);
        });
      }
      return existingRepairCatalogs.length === 0;
    }
    return true;
  }

  private formatter(value: string) {
    return value.replace(',', '').replace("'", '');
  }

  get allowedVendors() {
    const vendors = [] as string[];
    if (this.vendorConfigs && this.configuredVendors) {
      for (const [key, value] of Object.entries(this.vendorConfigs)) {
        // Temporarily limiting Upload to what we currently have capability for i.e. ServiceCentral
        // vendors.push(value.id);
        if (this.configuredVendors.includes(value.id)) {
          vendors.push(value.id);
        }
      }
    }
    return vendors;
  }
}


import { FETCH_PRICE_LIST_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { DynamicFulfillmentState, FulfillmentOption, IPriceListConfig, DeviceTypeIdLookupStrategy, PriceListItem } from '@/store/dynamicfulfillment/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DataTable from '../common/DataTable.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({components: {DataTable}})
export default class PriceListConfigModal extends Vue {
    @Prop() private programId: string;
    @Action(FETCH_PRICE_LIST_CONFIGS, { namespace }) private fetchPriceListConfigs: (request: {route: string, forceFetch: boolean}) => Promise<any>;
    @State(namespace) private profile!: DynamicFulfillmentState;
    private priceListsFields: any[] = [
    'id',
    'vendor',
    'serviceProviders',
    'fulfillmentOption',
    {
      key: 'priceListItemCount',
      label: 'Price List Item Count',
    },
    {
      key: 'invoiceTaxStrategy',
      label: 'Invoice Tax Strategy',
    },
    'deviceTypeIdLookupStrategy',
    'lastModifiedOnUtc',
    'lastModifiedBy',
  ];
  private apiRoute: string = 'pricelists';
  private configs: IPriceListConfig<PriceListItem>[] = null;

  private async mounted() {
    await this.fetchPriceListConfigs({route: this.apiRoute, forceFetch: false});
    this.configs = this.profile.fullPriceListConfigs.filter( (config: IPriceListConfig<PriceListItem>) => config.programIds.find( (programId: string) => programId === this.programId) === this.programId);
  }

  private getFulfillmentOptionName(fulfillmentOption: number) {
    return FulfillmentOption[fulfillmentOption];
  }
}


import { StoreHours } from '@/store/globalmodules/types';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AddedDaysComponent extends Vue {
  @Prop() private addedDays: StoreHours[];

  private removeDay(day: StoreHours) {
    const index = this.addedDays.indexOf(day, 0);
    if (index > -1) {
      this.addedDays.splice(index, 1);
    }
  }
}

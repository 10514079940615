
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ProductMappingAttribute } from '@/store/dynamicfulfillment/types';
import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue';

@Component({ components: { PerLineTextAreaControl } })
export default class ProductMappingAttributesEdit extends Vue {
  @Prop() private items: ProductMappingAttribute[];

  private mounted() {
    this.emitOnChange({
      items: this.items,
      isValid: !this.hasEmptyType && !this.hasDuplicateNames,
    });
  }

  private formatter(value: string) {
    return value.replace(',', '').replace("'", '');
  }

  private updateValues(request: { index: number; values: string[] }) {
    this.items[request.index].values =
      request.values[0] === '' ? [] : request.values;
  }

  private nameState(index: number): boolean {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[index] === this.items[i]) {
        continue;
      }

      if (
        this.items[index].name.toLowerCase() ===
        this.items[i].name.toLowerCase()
      ) {
        return false;
      }
    }

    return true;
  }

  private typeState(index: number): boolean {
    return this.items[index].type !== null;
  }

  private valueChanged() {
    this.emitOnChange({
      items: this.items,
      isValid: !this.hasEmptyType && !this.hasDuplicateNames,
    });
  }

  private typeChanged(request: { index: number; value: string }) {
    if (request.value === 'Range') {
      this.items[request.index].values = [];
    }

    this.emitOnChange({
      items: this.items,
      isValid: !this.hasEmptyType && !this.hasDuplicateNames,
    });
  }

  private get hasDuplicateNames(): boolean {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      const index = this.items.findIndex(
        (obj) => obj.name.toLowerCase() === item.name.toLocaleLowerCase(),
      );
      if (index !== -1 && index !== i) {
        return true;
      }
    }

    return false;
  }

  private get hasEmptyType(): boolean {
    return this.items.some((item) => item.type === null);
  }

  private addItem() {
    const newProductMappingAttribute: ProductMappingAttribute = {
      name: '',
      weight: 0,
      values: null,
      type: null,
      isRequired: false,
      path: null,
    };

    this.items.push(newProductMappingAttribute);
  }

  private removeItem(index: number) {
    this.items.splice(index, 1);
  }

  private emitOnChange(content: {
    items: ProductMappingAttribute[];
    isValid: boolean;
  }) {
    this.$emit('onChanged', content);
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DateTime } from "luxon";

@Component
export default class StoreHour extends Vue {
    @Prop() private startTime: string;
    @Prop() private endTime: string;
    @Prop() private day: string;

    private storeHourStartState: boolean = null;
    private storeHourEndState: boolean = null;
    private storeHourState: boolean = null;
    private storeStart: string = '';
    private storeEnd: string = '';

    private mounted() {
        this.storeStart = this.startTime;
        this.storeEnd = this.endTime;
    }

    @Watch('storeStart')
    private StartDateText_Changed() {
        this.storeHourChanged();
    }

    @Watch('storeEnd')
    private EndDateText_Changed() {
        this.storeHourChanged();
    }

    private storeHourChanged() {
        const startDate = DateTime.fromISO(this.storeStart).toFormat('HH:mm');
        const endDate = DateTime.fromISO(this.storeEnd).toFormat('HH:mm');

        if (endDate < startDate) {
            this.storeHourState = false;
            this.$emit('update:isValid', false);
        } else {
            this.storeHourState = null;
            this.$emit('update:isValid', true);
            this.$emit('update:startTime', this.storeStart);
            this.$emit('update:endTime', this.storeEnd);
        }
    }
}

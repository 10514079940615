
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DynamicFulfillmentState, EquipmentExclusionItem, FulfillmentOption, DeviceTypeIdLookupStrategy, EquipmentExclusionReason, DamageType } from '@/store/dynamicfulfillment/types';
import { State } from 'vuex-class';

const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: {} })
export default class EquipmentExclusionItemCreateEdit extends Vue {
  @Prop() id: string;
  @Prop() configId!: string;
  @Prop() config: EquipmentExclusionItem;
  @State(namespace) profile!: DynamicFulfillmentState;

  mounted() {
    this.config.equipmentExclusionConfigId = this.configId;
  }

  get isNew() {
    return this.config._etag === null;
  }

  get validForm() {
    return [this.isNew ? true : this.config.equipmentExclusionConfigId, this.config.equipmentIdentifier, this.config.equipmentIdentifierLookupStrategy, this.config.reason, this.config.startDate].every((prop) => prop != null && prop != '');
  }

  get allowedEquipmentIdentifierLookupStrategy() {
    return Object.keys(DeviceTypeIdLookupStrategy);
  }

  get allowedReasons() {
    return Object.keys(EquipmentExclusionReason);
  }

  get allowedDamageTypes() {
    return Object.entries(DamageType)
      .filter((e: any) => !isNaN(e[0] as any))
      .map(([index, damageType]) => damageType);
  }

  get minDate() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return new Date(today);
  }

  clearEndDate() {
    this.config.endDate = null;
  }

  emitOnChange(content: { config: EquipmentExclusionItem | null; isValid: boolean }) {
    this.$emit('onConfigChange', content);
  }

  @Watch('config', { immediate: false, deep: true })
  private configChange() {
    this.emitOnChange({ config: this.config, isValid: this.validForm });
  }
}

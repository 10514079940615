
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import VendorConfigCreateEdit from '@/components/configuration/VendorConfigCreateEdit.vue';
import { VendorConfig } from '@/store/dynamicfulfillment/types';
import {
  FETCH_VENDOR_CONFIG,
  VENDOR_CONFIG_DELETE,
  VENDOR_CONFIG_SAVE,
} from '@/store/constants';

@Component({ components: { ConfigEditComponent, VendorConfigCreateEdit } })
export default class VendorConfigCreate extends Vue {
  @Prop() private id!: string;

  private defaultConfig: VendorConfig = {
    id: null,
    _etag: null,
    userInterfaceSettings: {
      descriptionItems: [],
      descriptionItemsShadow: [],
      showAdvancedExchangeLowInventoryMessage: false,
      settings: null,
    },
    
    defaultWarrantyPeriod: null,
    serviceProviderConfigs: [],
    vendorStatusPathConfig: {
      statusPath: null,
      statusReasonPath: null,
    },
    statusMapperStrategy: null,
    isNotesAllowed: false,
    
  };

  private get fetchAction() {
    return FETCH_VENDOR_CONFIG;
  }

  private get saveAction() {
    return VENDOR_CONFIG_SAVE;
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { PermissionsConfig, RoleConfig } from '@/store/dynamicfulfillment/types';
import { Action } from 'vuex-class';
import { Permission } from '@/store/dynamicfulfillment/types';
import { FETCH_PERMISSIONS } from '@/store/dynamicfulfillment/constants';
import DataTable from '@/components/common/DataTable.vue';
import Multiselect from 'vue-multiselect';
import { sort, sortByProperty } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable, Multiselect } })
export default class RolesCreateEdit extends Vue {
  @Prop() config: RoleConfig;
  @Action(FETCH_PERMISSIONS, { namespace }) private fetchPermission: () => PermissionsConfig[];
  private permissions: Permission[] = [];
  permissionsMapped: {category: string, options: string[], permissions: string[] }[] = [];

  get isNew() {
    return this.config._etag === null;
  }

  async mounted() {
    await this.fetchPermissions();
  }

  async fetchPermissions() {
    const permissions = await this.fetchPermission();
    this.permissions = permissions[0].categories;

    this.permissions.forEach((x) => {
      let map = {
        category: x.category,
        options: sort(x.permissions),
        permissions: x.permissions.filter((y) => {
          return this.config.permissions.indexOf(`${x.category}-${y}`) > -1;
        }),
      };
      this.permissionsMapped.push(map);
    });
    
    this.permissionsMapped = sortByProperty(this.permissionsMapped, 'category');
  }

  inputChanged(request: { id: string; value: string[] }) {
    this.permissions
      .find((i) => i.category === request.id)
      .permissions.forEach((permission) => {
        const fullPermissionName = `${request.id}-${permission}`;

        if (request.value.includes(permission)) {
          if (!this.config.permissions.includes(fullPermissionName)) {
            this.config.permissions.push(fullPermissionName);
          }
        } else {
          this.removePermissions(fullPermissionName);
        }
      });
  }

  removePermissions(selectedPermissions: string) {
    this.config.permissions = this.config.permissions.filter((item) => item !== selectedPermissions);
  }
}

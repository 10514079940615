
import { GET_FULFILLMENTAUTHORIZATION_FILTER_LOG } from '@/store/dynamicfulfillment/constants';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import DataTable from '@/components/common/DataTable.vue';
import { FilterAction, FulfillmentAuthorizationFilterLog } from '@/store/dynamicfulfillment/types';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import {jsonToText} from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({components: {DataTable, VueJSONEditor}})
export default class FulfillmentAuthorizationFilterLogs extends Vue {
    @Prop() private id: string;
    @Action(GET_FULFILLMENTAUTHORIZATION_FILTER_LOG, { namespace }) private getFilterLogsById: any;
    private filterActions: FilterAction[] = [];
    private fields: any[] = ['filterType', 'filterName', 'filteredObjectNames'];
    private pageLoaded: boolean = false;
    private jsonEditorText: any = jsonToText;

    private async mounted() {
        const filterLog = await this.getFilterLogsById(this.id);
        this.filterActions = filterLog ? filterLog.filterActions : [];
        this.pageLoaded = true;
    }
}

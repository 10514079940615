
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  LegacyRepairStatus,
} from '@/store/dynamicfulfillment/types';
import Badge from '@/common/badge';

@Component
export default class LegacyClaimStatusLabel extends Vue {
    @Prop({required: true, type: Number}) private status!: LegacyRepairStatus;

    private get badge(): Badge {
    switch (this.status) {
      case LegacyRepairStatus.Created:
        return new Badge('New', 'info');
      case LegacyRepairStatus.InProcess:
        return new Badge('In Progress', 'primary');
      case LegacyRepairStatus.Cancel:
        return new Badge('Cancelled', 'warning');
      case LegacyRepairStatus.Reject:
        return new Badge('Rejected', 'danger');
      case LegacyRepairStatus.Fulfilled:
        return new Badge('Fulfilled', 'success');
      default:
        throw new Error(`Unmapped status type: ${this.status}`);
    }
  }
}

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, EquipmentExclusionConfig, EquipmentExclusionItem } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { filterConfigsByProgramId } from '@/common/utilities';

const EquipmentExclusionConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_EQUIPMENT_EXCLUSION_CONFIGS](context) {
    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    let equipmentExclusionConfigs = context.state.equipmentExclusionConfigs;
    if (equipmentExclusionConfigs.length > 0) {
      return equipmentExclusionConfigs;
    }
    equipmentExclusionConfigs = await authorizationApiProvider.getEquipmentExclusionConfigs();

    equipmentExclusionConfigs = filterConfigsByProgramId(
      equipmentExclusionConfigs,
      (config) => config.programIds,
      () => context.state.programConfigs.map((config) => config.id),
    );

    context.commit(apis.SET_EQUIPMENT_EXCLUSION_CONFIGS, equipmentExclusionConfigs);
    return equipmentExclusionConfigs;
  },

  async [apis.FETCH_EQUIPMENT_EXCLUSION_ITEMS](context, request: { id: string }) {
    return await authorizationApiProvider.getEquipmentExclusionItems(request.id);
  },

  async [apis.FETCH_EQUIPMENT_EXCLUSION_CONFIG](context, id: string) {
    const equipmentExclusionListConfig = context.state.equipmentExclusionConfigs.find((config: EquipmentExclusionConfig) => config.id === id);
    return equipmentExclusionListConfig ? equipmentExclusionListConfig : await authorizationApiProvider.getEquipmentExclusionConfigById(id);
  },

  async [apis.CREATE_EQUIPMENT_EXCLUSION_CONFIG](context, config: EquipmentExclusionConfig) {
    await authorizationApiProvider.createEquipmentExclusionConfig(config);
    context.commit(apis.RESET_EQUIPMENT_EXCLUSION_CONFIGS);
    return await context.dispatch(apis.FETCH_EQUIPMENT_EXCLUSION_CONFIGS);
  },

  async [apis.EDIT_EQUIPMENT_EXCLUSION_CONFIG](context, config: EquipmentExclusionConfig) {
    await authorizationApiProvider.editEquipmentExclusionConfig(config);
  },

  async [apis.EQUIPMENT_EXCLUSION_CONFIG_DELETE](context, params: { config: EquipmentExclusionConfig; hardDelete: boolean }) {
    await authorizationApiProvider.deleteEquipmentExclusionConfig(params.config, params.hardDelete).then(() => {
      context.dispatch(apis.CLEAR_CACHE);
      context.commit(apis.FETCH_EQUIPMENT_EXCLUSION_CONFIGS);
    });
  },

  async [apis.CREATE_EQUIPMENT_EXCLUSION_ITEM](context, config: EquipmentExclusionItem) {
    const savedItem = await authorizationApiProvider.createEquipmentExclusionItem(config);
    context.dispatch(apis.CLEAR_CACHE);
    await context.dispatch(apis.FETCH_EQUIPMENT_EXCLUSION_ITEMS, config.equipmentExclusionConfigId);
    return savedItem;
  },

  async [apis.FETCH_EQUIPMENT_EXCLUSION_ITEM](context, request: { configId: string; id: string }) {
    return await authorizationApiProvider.getEquipmentExclusionItem(request.configId, request.id);
  },

  async [apis.EDIT_EQUIPMENT_EXCLUSION_ITEM](context, config: EquipmentExclusionItem) {
    await authorizationApiProvider.editEquipmentExclusionItem(config);
    context.dispatch(apis.CLEAR_CACHE);
    await context.dispatch(apis.FETCH_EQUIPMENT_EXCLUSION_ITEMS, config.id);
  },

  async [apis.EQUIPMENT_EXCLUSION_ITEM_DELETE](context, params: { config: EquipmentExclusionItem }) {
    await authorizationApiProvider.deleteEquipmentExclusionItem(params.config).then(() => {
      context.dispatch(apis.CLEAR_CACHE);
    });
  },
};

export default EquipmentExclusionConfigActions;

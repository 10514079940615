
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ServiceManufacturersCreateEdit from '@/components/ServiceCenters/ServiceManufacturersCreateEdit.vue';
import { ServiceManufacturer } from '@/store/globalmodules/types';
import {
  FETCH_SERVICE_MANUFACTURER,
  SAVE_SERVICE_MANUFACTURER,
} from '@/store/constants';

@Component({
  components: { ConfigEditComponent, ServiceManufacturersCreateEdit },
})
export default class ServiceManufacturersCreate extends Vue {
  @Prop() private id!: string;

  private defaultConfig: ServiceManufacturer = {
    id: null,
    eTag: null,
    displayName: null,
    allowedValues: [],
  };

  private get fetchAction() {
    return FETCH_SERVICE_MANUFACTURER;
  }

  private get saveAction() {
    return SAVE_SERVICE_MANUFACTURER;
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import EquipmentExclusionItemCreateEdit from '@/components/configuration/EquipmentExclusionItemCreateEdit.vue';
import { FETCH_EQUIPMENT_EXCLUSION_ITEM, EDIT_EQUIPMENT_EXCLUSION_ITEM, FETCH_EQUIPMENT_EXCLUSION_CONFIG } from '@/store/dynamicfulfillment/constants';
import { EquipmentExclusionConfig } from '@/store/dynamicfulfillment/types';
import { Action } from 'vuex-class';

@Component({ components: { ConfigEditComponent, EquipmentExclusionItemCreateEdit } })
export default class EquipmentExclusionItemEdit extends Vue {
  @Prop() id!: string;
  @Prop() configId!: string;
  @Action('dynamicfulfillmentmodule/' + FETCH_EQUIPMENT_EXCLUSION_CONFIG) fetchEquipmentExclusionConfig: any;
  editedConfig: any = {};
  equipmentExclusionConfig: EquipmentExclusionConfig = {
    name: null,
    fulfillmentOption: null,
    programIds: [],
    items: [],
    id: null,
    _etag: null,
  };
  isValid: boolean = false;

  async created() {
    await this.getConfig();
  }

  get indexRoute() {
    return { name: 'EquipmentExclusionItems-Index', params: { id: this.configId } };
  }

  get fetchAction() {
    return 'dynamicfulfillmentmodule/' + FETCH_EQUIPMENT_EXCLUSION_ITEM;
  }

  get saveAction() {
    return 'dynamicfulfillmentmodule/' + EDIT_EQUIPMENT_EXCLUSION_ITEM;
  }

  get fetchParams() {
    return {
      configId: this.configId,
      id: this.id,
    };
  }

  async getConfig() {
    const exclusionConfig = (await this.fetchEquipmentExclusionConfig(this.configId)) as EquipmentExclusionConfig;
    this.equipmentExclusionConfig = exclusionConfig;
  }

  get getConfigName() {
    return this.equipmentExclusionConfig ? this.equipmentExclusionConfig.name : this.id;
  }

  onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.editedConfig = request.config;
    }
  }
}

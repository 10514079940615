import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ListItem from '@/components/common/ListItem.vue';
import Loading from '@/components/common/Loading.vue';
import FulfillmentOptionLabel from '@/components/common/FulfillmentOptionLabel.vue';
import AddedDaysComponent from '@/components/common/AddedDays.vue';
import FulfillmentOptionCategoryLabel from '@/components/common/FulfillmentOptionCategoryLabel.vue';
import PerilLabel from '@/components/common/PerilLabel.vue';
import ClipboardText from '@/components/common/ClipboardText.vue';
import vSelect from 'vue-select';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faFileExcel, faFileCsv, faUpload, faGear, faPenToSquare, faTrashCan, faCopy, faClipboard, faCog, faCheck, faPlay, faHourglassStart, faCircleXmark, faClock, faArrowUp, faTriangleExclamation, faChevronRight, faChevronDown, faTimes, faCircleNotch, faRedoAlt, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Default from '@/layouts/Default.vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/dist/vue-select.css';
import EventBus from '@/common/event.bus';
import VueSmartWidget from 'vue-smart-widget';
import { SmartWidget, SmartWidgetGrid } from 'vue-smart-widget';
import VueToastr from 'vue-toastr';
import VeeValidate from 'vee-validate';
import JSONView from 'vue-json-component';
import { copyToClipboard, extractContent } from '@/common/utilities';
import VueFormGenerator from 'vue-form-generator';
import DatePicker from '@/components/customControls/DatePicker.vue';
import CheckBoxSwitch from '@/components/customControls/CheckBoxSwitch.vue';
import { MdSteppers, MdButton } from 'vue-material/dist/components';
import { distinct } from './common/functions.helpers';
import { numberValidator, uniqueValueValidator } from './common/validators';
import Multiselect from 'vue-multiselect';

// use plugin
Vue.use(VueToastr);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueSmartWidget);
Vue.use(VeeValidate, {
   // This is the default
   inject: true,
   // Important to name this something other than 'fields'
   fieldsBagName: 'veeFields',
   // This is not required but avoids possible naming conflicts
   errorBagName: 'veeErrors',
});
Vue.use(MdSteppers);
Vue.use(MdButton);

declare var window: any;

library.add(fas,far, faFileExcel, faFileCsv, faUpload, faGear, faPenToSquare, faTrashCan, faClipboard, faCog, faCheck, faClock, faHourglassStart, faCircleXmark, faArrowUp, faTriangleExclamation, faChevronRight, faChevronDown, faTimes, faCircleNotch, faCopy, faRedoAlt, faClockRotateLeft);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('ListItem', ListItem);
Vue.component('loading', Loading);
Vue.component('default-layout', Default);
Vue.component('added-days', AddedDaysComponent);
Vue.component('fulfillment-option-label', FulfillmentOptionLabel);
Vue.component('fulfillment-option-category-label', FulfillmentOptionCategoryLabel);
Vue.component('peril-label', PerilLabel);
Vue.component('v-select', vSelect);
Vue.component('SmartWidgetGrid', SmartWidgetGrid);
Vue.component('SmartWidget', SmartWidget);
Vue.component('clipboard-text', ClipboardText);
Vue.use(JSONView);
Vue.use(VueFormGenerator, {
  validators: {
      numberValidator: numberValidator,
      uniqueValueValidator: uniqueValueValidator,
  }
});
Vue.component('field-vfj-datepicker', DatePicker);
Vue.component('field-vfj-checkboxswitch',CheckBoxSwitch);
Vue.component('multiselect', Multiselect);

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {    
    EventBus.$on('s', (data: any) => { (this as any).$toastr.s(data); });
    EventBus.$on('e', (data: any) => { 
      (this as any).$toastr.Add({
        name: "errorToast",
        clickClose: true, // Click Close Disable
        timeout: 0, // Remember defaultTimeout is 5 sec..
        type: "error",
        msg: data,
        progressbar: false,
        onClicked: () => {
          copyToClipboard(extractContent(data));
        }
      });
    });
    EventBus.$on('w-noexp', (data: any) => { 
      (this as any).$toastr.Add({
        name: "warningToast",
        clickClose: true, // Click Close Disable
        timeout: 0, // Remember defaultTimeout is 5 sec..
        type: "warning",
        msg: data,
        progressbar: false,
        onClicked: () => {
          copyToClipboard(extractContent(data));
        }
      });
    });
    EventBus.$on('w', (data: any) => { (this as any).$toastr.w(data); });
    EventBus.$on('i', (data: any) => { (this as any).$toastr.i(data); });
  },
}).$mount('#app');

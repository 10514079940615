<script>
import { Bar } from 'vue-chartjs';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    xLabel: {
      type: String,
      required: true,
    },
    yLabel: {
      type: String,
      required: true,
    },
  },
  extends: Bar,
  data() {
    return {
      chartData: {
        labels: this.data.xData,
        datasets: [
          {
            label: this.yLabel,
            data: this.data.yData,
            fill: false,
            backgroundColor: 'rgba(71, 183,132,.5)',
            borderWidth: 0,
          },
        ],
      },
      options: {
        layout: {
          padding: {
              left: 4,
              right: 10,
              top: 10,
              bottom: 4,
          },
        },
        scales: {
          pointLabels : {
           fontStyle: 'bold',
        },
          gridLines: {
            showBorder: false,
            drawBorder: true,
          },
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                padding: 2,
                autoSkipPadding: 15,
                maxRotation: 0,
                precision: 0,
                min: 0,
              },
              scaleLabel: {
                display: true,
                labelString: this.yLabel,
                fontStyle: 'bold',
              },
              gridLines: {
                display: true,
                drawOnChartArea: false,
                drawTicks: true,
                tickMarkLength: 6,
                color: '#D3D3D3',
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                padding: 6,
              },
              scaleLabel: {
                display: true,
                labelString: this.xLabel,
                fontStyle: 'bold',
              },
            },
          ],
        },
        legend: {
          display: false,
          labels: {
                fontColor: '#6c757d',
                boxWidth: 12,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>

import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  ServiceNetworkConfig
} from '@/store/globalmodules/types';
import CustomAttributesCreateEditComponent from '../configuration/CustomAttributesCreateEditComponent.vue';

@Component({ components: { CustomAttributesCreateEditComponent } })
export default class ServiceNetworkCreateEdit extends Vue {
  @Prop() private config: ServiceNetworkConfig;
  attributeState: boolean = false;

  private get isNew() {
    return this.config.eTag === null;
  }

  private get serviceNetworkIdState() {
    let validId = Boolean(
      typeof this.config.id != 'undefined' && this.config.id,
    );
    return validId;
  }

  private async created() {
    this.config.isEdit = this.isNew ? false : true;
  }

  validateConfig() {
      this.$emit('onConfigChange', {
          config: this.config,
          isValid: this.isNew ? this.serviceNetworkIdState : (this.attributeState && this.serviceNetworkIdState)
      });
  }

  private customAttributesChanged(content: {
    config: ServiceNetworkConfig | null;
    isValid: boolean;
  }) {
    this.attributeState = content.isValid;
    this.validateConfig();
  }

  @Watch('config', { immediate: false, deep: true })
  private configChanged() {
      this.validateConfig();
  }
}

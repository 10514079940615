
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ListItem from '@/components/common/ListItem.vue';
import { BasicEquipment, DamageType, EquipmentType } from '@/store/dynamicfulfillment/types';
import { humanizeString } from '@/common/utilities';

@Component({ components: { ListItem } })
export default class ClaimedEquipment extends Vue {
  @Prop() private basicEquipment: BasicEquipment;

  private humanizeItem(value: string): string {
    return humanizeString(value);
  }

  private formatter(propName: any, value: any) {
    switch (propName) {
      case 'serviceSkus':
        return this.serviceSkus(value);
      case 'damageTypes':
        return this.damageTypes(value);
      case 'equipmentType':
        return EquipmentType[value];
      default:
        return value;
    }
  }

  private get damageTypes() {
    return (data: any[]) => {
      if (data === null || data === undefined) {
        return '';
      } else {
        const types = data.map((d) => DamageType[d]);
        return types.join(', ');
      }
    };
  }
  private get serviceSkus() {
    return (data: any[]) => {
      if (data === null || data === undefined) {
        return '';
      } else {
        return data.join(', ');
      }
    };
  }
}


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_EQUIPMENT_EXCLUSION_ITEMS, EQUIPMENT_EXCLUSION_ITEM_DELETE, FETCH_EQUIPMENT_EXCLUSION_CONFIG, FETCH_CONFIG_HISTORY, EDIT_EQUIPMENT_EXCLUSION_ITEM } from '@/store/constants';
import { RoutingAction, EquipmentExclusionConfig, ConfigType } from '@/store/dynamicfulfillment/types';
import ProgramConfigModal from '@/components/configuration/ProgramConfigModal.vue';
import { Action } from 'vuex-class';

@Component({ components: { ConfigIndexComponent, ProgramConfigModal } })
export default class EquipmentExclusionItemsIndex extends Vue {
  @Prop() id!: string;
  @Action(FETCH_EQUIPMENT_EXCLUSION_CONFIG) fetchEquipmentExclusionConfig: any;
  equipmentExclusionConfig: EquipmentExclusionConfig = {
    name: null,
    fulfillmentOption: null,
    programIds: [],
    items: [],
    vendor: null,
    id: null,
    _etag: null,
  };
  fields: any[] = [
    {
      key: 'equipmentIdentifier',
      filterable: true,
    },
    {
      key: 'equipmentIdentifierLookupStrategy',
      filterable: true,
      sortable: true,
    },
    {
      key: 'damageTypes',
      filterable: true,
      formatter: (value: any, key: any, item: any) => {
        return item.damageTypes && item.damageTypes.length > 0 ? item.damageTypes.join('<br />') : '';
      },
    },
    {
      key: 'reason',
      filterable: true,
    },
    {
      key: 'startDate',
      filterable: true,
    },
    {
      key: 'endDate',
      filterable: true,
    },
  ];
  configType: ConfigType = ConfigType.EquipmentExclusionItems;

  async created() {
    await this.getConfig();
  }

  get fetchParams() {
    return {
      id: this.id,
    };
  }

  get createEditParams() {
    return {
      configId: this.id,
    };
  }

  getConfigName() {
    return this.equipmentExclusionConfig ? this.equipmentExclusionConfig.name : this.id;
  }

  async getConfig() {
    const exclusionConfig = (await this.fetchEquipmentExclusionConfig(this.id)) as EquipmentExclusionConfig;
    this.equipmentExclusionConfig = exclusionConfig;
  }

  get routeActions() {
    return [
      { name: RoutingAction.Edit, params: this.createEditParams, route: 'EquipmentExclusionItem-Edit', requiredPermission: 'Configuration-EquipmentExclusion-Edit' },
      { name: RoutingAction.Create, params: this.createEditParams, route: 'EquipmentExclusionItem-Create', requiredPermission: 'Configuration-EquipmentExclusion-Create' },
      { name: RoutingAction.Fetch, params: this.fetchParams, action: FETCH_EQUIPMENT_EXCLUSION_ITEMS, requiredPermission: 'Configuration-EquipmentExclusion-View' },
      { name: RoutingAction.Remove, action: EQUIPMENT_EXCLUSION_ITEM_DELETE, requiredPermission: 'Configuration-EquipmentExclusion-Delete' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-EquipmentExclusion-View' },      
      { name: RoutingAction.Revert, action: EDIT_EQUIPMENT_EXCLUSION_ITEM, requiredPermission: 'Configuration-EquipmentExclusion-Edit' },
    ];
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import UserConfigCreateEdit from '@/components/configuration/UserConfigCreateEdit.vue';
import { FETCH_USER_CONFIG, EDIT_USER_CONFIG } from '@/store/dynamicfulfillment/constants';
import { UserConfig } from '@/store/dynamicfulfillment/types';

@Component({ components: { ConfigEditComponent, UserConfigCreateEdit } })
export default class UserConfigEdit extends Vue {
    @Prop() id!: string;
    isValid: boolean = true;
    config: UserConfig = {
        _etag: null,
        id: null,
        name: null,
        roles: [],
        permissions: []
    }

    get fetchAction() {
        return 'dynamicfulfillmentmodule/' + FETCH_USER_CONFIG;
    }

    get saveAction() {
        return 'dynamicfulfillmentmodule/' + EDIT_USER_CONFIG;
    }

    onConfigChange(request: { config: UserConfig | null; isValid: boolean }) {

    }
}

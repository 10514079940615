import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, LocaleConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const LocaleConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_LOCALE_CONFIGS](context) {
    let localeConfigs = await authorizationApiProvider.getLocaleConfigs();
    context.commit(apis.SET_LOCALE_CONFIGS, localeConfigs);
    return localeConfigs;
  },

  async [apis.FETCH_LOCALE_CONFIG](context, id: string) {
    if (!context.state.localeConfigs || context.state.localeConfigs.length == 0) {
      await context.dispatch(apis.FETCH_LOCALE_CONFIGS);
    }
    return context.state.localeConfigs.find((c) => c.id === id);
  },

  async [apis.UPDATE_LOCALE_CONFIG](context, localeConfig: LocaleConfig) {
    await authorizationApiProvider.updateLocaleConfig(localeConfig);
    context.commit(apis.RESET_LOCALE_CONFIGS);
    await context.dispatch(apis.FETCH_LOCALE_CONFIGS);
  },

  async [apis.CREATE_LOCALE_CONFIG](context, localeConfig: LocaleConfig) {
    if (localeConfig._etag == null && context.state.localeConfigs.find((c) => c.id === localeConfig.id)) {
      throw Error(`LocaleConfig with Id ${localeConfig.id} already exists.`);
    }
    await authorizationApiProvider.createLocaleConfig(localeConfig);
    context.commit(apis.RESET_LOCALE_CONFIGS);
    await context.dispatch(apis.FETCH_LOCALE_CONFIGS);
  },

  async [apis.DELETE_LOCALE_CONFIG](context, id: string) {
    await authorizationApiProvider.deleteLocaleConfig(id);
    context.commit(apis.RESET_LOCALE_CONFIGS);
    await context.dispatch(apis.FETCH_LOCALE_CONFIGS);
  },
};

export default LocaleConfigActions;

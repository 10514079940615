
import { DynamicFulfillmentState, Peril } from '@/store/dynamicfulfillment/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

@Component
export default class PerilLabel extends Vue {
  @Prop({required: true, type: Number}) private peril!: number;
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;

  private get label() {
    return Peril[this.peril];
  }
}

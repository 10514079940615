
import { Component, Prop, Vue } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import UploadComponent from '@/components/common/UploadComponent.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { displayErrors } from '@/common/functions.helpers';
import { RepairCatalog } from '@/store/dynamicfulfillment/types';

@Component({
  components: { ViewContent, UploadComponent },
})
export default class RepairCatalogItemsUpload extends Vue {
  @Prop() private catalogItem: RepairCatalog;
  @Prop() private catalogId: string;

  private resetForm: boolean = false;
  private pageTitle: string = 'Upload repair catalog';
  private catalog: RepairCatalog = null;
  private isLoading: boolean = false;

  private mounted() {
    if (!this.catalogItem) {
      this.$router.push({ name: 'RepairCatalogs-Index' });
    }
    this.catalog = this.catalogItem;
  }

  public get vendorName(): string {
    return this.catalogItem ? this.catalogItem.vendor : '';
  }

  private validation(file: File) {
    if (file) {
      const extension = file.name.split('.').pop();
      return extension === 'csv' || extension === 'xlsx';
    }
    return true;
  }

  private formatFileName(files: File[]) {
    const fileName = files[0].name;
    return fileName.length > 50 ? [fileName.substring(0, 50), '...'].join() : fileName;
  }

  private invalidFeedBack() {
    return 'Invalid file. Select .csv or .xlsx file';
  }

  private async upload(eventData: any) {
    this.isLoading = true;
    await authorizationApiProvider
      .uploadRepairCatalogItems(eventData.file, this.catalog.id, this.catalog.vendor)
      .then((response: any) => {
        if (response && response.isUploaded) {
          this.isLoading = false;
          EventBus.$emit('s', `${eventData.file.name} uploaded successfully.`);
          this.resetForm = true;
          this.$router.push({ name: 'RepairCatalogs-Index' });
          return;
        }
        this.isLoading = false;
        EventBus.$emit('e', `<h4>Upload failed.</h4>${this.formatToastMessage(response)}`);
      })
      .catch((error) => {
        this.isLoading = false;
        EventBus.$emit('e', `<h3>Upload failed. The following error(s) occured.</h3>${this.formatToastMessage(error)}`);
      });
  }

  private formatToastMessage(error: any) {
    if (error && error.response && error.response.data && error.response.data.errors) {
      return displayErrors(error.response.data.errors);
    }
    if (error && error.response && error.response.data) {
      return displayErrors(error.response.data);
    }
    if (error && error.response && error.response.errors) {
      return displayErrors(error.response.errors);
    }
    return error;
  }
}


import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import UploadComponent from '@/components/common/UploadComponent.vue';
import { locationManagementApiProvider } from '@/common/locationManagement.api';
import { DELETE_SERVICECENTRAL_CACHE, DELETE_SERVICEPOWER_SERVICECENTERS_CACHE } from '@/store/dynamicfulfillment/constants';
import { GlobalModuleState } from '@/store/globalmodules/types';
import { displayError, displayErrors } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';
const gmnamespace: string = 'globalmodule';

@Component({
    components: { ViewContent, UploadComponent },
})
export default class ServiceCenterLocationUpload extends Vue {
    @Action(DELETE_SERVICECENTRAL_CACHE, { namespace }) private deleteServiceCentralCache: any;
    @Action(DELETE_SERVICEPOWER_SERVICECENTERS_CACHE, { namespace }) private deleteServicePowerServiceCentersCache: any;
    @State(gmnamespace) private gmprofile!: GlobalModuleState;
    
    private resetForm: boolean = false;
    private pageTitle: string = 'Upload Service Center Locations';
    private validation(file: File) {
        if (file) {
            const extension = file.name.split('.').pop();
            return extension === 'csv' || extension === 'xlsx';
        }
        return true;
    }

    private formatFileName(files: File[]) {
        const fileName = files[0].name;
        return fileName.length > 50 ? [fileName.substring(0, 50), '...'].join() : fileName;
    }

    private invalidFeedBack() {
        return 'Invalid file. Select .csv or .xlsx file';
    }

    private async upload(eventData: any) {
        await locationManagementApiProvider.postServiceCenterLocations(eventData.file).then( (response: any) => {
            if (response && response.isUploaded) {
                EventBus.$emit('s', `${eventData.file.name} uploaded successfully.`);
                this.resetForm = true;
                this.gmprofile.serviceCentersPage.serviceCenters = [];
                this.$router.push({ name: 'ViewServiceCenterLocations' });
                return;
            }
            EventBus.$emit('e', `<h4>Upload failed. The following error(s) occured.</h4> ${this.formatToastMessage(response)}`);
        }).catch( (error) => {
            EventBus.$emit('e', `<h3>Upload failed. The following error(s) occured.</h3>${this.formatToastMessage(error)}`);
        });
        await this.deleteServiceCentralCache();
        await this.deleteServicePowerServiceCentersCache();
    }

    private formatToastMessage(error: any) {
        if (error && error.response && error.response.data && error.response.data.errors) {
            return displayErrors(error.response.data.errors);
        }
        if (error && error.response && error.response.data) {
            return displayErrors(error.response.data);
        }
        if (error && error.response && error.response.errors) {
            return displayErrors(error.response.errors);
        }
        return error;
    }
}

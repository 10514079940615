
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DataTable from '@/components/common/DataTable.vue';
import { AllowedAttribute } from '@/store/globalmodules/types';

@Component({ components: { DataTable } })
export default class AttributeViewComponent extends Vue {
  @Prop() private allowedAttributes: AllowedAttribute[];
  @Prop({default: 'Custom Attributes'}) private title: string;

  private customAttributesFields: any[] = [
      {
        key: 'key',
        sortable: true,
      },
      {
        key: 'dataType',
        sortable: true,
      },
      {
        key: 'displayName',
        sortable: true,
      },
      {
        key: 'defaultValue',
        sortable: true,
      },
      {
        key: 'allowedValues',
        formatter: (value: any, key: any, item: any) => {
          if (item.allowedValues && item.allowedValues.length > 0) {
            return item.allowedValues.join('<br />');
          } else {
            return '';
          }
        },
        sortable: true,
      },
      {
        key: 'isRequired',
        sortable: true,
      },
      {
        key: 'allowMultiple',
        sortable: true,
      },
      {
        key: 'description',
        thStyle: { width: '250px' },
        sortable: true,
      },
    ];
}


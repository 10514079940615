
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  IStatusEntry,
  FulfillmentAuthorizationStatus,
} from '@/store/dynamicfulfillment/types';
import Badge from '@/common/badge';

@Component
export default class FulfillmentAuthorizationStatusLabel extends Vue {
  @Prop({required: true, type: Number}) private status!: FulfillmentAuthorizationStatus;

  private get badge(): Badge {
    switch (this.status) {
      case FulfillmentAuthorizationStatus.New:
        return new Badge('New', 'info');
      case FulfillmentAuthorizationStatus.InProgress:
        return new Badge('In Progress', 'primary');
      case FulfillmentAuthorizationStatus.Closed:
        return new Badge('Closed', 'success');
      default:
        throw new Error(`Unmapped status type: ${this.status}`);
    }
  }
}

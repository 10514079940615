
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DynamicFulfillmentState, EquipmentExclusionConfig, FulfillmentOption } from '@/store/dynamicfulfillment/types';
import { FETCH_FULFILLMENT_OPTION_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { State } from 'vuex-class';
import { FulfillmentOptionConfig, Vendor } from '@/store/dynamicfulfillment/types';
import { Action } from 'vuex-class';

const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: {} })
export default class EquipmentExclusionConfigCreateEdit extends Vue {
  @Prop() config: EquipmentExclusionConfig;
  @State(namespace) profile!: DynamicFulfillmentState;
  @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace }) fetchFulfillmentOptionConfigs: () => FulfillmentOptionConfig[];

  async beforeMount() {
    await this.fetchFulfillmentOptionConfigs();
  }

  get isNew() {
    return this.config._etag === null;
  }

  get allowedFulfillmentOptions() {
    return Object.entries(FulfillmentOption)
      .filter((e: any) => !isNaN(e[0] as any))
      .map((e: any) => e[0]);
  }

  getVendors(fulfillmentOption: FulfillmentOption) {
    if (this.profile.fulfillmentOptionConfigs.length > 0 && fulfillmentOption) {
      const selectedFulfillmentOptionConfig = this.profile.fulfillmentOptionConfigs.find((foConfig: FulfillmentOptionConfig) => foConfig.id == FulfillmentOption[Number.parseInt(fulfillmentOption.valueOf().toString(), 10)]);
      if (selectedFulfillmentOptionConfig) {
        return selectedFulfillmentOptionConfig.vendors.map((vendor: Vendor) => vendor.name);
      }
    }
  }

  get allowedPrograms() {
    return this.profile.programConfigs;
  }

  get fulfillmentOptionState() {
    return this.config.fulfillmentOption != null && Number.parseInt(this.config.fulfillmentOption.valueOf().toString(), 10) !== Number.parseInt(FulfillmentOption.None.valueOf().toString(), 10);
  }

  get programsState() {
    return this.config.programIds?.length > 0;
  }

  get vendorState() {
    if (this.config.vendor) {
      return this.profile.fulfillmentOptionConfigs.some((foConfig: FulfillmentOptionConfig) => foConfig.vendors.some((vendor: Vendor) => vendor.name === this.config.vendor));
    }

    return true;
  }

  get validForm() {
    return this.fulfillmentOptionState && this.programsState && this.config.name?.length > 0 && this.vendorState;
  }

  fulfillmentOptionLabel(option: number) {
    return FulfillmentOption[option];
  }

  emitOnChange(content: { config: EquipmentExclusionConfig | null; isValid: boolean }) {
    this.$emit('onConfigChange', content);
  }

  @Watch('config.fulfillmentOption', { immediate: false, deep: true })
  private fulfillmentOptionChanged() {
    Vue.set(this.config, 'vendor', null);
  }

  @Watch('config', { immediate: false, deep: true })
  private configChange() {
    this.emitOnChange({ config: this.config, isValid: this.validForm });
  }
}


import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import ViewContent from '@/components/ViewContent.vue';
import {
  FETCH_APPCONFIG_BY_KEY,
  CREATE_APPCONFIG,
  UPDATE_APPCONFIG,
} from '@/store/dynamicfulfillment/constants';
import { AppConfig } from '@/store/dynamicfulfillment/types';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: { ViewContent, ValidationObserver, ValidationProvider },
})
export default class AppConfigCreate extends Vue {
  @Prop() private appConfig: AppConfig;
  @Prop() private id: string;
  @Action(CREATE_APPCONFIG, { namespace })
  private createAppConfig: any;
  @Action(UPDATE_APPCONFIG, { namespace })
  private updateAppConfig: any;
  @Action(FETCH_APPCONFIG_BY_KEY, { namespace })
  private fetchAppConfigByKey: (key: string) => Promise<any>;
  private pageTitle = 'Application Configuration';
  private isLoading: boolean = false;
  private keyAppConfig: AppConfig = null;
  private localConfig: AppConfig = {
    key: '',
    value: '',
    lastModified: null,
    settings: null,
  };

  public async mounted() {
    if (!this.appConfig) {
      await this.getAppConfigSettings();
    } else {
      this.keyAppConfig = this.appConfig;
    }
  }

  private get configState() {
    return this.localConfig.key != '' && this.localConfig.value != '';
  }

  private getRegex(validator: string) {
    return validator ?? '^.{1,}$';
  }
  
  private checkFormValidity(): boolean {
    this.isLoading = true;
    return this.configState;
  }

  public async handleSubmit() {
    if (!this.checkFormValidity()) {
      return;
    }

    const key: string = `${this.id}:${this.localConfig.key}`;

    this.fetchAppConfigByKey(key)
      .then((response: any) => {
        if (response.length > 0 && response[0].key === key) {
          EventBus.$emit(
            'e',
            `${this.localConfig.settings.prefix} key named ${key} already exist.`,
          );
        } else {
          this.createAppConfig({
            key: key,
            value: this.localConfig.value,
          })
            .then((response: any) => {
              this.updateSentinels(this.keyAppConfig);
              this.isLoading = false;
              EventBus.$emit('s', `${key} created successfully.`);
              this.$router.push({
                name: 'AppConfig-Index',
              });
            })
            .catch((error: any) => {
              this.displayErrors(error);
              this.isLoading = false;
              return;
            });
        }
        this.isLoading = false;
      })
      .catch((error: any) => {
        this.displayErrors(error);
        this.isLoading = false;
      });
  }

  private async getAppConfigSettings() {
    let keyAppConfigs = await this.getKeyAppConfigs();

    keyAppConfigs.some((config: AppConfig) =>
      this.id.startsWith(config.settings.prefix),
    );

    let keyAppConfig = keyAppConfigs.find(
      (config: AppConfig) => config.settings.prefix === this.id,
    );

    if (keyAppConfig) {
      this.keyAppConfig = keyAppConfig;
    } else {
      EventBus.$emit('e', `AppConfig key ${this.id} does not exist.`);
      this.$router.push({
        name: 'AppConfig-Index',
      });
    }
  }

  private async getKeyAppConfigs() {
    const keyAppConfigs = await this.fetchAppConfigByKey('AppConfig:*');

    keyAppConfigs.forEach((config: AppConfig) => {
      config.settings = JSON.parse(config.value);
    });

    return keyAppConfigs;
  }

  private updateSentinels(appConfig: AppConfig) {
    if (appConfig.settings.sentinelKeys) {
        appConfig.settings.sentinelKeys.forEach((key) => {
            this.updateAppConfig({
                key: key,
                value: Date.now(),
            });
        });
    } 
    else if (appConfig.settings.sentinelKey) {
        this.updateAppConfig({
            key: appConfig.settings.sentinelKey,
            value: Date.now(),
        });
    }
  }

  private displayErrors(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit('e', message);
  }
}

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, Product } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const ProductActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.PRODUCTS_FETCH](context, request: { sourceSystem: string }): Promise<Product[]> {
    return (await authorizationApiProvider.getProducts(request.sourceSystem)) as Product[];
  },

  async [apis.PRODUCTS_BY_ID_FETCH](context, request: { sourceSystem: string; id: string }): Promise<Product> {
    return (await authorizationApiProvider.getProduct(request.sourceSystem, request.id)) as Product;
  },

  async [apis.PRODUCTS_SAVE](context, product: Product): Promise<Product> {
    let savedProduct = null;
    if (product.id) {
      savedProduct = await authorizationApiProvider.editProduct(product);
    } else {
      savedProduct = await authorizationApiProvider.createProduct(product);
    }
    context.dispatch(apis.CLEAR_CACHE);
    return savedProduct;
  },

  async [apis.PRODUCTS_DELETE](context, params: { config: Product; hardDelete: boolean }): Promise<void> {
    await authorizationApiProvider.deleteProduct(params.config, params.hardDelete).then(async () => {
      await authorizationApiProvider.clearCache();
      await context.dispatch(apis.PRODUCTS_FETCH, { sourceSystem: params.config.sourceSystem });
    });
  },
};

export default ProductActions;

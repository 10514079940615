import Vue from 'vue';
import { ActionTree } from 'vuex';
import { DynamicFulfillmentState } from './types';
import * as apis from './constants';
import { RootState } from '../types';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import SourceSystemConfigActions from './Actions/SourceSystemConfig.actions';
import VendorConfigActions from './Actions/VendorConfig.actions';
import ProgramConfigActions from './Actions/ProgramConfig.actions';
import PriceListConfigActions from './Actions/PriceListConfig.actions';
import UserConfigActions from './Actions/UserConfig.actions';
import RoleConfigActions from './Actions/RoleConfig.actions';
import AppConfigActions from './Actions/AppConfig.actions';
import EquipmentExclusionConfigActions from './Actions/EquipmentExclusionConfig.actions';
import AncillaryServiceTypeConfigActions from './Actions/AncillaryServiceTypeConfig.actions';
import ProductActions from './Actions/Product.actions';
import ProgramFeesConfigActions from './Actions/ProgramFeesConfig.actions';
import ServiceNetworkFulfillmentOptionTypeConfigActions from './Actions/ServiceNetworkFulfillmentOptionTypeConfig.actions';
import LocaleConfigActions from './Actions/LocaleConfig.actions';
import StatusMappingActions from './Actions/StatusMapping.actions';
import InventoryApiActions from './Actions/InventoryApi.actions';
import CacheApiActions from './Actions/CacheApi.actions';
import VendorApiActions from './Actions/VendorApi.actions';
import FulfillmentTicketApiActions from './Actions/FulfillmentTicketApi.actions';
import AuthorizationApiActions from './Actions/AuthorizationApi.actions';
import PermissionConfigActions from './Actions/PermissionConfig.actions';
import CountryConfigActions from './Actions/CountryConfig.actions';
import StatusEventsApiActions from './Actions/StatusEventsApi.actions';
import FulfillmentOptionConfigActions from './Actions/FulfillmentOptionConfig.actions';
import ConfigurationApisActions from './Actions/ConfigurationApis.actions';

export const actions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.INIT_USER](context): Promise<void> {
    if (!context.state.userClaims || !context.state.userClaims.preferred_username) {
      const claims = await Vue.prototype.$auth.getUser();
      const userAccessToken = await Vue.prototype.$auth.getAccessToken();
      const userAuthorizationProvider = userAuthorizationProviderFactory(context.state);
      const userId = userAuthorizationProvider.getUserId(userAccessToken);
      context.commit(apis.SET_USER_CLAIMS, claims);
      try {
        const userRolePermissions = await authorizationApiProvider.getUserRolesPermissions(userId);
        context.commit(apis.SET_USER_ROLES_PERMISSIONS, userRolePermissions);
      } catch (error) {
        throw Error(`Unable to retrieve UserConfig for user ${userId}.`);
      }
    }
  },

  async [apis.INIT_CONFIG](context): Promise<void> {
    if (context.state.initCompleted === false) {
      await context.dispatch(apis.INIT_USER);
      await context.dispatch(apis.FETCH_SOURCE_SYSTEM_CONFIGS);
      context.commit(apis.INIT_COMPLETED);
    }
  },

  ...SourceSystemConfigActions,
  ...VendorConfigActions,
  ...ProgramConfigActions,
  ...PriceListConfigActions,
  ...UserConfigActions,
  ...RoleConfigActions,
  ...AppConfigActions,
  ...EquipmentExclusionConfigActions,
  ...AncillaryServiceTypeConfigActions,
  ...ProductActions,
  ...ProgramFeesConfigActions,
  ...ServiceNetworkFulfillmentOptionTypeConfigActions,
  ...LocaleConfigActions,
  ...StatusMappingActions,
  ...InventoryApiActions,
  ...CacheApiActions,
  ...VendorApiActions,
  ...FulfillmentTicketApiActions,
  ...AuthorizationApiActions,
  ...PermissionConfigActions,
  ...CountryConfigActions,
  ...StatusEventsApiActions,
  ...FulfillmentOptionConfigActions,
  ...ConfigurationApisActions,
};


import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Order, Address, VendorStatusEvent } from '@/store/dynamicfulfillment/types';
import ViewContent from '@/components/ViewContent.vue';
import PhoneNumber from '@/components/common/PhoneNumber.vue';
import {  FETCH_ORDER_STATUS_EVENTS, SEARCH_FULFILLMENT_TICKET_BY_AUTH,} from '@/store/dynamicfulfillment/constants';
import OrderStatusLabel from '@/components/FulfillmentAuthorization/Orders/OrderStatusLabel.vue';
import DataTable from '@/components/common/DataTable.vue';
import { DateTime } from 'luxon';
import StatusHistoryComponent from '@/components/FulfillmentAuthorization/StatusHistoryComponent.vue';
import { copyToClipboard } from '@/common/utilities';
import { removeCosmosSystemProperties, jsonToText } from '@/common/functions.helpers';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({  components: { ViewContent, PhoneNumber, OrderStatusLabel, DataTable, StatusHistoryComponent, VueJSONEditor }})

export default class OrderDetail extends Vue {
  @Prop() private authorizationId!: string;
  @Prop() private fulfillmentTicketId!: string;
  @Prop() private orderId!: string;
  @Action(SEARCH_FULFILLMENT_TICKET_BY_AUTH, { namespace }) private searchFulfillmentTicketByAuth: any;
  @Action(FETCH_ORDER_STATUS_EVENTS, { namespace }) private fetchOrderStatusEvents: any;
  order: Order = null;
  orderLineItemFields: any[] = [
    {
      key: 'itemType',
      sortable: true,
    },
    {
      key: 'description',
      sortable: true,
    },
    {
      key: 'subTotal',
      sortable: true,
    },
    {
      key: 'tax',
      sortable: true,
    },
    {
      key: 'total',
      sortable: true,
    },
  ];
  statusHistoryFields: any[] = [
    {
      key: 'status',
      sortable: true,
    },
    {
      key: 'notes',
      sortable: true,
    },
    {
      key: 'data',
      sortable: true,
    },
    {
      key: 'status',
      sortable: true,
    },
    { 
      key: 'reason', 
      label: 'Status Reason' 
    }, 
    {
      key: 'modifiedDate',
      sortable: true,
    },
    {
      key: 'modifiedBy',
      sortable: true,
    },
  ];
  shipmentFields: any[] = [
    {
      key: 'courier',
      sortable: true,
      filterable: true,
    },
    {
      key: 'courierMode',
      sortable: true,
      filterable: true,
    },
    {
      key: 'shipmentType',
      sortable: true,
      filterable: true,
    },
    {
      key: 'trackingNumber',
      sortable: true,
      filterable: true,
    },
    { 
      key: 'status', 
      sortable: true,
      filterable: true,
    }, 
    {
      key: 'labelCreateTime',
      sortable: true,
    },
  ];
  private orderStatusUpdates: VendorStatusEvent[] = null;
  private orderStatusFields: any[] = [{ key: '_ts', label: 'Status Time', sortable: true }, 'Vendor', 'Payload', { key: 'root', label: 'Actions' }];
  private removeCosmosSystemProperties : any = removeCosmosSystemProperties;
  private statusModal: any = { id: '', title: '', content: '' };
  private jsonEditorText: any = jsonToText;

  async mounted() {
    await this.fetchFulfillmentTicket();
    this.orderStatusUpdates = await this.fetchOrderStatusEvents(this.orderId);
  }

  async fetchFulfillmentTicket() {
    const query = { authorizationId: this.authorizationId, fulfillmentTicketId: this.fulfillmentTicketId };
    const fulfillmentTicket = await this.searchFulfillmentTicketByAuth(query);
    this.order = fulfillmentTicket.orders.find((o: Order) => o.orderId == this.orderId);
  }

  get statusHistoryItems() {
    return this.order.status ? this.order.status.statusHistory : [];
  }

  getAddress(inputAddress: Address) {
    if (!inputAddress) {
      return;
    }
    let stAddress = inputAddress.line1;
    const line2 = inputAddress.line2;
    const line3 = inputAddress.line3;
    if (!!line2 && line2.length > 0) {
      stAddress = `${stAddress} ${line2}`;
    }
    if (!!line3 && line3.length > 0) {
      stAddress = `${stAddress} ${line3}`;
    }
    return `${stAddress}, ${inputAddress.city}, ${inputAddress.region} ${inputAddress.postalCode}`;
  }

  formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }

  private formatTimeSaved(value: any) {
    return DateTime.fromSeconds(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }

  private copyPayload(payload: any) {
    copyToClipboard(JSON.stringify(payload, null, 2));
  }

  private copyRawStatusEventJson(rawStatusEvent: any) {
    rawStatusEvent = removeCosmosSystemProperties(rawStatusEvent);
    copyToClipboard(JSON.stringify(rawStatusEvent, null, 2));
  }

  private resetStatusModal() {
    this.statusModal.title = '';
    this.statusModal.content = '';
  }

  private showStatusModal(statusItem: any, index: any, button: any) {
    this.statusModal.id = statusItem.id;
    this.statusModal.title = `Order Status Update Event`;
    this.statusModal.content = removeCosmosSystemProperties(statusItem);
    this.$root.$emit('bv::show::modal', this.statusModal.id, button);
  }
}

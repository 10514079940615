
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ProgramFeesConfigCreateEdit from '@/components/configuration/ProgramFeesConfigCreateEdit.vue';
import { FETCH_PROGRAM_FEES_CONFIG, EDIT_PROGRAM_FEES_CONFIG } from '@/store/dynamicfulfillment/constants';

@Component({ components: { ConfigEditComponent, ProgramFeesConfigCreateEdit } })
export default class ProgramFeesConfigEdit extends Vue {
  @Prop() id!: string;

  get fetchAction() {
    return 'dynamicfulfillmentmodule/' + FETCH_PROGRAM_FEES_CONFIG;
  }

  get saveAction() {
    return 'dynamicfulfillmentmodule/' + EDIT_PROGRAM_FEES_CONFIG;
  }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { InventoryState, RoutingAction } from '@/store/dynamicfulfillment/types';
import { DELETE_INVENTORY_STATE, FETCH_INVENTORY_STATES, REFRESH_INVENTORY_STATES } from '@/store/constants';
import LocationInventoryViewEdit from '@/components/inventory/LocationInventoryViewEdit.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ConfigIndexComponent, LocationInventoryViewEdit } })
export default class InventoryStatesIndex extends Vue {
  private configs: InventoryState[] = null;
  private ignoredFields: any[] = ['locationInventoryData'];
  private isLazyLoad: boolean = false;
  private fields: any[] = [
    {
      key: 'id',
      label: 'Id',
      sortable: true,
      filterable: true,
    },
    {
      key: 'manufacturer',
      label: 'Manufacturer',
      sortable: true,
      filterable: true,
    },
    {
      key: 'color',
      label: 'Color',
      sortable: true,
      filterable: true,
    },
    {
      key: 'vendorName',
      label: 'Vendor Name',
      sortable: true,
      filterable: true,
    },
    {
      key: 'vendorRepairTypeId',
      label: 'Vendor Repair Type Id',
      sortable: true,
      filterable: true,
    },
    {
      key: 'vendorDeviceTypeId',
      label: 'Vendor Device Type Id',
      sortable: true,
      filterable: true,
    },
    {
      key: 'locationInventory',
      label: 'Location Inventory',
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ];

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'InventoryStates-Edit', requiredPermission: 'Configuration-Inventory-Edit' },
      { name: RoutingAction.Create, route: 'InventoryStates-Create', requiredPermission: 'Configuration-Inventory-Create' },
      { name: RoutingAction.Upload, route: 'InventoryStates-Upload', requiredPermission: 'Configuration-Inventory-Upload' },
      { name: RoutingAction.Fetch, action: FETCH_INVENTORY_STATES, requiredPermission: 'Configuration-Inventory-View' },
      { name: RoutingAction.Refresh, action: REFRESH_INVENTORY_STATES, requiredPermission: 'Configuration-Inventory-View' },
      { name: RoutingAction.Remove, action: DELETE_INVENTORY_STATE, requiredPermission: 'Configuration-Inventory-Delete' },
    ];
  }

  private pageLoaded(configs: InventoryState[]) {
    if (this.configs && this.configs.length === configs.length) {
      return;
    } else {
      this.configs = [...configs];
      this.isLazyLoad = !this.isLazyLoad;
    }
  }

  private get locationInventory() {
    return (id: string) => {
      const state = this.configs.find((config: InventoryState) => config.id === id);
      if (state && state.locationInventoryData && state.locationInventoryData.length > 0) {
        return state.locationInventoryData;
      } else {
        return [];
      }
    };
  }
}

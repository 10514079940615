import axios, { AxiosRequestConfig } from 'axios';
import Vue from 'vue';
import {
  BenefitCheckSearchCriteria,
  BenefitCheckData,
} from '@/store/globalmodules/types';

declare var process: any;
declare var NProgress: any;

import qs from 'query-string';

const instance = axios.create({
  baseURL: process.env.VUE_APP_BENEFIT_CHECK_APIM_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

instance.interceptors.request.use(
  (config: any) => {
    NProgress.start();
    return config;
  },
  (error: any) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (config: any) => {
    NProgress.done();
    return config;
  },
  (error: any) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

export class BenefitCheckApiProvider {
  public async getBenefitCheckData(
    benefitCheckSearchCriteria: BenefitCheckSearchCriteria,
  ) {
    const queryString = qs.stringifyUrl(
      {
        url: '/BenefitCheck?',
        query: {
          mobileNumber: benefitCheckSearchCriteria.mobileNumber,
          imei: benefitCheckSearchCriteria.imei,
        },
      },
      { skipEmptyString: true },
    );
    return await this.get<BenefitCheckData>(queryString);
  }

  private async get<TReturn>(uri: string, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const response = await instance.get<TReturn>(uri, config);
    return response.data;
  }

  private async addAuthHeader(config?: any) {
    config = { ...{ headers: {} }, ...config };
    const accessToken = await Vue.prototype.$auth.getAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  }
}

export const benefitCheckApiProvider = new BenefitCheckApiProvider();


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import BrandMappingConfigEdit from '@/components/Vendors/ServicePower/BrandMappingConfigEdit.vue';

@Component({ components: { ViewContent, BrandMappingConfigEdit } })
export default class ServicePowerBrandMappingEdit extends Vue {
  @Prop() private brandMappingConfig: any;

  private get pageTitle() {
    return 'Service Power Brand Mapping';
  }

  private mounted() {
    if (!this.brandMappingConfig) {
      this.$router.push({ name: 'ServicePowerBrandMapping-Index' });
    }
  }
}

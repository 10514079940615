
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import { ServiceCentralServiceNetworkConfig } from '@/store/dynamicfulfillment/types';
import ServiceNetworkConfigCreate from '@/components/Vendors/ServiceCentral/ServiceNetworkConfigCreate.vue';

@Component({ components: { ViewContent, ServiceNetworkConfigCreate } })
export default class ServiceCentralNetworkManagementCreate extends Vue {
  private defaultConfig: ServiceCentralServiceNetworkConfig = {
    key: null,
    fulfillmentOption: null,
    programId: null,
    programName: null,
    serviceProvider: null,
    serviceNetwork: null,
    lastModified: null,
  };

  private get pageTitle() {
    return 'ServiceCentral Network Management';
  }

  private mounted() {
    if (!this.defaultConfig) {
      this.$router.push({ name: 'ServiceCentralNetworkManagement-Index' });
    }
  }
}

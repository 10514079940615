
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ProgramConfigCreateEdit from '@/components/configuration/ProgramConfigCreateEdit.vue';
import { FETCH_PROGRAM_BY_ID_CONFIG, PROGRAM_CONFIG_SAVE } from '@/store/constants';

@Component({ components: {ConfigEditComponent, ProgramConfigCreateEdit } })
export default class ProgramConfigEdit extends Vue {
    @Prop() private id!: string;
    private editedConfig: any = {};
    private isValid: boolean = false;

    private get fetchAction() {
        return FETCH_PROGRAM_BY_ID_CONFIG;
    }

    private get saveAction() {
        return PROGRAM_CONFIG_SAVE;
    }

    private onConfigChange(request: {config: any | null, isValid: boolean}) {
      this.isValid = request.isValid;

      if (request.isValid) {
        this.editedConfig = request.config;
      }
    }
}

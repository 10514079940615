
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import ViewContent from '@/components/ViewContent.vue';
import { FulfillmentAuthorization, FulfillmentAuthorizationSearchResult, SourceSystem, FulfillmentOption, FulfillmentTicket, SearchByAuthIdQuery } from '@/store/dynamicfulfillment/types';
import FulfillmentAuthorizationStatusLabel from '@/components/FulfillmentAuthorization/FulfillmentAuthorizationStatusLabel.vue';
import FulfillmentTicketStatus from '@/components/FulfillmentAuthorization/FulfillmentTicketStatusLabel.vue';
import {
  SEARCH_AUTH_BY_ID,
  SEARCH_AUTH_BY_SOURCE_IDENTIFIERS,
  SEARCH_FULFILLMENT_TICKET_BY_AUTH,
  SEARCH_AUTH_BY_FULFILLMENT_TICKET_ID,
  SEARCH_AUTH_BY_PHONE_NUMBER,
  SEARCH_AUTH_BY_KEY_VALUE,
  SEARCH_FULFILLMENT_TICKET_BY_BOOKED_OFFER_CONFIRMATION_ID,
} from '@/store/dynamicfulfillment/constants';
import { Action, State } from 'vuex-class';
import { legacyApiProvider } from '@/common/df.legacy.api';
import DataTable from '@/components/common/DataTable.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Loading from '@/components/common/Loading.vue';
import EventBus from '@/common/event.bus';
import { isNullOrEmpty } from '@/common/utilities';
import SearchCriteria from '@/views/SearchCriteria.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: { FulfillmentAuthorizationStatusLabel, FulfillmentTicketStatus, ViewContent, DataTable, ValidationObserver, ValidationProvider, Loading, SearchCriteria },
})
export default class Search extends Vue {
  authorizationId: string = '';
  ticketId: string = '';
  authorizations?: FulfillmentAuthorizationSearchResult[] = [];
  searchBy: string = 'claim';
  claimId: string = '';
  bookedOfferConfirmationId: string = '';
  fulfillmentTicketsResult?: FulfillmentTicket[] = [];
  noResultsFound: string = "No results found using provided criteria.";
  error: string = "Unexpected error while searching for authorization. Please try again.";
  searchByOptions = [
    { text: "Fulfillment Authorization Id", value: "fulfillmentAuthorization"},
    { text: "Fulfillment Ticket Id", value: "fulfillmentTicket"},
    { text: "Claim Number", value: "claim"},
    { text: "Policy Number", value: "policyNumber"},
    { text: "Legacy", value: "legacy"},
    { text: "Phone #", value: "phone"},
    { text: "Claim Authorization #", value: "claimAuthorization"},
    { text: "Booked Offer Confirmation Id", value: "bookedOfferConfirmation"},
  ]
  searchResultFields: any[] = [
    {
      key: 'authorizationId',
      sortable: true,
      label: 'Authorization Id',
    },
    {
      key: 'source',
      sortable: true,
      label: 'Source System',
      formatter: (value: any, key: any, item: any) => {
        return SourceSystem[value];
      },
    },
    {
      key: 'claimId',
      sortable: true,
      label: 'Claim Number',
    },
    {
      key: 'carrierNumber',
      sortable: true,
      label: 'Carrier Number',
    },
    {
      key: 'fulfillmentOption',
      sortable: true,
      label: 'Fulfillment Option',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
    },
  ];
  searchByBookedOfferConfirmationIdResultFields: any[] = [
    {
      key: 'id',
      sortable: true,
      label: 'Fulfillment Ticket Id',
    },
    {
      key: 'bookedOfferConfirmationId',
      sortable: true,
      label: 'Booked Offer Confirmation Id',
    },
    {
      key: 'acceptedOfferInfo.offer.fulfillmentOption',
      sortable: true,
      label: 'Fulfillment Option',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
    },
    {
      key: 'acceptedOfferInfo.offer.vendorId',
      sortable: true,
      label: 'Vendor',
    },
  ];
  pageTitle = 'Dynamic Fulfillment Search';
  searchResultCount: number = 1000;
  searchResultPageSize: number = 10;
  phoneNumber: string = null;
  isLazyLoad: boolean = false;
  pagingToken: string = null;
  isFetchingAdditionalRows: boolean;
  claimAuthorizationNumber: string = null;
  isPageLoad: boolean = false;
  policyNumberIdentifier: string = '';
  searchCriteria: { sourceSystem: string | null; claimId: string | null; searchCriteriaState: boolean } = { sourceSystem: null, claimId: null, searchCriteriaState: false };

  @Action(SEARCH_AUTH_BY_ID, { namespace }) private searchByAuthId: (request: SearchByAuthIdQuery) => Promise<any>;
  @Action(SEARCH_AUTH_BY_FULFILLMENT_TICKET_ID, { namespace }) private searchByFulfillmentTicketId: any;
  @Action(SEARCH_AUTH_BY_SOURCE_IDENTIFIERS, { namespace }) private searchBySourceIdentifiers: any;
  @Action(SEARCH_AUTH_BY_PHONE_NUMBER, { namespace }) private searchByPhoneNumber: any;
  @Action(SEARCH_AUTH_BY_KEY_VALUE, { namespace }) private searchByKeyValue: any;
  @Action(SEARCH_FULFILLMENT_TICKET_BY_BOOKED_OFFER_CONFIRMATION_ID, { namespace }) private searchFTByBookedOfferConfirmationId: any;

  async created() {
    this.isPageLoad = true;
  }

  async search() {
    this.isPageLoad = false;
    try {
      this.reset();
      let auth: FulfillmentAuthorization;
      switch (this.searchBy) {
        case 'fulfillmentAuthorization':
          auth = await this.searchByAuthId({ authorizationId: this.authorizationId.trim(), forceFetch: false });
          this.navigateToAuth(auth);
          break;
        case 'fulfillmentTicket':
          auth = await this.searchByFulfillmentTicketId(this.ticketId.trim());
          this.navigateToAuth(auth);
          break;
        case 'claim':
          auth = await this.searchBySourceIdentifiers({ sourceSystem: this.searchCriteria.sourceSystem, claimNumber: this.searchCriteria.claimId.trim() });
          this.navigateToAuth(auth);
          break;
        case 'legacy':
          const claimId = this.claimId.trim();
          const claimData = await legacyApiProvider.getClaimData(claimId);
          if (claimData.length === 0) {
            EventBus.$emit('w', this.noResultsFound);
            return;
          }
          this.$router.push({
            name: 'LegacyClaim-Index',
            params: { claimId },
          });
          break;
        case 'phone':
          await this.load({ count: this.searchResultCount, phone: this.phoneNumber, pagingToken: null });
          break;
        case 'claimAuthorization':
          await this.load({ count: this.searchResultCount, collection: 'c.claimData.claimSummary.claimAuthorizations', key: 'claimAuthorizationNumber', value: this.claimAuthorizationNumber, pagingToken: null });
          break;
        case 'bookedOfferConfirmation':
          this.fulfillmentTicketsResult = await this.searchFTByBookedOfferConfirmationId(this.bookedOfferConfirmationId.trim());
          if (this.fulfillmentTicketsResult.length === 0) {
            EventBus.$emit('w', this.noResultsFound);
            break;
          }
          break;
        case 'policyNumber':
          await this.load({ count: this.searchResultCount, collection: '', key: 'c.claimData.policyData.policyIdentifier', value: this.policyNumberIdentifier, pagingToken: null });
        default:
          break;
      }
    } catch (error) {
      const err: any = error;
      if (err.response && err.response.status === 404) {
        EventBus.$emit('w', this.noResultsFound);
      } else {
        EventBus.$emit('e', this.error);
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // tslint:disable-next-line:no-console
        console.log(err.response);
      }
    } finally {
      this.isPageLoad = true;
    }
  }

  navigateToAuth(auth: FulfillmentAuthorization) {
    this.$router.push({ name: 'Authorization', params: { authorizationId: auth.id } });
  }

  reset() {
    this.authorizations = [];
    this.fulfillmentTicketsResult = [];
  } 

  async load(params: any) {
    let searchResults: any = null;
    switch (this.searchBy) {
      case 'phone':
        searchResults = await this.searchByPhoneNumber(params);
        break;
      case 'claimAuthorization':
      case 'policyNumber':
        searchResults = await this.searchByKeyValue(params).catch();
        break;
      default:
        break;
    }
    this.isFetchingAdditionalRows = false;
    if (!searchResults || searchResults.results.length === 0) {
      EventBus.$emit('w', this.noResultsFound);
    } else if (!searchResults.pagingToken || searchResults.pagingToken === null) {
      if (searchResults.results.length === 1) {
        this.fetchAuthAndNavigate(searchResults.results[0].authorizationId);
      } else {
        this.authorizations = this.authorizations.concat(searchResults.results);
      }
      return;
    } else {
      this.authorizations = this.authorizations.concat(searchResults.results);
      this.pagingToken = searchResults.pagingToken;
      this.isLazyLoad = !this.isLazyLoad;
    }
  }

  @Watch('isLazyLoad', { immediate: false })
  async fetchAdditionalRecords() {
    this.isFetchingAdditionalRows = true;
    await this.load({ count: this.searchResultCount, phone: this.phoneNumber, pagingToken: this.pagingToken });
  }

  async fetchAuthAndNavigate(authId: string) {
    const auth = await this.searchByAuthId({authorizationId: authId, forceFetch: true});
    this.navigateToAuth(auth);
  }

  get isLoading() {
    return this.authorizations && this.authorizations.length === 0 && !this.isPageLoad;
  }

  @Watch('searchBy', { immediate: false })
  async onSearchByChange() {
    this.reset();
  }

  get isFormStateInvalid() {
    switch (this.searchBy) {
        case 'fulfillmentAuthorization':
          return isNullOrEmpty(this.authorizationId);
        case 'fulfillmentTicket':
          return isNullOrEmpty(this.ticketId);
        case 'claim':
          return !this.searchCriteria.searchCriteriaState;
        case 'legacy':
          return isNullOrEmpty(this.claimId);
        case 'phone':
          return isNullOrEmpty(this.phoneNumber);
        case 'claimAuthorization':
          return isNullOrEmpty(this.claimAuthorizationNumber);
        case 'bookedOfferConfirmation':
          return isNullOrEmpty(this.bookedOfferConfirmationId);
        case 'policyNumber':
          return isNullOrEmpty(this.policyNumberIdentifier);
        default:
          return false;
    }
  }

  onConfigChange(content: { sourceSystem: string | null; claimId: string; searchCriteriaState: boolean }) {
    this.searchCriteria = content;
  }
}

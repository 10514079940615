
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_MIGRATION_MATRIX_DATA, FETCH_ALL_MIGRATION_MATRIX_DATA } from '@/store/constants';
import { MigrationMatrixData } from '@/store/globalmodules/types';
import { RoutingAction } from '@/store/dynamicfulfillment/types';
import { DateTime } from 'luxon';
const namespace: string = 'globalmodule';
@Component({ components: { ConfigIndexComponent } })
export default class MigrationMatrixIndex extends Vue {
  private fields: any[] = ['#', 'deviceSku', 'description', 'clientId', 'replacementItems', 'effectiveStartDate', 'effectiveEndDate', 'lastModifiedBy', 'lastModifiedDate', 'fileReceivedDate'];
  private replacementItemfields: any[] = ['#', 'deviceSku', 'description', 'rank'];
  private ignoredFields: any[] = ['replacementItems'];
  private items: MigrationMatrixData[] = [];
  private isLazyLoad: boolean = false;

  get routeActions() {
    return [{ name: RoutingAction.Fetch, action: FETCH_MIGRATION_MATRIX_DATA, requiredPermission: 'Configuration-MigrationMatrix-View' }];
  }

  private formatDate(inputValue: string) {
    if (inputValue) {
      const formattedDate = DateTime.fromISO(inputValue).toFormat('yyyy-MM-dd HH:mm:ss');
      return formattedDate === 'Invalid date' ? inputValue : formattedDate;
    } else {
      return '';
    }
  }

  private pageLoaded(items: any[]) {
    if (this.items.length === items.length) {
      return;
    }
    this.items = [...items];
    this.isLazyLoad = !this.isLazyLoad;
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ProgramFeesConfigCreateEdit from '@/components/configuration/ProgramFeesConfigCreateEdit.vue';
import { FETCH_PROGRAM_FEES_CONFIG, CREATE_PROGRAM_FEES_CONFIG, FETCH_PROGRAM_CONFIGS, FETCH_FULFILLMENT_OPTION_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { ProgramFeesConfig, FulfillmentOptionConfig, ProgramConfig } from '@/store/dynamicfulfillment/types';
import { Action } from 'vuex-class';

const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ConfigEditComponent, ProgramFeesConfigCreateEdit } })
export default class ProgramFeesConfigCreate extends Vue {
  @Action(FETCH_PROGRAM_CONFIGS, { namespace }) fetchProgramConfigs: () => ProgramConfig[];
  @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace }) fetchFulfillmentOptionConfigs: () => FulfillmentOptionConfig[];
  editedConfig: any = {};
  isValid: boolean = false;
  defaultConfig: ProgramFeesConfig = {
    fulfillmentOption: null,
    programId: null,
    feeHistory: [],
    id: null,
    _etag: null,
  };

  mounted() {
    this.fetchProgramConfigs();
    this.fetchFulfillmentOptionConfigs();
  }

  get fetchAction() {
    return 'dynamicfulfillmentmodule/' + FETCH_PROGRAM_FEES_CONFIG;
  }

  get saveAction() {
    return 'dynamicfulfillmentmodule/' + CREATE_PROGRAM_FEES_CONFIG;
  }

  onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.editedConfig = request.config;
    }
  }
}

import axios, { AxiosRequestConfig } from 'axios';
import {
    LegacyClaimData,
    LegacyCallData,
} from '@/store/dynamicfulfillment/types';
import Vue from 'vue';

declare var process: any;
declare var NProgress: any;

const instance = axios.create({
    baseURL: process.env.VUE_APP_LEGACY_APIM_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
});

instance.interceptors.request.use(
    (config: any) => {
        NProgress.start();
        return config;
    },
    (error: any) => {
        NProgress.done();
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (config: any) => {
        NProgress.done();
        return config;
    },
    (error: any) => {
        NProgress.done();
        return Promise.reject(error);
    },
);

export class LegacyApiProvider {
    public async getClaimData(claimNumber: string): Promise<LegacyClaimData[]> {
        return await this.get<LegacyClaimData[]>(`api/ClaimData/${claimNumber}`);
    }

    public async getCallData(claimAuthNumber: string): Promise<LegacyCallData[]> {
        return await this.get<LegacyCallData[]>(`api/LeadCalls/${claimAuthNumber}`);
    }

    private async get<TReturn>(uri: string, config?: AxiosRequestConfig) {
        config = await this.addAuthHeader(config);
        const response = await instance.get<TReturn>(uri, config);
        return response.data;
    }

    private async addAuthHeader(config?: any) {
        config = { ...{ headers: {} }, ...config };
        const accessToken = await Vue.prototype.$auth.getAccessToken();
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    }

    public get instance() {
        return instance;
    }
}

export const legacyApiProvider = new LegacyApiProvider();

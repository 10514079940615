
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import AncillaryServiceTypeConfigCreateEdit from '@/components/configuration/AncillaryServiceTypeConfigCreateEdit.vue';
import {
  FETCH_ANCILLARY_SERVICE_TYPE_CONFIG,
  UPDATE_ANCILLARY_SERVICE_TYPE_CONFIG
} from '@/store/dynamicfulfillment/constants';
import { Action } from 'vuex-class';
import { AncillaryServiceTypeConfig, AllowedAttribute } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: { ConfigEditComponent, AncillaryServiceTypeConfigCreateEdit },
})
export default class AncillaryServiceTypeConfigEdit extends Vue {
  @Prop() private id!: string;
  @Action(FETCH_ANCILLARY_SERVICE_TYPE_CONFIG, { namespace })
  private fetchAncillaryServiceTypeConfig: (id: string) => Promise<AncillaryServiceTypeConfig>;
  private config: AncillaryServiceTypeConfig = {
    _etag: null,
    id: null,
    serviceType: null,
    allowedAttributes: [] as AllowedAttribute[],
  };
  private isValid: boolean = false;

  private get fetchAction() {
    return namespace + '/' + FETCH_ANCILLARY_SERVICE_TYPE_CONFIG;
  }

  private get saveAction() {
    return namespace + '/' + UPDATE_ANCILLARY_SERVICE_TYPE_CONFIG;
  }

  private async mounted() {
    const ancillaryServiceTypeConfig = await this.fetchAncillaryServiceTypeConfig(this.id);
    if (ancillaryServiceTypeConfig) {
      this.config = ancillaryServiceTypeConfig;
    }
  }

  private onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.config = request.config;
    }
  }
}


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import EventBus from '@/common/event.bus';
import ViewContent from '@/components/ViewContent.vue';
import {
  CLEAR_CACHE,
  CLEAR_CACHE_BY_KEY,
} from '@/store/dynamicfulfillment/constants';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: { ViewContent, ValidationObserver, ValidationProvider },
})
export default class ClearCache extends Vue {
  private pageTitle = 'Dynamic Fulfillment Clear Cache';
  private clearCacheFor: string = 'allKeys';
  private specificKey: string = '';

  @Action(CLEAR_CACHE, { namespace }) private clearCacheForAllKeys: () => void;
  @Action(CLEAR_CACHE_BY_KEY, { namespace }) private clearCacheForSpecificKey: ( key: string ) => void;

  private async clearCache() {
    try {
      switch (this.clearCacheFor) {
        case 'allKeys':
          await this.clearCacheForAllKeys();
          this.successMessage();
          break;
        case 'specificKey':
          await this.clearCacheForSpecificKey(this.specificKey.trim());
          this.successMessage();
          break;
        default:
          break;
      }
    } catch (error) {
      const err: any = error;
      EventBus.$emit('e', 'Unexpected error occurred while clearing cache. Please try again.');
    }
  }

  private successMessage() {
      this.specificKey = '';
      return EventBus.$emit('s', 'Cache has been successfully cleared.');
  }

}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_EQUIPMENT_EXCLUSION_CONFIGS, EQUIPMENT_EXCLUSION_CONFIG_DELETE, FETCH_PROGRAM_CONFIGS, FETCH_EQUIPMENT_EXCLUSION_ITEMS, FETCH_CONFIG_HISTORY, EDIT_EQUIPMENT_EXCLUSION_CONFIG } from '@/store/constants';
import { FulfillmentOption, DynamicFulfillmentState, RoutingAction, DeviceTypeIdLookupStrategy, ConfigType } from '@/store/dynamicfulfillment/types';
import ProgramConfigModal from '@/components/configuration/ProgramConfigModal.vue';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ConfigIndexComponent, ProgramConfigModal } })
export default class EquipmentExclusionConfigIndex extends Vue {
  @Action(FETCH_PROGRAM_CONFIGS) private fetchProgramConfigs: any;
  @Action(FETCH_EQUIPMENT_EXCLUSION_ITEMS) fetchEquipmentExclusionItems: any;
  @State(namespace) private dfprofile!: DynamicFulfillmentState;
  fields: any[] = [
    {
      key: 'id',
      sortable: true,
      filterable: true,
    },
    {
      key: 'name',
      filterable: true,
    },
    {
      key: 'fulfillmentOption',
      label: 'Fulfillment Option',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
      filterByFormatted: true,
      filterable: true,
    },
    {
      key: 'programIds',
      label: 'Program Names',
      formatter: (value: any, key: any, item: any) => {
        if (item.programIds.length > 0) {
          return this.getProgramNames(value);
        }
      },
      filterByFormatted: true,
      filterable: true,
    },
    {
      key: 'vendor',
      sortable: true,
      filterable: true,
    },
  ];
  configItemsExportFields: any[] = ['id', 'equipmentIdentifier', 'equipmentIdentifierLookupStrategy','damageTypes', 'reason', 'startDate', 'endDate', 'actions'];
  configItemsExport: {
    id: string;
    equipmentIdentifier: string;
    equipmentIdentifierLookupStrategy: DeviceTypeIdLookupStrategy;
    damageTypes: string[];
    reason: string;
    startDate: string;
    endDate: string;
    actions: string;
  }[] = null;
  configType: ConfigType = ConfigType.EquipmentExclusionConfig;

  async created() {
    await this.fetchProgramConfigs();
  }

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'EquipmentExclusionConfig-Edit', requiredPermission: 'Configuration-EquipmentExclusion-Edit' },
      { name: RoutingAction.Create, route: 'EquipmentExclusionConfig-Create', requiredPermission: 'Configuration-EquipmentExclusion-Create' },
      { name: RoutingAction.Fetch, action: FETCH_EQUIPMENT_EXCLUSION_CONFIGS, requiredPermission: 'Configuration-EquipmentExclusion-View' },
      { name: RoutingAction.Remove, action: EQUIPMENT_EXCLUSION_CONFIG_DELETE, requiredPermission: 'Configuration-EquipmentExclusion-Delete' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-EquipmentExclusion-View' },
      { name: RoutingAction.Revert, action: EDIT_EQUIPMENT_EXCLUSION_CONFIG, requiredPermission: 'Configuration-EquipmentExclusion-Edit' },
      { name: RoutingAction.RemovedItems, route: 'RemovedEquipmentExclusionConfig-Index', requiredPermission: 'Global-HardDelete' },
    ];
  }

  programName(programId: string) {
    const programConfig = this.dfprofile.programConfigs.filter((program) => program.id === programId)[0];
    return programConfig ? programConfig.name : `Unknown Program- ${programId}`;
  }

  getProgramNames(ids: string[]) {
    const programs: string[] = [];
    ids.forEach((p) => programs.push(this.programName(p)));
    return programs;
  }

  async download(configId: string, configName: string) {
    const configItems = await this.fetchEquipmentExclusionItems({ id: configId });
    this.configItemsExport = configItems.map((item: any) => ({
      id: item.id,
      equipmentIdentifier: item.equipmentIdentifier,
      equipmentIdentifierLookupStrategy: item.equipmentIdentifierLookupStrategy,
      damageTypes: item.damageTypes.join(','),
      reason: item.reason,
      startDate: item.startDate,
      endDate: item.endDate,
      actions: item.actions,
    })) as [];

    const exportFileName = `${configName}_EquipmentExclusionItems_${process.env.VUE_APP_ENVIRONMENT}_${DateTime.now().toFormat('yyyyMMddHHmm')}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(this.configItemsExport, {
      header: this.configItemsExportFields,
    });
    const newWorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkBook, worksheet, 'WorkSheet1');
    XLSX.writeFile(newWorkBook, `${exportFileName}`);
  }

  hasPermission(features: string[]) {
    return userAuthorizationProviderFactory(this.dfprofile).hasPermission(features);
  }
}


import { FETCH_PERMISSIONS } from '@/store/dynamicfulfillment/constants';
import { DynamicFulfillmentState, Product, RoleConfig, SourceSystem, ProductMappingAttribute } from '@/store/dynamicfulfillment/types';
import Vue from 'vue';
import { Permission, PermissionsConfig } from '@/store/dynamicfulfillment/types';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DataTable from '../common/DataTable.vue';
import { sort, sortByProperty } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable } })
export default class RolesConfigModal extends Vue {
  public permissions: Permission[] = [];
  public permissionsMapped: { category: string; options: string[]; permissions: string[] }[] = [];
  public config: RoleConfig = null;
  public isLoading: boolean = false;
  @Prop({ required: true }) private id: string;
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;
  @Action(FETCH_PERMISSIONS, { namespace }) private fetchPermission: () => PermissionsConfig[];

  public async mounted() {
    this.isLoading = true;
    this.config = this.profile.roles.find((roleConfig) => {
      return this.id === roleConfig.id;
    });
    await this.fetchPermissions().then(() => {
      this.isLoading = false;
    });
  }

  public async fetchPermissions() {
    const permissions = await this.fetchPermission();
    this.permissions = permissions[0].categories as any;

    this.permissions.forEach((x) => {
      const map = {
        category: x.category,
        options: sort(x.permissions),
        permissions: x.permissions.filter((y) => {
          return this.config.permissions.indexOf(`${x.category}-${y}`) > -1;
        }),
      };
      if (map.permissions.length > 0) {
        this.permissionsMapped.push(map);
      }
    });

    this.permissionsMapped = sortByProperty(this.permissionsMapped, 'category');
  }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { RoutingAction } from '@/store/dynamicfulfillment/types';
import  AttributeViewComponent from '@/components/common/AttributeViewComponent.vue';

@Component({ components: { ConfigIndexComponent, AttributeViewComponent } })
export default class AncillaryServiceTypeConfigIndex extends Vue {
  private initialSort: string = 'userAudit.current.modifiedOnUtc';
  private sortDesc: boolean = true;
  private attributesTitle: string = 'Custom Attributes';
  private fields: any[] = [
    {
      key: 'serviceType',
      thStyle: { width: '275px' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'customAttributes',
    },
  ];

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'AncillaryServiceTypeConfig-Edit', requiredPermission: 'Configuration-AncillaryServiceType-Edit' },
      { name: RoutingAction.Create, route: 'AncillaryServiceTypeConfig-Create', requiredPermission: 'Configuration-AncillaryServiceType-Create' },
      { name: RoutingAction.Fetch, action: 'dynamicfulfillmentmodule/' + FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS, requiredPermission: 'Configuration-AncillaryServiceType-View' },
    ];
  }

}


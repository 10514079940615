
import ServiceNetworkConfigEdit from '@/components/Vendors/ServiceCentral/ServiceNetworkConfigEdit.vue';
import ViewContent from '@/components/ViewContent.vue';
import { ServiceCentralServiceNetworkConfig } from '@/store/dynamicfulfillment/types';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({ components: {ViewContent, ServiceNetworkConfigEdit}})
export default class ServiceCentralNetworkManagementEdit extends Vue {
    @Prop() private serviceNetworkConfig: ServiceCentralServiceNetworkConfig;

    private get pageTitle() {
        return this.serviceNetworkConfig.serviceProvider
          ? `${this.serviceNetworkConfig.fulfillmentOption} - ${this.serviceNetworkConfig.serviceProvider}`
            : this.serviceNetworkConfig.fulfillmentOption;
    }

    private get contentTitle() {
        return (this.isNew) ? '' : `${this.serviceNetworkConfig.serviceProvider} - ${this.serviceNetworkConfig.serviceNetwork}`;
    }

    private get isNew() {
        return this.serviceNetworkConfig !== null && this.serviceNetworkConfig.serviceNetwork !== null;
    }

    private mounted() {
        if (!this.serviceNetworkConfig) {
            this.$router.push({name: 'ServiceCentralNetworkManagement-Index'});
        }
    }
}

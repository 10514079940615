import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, IPriceListConfig, IPriceListItem, SearchCriteria, PriceListItem } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { filterConfigsByProgramId } from '@/common/utilities';

const PriceListConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_PRICE_LIST_CONFIGS](context, request: { route: string; forceFetch: boolean }) {
    request.route = 'pricelists';
    if (context.state.priceListConfigs != null && !request.forceFetch) {
      return;
    }

    let configs = await authorizationApiProvider.getPriceListConfigs<IPriceListItem>(request.route);
    const dictionary = {} as { [key: string]: IPriceListConfig<IPriceListItem> };

    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    configs = filterConfigsByProgramId(
      configs,
      (config) => config.programIds,
      () => context.state.programConfigs.map((config) => config.id),
    );

    configs.forEach((c) => {
      dictionary[c.id] = c;
    });
    let routeSetting = request.route;
    context.commit(apis.SET_PRICE_LIST_CONFIGS, { dictionary, configs, routeSetting });
  },

  async [apis.CLEAR_PRICE_LIST_CONFIGS](context, route: string) {
    context.commit(apis.CLEAR_PRICE_LIST_CONFIGS, { route });
  },

  async [apis.FETCH_PRICE_LIST_CONFIG](context, request: { id: string; route: string; forceFetch: boolean }) {
    if (request.forceFetch) {
      const config = await authorizationApiProvider.getPriceListConfig(request.id, request.route);
      context.commit(apis.SET_PRICE_LIST_CONFIG, { config, route: request.route });
      return config;
    }

    if (!context.state.priceListConfigs) {
      await context.dispatch(apis.FETCH_PRICE_LIST_CONFIGS, { route: request.route, forceFetch: false });
    }
    return context.state.priceListConfigs[request.id];
  },

  async [apis.FETCH_PRICE_LIST_CONFIG_ITEMS](context, request: { id: string; route: string }) {
    if (context.state.priceListConfigs == null || context.state.priceListConfigs[request.id] == null) {
      await context.dispatch(apis.FETCH_PRICE_LIST_CONFIGS, { route: request.route, forceFetch: false });
    }

    if (context.state.priceListConfigs[request.id]?.items.length === 0) {
      const items = (await authorizationApiProvider.getPriceListItems<IPriceListItem>(request.id, request.route)) as PriceListItem[];
      context.state.priceListConfigs[request.id].items = items;
    }
    return context.state.priceListConfigs[request.id];
  },

  async [apis.FETCH_PRICE_LIST_CONFIG_ITEMS_BY_SEARCH_CRITERIA](context, request: { id: string; searchCriteria: SearchCriteria[] }) {
    return await authorizationApiProvider.getPriceListItemsSearch(request.id, request.searchCriteria);
  },

  async [apis.UPLOAD_PRICE_LIST_ITEMS](context, request: { payload: any; configId: string }) {
    return new Promise<void>(async (resolve, reject) => {
      await authorizationApiProvider
        .uploadPriceListItems(request.payload, request.configId)
        .then(async () => {
          resolve();
        })
        .catch((error) => {
          if (error.response && error.response.status === 413) {
            const message = request.payload.name.endsWith('.xlsx') 
              ? 'The excel file is too large. Try uploading in csv format.' 
              : 'The file is too large.';
            reject(message);
          }
          else {
            reject(error);
          }
        });
    });
  },

  async [apis.INSERT_PRICE_LIST_CONFIG](context, request: { payload: any; route: string }) {
    const config = await authorizationApiProvider.insertPriceListConfig<IPriceListItem>(request.payload, request.route);
    context.commit(apis.SET_PRICE_LIST_CONFIG, { config, route: request.route });
    return config;
  },

  async [apis.UPDATE_PRICE_LIST_CONFIG](context, request: { config: IPriceListConfig<IPriceListItem>; apiRoute: string }) {
    await authorizationApiProvider.updatePriceListConfig<IPriceListItem>(request.config, request.apiRoute);
    context.commit(apis.CLEAR_PRICE_LIST_CONFIGS, request.apiRoute);
  },

  async [apis.DELETE_PRICE_LIST_CONFIG](context, config: IPriceListConfig<IPriceListItem>) {
    if (!context.state.fullPriceListConfigs || context.state.fullPriceListConfigs.length === 0) {
      await context.dispatch(apis.FETCH_PRICE_LIST_CONFIGS, { route: 'pricelists', forceFetch: false });
    }

    await authorizationApiProvider.deletePriceListConfig(config).then(async () => {
      await authorizationApiProvider.clearCache();
      context.commit(apis.DELETE_PRICE_LIST_CONFIG, config);
    });
  },

  async [apis.FETCH_PRICE_LIST_CONFIG_ITEMS_KEYS](context, id: string) {
    return await authorizationApiProvider.getPriceListItemsKeys(id);
  },
};

export default PriceListConfigActions;


import { Component, Vue, Prop } from 'vue-property-decorator';
import { FulfillmentTicketStatus} from '@/store/dynamicfulfillment/types';
import Badge from '@/common/badge';

@Component
export default class FulfillmentTicketStatusLabel extends Vue {
  @Prop({required: true, type: Number}) private status!: FulfillmentTicketStatus;

  get badge(): Badge {
    switch (this.status) {
      case FulfillmentTicketStatus.None:
        return new Badge('None', 'light');
      case FulfillmentTicketStatus.New:
        return new Badge('New', 'info');
      case FulfillmentTicketStatus.Booked:
        return new Badge('Booked', 'secondary');
      case FulfillmentTicketStatus.InProgress:
        return new Badge('InProgress', 'primary');
      case FulfillmentTicketStatus.Cancelled:
        return new Badge('Cancelled', 'warning');
      case FulfillmentTicketStatus.Rejected:
        return new Badge('Rejected', 'danger');
      case FulfillmentTicketStatus.Failed:
        return new Badge('Failed', 'danger');
      case FulfillmentTicketStatus.Fulfilled:
        return new Badge('Fulfilled', 'success');
      default:
        throw new Error(`Unmapped status type: ${this.status}`);
    }
  }
}


import Vue from 'vue';
import { Component, Prop,} from 'vue-property-decorator';
import VueJSONEditor from '../../components/VueJSONEditor.vue';
import { IConfig, IConfigHistory, RouteAction, RoutingAction, } from '@/store/dynamicfulfillment/types';
import { displayError, displayErrors, jsonToText } from '../../common/functions.helpers';
import vueJsonCompare from 'vue-json-compare';
import EventBus from '@/common/event.bus';

@Component({
    components: {
        VueJSONEditor,
        vueJsonCompare
    }
})
export default class ConfigHistoryModalComponent extends Vue {
    @Prop() history: IConfigHistory[];
    @Prop() config: IConfig;
    @Prop() id: string;
    @Prop() routeActions: RouteAction[]; 
    @Prop() configLabel!: string;
    @Prop() indexRoute!: string | { name: string, query?: any };

    jsonEditorText: any = jsonToText;
    historyFields = [
        {
            key: 'userAudit.current.name',
            label: 'Name',
        },
        {
            key: 'userAudit.current.modifiedOnUtc',
            label: 'Modified On',
        },
        'action',
    ];
    sortHistoryBy = "userAudit.current.modifiedOnUtc";
    sortHistoryByDesc: boolean = true;
    show: boolean = false;
    compareItems: IConfig[] = [];
    skipCompareFields = ['id', '_etag', 'userAudit', '_rid', '_self', '_ts'];
    items: IConfig[] = [];

    mounted() {
        this.items = this.sortedCompareItems;
    }
        
    get historyCompareModalId() {
        return (id: string) => `${id}-history-compare`;
    }

    historyConfigModalId(id: string) {
        return `historyConfigModal-${id}`;
    }

    historyConfigModalTitle(item: IConfig) {
        return `Configuration History - ${item.id}`;
    }

    get historyCompareModalTitle() {
        if (this.sortedCompareItems && this.sortedCompareItems.length === 2) {
            return `${this.sortedCompareItems[0].modifiedOnUtc} vs ${this.sortedCompareItems[1].modifiedOnUtc}`;
        }
    }

    showHistoryConfig(item: IConfig) {
        this.$bvModal.show(this.historyConfigModalId(item.id));
    }

    onRowClick(item: IConfig) {
        // Remove the compare item if it is already in the list because the row is now unselected
        if(this.compareItems.includes(item)) {
            let index = this.compareItems.indexOf(item);
            if (index !== -1) {
                this.compareItems.splice(index, 1);
            }
            return;
        }
        this.compareItems.push(item);
        // Remove the first item if the list has more than 2 items
        if(this.compareItems.length > 2) {
            var removedItem = this.compareItems.shift();
            var removedIndex = this.history.findIndex((item) => item.id === removedItem.id);
            var table = this.$refs.historyTable as any;
            table.unselectRow(removedIndex);            
        }
    }

    onRowSelected(items: IConfig[]) {
        if(items.length === 0) {            
            this.compareItems = [];
        }
    }

    compare() {
        this.$bvModal.show(this.historyCompareModalId(this.id));
    }

    isComparable() {
        return this.compareItems.length === 2;
    }

    get canRevert() {
        return (item: IConfig) => {
            return this.history.findIndex((i) => i.id === item.id) === 1; // Only the previous item can be reverted
        }
    }

    revert(item: IConfig) {
        try { 
            this.$bvModal
            .msgBoxConfirm('Are you sure?', {
                title: ``,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
            })
            .then(async (value) => {
                if (value) {
                    item.id = this.config.id;
                    item._etag = this.config._etag;
                    item.userAudit = this.config.userAudit;                       
                    let saveAction: RouteAction = this.routeActions?.find((routeAction) => routeAction.name === RoutingAction.Revert);
                    let params = saveAction && saveAction.params? {apiRoute: saveAction.params.apiRoute, config: item} : item;
                    if(!saveAction) {
                        throw new Error('Revert action not found');
                    }
                    this.$store.dispatch(saveAction.action, params).then((item: IConfig) => { 
                        this.$emit('hideModal', this.config); 
                        EventBus.$emit('s', `${this.configLabel} config reverted successfully.`);
                        this.$emit('saved');
                    });
                }
            });
        } catch (error) {
            const err: any = error;
            this.displayError(error);
            this.$emit('error');
        } finally {
        }
    }  

    get sortedCompareItems() {
        let items = this.compareItems.sort((a, b) => {
                        return a.userAudit.current.modifiedOnUtc > b.userAudit.current.modifiedOnUtc ? 1 : -1;
                    }).map((item: any) => {
                        item["modifiedOnUtc"] = item.userAudit.current.modifiedOnUtc;
                        return item;
                    });
        items = JSON.parse(JSON.stringify(items));
        items.forEach((item) => {
            this.skipCompareFields.forEach((field) => {
                delete item[field];
            });
        });
        return items;
    }

    routeResolver(route: string | {}) {
        return typeof route === 'string' ? {name: route} : route;
    }

    displayError(error: any) {
        const message = (error.response === undefined) ? error : 
                        (error.response.errors) ? displayErrors(error.response.errors) : 
                        (error.response.data) ? (error.response.data.errors)? displayErrors(error.response.data.errors)  : displayError(error.response.data): error.response;
        EventBus.$emit('e', `<h4>Validation error(s) occured.</h4>${message}`);
    }

}


import { Component } from 'vue-property-decorator';
import RemovedConfigIndexComponent from '@/components/configuration/RemovedConfigIndexComponent.vue';
import { DELETE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIG } from '@/store/dynamicfulfillment/constants';
import { RoutingAction, ServiceNetworkFulfillmentOptionTypeConfig } from '@/store/dynamicfulfillment/types';
import  AttributeViewComponent from '@/components/common/AttributeViewComponent.vue';
import ServiceNetworkFulfillmentOptionTypeConfigIndex from './ServiceNetworkFulfillmentOptionTypeConfigIndex.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';

@Component({ components: { RemovedConfigIndexComponent, AttributeViewComponent } })
export default class RemovedServiceNetworkFulfillmentOptionTypeConfigIndex extends ServiceNetworkFulfillmentOptionTypeConfigIndex {
  private configItems: ServiceNetworkFulfillmentOptionTypeConfig[]  = null;

  async mounted() {
    await authorizationApiProvider.getServiceNetworkFulfillmentOptionTypeConfigs(true)
        .then((configs) => this.configItems = configs);
  }

  get removedRouteActions() {
    return [
     { name: RoutingAction.Delete, action: 'dynamicfulfillmentmodule/' + DELETE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIG, requiredPermission: 'Global-HardDelete', params: { hardDelete: true }},
    ];
  }
}


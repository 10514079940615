
import Vue from 'vue';
import EventBus from '@/common/event.bus';
import { Component, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { buildErrorMessage } from '@/common/functions.helpers';
import {
  FETCH_APPCONFIG_BY_KEY,
  UPDATE_APPCONFIG,
  DELETE_APPCONFIG,
} from '@/store/dynamicfulfillment/constants';
import { AppConfig } from '@/store/dynamicfulfillment/types';
import { Action } from 'vuex-class';
import DataTable from '@/components/common/DataTable.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable, ViewContent, ConfigIndexComponent } })
export default class AppConfigDetails extends Vue {
  @Action(FETCH_APPCONFIG_BY_KEY, { namespace })
  private fetchAppConfigByKey: (key: string) => Promise<any>;
  @Action(UPDATE_APPCONFIG, { namespace })
  private updateAppConfig: any;
  @Action(DELETE_APPCONFIG, { namespace })
  private deleteAppConfig: any;
  private selectedConfig: AppConfig = null;
  private appConfigs: AppConfig[] = null;
  private selectedAppConfigs: AppConfig[] = [];
  private appConfigKey: string = '';
  private fields: any[] = [];
  private isLoading: boolean = false;
  private currentPage = 1;
  private pageSize = 10;

  public async mounted() {
    this.appConfigs = await this.fetchAppConfigByKey('AppConfig:*');
    this.appConfigs.forEach((config: AppConfig) => {
      config.settings = JSON.parse(config.value);
    });
  }

  private get appConfigOptions(): string[] {
    if (this.appConfigs === null) {
      return [];
    }

    return this.appConfigs.map((config) => config.settings.prefix);
  }

  private buildKey(rowItem: any): string {
    return `${this.appConfigKey}:${
      rowItem[this.selectedConfig.settings.keySettings.label]
    }`;
  }

  private async appConfigSelected() {
    this.isLoading = true;
    this.selectedConfig = this.appConfigs.find(
      (config: AppConfig) => config.key == `AppConfig:${this.appConfigKey}`,
    );
    let selectedAppConfigs = await this.fetchAppConfigByKey(
      `${this.appConfigKey}:*`,
    );
    this.loadFieldsAndData(selectedAppConfigs);
    this.isLoading = false;
  }

  private loadFieldsAndData(selectedAppConfigs: AppConfig[]) {
    this.fields = [
      {
        key: this.selectedConfig.settings.keySettings.label,
        sortable: true,
        filterable: true,
        formatter: (value: any, key: any, item: any) => {
          return value.charAt(0) === ':' ? value.slice(1) : value;
        },
      },
      {
        key: this.selectedConfig.settings.valueSettings.label,
        sortable: true,
        filterable: true,
      },
      'actions',
    ];

    this.selectedAppConfigs = [];
    selectedAppConfigs.forEach((config: AppConfig) => {
      let data: any = {
        [this.selectedConfig.settings.keySettings.label]: config.key.replace(
          `${this.selectedConfig.settings.prefix}:`,
          '',
        ),
        [this.selectedConfig.settings.valueSettings.label]: config.value,
      };
      this.selectedAppConfigs.push(data);
    });
  }

  private async remove(rowItem: any) {
    const selectedKey = this.buildKey(rowItem);
    this.$bvModal
      .msgBoxConfirm('Are you sure? This cannot be undone.', {
        title: `Delete ${selectedKey}?`,
        size: 'lg',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
        headerClass: 'text-break'
      })
      .then(async (value) => {
        if (value) {
          const configToDelete = this.selectedAppConfigs.find(
            (config: AppConfig) => config.key === selectedKey,
          );
          this.deleteAppConfig(selectedKey)
            .then((response: any) => {
              this.updateSentinels(this.selectedConfig);
              EventBus.$emit('s', `${selectedKey} deleted successfully`);
              this.appConfigSelected();
            })
            .catch((error: any) => {
              this.displayErrors(error);
            });
        }
      })
      .catch((err) => {
        this.displayErrors(err);
      });
  }

  private updateSentinels(appConfig: AppConfig) {
    if (appConfig.settings.sentinelKeys) {
        appConfig.settings.sentinelKeys.forEach((key) => {
            this.updateAppConfig({
                key: key,
                value: Date.now(),
            });
        });
    } 
    else if (appConfig.settings.sentinelKey) {
        this.updateAppConfig({
            key: appConfig.settings.sentinelKey,
            value: Date.now(),
        });
    }
  }

  private displayErrors(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit('e', message);
  }
}

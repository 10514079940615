import { render, staticRenderFns } from "./Communications.vue?vue&type=template&id=a8b2dba0"
import script from "./Communications.vue?vue&type=script&lang=ts"
export * from "./Communications.vue?vue&type=script&lang=ts"
import style0 from "./Communications.vue?vue&type=style&index=0&id=a8b2dba0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
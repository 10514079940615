
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FulfillmentTicket } from '@/store/dynamicfulfillment/types';
import { SERVICECENTRAL_GET_CLAIM } from '@/store/dynamicfulfillment/constants';
import { Action } from 'vuex-class';
import ListItem from '@/components/common/ListItem.vue';
import { jsonToText } from '@/common/functions.helpers';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: {
    ListItem,
    VueJSONEditor,
  },
})
export default class FixtqDetail extends Vue {
  @Prop() private fulfillmentTicket!: FulfillmentTicket;
  @Action(SERVICECENTRAL_GET_CLAIM, { namespace })
  private getServiceCentralClaimById: any;
  private jsonEditorText: any = jsonToText;
  private orderDetails: any = null;

  public async mounted() {
    this.orderDetails = await this.getServiceCentralClaimById(
      this.fulfillmentTicket.claimAuthorizationNumber,
    );
  }
}

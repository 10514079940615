
import Vue from 'vue';
import ViewContent from '@/components/ViewContent.vue';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { Component } from 'vue-property-decorator';
import DataTable from '@/components/common/DataTable.vue';
import { DELETE_USER_CONFIG, FETCH_USER_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import { ConfigType, DynamicFulfillmentState, RoutingAction } from '@/store/dynamicfulfillment/types';
import { State } from 'vuex-class';
import { DateTime } from 'luxon';
import { EDIT_USER_CONFIG, FETCH_CONFIG_HISTORY } from '@/store/constants';

@Component({ components: { ViewContent, ConfigIndexComponent, DataTable } })
export default class UserConfigIndex extends Vue {
  @State('dynamicfulfillmentmodule') private profile: DynamicFulfillmentState;
  initialSort: string = 'name';
  sortDesc: boolean = false;
  fields: any[] = [
    {
      key: 'id',
      sortable: true,
      filterable: true,
    },
    {
      key: 'name',
      sortable: true,
      filterable: true,
    },
    {
      key: 'roles',
      sortable: true,
      filterable: true,
      formatter: (value: any, key: any, item: any) => {
        return item.roles && item.roles.length > 0 ? item.roles.join('<br />') : '';
      },
    },
  ];
  exportFields: any[] = ['id', 'name', 'roles', 'actions'];
  configExport: {
    id: string;
    name: string;
    roles: [];
    actions: string;
  }[] = null;
  configType: ConfigType = ConfigType.Users;

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'User-Edit', requiredPermission: 'Configuration-Users-Edit' },
      { name: RoutingAction.Create, route: 'User-Create', requiredPermission: 'Configuration-Users-Create' },
      { name: RoutingAction.Copy, route: 'User-Copy', requiredPermission: 'Configuration-Users-Create' },
      { name: RoutingAction.Fetch, action: 'dynamicfulfillmentmodule/' + FETCH_USER_CONFIGS, requiredPermission: 'Configuration-Users-View' },
      { name: RoutingAction.Remove, action: 'dynamicfulfillmentmodule/' + DELETE_USER_CONFIG, requiredPermission: 'Configuration-Users-Delete' },
      { name: RoutingAction.Download, requiredPermission: 'Configuration-Users-Download' },
      { name: RoutingAction.Upload, route: 'UserConfigs-Upload', requiredPermission: 'Configuration-Users-Upload' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-Users-View' },
      { name: RoutingAction.Revert, action: EDIT_USER_CONFIG, requiredPermission: 'Configuration-Users-Edit' },
      { name: RoutingAction.RemovedItems, route: 'RemovedUsers-Index', requiredPermission: 'Global-HardDelete' },
    ];
  }

  get exportFileName() {
    return `userconfig_${process.env.VUE_APP_REGION}_${process.env.VUE_APP_ENVIRONMENT}_${DateTime.now().toFormat('yyyyMMdd')}.xlsx`;
  }

  mapConfig(configs: any[]) {
    this.configExport = configs.map((item: any) => ({
      id: item.id,
      name: item.name,
      roles: item.roles.join('|'),
      actions: item.actions,
    })) as [];
  }
}


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ViewContent from '../ViewContent.vue';
import ConfigEditComponent from './ConfigEditComponent.vue';
import { DynamicFulfillmentState, RoleConfig as RoleConfig, UserConfig } from '@/store/dynamicfulfillment/types';
import { Action, State } from 'vuex-class';
import { FETCH_ROLES, FETCH_USER_CONFIGS } from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: { ViewContent, ConfigEditComponent },
})
export default class UserConfigCreateEdit extends Vue {
  @Prop() config: UserConfig;
  @Prop() isEdit: boolean;
  @Prop({ default: false }) isCopy: boolean;
  @Action('dynamicfulfillmentmodule/' + FETCH_ROLES) fetchRoles: any;
  @Action('dynamicfulfillmentmodule/' + FETCH_USER_CONFIGS) fetchUserConfigs: any;
  @State(namespace) private profile!: DynamicFulfillmentState;

  availableRoles: RoleConfig[] = [];
  copyFromUser: string = '';

  async mounted() {
    this.availableRoles = await this.fetchRoles();
    this.fetchUserConfigs();
  }

  get allRoles() {
    return this.availableRoles
      .map((roleConfig: RoleConfig) => roleConfig.id)
      .sort((a, b) => {
        return a < b ? -1 : 1;
      });
  }

  get allUsers() {
    return this.profile.userConfigs
      .map((userConfig: UserConfig) => userConfig.name)
      .sort((a, b) => {
        return a < b ? -1 : 1;
      });
  }

  get isRolesSelected() {
    return !(this.config.roles && this.config.roles.length > 0);
  }

  onIdChange() {
    this.config.id = !!this.config.id ? this.config.id.toLocaleLowerCase() : this.config.id;
  }

  onCopyUser() {
    this.config.roles = [...this.profile.userConfigs.find((user: UserConfig) => user.name === this.copyFromUser).roles];
  }

  clearRoles() {
    this.config.roles = [];
  }

  clearCopyFromUser() {
    this.copyFromUser = '';
    this.clearRoles();
  }
}

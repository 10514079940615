
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

    @Component
    export default class UploadComponent extends Vue {
        @Prop({ required: true}) private formLabel: string;
        @Prop({ required: true}) private placeHolderText: string;
        @Prop({ required: true}) private dropPlaceHolderText: string;
        @Prop({ required: true}) private acceptFileFormat: string;
        @Prop({ required: true}) private validationDelegate: any;
        @Prop({ required: true}) private formatFileNameDelegate: any;
        @Prop({ required: true}) private handleinvalidFeedBack: any;
        @Prop({default: false}) private resetForm: boolean;
        @Prop({default: true}) private isValidState: boolean;
        private file: File = null;
        private formattedFileName: string = null;
        private isValidFile: boolean = false;
        private eventData: any  = {};

        private validation() {
            this.isValidFile = this.validationDelegate(this.file);
            return this.isValidFile;
        }

        private formatFileName(files: File[]) {
            const fileName = this.formatFileNameDelegate(files);
            this.formattedFileName = fileName;
            return fileName;
        }

        private get invalidFeedback() {
           return this.handleinvalidFeedBack();
        }

        private async handleSubmit(e: any) {
            e.preventDefault();
            this.eventData = {
                file: this.file,
            };
            this.$emit('handleSubmit', this.eventData);
        }

        private async fileChangeEvent(files: File[]) {
            this.isValidFile = true;
            await this.$emit('reset');
        }

        @Watch('resetForm')
        private onPropertyChanged(value: boolean, oldValue: boolean) {
            if (value === true) {
                this.reset();
            }
        }

        private reset() {
            this.file = null;
            this.formattedFileName = '';
            this.isValidFile = true;
            this.$emit('reset');
        }
    }

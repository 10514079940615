
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import {
  FETCH_INVENTORY_STATE,
  SAVE_INVENTORY_STATE,
} from '@/store/constants';
import InventoryStateCreateEdit from '@/components/inventory/InventoryStateCreateEdit.vue';
import { InventoryState } from '@/store/dynamicfulfillment/types';

@Component({components: {ConfigEditComponent, InventoryStateCreateEdit}})
export default class InventoryStatesCreate extends Vue {
  @Prop() private id!: string;
  private defaultConfig: InventoryState = {
    manufacturer: null,
    vendorName: null,
    vendorDeviceTypeId: null,
    vendorRepairTypeId: null,
    color: null,
    id: null,
    locationInventoryData: [],
    _etag: null,
  };
  private isStateChanged: boolean;

  private get fetchAction() {
    return FETCH_INVENTORY_STATE;
  }

  private get saveAction() {
    return SAVE_INVENTORY_STATE;
  }

  private stateUpdated() {
    this.isStateChanged = true;
  }

  private stateSaved() {
    this.isStateChanged = false;
  }

  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.isStateChanged) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
}



import Vue from 'vue';
import { Watch, Component } from 'vue-property-decorator';

const defaultLayout = 'default';

@Component
export default class App extends Vue {
  private authenticated: boolean = false;

  @Watch('$route')
  private async isAuthenticated() {
    this.authenticated = await this.$auth.isAuthenticated();
  }

  private login() {
    this.$auth.loginRedirect('/search');
  }

  private async logout() {
    await this.$auth.logout();
    await this.isAuthenticated();
    this.$router.push({ name: 'Home' } );
  }

  private get layout() {
    return (this.$route.meta.layout || defaultLayout) + '-layout';
  }
}

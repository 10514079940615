
import { Component, Prop } from 'vue-property-decorator';
import { PriceListConfig, PriceListItem } from '@/store/dynamicfulfillment/types';
import PriceListConfigItemsUploadComponent from '@/components/PriceLists/PriceListConfigItemsUploadComponent.vue';
@Component({
    components: { PriceListConfigItemsUploadComponent },
})
export default class PriceListConfigItemsUpload extends PriceListConfigItemsUploadComponent<PriceListItem> {
    @Prop() private item: PriceListConfig;

    private mounted() {
        if (!this.item) {
            this.$router.push({name: 'PriceListConfig-Index'});
        }
    }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { JustInTimeInventoryPartsMappingState, RoutingAction } from '@/store/dynamicfulfillment/types';
import { FETCH_JUSTINTIME_INVENTORY_PARTS_MAPPING_STATE } from '@/store/constants';
import XLSX from 'xlsx';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ConfigIndexComponent } })
export default class JustInTimeInventoryMapIndex extends Vue {
  private configs: JustInTimeInventoryPartsMappingState[] = null;
  private fields: any[] = [
    {
      key: 'serviceProvider',
      label: 'Service Network',
      filterable: true,
    },
    {
      key: 'deviceTypeId',
      filterable: true,
    },
    {
      key: 'repairTypeId',
      filterable: true,
    },
    {
      key: 'partSkus',
      filterable: true,
    },
    {
      key: 'externalPartSkus',
      filterable: true,
    },
  ];
  private downloadFileName = 'JustinTimePartsMapping';
  private mappedStates: {
    serviceProvider: string;
    deviceTypeId: string;
    repairTypeId: string;
    partSkus: string[];
    externalPartSkus: string[];
  }[] = [];

  get routeActions() {
    return [
      { name: RoutingAction.Upload, route: 'JustInTimeInventoryPartsMappingStates-Upload', requiredPermission: 'Configuration-JustInTimeInventoryPartsMapping-Upload' },
      { name: RoutingAction.Fetch, action: FETCH_JUSTINTIME_INVENTORY_PARTS_MAPPING_STATE, requiredPermission: 'Configuration-JustInTimeInventoryPartsMapping-View' },
      { name: RoutingAction.Download, requiredPermission: 'Configuration-JustInTimeInventoryPartsMapping-Download' },
    ];
  }

  private pageLoad(configs: JustInTimeInventoryPartsMappingState[]) {
    this.configs = configs;
  }

  private get exportFileName() {
    return `${this.downloadFileName}.xlsx`;
  }

  private mapStates(states: any[]) {
    this.mappedStates = states.map((item: any) => ({
      serviceProvider: item.serviceProvider,
      deviceTypeId: item.deviceTypeId,
      repairTypeId: item.repairTypeId,
      partSkus: item.partSkus.join(','),
      externalPartSkus: item.externalPartSkus.join(','),
    })) as [];
  }
}

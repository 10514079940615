var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ViewContent',{attrs:{"page-title":_vm.pageTitle,"contentTitle":_vm.id},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Configuration")]),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'SourceSystems' }}},[_vm._v("Source Systems")])],1),_c('li',{staticClass:"breadcrumb-item"},[_vm._v(_vm._s(_vm.pageTitle))])]},proxy:true},{key:"content",fn:function(){return [_c('div',[(_vm.allowedEdit)?[_c('div',{staticClass:"ibox-tools menu padding-right"},[_c('b-dropdown',{attrs:{"id":"dropdown-right","right":"","text":"Actions","variant":"primary"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"shadow rounded-bottom"},[_c('b-dropdown-item',{staticClass:"dropdown-item-custom",attrs:{"to":{
                    name: 'SourceSystemConfigDetails-CreateEdit',
                    params: {
                      id: _vm.currentTab,
                      sourceSystem: _vm.id,
                      items: _vm.currentTabData,
                    },
                  }}},[_c('font-awesome-icon',{attrs:{"icon":"fa-pen-to-square"}}),_vm._v(" Edit ")],1)],1)]},proxy:true}],null,false,1339172408)})],1)]:_vm._e(),(_vm.sourceSystemConfig)?_c('b-tabs',{attrs:{"content-class":"mt-3","active-nav-item-class":"tab-active"}},_vm._l((_vm.tabData),function(item,index){return _c('b-tab',{key:item.key,attrs:{"title":item.label},on:{"click":function($event){_vm.currentTab = item.key}}},[_c('data-table',{attrs:{"items":item.value,"fields":item.fields,"empty-text":`No ${item.label} to display.`,"show-empty":true,"filter":_vm.filter[index],"current-page":_vm.currentPage,"sort-by.sync":"key","sort-desc":_vm.sortByDesc,"filter-included-fields":_vm.filterOnFields},on:{"update:sortDesc":function($event){_vm.sortByDesc=$event},"update:sort-desc":function($event){_vm.sortByDesc=$event}},scopedSlots:_vm._u([{key:"cell(value)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.value)+" ")]}}],null,true)})],1)}),1):_c('loading')],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }

import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import StatusMappingCreateEdit from '@/components/configuration/StatusMappingCreateEdit.vue';
import { OrderStatusMapping, RoutingAction, VendorTicketStatusMapping } from '@/store/dynamicfulfillment/types';
import { FETCH_STATUS_MAPPING_BY_ID, CREATE_STATUS_MAPPING, DELETE_STATUS_MAPPING, STATUS_MAPPING_FETCH } from '@/store/constants';
import { FulfillmentOption } from '@/store/dynamicfulfillment/types';

@Component({components: {ConfigEditComponent, StatusMappingCreateEdit}})
export default class StatusMappingCreate extends Vue {
    @Prop() statusType: string;
    defaultConfig: VendorTicketStatusMapping | OrderStatusMapping = {
        id: null,
        _etag: null,
        vendor: this.$route.query.vendor ? this.$route.query.vendor as string : null,
        fulfillmentOption: this.$route.query.fulfillmentOption ? FulfillmentOption[this.$route.query.fulfillmentOption as any] as any : null,
        serviceNetwork: null,
        sourceStateId: null,
        targetStatus : null,
        targetReason: null,
        documentType: null,
        triggeringWorkflows: [],
    };
    isValidState: boolean = true;

    mounted(){
        this.defaultConfig.documentType = this.statusType === 'vendorticketstatus' ? 'VendorTicketStatusMapping': 'OrderStatusMapping';
    }

    get label() {
        switch(this.statusType) {
            case 'vendorticketstatus':
                return 'Vendor Ticket Status Mapping';
            case 'orderstatus':
                return 'Order Status Mapping';
        }
    }

    get title() {
        switch(this.statusType) {
            case 'vendorticketstatus':
                return 'Vendor Ticket';
            case 'orderstatus':
                return 'Order';
        }
    }

    get indexRoute() {
        let route = '';
        let detailsRoute = '';
        switch(this.statusType) {
            case 'vendorticketstatus': 
                route = 'vendorticketstatusmapping';
                detailsRoute = 'VendorTicketStatusMapping-Details';
                break;
            case 'orderstatus':
                route = 'orderstatusmapping';
                detailsRoute = 'OrderStatusMapping-Details';
                break;
        }
        if (!this.$route.query.fulfillmentOption && !this.$route.query.vendor) {
            return route;
        } else {
            return {name: detailsRoute, query: {fulfillmentOption: this.$route.query.fulfillmentOption, vendor: this.$route.query.vendor} };
        }
    }

    get fetchAction() {
        return FETCH_STATUS_MAPPING_BY_ID;
    }

    get saveAction() {
        return CREATE_STATUS_MAPPING;
    }

    get routeActions() {
        switch(this.statusType) {
            case 'vendorticketstatus': 
                return [
                    { name: RoutingAction.Create, action: CREATE_STATUS_MAPPING, params: {apiRoute: 'vendorticketstatus'}},        
                    { name: RoutingAction.Fetch, action: FETCH_STATUS_MAPPING_BY_ID, params: {apiRoute: 'vendorticketstatus'} },        
                ];
            case 'orderstatus':
                return [
                    { name: RoutingAction.Create, action: CREATE_STATUS_MAPPING, params: {apiRoute: 'orderstatus'}},        
                    { name: RoutingAction.Fetch, action: FETCH_STATUS_MAPPING_BY_ID, params: {apiRoute: 'orderstatus'} },        
                ];
        }
    }

    onStateChanged(isValid: boolean) {
        this.isValidState = isValid;
    }
}

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, FulfillmentOptionConfig, Vendor } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { mapDescriptionItemPrimaryToShadow, mapDescriptionItemShadowToPrimary } from '@/common/utilities';

const FulfillmentOptionConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_FULFILLMENT_OPTION_CONFIGS](context) {
    let fulfillmentOptionConfigs = context.state.fulfillmentOptionConfigs;
    if (fulfillmentOptionConfigs.length > 0) {
      return fulfillmentOptionConfigs;
    }
    // tslint:disable-next-line:max-line-length
    fulfillmentOptionConfigs = await authorizationApiProvider.getFulfillmentOptionConfigs();
    fulfillmentOptionConfigs.forEach((config) => {
      mapDescriptionItemPrimaryToShadow(config);
    });
    context.commit(apis.SET_FULFILLMENT_OPTION_CONFIGS, fulfillmentOptionConfigs);
    return fulfillmentOptionConfigs;
  },

  async [apis.FETCH_FULFILLMENT_OPTION_CONFIG](context, id: string) {
    if (!context.state.fulfillmentOptionConfigs || context.state.fulfillmentOptionConfigs.length == 0) {
      await context.dispatch(apis.FETCH_FULFILLMENT_OPTION_CONFIGS, true);
    }
    return context.state.fulfillmentOptionConfigs.find((c) => c.id === id);
  },

  async [apis.FULFILLMENT_OPTION_CONFIG_SAVE](context, config: FulfillmentOptionConfig) {
    mapDescriptionItemShadowToPrimary(config);
    if (config && config.vendors) {
      config.vendors.forEach((v: Vendor) => {
        if (v.operationsStrategy?.name == '' || v.operationsStrategy?.name == null) {
          v.operationsStrategy = null;
        }
      });
    }

    await authorizationApiProvider.updateFulfillmentOptionConfig(config);
    context.commit(apis.RESET_FULFILLMENT_OPTION_CONFIGS);
    await context.dispatch(apis.FETCH_FULFILLMENT_OPTION_CONFIGS);
  },
};

export default FulfillmentOptionConfigActions;

import { render, staticRenderFns } from "./InventoryMapUpload.vue?vue&type=template&id=470a7518&scoped=true"
import script from "./InventoryMapUpload.vue?vue&type=script&lang=ts"
export * from "./InventoryMapUpload.vue?vue&type=script&lang=ts"
import style0 from "./InventoryMapUpload.vue?vue&type=style&index=0&id=470a7518&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "470a7518",
  null
  
)

export default component.exports

import Vue from 'vue';
import ServiceCenterLocation from '@/components/ServiceCenters/ServiceCenterLocation.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import {
  ServiceLocationAddress,
  ServiceLocationContactData,
  ServiceLocationMakes,
  StoreHours,
  ServiceCenterLocationConfig,
} from '@/store/globalmodules/types';
import { Action, State } from 'vuex-class';
import {
  SET_SERVICECENTER_LOCATION_STATE,
  FETCH_SERVICECENTER_STATE,
  SAVE_SERVICECENTER_LOCATION,
} from '@/store/constants';
import { FETCH_COUNTRY_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { CountryConfig, } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';

Component.registerHooks(['beforeRouteLeave']);

@Component({ components: { ConfigEditComponent, ServiceCenterLocation } })
export default class ServiceCenterLocationEdit extends Vue {
  @Prop() private id!: string;
  @Prop() private serviceLocationData: ServiceCenterLocationConfig;
  @Action(SET_SERVICECENTER_LOCATION_STATE, { namespace })
  private setServiceCenterLocationConfig: any;
  @Action(FETCH_COUNTRY_CONFIGS, { namespace }) 
  fetchCountryConfigs: (() => Promise<CountryConfig[]>);
  private isValidState: boolean = true;
  private isStateChanged: boolean;
  private config: ServiceCenterLocationConfig = null;
  private submitForm: boolean = false;
  private isConfigError: boolean = false;

  mounted() {    
    this.fetchCountryConfigs();
  }

  private get fetchAction() {
    return FETCH_SERVICECENTER_STATE;
  }

  private get saveAction() {
    return SAVE_SERVICECENTER_LOCATION;
  }

  private formState(value: boolean) {
    this.isValidState = value;
  }

  private stateUpdated() {
    this.isStateChanged = true;
  }

  private stateSaved() {
    this.isStateChanged = false;
  }

  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.isStateChanged) {
      const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!',
      );
      if (answer) {
        this.setServiceCenterLocationConfig(this.config);
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }

  private pageLoaded(config: ServiceCenterLocationConfig) {
    this.config = JSON.parse(JSON.stringify(config));
  }

  private formSubmit(e: any) {
    this.submitForm = !this.submitForm;
  }

  private configError() {
    this.isConfigError = !this.isConfigError;
  }
}


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FETCH_SOURCE_SYSTEM_CLAIM_DATA } from '@/store/dynamicfulfillment/constants';
import DataTable from '@/components/common/DataTable.vue';
import { Action } from 'vuex-class';
import { jsonToText } from '@/common/functions.helpers';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { SourceSystemClaimData } from '@/store/dynamicfulfillment/types';
import { DateTime } from 'luxon';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable, VueJSONEditor } })
export default class SourceSystemClaimDataHistory extends Vue {
  @Prop() private authorizationId!: string;
  @Action(FETCH_SOURCE_SYSTEM_CLAIM_DATA, { namespace })
  private fetchSourceSystemClaimData: any;
  private items: any[] = null;
  private jsonEditorText: any = jsonToText;
  private fields = [
    {
      key: 'claimDateTime',
      sortable: true,
      thStyle: { width: '150px' },
    },
    {
      key: 'claimNumber',
      sortable: true,
    },
    {
      key: 'data',
      sortable: true,
    },
  ];

  public async mounted() {
    this.items = await this.fetchSourceSystemClaimData(this.authorizationId);
  }

  private configModal: any = {
    id: '',
    title: '',
    content: '',
  };

  private config(item: SourceSystemClaimData, index: any, button: any) {
    this.configModal.id = item.claimDateTime;
    this.configModal.title = `Source System Claim Data - ${ this.formatDateTime(item.claimDateTime) }`;
    this.configModal.content = JSON.parse(item.data);
    this.$root.$emit('bv::show::modal', item.claimDateTime, button);
  }

  private resetConfigModal() {
    this.configModal.title = '';
    this.configModal.content = '';
  }

  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}


import { Component, Vue, Prop } from 'vue-property-decorator';
import { OrderStatusEnum } from '@/store/dynamicfulfillment/types';
import Badge from '@/common/badge';

@Component
export default class OrderStatusLabel extends Vue {
  @Prop({ required: true, type: Number }) private status!: OrderStatusEnum;

  private get badge(): Badge {
    switch (this.status) {
      case OrderStatusEnum.None:
        return new Badge('None', 'light');
      case OrderStatusEnum.Pending:
        return new Badge('Pending', 'secondary');
      case OrderStatusEnum.InProgress:
        return new Badge('InProgress', 'primary');
      case OrderStatusEnum.Closed:
        return new Badge('Closed', 'success');
      case OrderStatusEnum.Cancelled:
        return new Badge('Cancelled', 'warning');
      case OrderStatusEnum.Rejected:
        return new Badge('Rejected', 'danger');
      case OrderStatusEnum.Failed:
        return new Badge('Failed', 'danger');
      case OrderStatusEnum.Created:
        return new Badge('Created', 'info');
      default:
        throw new Error(`Unmapped status type: ${this.status}`);
    }
  }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ServiceNetworkFulfillmentOptionTypeConfigCreateEdit from '@/components/configuration/ServiceNetworkFulfillmentOptionTypeConfigCreateEdit.vue';
import {
  FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG,
  CREATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG
} from '@/store/dynamicfulfillment/constants';
import { ServiceNetworkFulfillmentOptionTypeConfig } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ConfigEditComponent, ServiceNetworkFulfillmentOptionTypeConfigCreateEdit } })
export default class ServiceNetworkFulfillmentOptionTypeConfigCreate extends Vue {
  defaultConfig: ServiceNetworkFulfillmentOptionTypeConfig = {
    id: null,
    fulfillmentOption: null,
    allowedAttributes: [],
    _etag: null,
  };
  isValid: boolean = false;

  get fetchAction() {
    return namespace + '/' + FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG;
  }

  get saveAction() {
    return namespace + '/' + CREATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG;
  }

  onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.defaultConfig = request.config;
    }
  }
}

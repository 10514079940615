import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, VendorConfig, LoanerProgramConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { mapDescriptionItemPrimaryToShadow, mapDescriptionItemShadowToPrimary } from '@/common/utilities';

const VendorConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_VENDOR_CONFIGS](context) {
    let vendorConfigs = context.state.vendorConfigs;
    if (vendorConfigs && vendorConfigs.length > 0) {
      return vendorConfigs;
    }
    vendorConfigs = await authorizationApiProvider.getVendorConfigs();
    vendorConfigs.forEach((config) => {
      mapDescriptionItemPrimaryToShadow(config);
      if (config.serviceProviderConfigs === undefined) {
        config.serviceProviderConfigs = [];
      } else {
        config.serviceProviderConfigs.forEach((element) => {
          if (element.loanerProgramConfig === null) {
            element.loanerProgramConfig = {} as LoanerProgramConfig;
          }
        });
      }
    });
    context.commit(apis.SET_VENDOR_CONFIGS, vendorConfigs);
    return vendorConfigs;
  },

  async [apis.FETCH_VENDOR_CONFIG](context, id: string) {
    if (!context.state.vendorConfigs || context.state.vendorConfigs.length == 0) {
      await context.dispatch(apis.FETCH_VENDOR_CONFIGS, true);
    }
    return context.state.vendorConfigs.find((c) => c.id === id);
  },

  async [apis.VENDOR_CONFIG_SAVE](context, config: VendorConfig) {
    mapDescriptionItemShadowToPrimary(config);
    if ((config.id === null || config.id === undefined) && context.state.vendorConfigs.find((c) => c.id === config.id)) {
      throw Error(`Vendor with Id ${config.id} already exists.`);
    }
    await authorizationApiProvider.updateVendorConfig(config);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_VENDOR_CONFIGS);
    await context.dispatch(apis.FETCH_VENDOR_CONFIGS);
  },

  async [apis.VENDOR_CONFIG_DELETE](context, params: { config: any; hardDelete: boolean }) {
    await authorizationApiProvider.removeVendorConfig(params.config, params.hardDelete);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_VENDOR_CONFIGS);
    await context.dispatch(apis.FETCH_VENDOR_CONFIGS, true);
  },

  async [apis.RESET_VENDOR_CONFIGS](context) {
    context.commit(apis.RESET_VENDOR_CONFIGS);
  },
};

export default VendorConfigActions;

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ViewContent',{attrs:{"page-title":_vm.pageTitle,"contentTitle":_vm.id},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_vm._t("breadcrumbs"),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'AppConfig-Index' }}},[_vm._v("Application Configuration")])],1),_c('li',{staticClass:"breadcrumb-item"},[_vm._v(_vm._s(_vm.id))]),_c('li',{staticClass:"breadcrumb-item active"},[_c('strong',[_vm._v("Edit")])])]},proxy:true},{key:"title",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.pageTitle))])]},proxy:true},{key:"content",fn:function(){return [(_vm.keyAppConfig)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('b-form',{class:{ configState: _vm.configState },on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm","blur":"2px","opacity":"0.85","variant":"transparent"}},[_c('b-form-row',[_c('b-col',{attrs:{"sm":"8","md":"6"}},[_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-heading"},[_vm._v(_vm._s(_vm.id))]),_c('div',{staticClass:"panel-body"},[_c('validation-provider',{attrs:{"name":"key","rules":{
                        required: true,
                        regex: _vm.getRegex(
                          _vm.keyAppConfig.settings.keySettings.validator,
                        ),
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label":_vm.keyAppConfig.settings.keySettings.label,"label-for":"keySettingsLabel","label-cols-md":"3","label-cols-lg":"3"}},[_c('b-form-input',{attrs:{"id":"keySettingsLabel","type":"text","placeholder":_vm.keyAppConfig.settings.keySettings.helpText,"state":errors.length === 0,"aria-describedby":"key-live-feedback","required":"","disabled":true},model:{value:(_vm.localConfig.key),callback:function ($$v) {_vm.$set(_vm.localConfig, "key", $$v)},expression:"localConfig.key"}}),_c('b-form-invalid-feedback',{attrs:{"id":"key-live-feedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"value","rules":{
                        required: true,
                        regex: _vm.getRegex(
                          _vm.keyAppConfig.settings.valueSettings.validator,
                        ),
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label":_vm.keyAppConfig.settings.valueSettings.label,"label-for":"valueSettingsLabel","label-cols-md":"3","label-cols-lg":"3"}},[_c('b-form-input',{attrs:{"id":"valueSettingsLabel","type":"text","placeholder":_vm.keyAppConfig.settings.valueSettings.helpText,"state":errors.length === 0,"aria-describedby":"value-live-feedback","required":""},model:{value:(_vm.localConfig.value),callback:function ($$v) {_vm.$set(_vm.localConfig, "value", $$v)},expression:"localConfig.value"}}),_c('b-form-invalid-feedback',{attrs:{"id":"value-live-feedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":!_vm.configState || invalid}},[_vm._v("Submit")])],1)],1)])])],1)],1)],1)],1)]}}],null,false,3515007132)}):_c('loading')]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }
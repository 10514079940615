
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import EquipmentExclusionConfigCreateEdit from '@/components/configuration/EquipmentExclusionConfigCreateEdit.vue';
import { FETCH_EQUIPMENT_EXCLUSION_CONFIG, EDIT_EQUIPMENT_EXCLUSION_CONFIG, FETCH_PROGRAM_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { Action } from 'vuex-class';

@Component({ components: { ConfigEditComponent, EquipmentExclusionConfigCreateEdit } })
export default class EquipmentExclusionConfigEdit extends Vue {
  @Prop() id!: string;
  @Action('dynamicfulfillmentmodule/' + FETCH_PROGRAM_CONFIGS) fetchProgramConfigs: any;
  editedConfig: any = {};
  isValid: boolean = false;

  private mounted() {
    this.fetchProgramConfigs();
  }

  get fetchAction() {
    return 'dynamicfulfillmentmodule/' + FETCH_EQUIPMENT_EXCLUSION_CONFIG;
  }

  get saveAction() {
    return 'dynamicfulfillmentmodule/' + EDIT_EQUIPMENT_EXCLUSION_CONFIG;
  }

  onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.editedConfig = request.config;
    }
  }
}

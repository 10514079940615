
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PhoneNumber as Phone } from '@/store/dynamicfulfillment/types';

@Component
export default class PhoneNumber extends Vue {
  @Prop() private model: Phone;

  private get phoneNumber() {
    return this.model && this.model.number
      ? this.phoneNumberFormatted(this.model.number)
      : '';
  }

  private phoneNumberFormatted(phoneNumber: string) {
    if (phoneNumber.length !== 10) {
      return phoneNumber;
    }

    return `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, 10)}`;
  }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_SERVICE_MANUFACTURERS } from '@/store/constants';
import { RoutingAction } from '@/store/dynamicfulfillment/types';
const namespace: string = 'globalmodule';

@Component({ components: { ConfigIndexComponent } })
export default class ServiceManufacturersIndex extends Vue {
  private fields: any[] = [
    {
      key: 'id',
      sortable: true,
      filterable: true,
    },
    {
      key: 'displayName',
      sortable: true,
      filterable: true,
    },
    {
      key: 'allowedValues',
      thStyle: {
        width: '600px',
      },
      filterable: true,
      formatter: (value: string[], key: any, item: string) => {
        return value.join(', ');
      },
    },
  ];

  private exportFields: any[] = ['Id', 'DisplayName', 'AllowedValues'];

  private configExport: {
    id: string;
    displayName: string;
    allowedValues: [];
  }[] = null;

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'ServiceManufacturers-Edit', requiredPermission: 'ServiceCenters-Manufacturers-Edit' },
      { name: RoutingAction.Create, route: 'ServiceManufacturers-Create', requiredPermission: 'ServiceCenters-Manufacturers-Create' },
      { name: RoutingAction.Upload, route: 'ServiceManufacturers-Upload', requiredPermission: 'ServiceCenters-Manufacturers-Upload' },
      { name: RoutingAction.Fetch, action: FETCH_SERVICE_MANUFACTURERS, requiredPermission: 'ServiceCenters-Manufacturers-View' },
      { name: RoutingAction.Download, requiredPermission: 'ServiceCenters-Manufacturers-Download' },
    ];
  }

  private get exportFileName() {
    return `ServiceManufacturers_${process.env.VUE_APP_REGION}_${process.env.VUE_APP_ENVIRONMENT}.xlsx`;
  }

  private mapConfig(configs: any[]) {
    this.configExport = configs.map((item: any) => ({
      Id: item.id,
      DisplayName: item.displayName,
      AllowedValues: item.allowedValues.join(';'),
    })) as [];
  }
}

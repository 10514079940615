
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_LOCALE_CONFIGS, DELETE_LOCALE_CONFIG, UPDATE_LOCALE_CONFIG, FETCH_CONFIG_HISTORY } from '@/store/constants';
import { jsonToText } from '@/common/functions.helpers';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { ConfigType, RoutingAction } from '@/store/dynamicfulfillment/types';

@Component({ components: { ConfigIndexComponent, VueJSONEditor } })
export default class LocaleConfigIndex extends Vue {
  fields: any[] = [
    {
      key: 'id',
      sortable: true,
      filterable: true,
    },
    {
      key: 'resources',
      sortable: true,
      filterable: true,
      label: 'Resource Count',
      formatter: (value: any, key: any, item: any) => {
        return Object.entries(item.resources).length;
      },
    },
    {
      key: 'localeConfig',
      label: 'View Locale Config',
    },
  ];
  configType: ConfigType = ConfigType.Locales;

  configModal: any = {
    id: '',
    title: '',
    content: '',
  };

  config(item: any, index: any, button: any) {
    this.configModal.id = item.id;
    this.configModal.title = `Locale Config - ${item.id}`;
    this.configModal.content = item.resources;
    this.$root.$emit('bv::show::modal', this.configModal.id, button);
  }

  resetConfigModal() {
    this.configModal.title = '';
    this.configModal.content = '';
  }

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'LocaleConfig-Edit', requiredPermission: 'Configuration-Locales-Edit' },
      { name: RoutingAction.Fetch, action: FETCH_LOCALE_CONFIGS, requiredPermission: 'Configuration-Locales-View' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-Locales-View' },
      { name: RoutingAction.Revert, action: UPDATE_LOCALE_CONFIG, requiredPermission: 'Configuration-Locales-Edit' },
    ];
  }

  jsonEditorText: any = jsonToText;

  get environment() {
    return process.env.VUE_APP_ENVIRONMENT;
  }
}


import { Component, Vue } from 'vue-property-decorator';
import { RecentAuthorizations, FulfillmentAuthChart, AppInsightsExceptions } from '@/components/widgets';
import { widgetsConfigSettings, WidgetsConfig, Widget } from '@/common/widgetsConfigSettings';
import { DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import { State } from 'vuex-class';

@Component({components: {RecentAuthorizations, FulfillmentAuthChart, AppInsightsExceptions}})
export default class Home extends Vue {
  private config: WidgetsConfig = { layout: [], widgets: null };
  private layout = this.config.layout;
  private refreshRecentAuths: boolean = false;
  private isRecentAuthsLoading: boolean = false;
  private chartComponentKey: number =  0;
  private refreshAiExceptions: boolean = false;
  private isAiExceptionLoading: boolean = false;
  private isAiExceptionFullScreen: boolean = false;
  private isRecentAuthsFullScreen: boolean = false;
  private widget: any = Widget;
  private authenticated: boolean = false;
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;

  public async created() {
    this.layout = widgetsConfigSettings.getConfig().layout;
    this.config = await this.getWidgetConfigWithPermission();
    this.layout = this.config.layout;
  }

  private reloadWidget(type: Widget) {
    switch (type) {
      case Widget.RecentAuthorizations:
        this.refreshRecentAuths = !this.refreshRecentAuths;
        this.isRecentAuthsLoading = true;
        break;
      case Widget.AuthorizationRequestsChart:
        this.chartComponentKey += 1;
        break;
      case Widget.AppInsightsExceptions:
        this.refreshAiExceptions = !this.refreshAiExceptions;
        this.isAiExceptionLoading = true;
        break;
    }
  }

  private reloaded(type: Widget) {
    switch (type) {
      case Widget.RecentAuthorizations:
        this.isRecentAuthsLoading = false;
        break;
      case Widget.AppInsightsExceptions:
        this.isAiExceptionLoading = false;
        break;
    }
  }

  private get loading() {
    return (type: Widget): boolean => {
      switch (type) {
        case Widget.RecentAuthorizations:
          return this.isRecentAuthsLoading;
        case Widget.AppInsightsExceptions:
          return this.isAiExceptionLoading;
        default:
          return false;
      }
    };
  }

  private get translateY() {
    return 7;
  }

  private fullscreen(type: Widget) {
     switch (type) {
        case Widget.AppInsightsExceptions:
          this.isAiExceptionFullScreen = !this.isAiExceptionFullScreen;
          break;
        case Widget.RecentAuthorizations:
          this.isRecentAuthsFullScreen = !this.isRecentAuthsFullScreen;
          break;
     }
  }

  private async isAuthenticated() {
    this.authenticated = await this.$auth.isAuthenticated();
  }

  private async getWidgetConfigWithPermission(): Promise<WidgetsConfig> {
    await this.isAuthenticated();
    if (this.authenticated) {
     let getConfig: WidgetsConfig = null;
     return getConfig = widgetsConfigSettings.isAuthorizedToViewWidget(this.profile.userRolesPermissions.permissions);
    }
  }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import AncillaryServiceTypeConfigCreateEdit from '@/components/configuration/AncillaryServiceTypeConfigCreateEdit.vue';
import {
  FETCH_ANCILLARY_SERVICE_TYPE_CONFIG,
  CREATE_ANCILLARY_SERVICE_TYPE_CONFIG
} from '@/store/dynamicfulfillment/constants';
import { AncillaryServiceTypeConfig } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ConfigEditComponent, AncillaryServiceTypeConfigCreateEdit } })
export default class AncillaryServiceTypeConfigCreate extends Vue {
  private defaultConfig: AncillaryServiceTypeConfig = {
    id: null,
    serviceType: null,
    allowedAttributes: [],
    _etag: null,
  };
  private isValid: boolean = false;

  private get fetchAction() {
    return namespace + '/' + FETCH_ANCILLARY_SERVICE_TYPE_CONFIG;
  }

  private get saveAction() {
    return namespace + '/' + CREATE_ANCILLARY_SERVICE_TYPE_CONFIG;
  }

  private onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.defaultConfig = request.config;
    }
  }
}

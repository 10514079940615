
import Vue from 'vue';
import { FulfillmentTicketStatus, VendorTicketStatusUpdateRequest } from '@/store/dynamicfulfillment/types';
import { Component, Prop } from 'vue-property-decorator';
import { SEND_VENDOR_TICKET_STATUS } from '@/store/dynamicfulfillment/constants';
import { Action } from 'vuex-class';
import { validateJson } from '@/common/utilities';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { jsonToText } from '@/common/functions.helpers';
import { displayError } from '@/common/functions.helpers';
import EventBus from '@/common/event.bus';
import { AxiosResponse } from 'axios';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: {VueJSONEditor} })
export default class SendVendorTicketStatus extends Vue {
  @Prop() private authorizationId!: string;
  @Prop() private fulfillmentTicketId!: string;
  @Action(SEND_VENDOR_TICKET_STATUS, { namespace }) private sendVendorTicketStatus: any;
  vendorTicketStatusUpdateRequest: Partial<VendorTicketStatusUpdateRequest> = {
    authorizationId: this.authorizationId,
    fulfillmentTicketId: this.fulfillmentTicketId,
    data: []
  };
  errorMessage: string = null;
  requestValid: boolean = true;
  jsonEditorText: any = jsonToText;

  created() {
    this.$root.$on('send-vendor-status-update', () => {
      this.$bvModal.show('send-vendor-status-modal');
    });
  }

  checkFormValidity() {
    return this.vendorTicketStatusUpdateRequest.status && this.vendorTicketStatusUpdateRequest.status.length > 0 && this.requestValid;
  }

  cancel() {
    this.resetModal();
    this.$bvModal.hide('send-vendor-status-modal');
  }

  resetModal() {
    this.vendorTicketStatusUpdateRequest = {
      authorizationId: this.authorizationId,
      fulfillmentTicketId: this.fulfillmentTicketId,
      data: []
    };
    this.errorMessage = null;
  }

  handleOk(bvModalEvt: any) {
    // Prevent modal from closing
    bvModalEvt.preventDefault();

    // Trigger submit handler
    this.handleSubmit();
  }

  onDataChanged(event: any) {
    if (event.text === null || event.text.trim() === ''){
      this.vendorTicketStatusUpdateRequest.data = [];
      this.requestValid = true;
    } else if (this.validateData(event.text))
    {
      this.vendorTicketStatusUpdateRequest.data = JSON.parse(event.text);
      this.requestValid = true;
    } else {
      this.vendorTicketStatusUpdateRequest.data = null;
      this.requestValid = false;
    }
  }

  validateData(data: any) {
    let value = typeof data !== "string" ? JSON.stringify(data) : data;    
    try {
      value = JSON.parse(data);
    } catch (e) {
      return false;
    }

    if (!Array.isArray(value)) {
      return false;
    }

    if (value.length === 0) {
      return true;
    }

    return value.every(item => 
      typeof item === 'object' &&
      item.hasOwnProperty('name') &&
      (typeof item.name === "string" &&
      item.name.trim() !== '') &&
      item.hasOwnProperty('value') &&
      item.value !== null &&
      item.value !== undefined
    );
  }

  async handleSubmit() {
    this.errorMessage = null;

    // Exit when the form isn't valid
    if (!this.checkFormValidity()) {
      return;
    }

    await this.sendVendorTicketStatus(this.vendorTicketStatusUpdateRequest)
    .then((response: AxiosResponse) => {
      this.$emit('sent-vendor-ticket-status-update');
      this.$bvModal.hide('send-vendor-status-modal');
    })
    .catch((error: any) => {
      const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
      EventBus.$emit('e', `<h4>Validation error(s) occured.</h4>${displayError(errorMessage)}`);

    });
  }
}

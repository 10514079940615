
import { Component, Vue } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import { benefitCheckApiProvider } from '@/common/benefitCheck.api';

@Component({
  components: { ViewContent },
})
export default class Search extends Vue {
  private pageTitle: string = 'Benefit Check';
  private phone: string = '';
  private imei: string = '';
  private carrierName: string = '';
  private hasActiveProtectionPlan: string = '';
  private noResultsFound: boolean = false;
  private error: boolean = false;
  private errorMessage: string = '';

  private async search(e: any) {
    e.preventDefault();

    try {
      this.reset();

      const benefitCheckData = await benefitCheckApiProvider.getBenefitCheckData(
        {
          mobileNumber: this.phone,
          imei: this.imei,
        },
      );
      this.carrierName = benefitCheckData.carrierName;
      this.hasActiveProtectionPlan = benefitCheckData.hasActiveProtectionPlan.toString();
    } catch (err) {
      const error: any = err;
      if (error.response && error.response.status === 404) {
        this.noResultsFound = true;
      } else {
        this.error = true;
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.MobileNumber
        ) {
          this.errorMessage += error.response.data.errors.MobileNumber.join(
            ', ',
          );
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.Imei
        ) {
          if (this.errorMessage !== '') {
            this.errorMessage += `, ${error.response.data.errors.Imei.join(
              ', ',
            )}`;
          } else {
            this.errorMessage += error.response.data.errors.Imei.join(', ');
          }
        }
      }
    }
  }

  private reset() {
    this.noResultsFound = false;
    this.error = false;
    this.errorMessage = '';
  }
}

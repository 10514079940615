import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, ProgramConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const ProgramConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_PROGRAM_CONFIGS](context): Promise<ProgramConfig[]> {
    if (!context.state.sourceSystemConfigs || context.state.sourceSystemConfigs.length == 0) {
      await context.dispatch(apis.FETCH_SOURCE_SYSTEM_CONFIGS, true);
    }

    const eligibleSourceSystems = context.state.sourceSystemConfigs.map((sourceSystemConfig) => sourceSystemConfig.sourceSystem);

    const configs = await authorizationApiProvider.getProgramConfigs();

    const eligibleProgramConfigs = configs.filter((programConfig) => eligibleSourceSystems.includes(programConfig.externalSystem));

    context.commit(apis.SET_PROGRAM_CONFIGS, eligibleProgramConfigs);
    return eligibleProgramConfigs;
  },

  async [apis.FETCH_SOURCE_SYSTEM_PROGRAM_CONFIGS](context, request: { sourceSystem: string }): Promise<ProgramConfig[]> {
    let programConfigs: ProgramConfig[] = [];

    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    programConfigs = context.state.programConfigs.filter((c) => c.externalSystem.toLowerCase() === request.sourceSystem.toLowerCase());

    return programConfigs.length > 0 ? programConfigs : [];
  },

  async [apis.FETCH_PROGRAM_BY_ID_CONFIG](context, id: string) {
    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS);
    }
    return context.state.programConfigs.find((c) => c.id === id);
  },

  async [apis.PROGRAM_CONFIG_SAVE](context, config: ProgramConfig) {
    if ((config.id === null || config.id === undefined) && context.state.programConfigs.find((c) => c.id === config.id)) {
      throw Error(`Program with Id ${config.id} already exists.`);
    }
    await authorizationApiProvider.updateProgramConfig(config);
    context.commit(apis.RESET_PROGRAM_CONFIGS);
    await context.dispatch(apis.FETCH_PROGRAM_CONFIGS);
  },

  async [apis.DELETE_PROGRAM](context, params: { config: ProgramConfig; hardDelete: boolean }) {
    if (!context.state.programConfigs || context.state.programConfigs.length === 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    await authorizationApiProvider.deleteProgram(params.config, params.hardDelete).then(async () => {
      await authorizationApiProvider.clearCache();
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS);
    });
  },
};

export default ProgramConfigActions;


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BookOfferAttempt } from '@/store/dynamicfulfillment/types';
import { DateTime } from "luxon";
import DataTable from '@/components/common/DataTable.vue';

@Component({ components: { DataTable } })
export default class bookOfferAttempts extends Vue {
  @Prop() private bookOfferAttempts: BookOfferAttempt[];
  private fields: any[] = [
    {
      key: 'attemptedOnUtc',
      label: 'Timestamp',
    },
    'isSuccess',
    'message',
  ];
  
  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}


import { Component, Vue } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import UploadComponent from '@/components/common/UploadComponent.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { displayErrors } from '@/common/functions.helpers';

@Component({
  components: { ViewContent, UploadComponent },
})
export default class UserConfigUpload extends Vue {
  private resetForm: boolean = false;
  private pageTitle: string = 'Upload User Configs';
  private validation(file: File) {
    if (file) {
      const extension = file.name.split('.').pop();
      return extension === 'csv' || extension === 'xlsx';
    }
    return true;
  }

  private formatFileName(files: File[]) {
    const fileName = files[0].name;
    return fileName.length > 50 ? [fileName.substring(0, 50), '...'].join() : fileName;
  }

  private invalidFeedBack() {
    return 'Invalid file. Select .csv or .xlsx file';
  }

  private async upload(eventData: any) {
    await authorizationApiProvider
      .uploadUserConfig(eventData.file)
      .then((response: any) => {
        EventBus.$emit('s', `${eventData.file.name} uploaded successfully.`);
        this.resetForm = true;
        this.$router.push({ name: 'Users-Index' });
        return;
      })
      .catch((error) => {
        EventBus.$emit('e', `<h3>Upload failed. The following error(s) occured.</h3>${this.formatToastMessage(error)}`);
      });
  }

  private formatToastMessage(error: any) {
    if (error && error.response && error.response.data && error.response.data.errors) {
      return displayErrors(error.response.data.errors);
    }
    if (error && error.response && error.response.data) {
      return displayErrors(error.response.data);
    }
    if (error && error.response && error.response.errors) {
      return displayErrors(error.response.errors);
    }
    return error;
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import ViewContent from '@/components/ViewContent.vue';
import LegacyClaimStatusLabel from '@/components/LegacyClaimStatusLabel.vue';
import EventBus from '@/common/event.bus';
import { legacyApiProvider } from '@/common/df.legacy.api';
import { LegacyClaimData, LegacyRepairStatus } from '@/store/dynamicfulfillment/types';
import { DateTime } from 'luxon';

@Component({
  components: { ViewContent, LegacyClaimStatusLabel },
})
export default class LegacyClaimIndex extends Vue {
  @Prop() private title: string;
  @Prop() private claimId!: string;
  private claimData: LegacyClaimData[] = null;

  private fields: any[] = [
    'id',
    'claimNumber',
    { key: 'authorizationNumber', label: 'Authorization Number' },
    'repairStatus',
    { key: 'authorizationDate', label: 'Authorization Date' },
  ];

  public async created() {
    this.claimData = await legacyApiProvider.getClaimData(this.claimId);
  }

  private get legacyClaimData() {
    return this.claimData
      .sort((a, b) =>
        a.RepairData.AuthorizationDate > b.RepairData.AuthorizationDate ? -1 : 1,
      )
      .map((t) => {
        return {
          t,
          id: t.id,
          authorizationDate: t.RepairData.AuthorizationDate,
          authorizationNumber: t.RepairData.AuthorizationNumber,
          claimNumber: t.ClaimNumber,
          repairStatus: t.RepairData.RepairStatus,
        };
      });
  }

  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import { ServiceCentralServiceNetworkConfig } from '@/store/dynamicfulfillment/types';
import {
  DELETE_APPCONFIG,
  UPDATE_APPCONFIG,
} from '@/store/dynamicfulfillment/constants';
import ProgramConfigModal from '../../configuration/ProgramConfigModal.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ProgramConfigModal: ProgramConfigModal } })
export default class ServiceNetworkConfigEdit extends Vue {
  @Prop({ required: true }) private serviceNetworkConfig: ServiceCentralServiceNetworkConfig;
  @Action(UPDATE_APPCONFIG, { namespace }) private updateAppConfig: any;
  @Action(DELETE_APPCONFIG, { namespace }) private deleteAppConfig: any;
  private isLoading: boolean = false;
  private sentinelKey: string = 'ServiceCentralApi:Sentinel';
  private forceUpdate: boolean = true;

  private get configModelId() {
    return `${this.serviceNetworkConfig.programId}:${this.serviceNetworkConfig.serviceProvider}`;
  }

  private get serviceNetworkState() {
    return (
      this.serviceNetworkConfig.serviceNetwork === null ||
      this.serviceNetworkConfig.serviceNetwork.trim() === ''
    );
  }

  public async handleSubmit() {
    if (!this.checkFormValidity()) {
      return;
    }

    this.updateAppConfig({
      key: this.serviceNetworkConfig.key,
      value: this.serviceNetworkConfig.serviceNetwork,
    })
      .then((response: any) => {
        if (this.forceUpdate) {
          this.updateAppConfig({ key: this.sentinelKey, value: Date.now() });
        }
        this.isLoading = false;
        EventBus.$emit('s', 'Service Network updated successfully.');
        this.$router.push({ name: 'ServiceCentralNetworkManagement-Index' });
      })
      .catch((error: any) => {
        const message = buildErrorMessage(error);
        EventBus.$emit('e', message);
        this.isLoading = false;
        return;
      });
  }

  private checkFormValidity(): boolean {
    this.isLoading = true;
    return !this.serviceNetworkState;
  }

  private get isNew() {
    return (
      this.serviceNetworkConfig !== null &&
      this.serviceNetworkConfig.serviceNetwork !== null
    );
  }

  private async remove() {
    this.$bvModal
      .msgBoxConfirm('Are you sure? This cannot be undone.', {
        title: `Delete ${this.serviceNetworkConfig.key}`,
        size: 'lg',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          this.deleteAppConfig(this.serviceNetworkConfig.key)
            .then((response: any) => {
              EventBus.$emit(
                's',
                `${this.serviceNetworkConfig.key} deleted successfully`,
              );
              this.$router.push({
                name: 'ServiceCentralNetworkManagement-Index',
              });
            })
            .catch((error: any) => {
              this.displayError(error);
            });
        }
      })
      .catch((error) => {
        this.displayError(error);
      });
  }

  private displayError(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit(
      'e',
      `There was a problem in processing the request. ${message}`,
    );
  }
}

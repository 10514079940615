
import { Component, Prop } from 'vue-property-decorator';
import { PriceListConfig, PriceListItem, FulfillmentOption, DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import PriceListConfigIndexComponent from '@/components/PriceLists/PriceListConfigIndexComponent.vue';
import { State } from 'vuex-class';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { PriceListConfigIndexComponent } })
export default class PriceListConfigIndex extends PriceListConfigIndexComponent<PriceListItem> {
  @State(namespace) private dfprofile!: DynamicFulfillmentState;
  private priceListsFields: any[] = [
    {
      key: 'id',
      thStyle: { width: '325px' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'vendor',
      filterable: true,
    },
    {
      key: 'serviceProviders',
      filterable: true,
      label: 'Service Networks',
    },
    {
      key: 'fulfillmentOption',
      label: 'Fulfillment Option',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
      filterByFormatted: true,
      filterable: true,
    },
    {
      key: 'invoiceTaxStrategy',
      label: 'Invoice Tax Strategy',
      filterByFormatted: true,
      filterable: true,
    },
    {
      key: 'programIds',
      label: 'Program Names',
      formatter: (value: any, key: any, item: any) => {
        if (item.programIds.length > 0) {
        return this.getProgramNames(value);
        }
      },
      filterByFormatted: true,
      filterable: true,
    },
    {
      key: 'priceListItemCount',
      label: 'Price List Item Count',
    },
    {
      key: 'deviceTypeIdLookupStrategy',
      label: 'Device Type Id Lookup Strategy',
      filterByFormatted: true,
      filterable: true,
    },
    {
      key: 'flowTypes',
      label: 'FlowTypes',
      filterByFormatted: true,
      filterable: true,
    },
    {
      key: 'userAudit.current.name',
      label: 'Last Modified User',
      sortable: true,
    },
    {
      key: 'userAudit.current.modifiedOnUtc',
      sortable: true,
      label: 'ModifiedOn Utc',
      formatter: (value: any, key: any, item: any) => {
        return value === '0001-01-01T00:00:00' ? '' : value;
      },
    },
    {
      key: 'actions',
      thStyle: { width: '200px' },
    },
  ];
  private cFields: any[] = ['manufacturer', 'deviceName', 'deviceTypeId', 'repairTypeId', 'vendorDeviceTypeId', 'vendorRepairTypeId', 'serviceName', 'partsCost', 'laborCost', 'subTotal', 'maxTaxValue', 'maxTotalAmount', 'damageType', 'color', 'effectiveStartDate', 'effectiveEndDate'];
  private configExport: PriceListConfig = {
            fulfillmentOption: null,
            vendor: null,
            itemsBlobName: null,
            programIds: [],
            serviceProviders: [],
            documentType: null,
            invoiceTaxStrategy: null,
            items: null,
            id: null,
            uploadInProgress: null,
            itemVersions: [],
            _etag: null,
            lastModifiedOnUtc: null,
            lastModifiedBy: null,
            priceListItemCount: null,
            deviceTypeIdLookupStrategy: null,
            flowTypes: [],
        };

  private mapConfig(config: PriceListConfig) {
    const mappedItems = config.items.map( (item: any) => ({ manufacturer: item.manufacturer,
    deviceName: item.deviceName,
    deviceTypeId: item.deviceTypeId,
    repairTypeId: item.repairTypeId,
    serviceName: item.serviceName,
    partsCost: item.partsCost,
    laborCost: item.laborCost,
    platformFee: item.platformFee,
    subTotal: item.subTotal,
    maxTaxValue: item.maxTaxValue,
    maxTotalAmount: item.maxTotalAmount,
    vendorDeviceTypeId: item.vendorDeviceTypeId,
    vendorRepairTypeId: item.vendorRepairTypeId,
    modelVariantId: item.modelVariantId,
    effectiveStartDate: item.effectiveStartDate,
    effectiveEndDate: item.effectiveEndDate,
    color: item.color,
    damageType: item.damageType})) as [];
    this.configExport.items = mappedItems;
  }

  private programName(programId: string) {
      const programConfig = this.dfprofile.programConfigs.filter((program) => program.id === programId)[0];
      return programConfig ? programConfig.name : `Unknown Program- ${programId}`;
  }

  private getProgramNames(ids: string[]) {
    const programs: string[] = [];
    ids.forEach((p) => programs.push(this.programName(p)));
    return programs;
  }
}


import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import { Action, State } from 'vuex-class';
import DataTable from '../../common/DataTable.vue';
import { FETCH_APPCONFIG_BY_KEY } from '@/store/dynamicfulfillment/constants';
import { AppConfig, DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable } })
export default class BrandMappingConfigIndex extends Vue {
  @Action(FETCH_APPCONFIG_BY_KEY, { namespace })
  private fetchAppConfigByKey: any;
  @State(namespace) private profile!: DynamicFulfillmentState;
  private appConfigKey = 'ServicePowerApi:EquipmentMakeAliasMapping:*';
  private appConfigs: AppConfig[] = [];
  private servicePowerBrandsConfig: any[] = [];
  private isLoading: boolean = false;
  private initialSort: string = 'key';
  private currentPage = 1;
  private pageSize = 10;

  private fields = [
    {
      key: 'brand',
      label: 'Brand',
      thStyle: { width: '275px' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'servicePowerCode',
      label: 'Service Power Code',
      sortable: true,
      filterable: true,
    },
    {
      key: 'lastModified',
      label: 'Last Modified On',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ];

  private exportFields: any[] = ['Brand', 'ServicePowerCode'];
  
  private configExport: {
    Brand: null,
    ServicePowerCode: null,
  }[] = null;

  public async mounted() {
    await this.loadConfig();
  }

  private async loadConfig() {
    this.isLoading = true;
    this.servicePowerBrandsConfig = [];
    this.fetchAppConfigByKey(this.appConfigKey)
      .then((response: any) => {
        this.appConfigs = response;
        this.appConfigs.forEach((config: AppConfig) => {
          this.servicePowerBrandsConfig.push(
            ...[
              {
                key: config.key,
                brand: this.getBrand(config.key),
                servicePowerCode: config.value,
                lastModified: config.lastModified,
              },
            ],
          );
        });
        this.isLoading = false;
      })
      .catch((error: any) => {
        const message = buildErrorMessage(error);
        EventBus.$emit('e', message);
        this.isLoading = false;
      });
  }

  private getBrand(key: string) {
    const brand = key.split(':');
    if (brand.length === 3) {
      return brand[2];
    } else {
      return key;
    }
  }

  private download() {
    this.configExport = this.servicePowerBrandsConfig.map((item: any) => ({
      Brand: item.brand,
      ServicePowerCode: item.servicePowerCode,
    })) as [];
  }

  @Watch('configExport', { immediate: false, deep: true })
  private mappedConfigExportChange() {
    const exportFileName = `ServicePowerBrandMapping_${DateTime.now().toFormat('yyyyMMddHHmm')}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(this.configExport, {
      header: this.exportFields,
    });
    const newWorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkBook, worksheet, 'WorkSheet1');
    XLSX.writeFile(newWorkBook, `${exportFileName}`);
  }

  private hasPermission(features: string[]) {
    return userAuthorizationProviderFactory(this.profile).hasPermission(features);
  }
}


import { Component, Vue } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import UploadComponent from '@/components/common/UploadComponent.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { DynamicFulfillmentState, VendorConfig } from '@/store/dynamicfulfillment/types';
import { FETCH_VENDOR_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { Action, State } from 'vuex-class';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({components: { ViewContent, UploadComponent } })
export default class InventoryStateUpload extends Vue {
    @State(namespace) private profile!: DynamicFulfillmentState;
    @Action(FETCH_VENDOR_CONFIGS, { namespace }) private fetchVendorConfigs: any;
    private pageTitle: string = 'Upload Inventory States';
    private vendorConfigs: VendorConfig[] = [];
    private vendor: string = null;
    private isLoading: boolean = false;
    private blur: string = '2px';
    private resetForm: boolean = false;

    private async created() {
        await this.fetchVendorConfigs();
        this.vendorConfigs = this.profile.vendorConfigs;
    }
    private validation(file: File) {
        if (file) {
            return file.name.split('.').pop() === 'csv';
        }
        return true;
    }

    private formatFileName(files: File[]) {
        const fileName = files[0].name;
        return fileName.length > 50 ? [fileName.substring(0, 50), '...'].join() : fileName;
    }

    private invalidFeedBack() {
        return 'Invalid file. Select .csv file';
    }

    private async upload(eventData: any) {
        this.isLoading = true;
        await authorizationApiProvider.uploadInventoryStates(eventData.file, this.vendor).then( (response: any) => {
            if (response && response.isUploaded) {
                this.isLoading = false;
                EventBus.$emit('s', `${eventData.file.name} uploaded successfully.`);
                this.resetForm = true;
                this.$router.push({ name: 'InventoryStates-Index' } );
                return;
            }
            this.isLoading = false;
            EventBus.$emit('e', `<h4>Upload failed.</h4>${this.formatToastMessage(response)}`);
        }).catch( (error) => {
            this.isLoading = false;
            EventBus.$emit('e', `<h3>Upload failed.</h3>${this.formatToastMessage(error)}`);
        });
    }

    private formatToastMessage(error: any) {
        if (error && error.response && error.response.data && error.response.data.errors) {
            const errors = error.response.data.errors;
            let messages = '';
            Object.keys(error.response.data.errors).forEach( (e) => {
                messages += `<div><strong>${errors[e]}</strong><br/>`;
            });
            return messages;
        }
        if (error && error.response && error.response.data && error.response.data.message) {
            return `<div><strong>${error.response.data.message}</strong><br/>`;
        }
        return `<div><strong>Upload Failed</strong><br/>`;
    }

    private get isValidState() {
        return !(this.vendor === null);
    }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import LocaleConfigCreateEdit from '@/components/configuration/LocaleConfigCreateEdit.vue';
import { FETCH_LOCALE_CONFIG, UPDATE_LOCALE_CONFIG } from '@/store/constants';

@Component({ components: { ConfigEditComponent, LocaleConfigCreateEdit } })
export default class LocaleConfigEdit extends Vue {
  @Prop() private id!: string;
  private editedConfig: any = {};
  private isValid: boolean = false;

  private get fetchAction() {
    return FETCH_LOCALE_CONFIG;
  }

  private get saveAction() {
    return UPDATE_LOCALE_CONFIG;
  }

  private get indexRoute() {
    return 'LocaleConfig-Index';
  }

  private onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.editedConfig = request.config;
    }
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ProgramConfigCreateEdit from '@/components/configuration/ProgramConfigCreateEdit.vue';
import { FETCH_PROGRAM_BY_ID_CONFIG, PROGRAM_CONFIG_SAVE } from '@/store/constants';
import { ProgramConfig } from '@/store/dynamicfulfillment/types';
import { JSONData, JSONPath, ValidationError, ValidationSeverity } from 'vanilla-jsoneditor';

@Component({components: {ConfigEditComponent, ProgramConfigCreateEdit}})
export default class ProgramConfigCreate extends Vue {
    private editedConfig: any = {};
    private isValid: boolean = false;

    private defaultConfig: ProgramConfig = {
        name: '',
        programValue: 0,
        externalSystem: '',
        activeVersion: 1,
        productId: null,
        performBestReplacementCheck: false,
        inventoryThreshold: 0,
        userInterfaceSettings: null,
        productIds: [],
        programConfigVersions: [
          {
            version: 1,
            fulfillmentOptions: [
              {
              name: '',
              fulfillmentOption: 0,
              useLegacy: false,
              distanceToSearch: null,
              numberOfRecordsToReturn: null,
              filterDefinitions: [],
              equipmentFilterConfigs: [],
              serviceProviders: [],
              receiptRequired: true,
              isPartsOrderingEligible: false,
              },
            ],
            filterDefinitions: [],
            optionSorter: null,
            fallbackOfferDefinition: null,
            offerEnhancers: [],
          },
        ],
        id: null,
        _etag: null,
    };

    private get fetchAction() {
        return FETCH_PROGRAM_BY_ID_CONFIG;
    }

    private get saveAction() {
        return PROGRAM_CONFIG_SAVE;
    }

    private validator(json: JSONData): ValidationError[] {
      const config = (json as unknown) as ProgramConfig;
      let validationErrorArray: ValidationError[] = [];
      
      if (!!config.id) {
        let pathArr: JSONPath = [];
        pathArr.push("id");
        validationErrorArray.push({message: "Id cannot be present when creating a new program.", severity: ValidationSeverity.error, path: pathArr})
        }
     
      if (!!config._etag) {
        let pathArr: JSONPath = [];
        pathArr.push("etag");
        validationErrorArray.push({message: "etag cannot be present when creating a new program.", severity: ValidationSeverity.error, path: pathArr})
        }
     
     return validationErrorArray;
    }

    private onConfigChange(request: {config: ProgramConfig | null, isValid: boolean}) {
      this.isValid = request.isValid;

      if (request.isValid) {
        this.editedConfig = request.config;
      }
    }
}

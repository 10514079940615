var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.selectedConfig)?[_c('div',{staticClass:"ibox-tools menu padding-right"},[_c('b-dropdown',{attrs:{"id":"dropdown-right","right":"","text":"Actions","variant":"primary"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"shadow rounded-bottom"},[_c('b-dropdown-item',{staticClass:"dropdown-item-custom",attrs:{"to":{
                name: 'AppConfig-Create',
                params: { appConfig: _vm.selectedConfig, id: _vm.appConfigKey },
              }}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" Create ")],1)],1)]},proxy:true}],null,false,271100736)})],1)]:_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6","xl":"4"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"App Config(s)","label-cols":"auto","label-align":"left","label-size":"sm","label-for":"appConfigs"}},[_c('v-select',{attrs:{"id":"appConfigs","options":_vm.appConfigOptions,"placeholder":"Select an App Configuration"},on:{"input":_vm.appConfigSelected},model:{value:(_vm.appConfigKey),callback:function ($$v) {_vm.appConfigKey=$$v},expression:"appConfigKey"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-4"},[(!_vm.isLoading)?_c('data-table',{attrs:{"items":_vm.selectedAppConfigs,"current-page":_vm.currentPage,"per-page":_vm.pageSize,"fields":_vm.fields,"show-empty":!(_vm.selectedAppConfigs && _vm.selectedAppConfigs.length > 0)},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('router-link',{attrs:{"to":{
              name: 'AppConfig-Edit',
              params: { appConfig: _vm.selectedConfig, id: _vm.buildKey(row.item) },
            },"title":"Edit"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-pen-to-square","size":"lg"}})],1),_c('b-link',{attrs:{"title":"Remove"}},[_c('font-awesome-icon',{staticClass:"ml-2",attrs:{"icon":"fa-trash-can","size":"lg"},on:{"click":function($event){return _vm.remove(row.item)}}})],1)]}}],null,false,2191532462)}):_c('loading')],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const PermissionConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_PERMISSIONS](context) {
    let permissions = await authorizationApiProvider.getPermissions();
    context.commit(apis.SET_PERMISSIONS, permissions);
    return permissions;
  },
};

export default PermissionConfigActions;


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ViewContent extends Vue {
  @Prop({type: Boolean, default: true}) private breadcrumbs: boolean;
  @Prop() private pageTitle!: string;
  @Prop() private contentTitle: string;
}

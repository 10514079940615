import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, SearchByAuthIdQuery, FulfillmentAuthorization, SourceSystemIdentifier, TicketAuthorizationAmountUpdateRequest, OfferSetQuery, FulfillmentAuthorizationSearchPageResult, UpdateTicketStatusModel, SourceSystemClaimData } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const AuthorizationApiActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.SEARCH_AUTH_BY_ID]({ state, commit }, request: SearchByAuthIdQuery): Promise<FulfillmentAuthorization> {
    let auth = state.authorizations[request.authorizationId] as FulfillmentAuthorization;
    if (!auth || request.forceFetch === true) {
      auth = await authorizationApiProvider.getAuthorization(request.authorizationId);
      commit(apis.SET_FULFILLMENT_AUTHORIZATION, auth);
    }
    return auth;
  },

  async [apis.FETCH_SOURCE_SYSTEM_CLAIM_DATA](context, authorizationId: string) {
    return await authorizationApiProvider.getSourceSystemClaimData(authorizationId);
  },

  async [apis.SEARCH_AUTH_BY_SOURCE_IDENTIFIERS](context, sourceSystemIdentifier: SourceSystemIdentifier): Promise<FulfillmentAuthorization> {
    const auth = await authorizationApiProvider.getAuthorizationBySourceIdentifiers(sourceSystemIdentifier.sourceSystem, sourceSystemIdentifier.claimNumber);
    context.commit(apis.SET_FULFILLMENT_AUTHORIZATION, auth);
    return auth;
  },

  async [apis.SEARCH_AUTH_BY_FULFILLMENT_TICKET_ID](context, fulfillmentTicketId: string): Promise<FulfillmentAuthorization> {
    const auth = await authorizationApiProvider.getAuthorizationByTicketId(fulfillmentTicketId);
    context.commit(apis.SET_FULFILLMENT_AUTHORIZATION, auth);
    return auth;
  },

  async [apis.SEARCH_AUTH_BY_PHONE_NUMBER](context, params: { count: number; phone: string; pagingToken: string }): Promise<FulfillmentAuthorizationSearchPageResult> {
    const auth = await authorizationApiProvider.getAuthorizationsByPhoneNumber(params.count, params.phone, params.pagingToken);
    return auth;
  },

  async [apis.SEARCH_AUTH_BY_KEY_VALUE](context, params: { count: number; collection: string; key: string; value: string; pagingToken: string }): Promise<FulfillmentAuthorizationSearchPageResult> {
    const auth = await authorizationApiProvider.getAuthorizationsByKeyValue(params.count, params.collection, params.key, params.value, params.pagingToken);
    return auth;
  },

  async [apis.GET_FULFILLMENTAUTHORIZATION_FILTER_LOGS](context, id: string) {
    let logs = await authorizationApiProvider.getFulfillmentAuthorizationFilterLogs(id);
    context.commit(apis.SET_FULFILLMENTAUTHORIZATION_FILTER_LOGS, logs);
    return logs;
  },

  async [apis.GET_FULFILLMENTAUTHORIZATION_FILTER_LOG](context, id: string) {
    let filterLog = context.state.fulfillmentAuthFilterLogs.find((l) => l.id === id);
    return filterLog;
  },

  async [apis.FETCH_ALL_SUCCESSFUL_AUTH_REQUESTS]() {
    return await authorizationApiProvider.getAllSuccessFulAuthDiagnosticRequests();
  },

  async [apis.FETCH_RECENT_AUTHORIZATIONS](context, count: number) {
    return await authorizationApiProvider.getRecentAuthorizations(count);
  },

  async [apis.UPDATE_TICKET_STATUS](context, request: UpdateTicketStatusModel) {
    await authorizationApiProvider.updateTicketStatus(request);
    context.commit(apis.RESET_AUTH, request.authorizationId);
  },

  async [apis.OVERRIDE_TICKET_STATUS](context, request: UpdateTicketStatusModel) {
    await authorizationApiProvider.overrideTicketStatus(request);
    context.commit(apis.RESET_AUTH, request.authorizationId);
  },

  async [apis.UPDATE_TICKET_AUTHORIZATION_AMOUNT](context, request: TicketAuthorizationAmountUpdateRequest) {
    await authorizationApiProvider.updateTicketAuthorizationAmountAsync(request);
    context.commit(apis.RESET_AUTH, request.authorizationId);
  },

  async [apis.FETCH_OFFER_SET](context, query: OfferSetQuery) {
    return await authorizationApiProvider.getOfferSet(query.authorizationId, query.offerSetId);
  },

  async [apis.FETCH_COMMUNICATIONS](context, authorizationId: string) {
    return await authorizationApiProvider.getCommunicationsByAuthId(authorizationId);
  },

  async [apis.FETCH_AUTH_TRACES](context, authorizationId: string) {
    return await authorizationApiProvider.getDiagnosticTraces(authorizationId);
  },

  async [apis.FETCH_AUTH_REQUESTS](context, authorizationId: string) {
    return await authorizationApiProvider.getDiagnosticRequests(authorizationId);
  },

  async [apis.FETCH_AI_EXCEPTIONS](context, count: number) {
    return await authorizationApiProvider.getDiagnosticExceptions(count);
  },

  async [apis.FETCH_SOURCE_SYSTEM_CLAIM](context, sourceSystemIdentifier: SourceSystemIdentifier) {
    return await authorizationApiProvider.getClaimData(sourceSystemIdentifier);
  },

  async [apis.FETCH_SOURCE_SYSTEM_MOCK_CLAIM_DATA](context, sourceSystemIdentifier: SourceSystemIdentifier): Promise<SourceSystemClaimData> {
    return await authorizationApiProvider.fetchMockClaimData(sourceSystemIdentifier);
  },

  async [apis.UPDATE_SOURCE_SYSTEM_MOCK_CLAIM_DATA](context, sourceSystemClaimData: SourceSystemClaimData) {
    return await authorizationApiProvider.updateMockClaimData(sourceSystemClaimData);
  },

  async [apis.DELETE_SOURCE_SYSTEM_MOCK_CLAIM_DATA](context, sourceSystemIdentifier: SourceSystemIdentifier) {
    return await authorizationApiProvider.deleteMockClaimData(sourceSystemIdentifier);
  },
};

export default AuthorizationApiActions;

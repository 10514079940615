import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const StatusMappingActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.STATUS_MAPPING_FETCH](context, param: { apiRoute: string }) {
    let statusMappings = await authorizationApiProvider.getStatusMapping(param.apiRoute);
    switch (param.apiRoute) {
      case 'vendorticketstatus':
        statusMappings.map((s) => (s.documentType = 'VendorTicketStatusMapping'));
        break;
      case 'orderstatus':
        statusMappings.map((s) => (s.documentType = 'OrderStatusMapping'));
        break;
    }
    context.commit(apis.SET_STATUS_MAPPINGS, { apiRoute: param.apiRoute, statusMappings: statusMappings });
    return statusMappings;
  },

  async [apis.FETCH_STATUS_MAPPING_BY_ID](context, params: { apiRoute: string; id: string }) {
    switch (params.apiRoute) {
      case 'vendorticketstatus':
        if (!context.state.vendorTicketStatusMappings || context.state.vendorTicketStatusMappings.length == 0) {
          await context.dispatch(apis.STATUS_MAPPING_FETCH, params);
        }
        return context.state.vendorTicketStatusMappings.find((c) => c.id === params.id);
      case 'orderstatus':
        if (!context.state.orderStatusMappings || context.state.orderStatusMappings.length == 0) {
          await context.dispatch(apis.STATUS_MAPPING_FETCH, params);
        }
        return context.state.orderStatusMappings.find((c) => c.id === params.id);
      default:
        return [];
    }
  },

  async [apis.SAVE_STATUS_MAPPING](context, params: { apiRoute: string; config: any }) {
    await authorizationApiProvider.updateStatusMapping(params.apiRoute, params.config);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_STATUS_MAPPINGS);
    await context.dispatch(apis.STATUS_MAPPING_FETCH, params);
  },

  async [apis.CREATE_STATUS_MAPPING](context, params: { apiRoute: string; config: any }) {
    switch (params.apiRoute) {
      case 'vendorticketstatus':
        if (params.config.eTag == null && context.state.vendorTicketStatusMappings.find((c) => c.id === params.config.id)) {
          throw Error(`Status Mapping with Id ${params.config.id} already exists.`);
        }
        break;
      case 'orderstatus':
        if (params.config.eTag == null && context.state.orderStatusMappings.find((c) => c.id === params.config.id)) {
          throw Error(`Status Mapping with Id ${params.config.id} already exists.`);
        }
        break;
    }

    await authorizationApiProvider.createStatusMapping(params.apiRoute, params.config);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_STATUS_MAPPINGS, params.apiRoute);
    await context.dispatch(apis.STATUS_MAPPING_FETCH, params);
  },

  async [apis.DELETE_STATUS_MAPPING](context, param: { apiRoute: string; config: any; hardDelete: boolean }) {
    await authorizationApiProvider.deleteStatusMapping(param.apiRoute, param.config, param.hardDelete);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_STATUS_MAPPINGS);
    await context.dispatch(apis.STATUS_MAPPING_FETCH, param);
  },

  async [apis.STATUS_MAPPING_BY_VENDOR_FULFILLMENTOPTION_FETCH](context: any, request: { apiRoute: string; fulfillmentOption: number; vendor: string }) {
    return context.dispatch(apis.STATUS_MAPPING_FETCH, { apiRoute: request.apiRoute }).then(() => {
      switch (request.apiRoute) {
        case 'vendorticketstatus':
          return context.state.vendorTicketStatusMappings.filter((c: any) => c.fulfillmentOption === request.fulfillmentOption && c.vendor === request.vendor);
        case 'orderstatus':
          return context.state.orderStatusMappings.filter((c: any) => c.fulfillmentOption === request.fulfillmentOption && c.vendor === request.vendor);
      }
    });
  },
};

export default StatusMappingActions;

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, UserConfig, AccessRequest } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const 
UserConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_USER_CONFIGS](context) {
    const userConfigs = await authorizationApiProvider.getUserConfigs();
    context.commit(apis.SET_USER_CONFIGS, userConfigs);
    return userConfigs;
  },

  async [apis.FETCH_USER_CONFIG](context, id: string) {
    const userConfig = context.state.userConfigs.find((config: UserConfig) => config.id === id);
    return userConfig ? userConfig : await authorizationApiProvider.getUserById(id);
  },

  async [apis.CREATE_USER_CONFIG](context, config: UserConfig) {
    await authorizationApiProvider.createUser(config);
  },

  async [apis.EDIT_USER_CONFIG](context, config: UserConfig) {
    await authorizationApiProvider.editUser(config);
  },

  async [apis.DELETE_USER_CONFIG](context, params: { config: UserConfig; hardDelete: boolean }) {
    await authorizationApiProvider.deleteUser(params.config, params.hardDelete);
    context.dispatch(apis.CLEAR_CACHE);
    context.commit(apis.FETCH_USER_CONFIGS);
  },

  async [apis.FETCH_USER_ROLES_PERMISSIONS](context, id: string) {
    let userRolesPermissions = context.state.userRolesPermissions;
    if (userRolesPermissions != undefined) {
      return userRolesPermissions;
    }
    userRolesPermissions = await authorizationApiProvider.getUserRolesPermissions(id);
    context.commit(apis.SET_USER_ROLES_PERMISSIONS, userRolesPermissions);
    return userRolesPermissions;
  },

  async [apis.SEND_ACCESS_REQUEST](context, accessRequest: AccessRequest) {
     await authorizationApiProvider.sendAccessRequest(accessRequest);
  },
};

export default UserConfigActions;

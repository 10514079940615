
import Vue from 'vue';
import ViewContent from '@/components/ViewContent.vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_SERVICENETWORK_CONFIGS } from '@/store/globalmodules/constants';
import EventBus from '@/common/event.bus';
import { CLEAR_CACHE } from '@/store/dynamicfulfillment/constants';
import { RoutingAction } from '@/store/dynamicfulfillment/types';
import  AttributeViewComponent from '@/components/common/AttributeViewComponent.vue';
const namespace: string = 'globalmodule';

@Component({ components: { ViewContent, ConfigIndexComponent, AttributeViewComponent } })
export default class ServiceNetworkIndex extends Vue {
  @Action(FETCH_SERVICENETWORK_CONFIGS, { namespace })
  @Action(CLEAR_CACHE, { namespace })
  private clearCacheForAllKeys: () => void;
  private initialSort: string = 'userAudit.current.modifiedOnUtc';
  private sortDesc: boolean = true;
  private attributesTitle: string = 'Custom Attributes';
  private fields: any[] = [
    {
      key: 'id',
      thStyle: { width: '275px' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'customAttributes',
    },
  ];

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'ServiceNetworks-Edit', requiredPermission: 'ServiceCenters-ServiceNetworks-Edit' },
      { name: RoutingAction.Create, route: 'ServiceNetworks-Create', requiredPermission: 'ServiceCenters-ServiceNetworks-Create' },
      { name: RoutingAction.Fetch, action: 'globalmodule/' + FETCH_SERVICENETWORK_CONFIGS, requiredPermission: 'ServiceCenters-ServiceNetworks-View' },
    ];
  }

  private async clearCache() {
    try {
      await this.clearCacheForAllKeys();
      EventBus.$emit('s', 'Cache has been successfully cleared.');
    } catch (error) {
      EventBus.$emit('e', `<h3>Clear cache failed.</h3>${error}`);
    }
  }
}

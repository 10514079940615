import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, UserConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const ConfigurationApisActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_CONFIG_HISTORY](context, request: { configType: string; id: string }) {
    const history = await authorizationApiProvider.getConfigHistory(request.configType, request.id);
    return history;
  },
};

export default ConfigurationApisActions;

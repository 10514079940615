
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  DynamicFulfillmentState,
  InventoryState,
  LocationInventory,
  VendorConfig,
} from '@/store/dynamicfulfillment/types';
import LocationInventoryViewEdit from '@/components/inventory/LocationInventoryViewEdit.vue';
import { Action, State } from 'vuex-class';
import { FETCH_VENDOR_CONFIGS } from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({components: {LocationInventoryViewEdit}})
export default class InventoryStateCreateEdit extends Vue {
  @Prop() private config: InventoryState;
  @Prop() private isEdit: boolean;
  @State(namespace) private profile!: DynamicFulfillmentState;
  @Action(FETCH_VENDOR_CONFIGS, { namespace }) private fetchVendorConfigs: any;
  private vendorConfigs: VendorConfig[] = [];

  private async created() {
    await this.fetchVendorConfigs();
    this.vendorConfigs = this.profile.vendorConfigs;
  }

  private get isNew() {
    return this.config._etag === null;
  }

  private vendorLabel(option: string) {
        return this.vendorConfigs.filter((vendor) => vendor.id === option)[0].id;
  }

  private get vendorState() {
        return this.config.vendorName !== null;
  }

  private addLocation() {
    this.config.locationInventoryData.unshift( { id: null, name: null, inventoryParts: []} as LocationInventory);
  }

  private formState(value: boolean) {
    this.$emit('setFormState', value);
  }

  @Watch('config', {immediate: false, deep: true})
  private config_changed() {
    this.$emit('stateUpdate');
  }

  @Watch('config.vendorRepairTypeId', {immediate: false})
  private setId() {
    if (!this.isEdit) {
      this.config.id = this.config.vendorRepairTypeId;
    }
  }
}



import { Component, Vue, Prop } from 'vue-property-decorator';
import { GET_FULFILLMENT_OPTION_CONFIG } from '@/store/dynamicfulfillment/constants';
import { State, Action, Getter } from 'vuex-class';
import { DynamicFulfillmentState, FulfillmentOption, FulfillmentOptionConfig } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';

@Component
export default class FulfillmentOptionCategoryLabel extends Vue {
  @Prop({required: true, type: Number}) private fulfillmentOptionCategory!: number;

  @State('dynamicfulfillmentmodule') private profile: DynamicFulfillmentState;

  private get label () {
    return this.fulfillmentOptionCategoryLabel(this.fulfillmentOptionCategory);
  }

  private fulfillmentOptionCategoryLabel(category: number) {
    return this.profile.fulfillmentOptionCategories[category];
  }
}

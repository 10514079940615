import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const StatusEventsApiActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_VENDOR_STATUS_EVENTS](context, correlationId: string) {
    return await authorizationApiProvider.getVendorStatusEvents(correlationId, 'VendorTicketStatusUpdatedEvent');
  },

  async [apis.FETCH_ORDER_STATUS_EVENTS](context, correlationId: string) {
    return await authorizationApiProvider.getVendorStatusEvents(correlationId, 'VendorOrderStatusUpdatedEvent');
  },
};

export default StatusEventsApiActions;

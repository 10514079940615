
import { copyToClipboard } from '@/common/utilities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TimeLineControl from './TimeLineControl.vue';

@Component({ components: { TimeLineControl }})
export default class TimeLineItem extends Vue {
    @Prop() private item: any;

    private get badge() {
        switch (this.item.status) {
            case 'Completed':
            case 'TaskCompleted':
            case 'ExecutionCompleted': 
            case 'SubOrchestrationInstanceCompleted': {
                return `timelinecontrol-badge success`;
            }
            case 'Started':
            case 'ExecutionStarted':
            case 'EventRaised': 
            case 'ContinuedAsNew': {
                return `timelinecontrol-badge primary`;
            }
            case 'Waiting':
            case 'Running': {
                return `timelinecontrol-badge info`;
            }
            case 'Pending': {
                return `timelinecontrol-badge warning`;
            }
            case 'Failed':
            case 'TaskFailed':
            case 'Unknown':
            case 'Terminated': 
            case 'Suspended': {
                return `timelinecontrol-badge danger`;
            }
            default: {
                return `timelinecontrol-badge info`;
            }
        }
    }

    private get animate() {
        switch (this.item.status) {
            case 'Waiting':
            case 'Pending':
            case 'Running': {
                return true;
            }
            default: {
                return false;
            }
        }
    }

    private get icon() {
        switch (this.item.status) {
            case 'Completed':
            case 'TaskCompleted':
            case 'ExecutionCompleted' : 
            case 'SubOrchestrationInstanceCompleted': {
                return `fa-check`;
            }
            case 'Started':
            case 'ExecutionStarted' : 
            case 'ContinuedAsNew': {
                return `fa-hourglass-start`;
            }
            case 'Running': {
                return `fa-cog`;
            }
            case 'Pending': {
                return `fa-clock`;
            }
            case 'Waiting': {
                return `fa-hourglass-start`;
            }
            case 'EventRaised': {
                return `fa-arrow-up`;
            }
            case 'Failed':
            case 'TaskFailed':
            case 'Unknown':
            case 'Terminated': 
            case 'Suspended': {
                return `fa-circle-xmark`;
            }
            default: {
                return `fa-triangle-exclamation`;
            }
        }
    }

    private get disabled() {
        return (control: any) => {
            switch (control.method) {
                case 'copyInput': {
                    return (this.item.input === null);
                }
                case 'copyResult': {
                    return (this.item.result === null || this.item.result === false || this.item.result === undefined);
                }
                case 'copyDetails': {
                    return (this.item.details === null || this.item.details === undefined);
                }
            }
        };
    }

    private copyInput() {
        copyToClipboard(JSON.stringify(this.item.input, null, 2));
    }

    private copyResult() {
        copyToClipboard(JSON.stringify(this.item.result, null, 2));
    }

    private copyDetails() {
        copyToClipboard(this.item.details);
    }
}


import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import {
  DELETE_APPCONFIG,
  FETCH_APPCONFIG_BY_KEY,
  FETCH_FULFILLMENT_OPTION_CONFIGS,
  FETCH_PROGRAM_CONFIGS,
  UPDATE_APPCONFIG,
} from '@/store/dynamicfulfillment/constants';
import {
  AppConfig,
  DynamicFulfillmentState,
  FulfillmentOption,
  FulfillmentOptionConfig,
  ProgramConfig,
  ProgramConfigVersion,
  ProgramFulfillmentOptionConfig,
  ServiceCentralServiceNetworkConfig,
} from '@/store/dynamicfulfillment/types';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DataTable from '../../common/DataTable.vue';
import ProgramConfigModal from '../../configuration/ProgramConfigModal.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable, ProgramConfigModal: ProgramConfigModal } })
export default class ServiceNetworkConfigIndex extends Vue {
  @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace })
  private fetchFulfillmentOptionConfigs: any;
  @Action(FETCH_PROGRAM_CONFIGS, { namespace })
  private fetchProgramConfigs: any;
  @Action(FETCH_APPCONFIG_BY_KEY, { namespace })
  private fetchAppConfigByKey: any;
  @Action(DELETE_APPCONFIG, { namespace }) private deleteAppConfig: any;
  @Action(UPDATE_APPCONFIG, { namespace }) private updateAppConfig: any;
  @State(namespace) private profile!: DynamicFulfillmentState;
  private selectedFulfillmentOption: FulfillmentOption = FulfillmentOption.None;
  private programConfigs: ProgramConfig[] = [];
  private fulfillmentOptionConfigs: FulfillmentOptionConfig[] = [];
  private formattedServiceProviders: string = '';
  private selectedServiceProvider: string = null;
  private initialSort: string = 'fulfillmentOption';
  private currentPage = 1;
  private pageSize = 10;
  private appConfigs: AppConfig[] = [];
  private selectedProgram: string = null;
  private serviceNetworks: ServiceCentralServiceNetworkConfig[] = [];
  private isLoading: boolean = false;
  private configModal: any = {
    id: '',
    title: '',
    content: '',
  };
  private fields: any[] = [
    {
      key: 'fulfillmentOption',
      label: 'FulfillmentOption',
      sortable: true,
    },
    {
      key: 'programId',
      label: 'Program Id',
      sortable: true,
    },
    {
      key: 'programName',
      label: 'Program Name',
      sortable: true,
    },
    {
      key: 'serviceProvider',
      label: 'ServiceProvider',
      sortable: true,
    },
    {
      key: 'serviceNetwork',
      label: 'ServiceNetwork',
      sortable: true,
    },
    {
      key: 'lastModified',
      label: 'LastModifiedDate',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ];
  private sentinelKey: string = 'ServiceCentralApi:Sentinel';

  private async mounted() {
    await this.fetchProgramConfigs();
    await this.fetchFulfillmentOptionConfigs();
    this.programConfigs = this.profile.programConfigs;
    this.fulfillmentOptionConfigs = this.profile.fulfillmentOptionConfigs;
  }
  private get allowedFulfillmentOptions() {
    return Object.keys(FulfillmentOption)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key: any) => FulfillmentOption[key])
      .sort((a, b) => {
        return a < b ? -1 : 1;
      });
  }

  private get allowedServiceProviders() {
    const serviceProviders: string[] = [];
    this.programConfigs.forEach((config: ProgramConfig) => {
      const currentVersion = config.programConfigVersions.filter(
        (configVersion: ProgramConfigVersion) =>
          config.activeVersion === configVersion.version,
      )[0];
      currentVersion.fulfillmentOptions.forEach(
        (fo: ProgramFulfillmentOptionConfig) =>
          serviceProviders.push(...fo.serviceProviders),
      );
    });
    return [...new Set(serviceProviders.map((obj) => obj))];
  }

  private fulfillmentOptionLabel(option: any) {
    if (isNaN(option)) {
      return option;
    } else {
      return FulfillmentOption[option];
    }
  }

  private get programState() {
    if (
      this.selectedFulfillmentOption === FulfillmentOption.None ||
      this.selectedFulfillmentOption === null
    ) {
      return false;
    }
    return true;
  }

  private get invalidProgramFeedback() {
    return 'Select a fulfillment option';
  }

  private get serviceProviderState() {
    if (
      this.selectedFulfillmentOption === FulfillmentOption.None ||
      this.selectedFulfillmentOption === null ||
      this.selectedProgram === null
    ) {
      return false;
    }
    return true;
  }

  private get invalidServiceProviderFeedback() {
    if (
      (this.selectedFulfillmentOption === FulfillmentOption.None ||
        this.selectedFulfillmentOption === null) &&
      this.selectedProgram === null
    ) {
      return 'Select fulfillment option and a program';
    } else if (
      this.selectedFulfillmentOption === FulfillmentOption.None ||
      this.selectedFulfillmentOption === null
    ) {
      return 'Select a fulfillment option';
    } else if (this.selectedProgram === null) {
      return 'Select a program';
    }
  }

  private configModelId(row: any) {
    return `${row.item.programId}:${row.item.serviceProvider}`;
  }

  @Watch('selectedFulfillmentOption', { immediate: false })
  private onFulfillmentOptionChange() {
    this.search();
  }

  @Watch('selectedProgram', { immediate: false })
  private onselectedProgramChange() {
    if (this.selectedProgram === null) {
      this.selectedServiceProvider = null;
    }
    this.search();
  }

  @Watch('selectedServiceProvider', { immediate: false })
  private onselectedServiceProviderChange() {
    this.search();
  }

  private search() {
    this.isLoading = true;
    this.serviceNetworks = [];
    let key: string = '';
    this.selectedFulfillmentOption !== FulfillmentOption.None &&
    this.selectedProgram !== null &&
    this.selectedServiceProvider !== null
      ? (key = `${this.selectedFulfillmentOption}:${this.selectedProgram}_${this.selectedServiceProvider}*`)
      : this.selectedFulfillmentOption !== FulfillmentOption.None &&
        this.selectedProgram !== null
      ? (key = `${this.selectedFulfillmentOption}:${this.selectedProgram}*`)
      : (key =
          this.selectedFulfillmentOption !== FulfillmentOption.None
            ? `${this.selectedFulfillmentOption}:*`
            : '');
    if (key === '') {
      this.isLoading = false;
      return;
    }
    this.fetchAppConfigByKey(key)
      .then((response: any) => {
        this.appConfigs = response;
        this.appConfigs.forEach((config: AppConfig) => {
          const programId = this.getProgramId(config.key);

          const programConfig = this.programConfigs.filter(
            (pc: ProgramConfig) =>
              pc.id === programId,
          )[0];
          
          this.serviceNetworks.push(
            ...[
              {
                key: config.key,
                fulfillmentOption: this.selectedFulfillmentOption,
                programId: programConfig
                  ? programConfig.id
                  : 'Program Not Found',
                programName: programConfig
                  ? programConfig.name
                  : 'Program Not Found',
                serviceProvider: this.getServiceProvider(config.key),
                serviceNetwork: config.value,
                lastModified: config.lastModified,
              },
            ],
          );
        });
        this.isLoading = false;
      })
      .catch((error: any) => {
        const message = buildErrorMessage(error);
        EventBus.$emit('e', message);
        this.isLoading = false;
      });
  }

  private async remove(id: string) {
    this.$bvModal
      .msgBoxConfirm('Are you sure? This cannot be undone.', {
        title: `Delete ${id}`,
        size: 'lg',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          this.deleteAppConfig(id)
            .then((response: any) => {
              this.updateAppConfig({
                key: this.sentinelKey,
                value: Date.now(),
              });
              this.search();
              EventBus.$emit('s', `${id} deleted successfully`);
            })
            .catch((error: any) => {
              this.displayError(error);
            });
        }
      })
      .catch((err) => {
        this.displayError(err);
      });
  }

  private clear() {
    this.selectedFulfillmentOption = FulfillmentOption.None;
    this.selectedServiceProvider = null;
    this.selectedProgram = null;
    this.serviceNetworks = [];
  }

  private displayError(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit(
      'e',
      `There was a problem in processing the request. ${message}`,
    );
  }

  private async forceUpdate() {
    this.$bvModal
      .msgBoxConfirm('Are you sure? This cannot be undone.', {
        title: ``,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          this.updateAppConfig({ key: this.sentinelKey, value: Date.now() });
          EventBus.$emit('s', 'Service Network updated successfully.');
        }
      })
      .catch((err) => {
        // An error occurred
        EventBus.$emit('e', `Request failed. ${err}`);
      });
  }

  private getServiceProvider(key: string) {
    return key.includes('_') ? key.substring(key.indexOf('_') + 1, key.length) : null;
  }

  private getProgramId(key: string) {
    return key.includes('_')
    ? key.substring(key.indexOf(':') + 1, key.indexOf('_'))
    : key.substring(key.indexOf(':') + 1)

  }
}

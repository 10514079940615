
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import { FETCH_USER_CONFIG, EDIT_USER_CONFIG, CREATE_USER_CONFIG } from '@/store/dynamicfulfillment/constants';
import { UserConfig } from '@/store/dynamicfulfillment/types';
import UserConfigCreateEdit from '@/components/configuration/UserConfigCreateEdit.vue';

@Component({ components: { ConfigEditComponent, UserConfigCreateEdit } })
export default class UserConfigCopy extends Vue {   
    isValid: boolean = true;
    defaultConfig: UserConfig = {        
        id: null,
        name: null,
        roles: [],
        permissions: [],
        _etag: null,
    };

    get fetchAction() {
        return 'dynamicfulfillmentmodule/' + FETCH_USER_CONFIG;
    }

    get saveAction() {
        return 'dynamicfulfillmentmodule/' + CREATE_USER_CONFIG;
    }

    onConfigChange(request: { config: UserConfig | null; isValid: boolean }) {
        this.isValid = request.isValid;
        if (request.isValid) {
            this.defaultConfig = request.config;
        }
    }
}

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { GlobalModuleState, MigrationMatrixDataResponse, ServiceLocationPage, ServiceNetworkConfig, ServiceManufacturer, ServiceCenterLocationConfig } from './types';
import { MigrationMatrixSettingData, MigrationMatrixData } from '@/store/globalmodules/types';

const namespaced: boolean = true;

export const state: GlobalModuleState = {
    migrationMatrixClients: [] as MigrationMatrixSettingData[],
    migrationMatrixData: [] as MigrationMatrixData[],
    migrationMatrixPagingData: null as MigrationMatrixDataResponse,
    serviceCentersPage: {serviceCenters: null, pagingToken: null} as ServiceLocationPage,
    serviceNetworkConfigs: [] as ServiceNetworkConfig[],
    serviceManufacturers: [] as ServiceManufacturer[],
    serviceCenterLocationConfig: {} as ServiceCenterLocationConfig,
};

const globalmodule: Module<GlobalModuleState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};

export default globalmodule;

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const CountryConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_COUNTRY_CONFIGS](context) {
    const configs = await authorizationApiProvider.getCountryConfigs();
    context.commit(apis.SET_COUNTRY_CONFIGS, configs);
    return context.state.countryConfigs;
  },
};

export default CountryConfigActions;

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, AncillaryServiceTypeConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const AncillaryServiceTypeConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS](context) {
    let ancillaryServiceTypes = await authorizationApiProvider.getAncillaryServiceTypeConfigs();
    context.commit(apis.SET_ANCILLARY_SERVICE_TYPES, ancillaryServiceTypes);
    return ancillaryServiceTypes;
  },

  async [apis.FETCH_ANCILLARY_SERVICE_TYPE_CONFIG](context, id: string) {
    const ancillaryServiceTypeConfig = context.state.ancillaryServiceTypeConfigs.find((config: AncillaryServiceTypeConfig) => config.id === id);
    return ancillaryServiceTypeConfig ? ancillaryServiceTypeConfig : await authorizationApiProvider.getAncillaryServiceTypeById(id);
  },

  async [apis.CREATE_ANCILLARY_SERVICE_TYPE_CONFIG](context, ancillaryServiceTypeconfig: AncillaryServiceTypeConfig) {
    if (ancillaryServiceTypeconfig._etag == null && context.state.localeConfigs.find((c) => c.id === ancillaryServiceTypeconfig.id)) {
      throw Error(`AncillaryServiceTypeconfig with Id ${ancillaryServiceTypeconfig.id} already exists.`);
    }
    await authorizationApiProvider.createAncillaryServiceTypeConfig(ancillaryServiceTypeconfig);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_ANCILLARY_SERVICE_TYPES);
    await context.dispatch(apis.FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS);
  },

  async [apis.UPDATE_ANCILLARY_SERVICE_TYPE_CONFIG](context, ancillaryServiceTypeconfig: AncillaryServiceTypeConfig) {
    await authorizationApiProvider.updateAncillaryServiceTypeConfig(ancillaryServiceTypeconfig);
    context.commit(apis.RESET_ANCILLARY_SERVICE_TYPES);
    await context.dispatch(apis.FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS);
  },
};

export default AncillaryServiceTypeConfigActions;

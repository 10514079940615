
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import VendorConfigCreateEdit from '@/components/configuration/VendorConfigCreateEdit.vue';
import { FETCH_VENDOR_CONFIG, VENDOR_CONFIG_SAVE } from '@/store/constants';
import { RESET_VENDOR_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { InventoryConfig } from '@/store/dynamicfulfillment/types';
import { Action } from 'vuex-class';
const namespace: string = 'dynamicfulfillmentmodule';

Component.registerHooks([
    'beforeRouteLeave',
]);

@Component({ components: {ConfigEditComponent, VendorConfigCreateEdit } })
export default class VendorConfigEdit extends Vue {
    @Prop() private id!: string;
    @Action(RESET_VENDOR_CONFIGS, { namespace }) private resetVendorConfigs: () => void;
    private isStateChanged: boolean = false;

    private get fetchAction() {
        return FETCH_VENDOR_CONFIG;
    }

    private get saveAction() {
        return VENDOR_CONFIG_SAVE;
    }

    private get initializeConfig() {
      return (config: any) => {
        if (!config.vendorStatusPathConfig) {
            config.vendorStatusPathConfig = {
            statusPath: null,
            statusReasonPath: null,
          }
        }
        
        config.serviceProviderConfigs.forEach((sp: any) => {
          if (sp.inventoryConfig === null) {
            const inventoryConfig: InventoryConfig = {
            inventoryFilteringEnabled: false,
            minimumInventoryThreshold: 1,
            vendorManagedInventory: false,
          };
          sp.inventoryConfig = inventoryConfig;
        }
      });
      
      return config;
      }
    }

    private stateUpdated() {
      this.isStateChanged = true;
    }

    private stateSaved() {
      this.isStateChanged = false;
    }

    private beforeRouteLeave(to: any, from: any, next: any) {
      if (this.isStateChanged) {
        const answer = window.confirm(
          'Do you really want to leave? you have unsaved changes!',
        );
        if (answer) {
          this.resetVendorConfigs();
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    }
}

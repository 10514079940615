
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { FETCH_FULFILLMENT_OPTION_CONFIGS, FETCH_PROGRAM_CONFIGS, FETCH_FEE_TYPES } from '@/store/dynamicfulfillment/constants';
import { State } from 'vuex-class';
import { DynamicFulfillmentState, FulfillmentOptionConfig, ProgramFeesConfig, ProgramConfig, FulfillmentOption, FeeType, FeeAmount, ProgramFeeEntry } from '@/store/dynamicfulfillment/types';
import { Action } from 'vuex-class';
import DataTable from '@/components/common/DataTable.vue';

const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable } })
export default class ProgramFeesConfigCreateEdit extends Vue {
  @Prop() config: ProgramFeesConfig;
  @State(namespace) profile!: DynamicFulfillmentState;
  @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace }) fetchFulfillmentOptionConfigs: () => FulfillmentOptionConfig[];
  @Action(FETCH_PROGRAM_CONFIGS, { namespace }) fetchProgramConfigs: () => ProgramConfig[];
  @Action(FETCH_FEE_TYPES, { namespace }) fetchFeeTypes: () => FeeType[];
  fulfillmentOptionConfigs: FulfillmentOptionConfig[] = [];
  programConfigs: ProgramConfig[] = [];
  feeTypes: FeeType[] = [];
  feeHistoryIndex: number;
  isNew: boolean = false;
  selectedFee: FeeAmount = {
    feeType: null,
    proposedAmount: 0,
    actualAmount: 0,
  };
  feeFields: any[] = [
    {
      key: 'feeType',
      thStyle: { width: '350px' },
    },
    {
      key: 'proposedAmount',
    },
    {
      key: 'actualAmount',
    },
    {
      key: 'actions',
    },
  ];

  async beforeMount() {
    this.fulfillmentOptionConfigs = await this.fetchFulfillmentOptionConfigs();
    this.programConfigs = await this.fetchProgramConfigs();
    this.feeTypes = await this.fetchFeeTypes();
  }

  created() {
    this.isNew = this.config.id === null;
  }

  get allowedFulfillmentOptions() {
    return Object.keys(FulfillmentOption)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key: any) => FulfillmentOption[key])
      .sort((a, b) => {
        return a < b ? -1 : 1;
      });
  }

  get fulfillmentOptionState() {
    return this.fulfillmentOptionConfigs?.length > 0;
  }

  get programsState() {
    return this.programConfigs?.length > 0;
  }

  get feeState() {
    return this.selectedFee.actualAmount > 0 && this.selectedFee.proposedAmount > 0 && this.selectedFee.feeType !== null;
  }

  get validForm() {
    return this.fulfillmentOptionState && this.programsState;
  }

  fulfillmentOptionLabel(option: number) {
    if (isNaN(option)) {
      return option;
    } else {
      return FulfillmentOption[option];
    }
  }

  addFeeHistory() {
    this.config.feeHistory.push({
      id: '',
      fees: [],
      effectiveStartDate: '',
      effectiveEndDate: '',
    });
  }

  addFee(index: number) {
    this.feeHistoryIndex = index;
    this.selectedFee = {
      feeType: null,
      proposedAmount: 0,
      actualAmount: 0,
    };

    this.$bvModal.show('fee-modal');
  }

  editFee(feeAmount: FeeAmount[], index: number) {
    this.selectedFee = feeAmount[index];

    this.$bvModal.show('fee-modal');
  }

  cancelFee() {
    this.$bvModal.hide('fee-modal');

    this.feeHistoryIndex = undefined;
    this.selectedFee = {
      feeType: null,
      proposedAmount: null,
      actualAmount: null,
    };
  }

  updateFee() {
    this.$bvModal.hide('fee-modal');
    this.config.feeHistory[this.feeHistoryIndex].fees.push(this.selectedFee);
  }

  removeFeeHistory(index: number) {
    this.config.feeHistory.splice(index, 1);
  }

  removeFee(feeAmount: FeeAmount[], index: number) {
    feeAmount.splice(index, 1);
  }

  emitOnChange(content: { config: ProgramFeesConfig | null; isValid: boolean }) {
    this.$emit('onConfigChange', content);
  }

  @Watch('config', { immediate: false, deep: true })
  private configChange() {
    if (this.validForm) {
      this.config.id = `${this.config.programId}_${this.config.fulfillmentOption}`;
    }

    this.emitOnChange({ config: this.config, isValid: this.validForm });
  }
}

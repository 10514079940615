
  import Vue from 'vue';
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import { ServiceManufacturer } from '@/store/globalmodules/types';
  import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue';
  
  @Component({ components: { PerLineTextAreaControl } })
  export default class ServiceManufacturersCreateEdit extends Vue {
    @Prop() private config: ServiceManufacturer;
  
    private formatter(value: string) {
      return value.replace(',', '').replace("'", '');
    }
  
    private updateAllowedValues(allowedValues: string[]) {
      this.config.allowedValues = allowedValues;
    }
  
    @Watch('config', { immediate: false, deep: true })
    private configChange() {
      this.emitOnChange({ config: this.config, isValid: true });
    }
  
    private emitOnChange(content: {
      config: ServiceManufacturer | null;
      isValid: boolean;
    }) {
      this.$emit('onConfigChange', content);
    }
  }
  
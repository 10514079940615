import { MIGRATION_MATRIX_PAGING_COUNT } from '@/store/globalmodules/constants';
import { MigrationMatrixSettingData, MigrationMatrixData, MigrationMatrixDataResponse } from '@/store/globalmodules/types';
import axios, { AxiosRequestConfig } from 'axios';
import Vue from 'vue';

declare var process: any;
declare var NProgress: any;

const instance = axios.create({
  baseURL: process.env.VUE_APP_BESTREPLACEMENT_APIM_BASE_URL,
  headers: {
    ...axios.defaults.headers,
  },
});

instance.interceptors.request.use(
  (config: any) => {
    NProgress.start();
    return config;
  },
  (error: any) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response: any) => {
    NProgress.done();
    return Promise.resolve(response);
  },
  (error: any) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

export class OperationsApiProvider {
  public async postClientMigrationMatrix(clientId: string, file: File, modifiedBy: string) {
    const formData = new FormData();
    formData.append('clientId', clientId);
    formData.append('file', file);
    formData.append('modifiedBy', modifiedBy);
    return await this.post('/MigrationMatrix', formData);
  }

  public async postAssurantMigrationMatrix(clientId: string, file: File, modifiedBy: string) {
    const formData = new FormData();
    formData.append('clientId', clientId);
    formData.append('file', file);
    formData.append('modifiedBy', modifiedBy);
    return await this.post(`/MigrationMatrix/internalmatrixupload`, formData);
  }

  public async getMigrationReport(clientName: string, isInternal: boolean) {
    return await this.getReport('/migrationMatrix/' + clientName + '/migrationmatrixreport?isInternal=' + isInternal);
  }

  public async getMissingSkuDataReport(clientName: string) {
    return await this.getReport('/migrationMatrix/' + clientName + '/missingskudata');
  }

  public async getMigrationMatrixClients() {
   return await this.get<MigrationMatrixSettingData[]>('/MigrationMatrix/migrationmatrixenabledclients');
  }

  public async getMigrationMatrixPagingData(pagingToken: string) {
    if (pagingToken) {
      return await this.get<MigrationMatrixDataResponse>(`/MigrationMatrix/Paging?count=${MIGRATION_MATRIX_PAGING_COUNT}&pagingToken=${pagingToken}`);
    } else {
      return await this.get<MigrationMatrixDataResponse>(`/MigrationMatrix/Paging?count=${MIGRATION_MATRIX_PAGING_COUNT}`);
    }
  }

  private async getReport(route: string) {
    return await this.getBaseResponse<Blob>(route, {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    }).then((response) => {
      const blob = new Blob([response.data], { type: 'arraybuffer' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'unknown';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=(.+)/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1].split(';')[0];
        }
      }
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    });
  }

  private async post<TReturn>(uri: string, body?: any, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const { data } = await instance.post<TReturn>(uri, body, config);
    return data;
  }

  private async getBaseResponse<TReturn>(uri: string, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const response = await instance.get<TReturn>(uri, config);
    return response;
  }

  private async get<TReturn>(uri: string, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const response = await instance.get<TReturn>(uri, config);
    return response.data;
  }

  private async addAuthHeader(config?: any) {
    config = { ...{ headers: {} }, ...config };
    const accessToken = await Vue.prototype.$auth.getAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  }
}

export const operationsApiProvider = new OperationsApiProvider();


import Vue from 'vue';
import DataTable from '../common/DataTable.vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { FETCH_REPAIR_CATALOG_INVENTORY_ITEM } from '@/store/dynamicfulfillment/constants';
import { InventoryItem } from '@/store/dynamicfulfillment/types';
import { buildErrorMessage } from '@/common/functions.helpers';
import EventBus from '@/common/event.bus';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable } })
export default class RepairCatalogInventoryItemModal extends Vue {
  @Prop({ required: true }) private repairCatalogId!: string;
  @Prop({ required: true }) private itemId!: string;
  @Action(FETCH_REPAIR_CATALOG_INVENTORY_ITEM, { namespace }) private fetchRepairCatalogInventoryItem: (request: { repairCatalogId: string; itemId: string }) => Promise<any>;

  inventoryItem: InventoryItem = {
    id: '',
    itemType: '',
    description: '',
    catalog: '',
    replacementSkus: [],
  };
  isLoading: boolean = true;
  private fields: any[] = [
    {
      key: 'sku',
      sortable: true,
    },
    {
      key: 'brand',
      sortable: true,
    },
    {
      key: 'description',
    },
  ];

  private async mounted() {
    this.inventoryItem = this.inventoryItem = await this.fetchRepairCatalogInventoryItem({ repairCatalogId: this.repairCatalogId, itemId: this.itemId })
      .catch((err) => {
        // An error occurred
        this.displayError(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  displayError(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit('e', `Request failed. ${message}`);
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class PerLineTextAreaControl extends Vue {
    @Prop() private id: string;
    @Prop({ required: true}) private initArray: string[];
    @Prop({ default: "Enter an item (1 per line)"}) private placeholder: string;
    @Prop() private formatter: string;
    @Prop({ default: true}) private trim: boolean;
    @Prop({ default: "1"}) private rows: string;
    @Prop({ default: "20"}) private maxRows: string;
    @Prop({ default: false}) private required: boolean;
    @Prop({ default: 0}) private debounce: number;
    @Prop({default: true}) private textAreaState: boolean;
    @Prop({default:false}) private ariaInvalid: boolean;
    @Prop({default: false}) private isClear: boolean;
    private formattedArray: string = '';

    public mounted() {
        if (this.initArray && this.initArray.length > 0) {
            this.formattedArray = this.initArray.join('\n');
        } else {
            this.formattedArray = '';
        }
    }

    private updateTextArea(value: any) {
        let itemArray = [];
        if (/\r|\n/.exec(value)) {
            let valArr = (value as string).split('\n').map((text: string) => text.trim());
            for (const idx in valArr) {
                if (valArr[idx]) {
                    itemArray.push(valArr[idx]);
                }
            }
        } else {
            itemArray.push(value);
        }

        this.$emit('onTextAreaUpdated', itemArray);
    }

    @Watch('isClear', {immediate: false})
    private clear() {
        this.formattedArray='';
    }
}

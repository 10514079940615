
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import UploadComponent from '@/components/common/UploadComponent.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { CLEAR_CACHE, FETCH_EQUIPMENT_EXCLUSION_CONFIG } from '@/store/dynamicfulfillment/constants';
import { EquipmentExclusionConfig } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';
@Component({
  components: { ViewContent, UploadComponent },
})
export default class EquipmentExclusionItemsUpload extends Vue {
  @Prop() configId!: string;
  @Action(CLEAR_CACHE, { namespace }) private clearCacheForAllKeys: () => void;
  @Action('dynamicfulfillmentmodule/' + FETCH_EQUIPMENT_EXCLUSION_CONFIG) fetchEquipmentExclusionConfig: any;
  private resetForm: boolean = false;
  private pageTitle: string = 'Upload Equipment Exclusion Items';
  private equipmentExclusionConfig: EquipmentExclusionConfig = {
    name: null,
    fulfillmentOption: null,
    programIds: [],
    items: [],
    id: null,
    _etag: null,
  };

  private async created() {
    await this.getConfig();
  }

  async getConfig() {
    this.equipmentExclusionConfig = (await this.fetchEquipmentExclusionConfig(this.configId)) as EquipmentExclusionConfig;
  }

  get getConfigName() {
    return this.equipmentExclusionConfig ? this.equipmentExclusionConfig.name : this.configId;
  }

  private validation(file: File) {
    if (file) {
      return file.name.split('.').pop() === 'csv' || file.name.split('.').pop() === 'xlsx';
    }
    return true;
  }

  private formatFileName(files: File[]) {
    const fileName = files[0].name;
    return fileName.length > 50 ? [fileName.substring(0, 50), '...'].join() : fileName;
  }

  private invalidFeedBack() {
    return 'Invalid file. Select .csv or .xlsx file';
  }

  private async upload(eventData: any) {
    await authorizationApiProvider
      .uploadEquipmentExclusionItems(eventData.file, this.configId)
      .then((response: any) => {
        EventBus.$emit('s', `${eventData.file.name} uploaded successfully.`);
        this.resetForm = true;
        this.clearCacheForAllKeys();
        return;
      })
      .catch((error: any) => {
        EventBus.$emit('e', `<h3>Upload failed.</h3>${this.formatToastMessage(error)}`);
      });
  }

  private formatToastMessage(error: any) {
    if (error && error.response && error.response.data && error.response.data.errors) {
      const errors = error.response.data.errors;
      return `<div><strong>${errors[Object.keys(error.response.data.errors)[0]]}</strong><br/>`;
    }
    if (error && error.response && error.response.data && error.response.data.message) {
      return `<div><strong>${error.response.data.message}</strong><br/>`;
    }
    return `<div><strong>Upload Failed</strong><br/>`;
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class RangeInput extends Vue {
  @Prop() private minMaxValue: string;
  @Prop() private required: boolean;
  private minValue: number = null;
  private maxValue: number = null;

  private mounted() {
    if (this.minMaxValue) {
      this.minValue = Number(this.minMaxValue.split('_')[0]);
      this.maxValue = Number(this.minMaxValue.split('_')[1]);
    }

    if (!this.required) {
      this.emitOnChange({
        minValue: this.minValue,
        maxValue: this.maxValue,
        isValid: true,
      });
    }
  }

  private minMaxValueChanged() {
    var minMaxIsValid = this.minMaxValidation();

    this.emitOnChange({
      minValue: this.minValue,
      maxValue: this.maxValue,
      isValid: minMaxIsValid,
    });
  }

  private minMaxValidation(): boolean {
    return this.minMaxState && this.hasValidValueState;
  }

  private get minMaxState(): boolean {
    const minValue = Number(this.minValue);
    const maxValue = Number(this.maxValue);

    if (!this.hasValidValueState) {
      return true;
    }

    if (this.required) {
      if (maxValue > 0 && minValue === maxValue) {
        return false;
      }
    }

    if (minValue > maxValue || (minValue > 0 && minValue === maxValue)) {
      return false;
    }

    return true;
  }

  private get hasValidValueState(): boolean {
    if (
      !this.required &&
      this.isNullOrEmpty(this.minValue) &&
      this.isNullOrEmpty(this.maxValue)
    ) {
      return true;
    }

    if (
      this.isNullOrEmpty(this.minValue) ||
      this.isNullOrEmpty(this.maxValue)
    ) {
      return false;
    }

    return true;
  }

  private isNullOrEmpty(value: any | null): boolean {
    return value === null || value === '';
  }

  private emitOnChange(content: {
    minValue: number;
    maxValue: number;
    isValid: boolean;
  }) {
    this.$emit('rangeState_Changed', content);
  }
}


import { Component } from 'vue-property-decorator';
import RemovedConfigIndexComponent from '@/components/configuration/RemovedConfigIndexComponent.vue';
import EquipmentExclusionConfigIndex from './EquipmentExclusionConfigIndex.vue';
import { EQUIPMENT_EXCLUSION_CONFIG_DELETE } from '@/store/constants';
import { RoutingAction, EquipmentExclusionConfig } from '@/store/dynamicfulfillment/types';
import ProgramConfigModal from '@/components/configuration/ProgramConfigModal.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';

@Component({ components: { RemovedConfigIndexComponent, ProgramConfigModal } })
export default class RemovedEquipmentExclusionConfigIndex extends EquipmentExclusionConfigIndex {
  private configItems: EquipmentExclusionConfig[] = null;

  async mounted() {
    await authorizationApiProvider.getEquipmentExclusionConfigs(true)
        .then((configs) => this.configItems = configs);
  }

  get removedRouteActions() {
    return [
     { name: RoutingAction.Delete, action: EQUIPMENT_EXCLUSION_CONFIG_DELETE, requiredPermission: 'Global-HardDelete',  params: { hardDelete: true }},
    ];
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  DynamicFulfillmentState,
  VendorTicketStatusMapping,
  Vendor,
  FulfillmentTicketStatus,
  FulfillmentOption,
  VendorConfig,
  OrderStatusMapping,
  Enums,
  WorkflowDefinition,
} from '@/store/dynamicfulfillment/types';
import { Action, State } from 'vuex-class';
import { FETCH_FULFILLMENT_OPTION_CONFIG } from '@/store/dynamicfulfillment/constants';
import { FETCH_VENDOR_CONFIGS } from '@/store/constants';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { jsonToText } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: {ValidationObserver, ValidationProvider, VueJSONEditor} })
export default class StatusMappingCreateEdit extends Vue {
  @Prop() config: VendorTicketStatusMapping | OrderStatusMapping;
  @Prop() title: string;
  @Prop() statusType: string;
  @Action(FETCH_FULFILLMENT_OPTION_CONFIG, { namespace })  fetchFulfillmentOptionConfig: any;
  @State(namespace) profile!: DynamicFulfillmentState;
  jsonEditorText: any = jsonToText;
  selectedFulfillmentOption: FulfillmentOption = null;
  vendors: Vendor[] = [];
  vendorConfigs: VendorConfig[] = [];
  serviceNetworks: string[] = [];
  workflowDefinition: WorkflowDefinition = { name: '', data: null };
  currentIndex: number | undefined = undefined;
  isWorkflowValid: boolean = true;

  async mounted() {
    if (this.config.fulfillmentOption) {
      this.fetchVendors(
        FulfillmentOption[this.config.fulfillmentOption] as any,
      );
      this.selectedFulfillmentOption = this.config.fulfillmentOption;
    }
    this.vendorConfigs = await this.$store.dispatch(FETCH_VENDOR_CONFIGS);
  }

  get isNew() {
    return this.config._etag === null;
  }

  get targetStatus() {
    switch(this.config.documentType) {
      case 'VendorTicketStatusMapping':
        return Object.entries(FulfillmentTicketStatus)
        .filter((value) => isNaN(Number(value[1])) === true)
        .map(([index, targetStatus]) => ({ value: index, text: targetStatus }));
      case 'OrderStatusMapping':
        return Object.entries(Enums.OrderStatus)
        .filter((value) => isNaN(Number(value[1])) === true)
        .map(([index, targetStatus]) => ({ value: index, text: targetStatus }));
      default:
        return [];
    }
  }

  fulfillmentOptionLabel(option: any) {
    if (isNaN(option)) {
      return option;
    } else {
      return FulfillmentOption[option];
    }
  }

  label(option: any) {
    if (isNaN(option)) {
      return option;
    } else {
      return option;
    }
  }

  get allowedFulfillmentOptions() {
    return Object.keys(FulfillmentOption)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key: any) => FulfillmentOption[key])
      .sort((a, b) => {
        return a < b ? -1 : 1;
      });
  }

  get fulfillmentOptionState() {
    return this.selectedFulfillmentOption != null && this.selectedFulfillmentOption.toString() !== FulfillmentOption[FulfillmentOption.None];
  }

  get vendorState() {
    return this.fulfillmentOptionState && this.config.vendor !== null;    
  }

  get targetReasonDisabled() {
    return Number(this.config.targetStatus) === FulfillmentTicketStatus.None;
  }

  get targetReasonPlaceHolder() {
    return this.targetReasonDisabled ? "": "Select a Target Reason";
  }

  get invalidSourceStateId() {
    return (errors: any) => {
      var isValid = errors.length === 0;
      this.$emit('onStateChanged', isValid);
      return isValid;
    }
  }

  @Watch('selectedFulfillmentOption', { immediate: false })
  async fulfillmentOptionChanged() {
    if (this.isNew && typeof this.selectedFulfillmentOption === 'string') {
      this.config.fulfillmentOption = FulfillmentOption[
        this.selectedFulfillmentOption
      ] as any;
      this.config.vendor = null;
    }
    this.fetchVendors(this.selectedFulfillmentOption);    
  }

  @Watch('config.vendor', { immediate: true })
  async vendorChanged() {
    this.config.serviceNetwork = this.isNew ? null : this.config.serviceNetwork;
    this.serviceNetworks = [...this.getServiceProviders()];
  }

  @Watch('config.targetReason', { immediate: true, deep: true })
  setTargetReason() {
    if (this.config.targetReason == '') {
      this.config.targetReason = null;
    }
  }

  async fetchVendors(fulfillmentOption: FulfillmentOption) {
    const fulfillmentOptionConfig = await this.fetchFulfillmentOptionConfig(
      fulfillmentOption,
    );
    if (fulfillmentOptionConfig) {
      this.vendors = [...fulfillmentOptionConfig.vendors];
    }
  }

  get allowedVendors() {
    switch(this.statusType) {
      case 'vendorticketstatus':
        return Object.values(this.vendors)
          .map((value: any) => value.name)
          .sort((a, b) => {
            return a < b ? -1 : 1;
          });
      case 'orderstatus':
        return Object.values(
          this.vendorConfigs.filter(config => 
            config.serviceProviderConfigs.some(item => item.category === 'Ancillary')))
          .map((value: any) => value.id)
          .sort((a, b) => {
            return a < b ? -1 : 1;
          });
    }
  }

  get allowedServiceNetworks() {
    return Object.values(this.serviceNetworks)
      .map((value: any) => value.name)
      .sort((a, b) => {
        return a < b ? -1 : 1;
      });
  }

  formatter(value: string) {
    return value
      .replaceAll(' ', '')
      .replaceAll('\\', '')
      .replaceAll('?', '')
      .replaceAll('#', '')
      .replaceAll('/', '');
  }

  getServiceProviders() {
    var sps = [] as any[];
    if (this.vendorConfigs) {
      const vendor = this.vendorConfigs.find(
        (vendorConfig: VendorConfig) => this.config.vendor === vendorConfig.id,
      );
      vendor?.serviceProviderConfigs.forEach((x) => {
        sps.push({name: x.name});
      });
    }
    return sps;
  }

  updateWorkflowDefinition() {
    if (this.currentIndex !== undefined) {
      Vue.set(this.config.triggeringWorkflows, this.currentIndex, { ...this.workflowDefinition });
    } else {
      this.config.triggeringWorkflows.push({ ...this.workflowDefinition });
    }

    this.workflowDefinition = { name: '', data: null };
    this.currentIndex = undefined;
    this.$bvModal.hide('bv-workflow-modal');
  }

  onWorkflowDataChanged(event: any) {
    if (event.text === null || event.text.trim() === ''){
      this.workflowDefinition.data = null;
      this.isWorkflowValid = true;
    } else if (this.validJson(event.text))
    {
      this.workflowDefinition.data = JSON.parse(event.text);
      this.isWorkflowValid = true;
    } else {
      this.workflowDefinition.data = null;
      this.isWorkflowValid = false;
    }
  }

  removeWorkflow(index: number) {
    this.config.triggeringWorkflows.splice(index, 1);
  }

  editWorkflow(row: { index: number; item: WorkflowDefinition; }) {
    this.currentIndex = row.index;
    this.workflowDefinition = {...row.item};
    this.$bvModal.show('bv-workflow-modal');
  }
  
  cancel() {
    this.currentIndex = undefined;
    this.workflowDefinition = { name: '', data: {} };
    this.$bvModal.hide('bv-workflow-modal');
  }

  validJson(data: any) {
    if (data === null) {
      return false;
    }
 
    let value = typeof data !== "string" ? JSON.stringify(data) : data;    
    try {
      value = JSON.parse(value);
      return value && typeof value === "object" && !Array.isArray(value);
    } catch (e) {
      return false;
    }
  }
}

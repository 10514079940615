
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ServiceNetworkCreateEdit from '@/components/ServiceCenters/ServiceNetworkCreateEdit.vue';
import {
  FETCH_SERVICENETWORK_CONFIG,
  SET_SERVICENETWORK_CONFIGS,
} from '@/store/globalmodules/constants';
import { ServiceNetworkConfig } from '@/store/globalmodules/types';

@Component({ components: { ConfigEditComponent, ServiceNetworkCreateEdit } })
export default class ServiceNetworkCreate extends Vue {
  private defaultConfig: ServiceNetworkConfig = {
    id: null,
    allowedAttributes: [],
    eTag: null,
  };
  private isValid: boolean = false;

  private get fetchAction() {
    return 'globalmodule/' + FETCH_SERVICENETWORK_CONFIG;
  }

  private get saveAction() {
    return 'globalmodule/' + SET_SERVICENETWORK_CONFIGS;
  }

  private onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.defaultConfig = request.config;
    }
  }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import RemovedConfigIndexComponent from '@/components/configuration/RemovedConfigIndexComponent.vue';
import { DELETE_PROGRAM_FEES_CONFIG } from '@/store/dynamicfulfillment/constants';
import { RoutingAction, ProgramFeesConfig } from '@/store/dynamicfulfillment/types';
import ProgramConfigModal from '@/components/configuration/ProgramConfigModal.vue';
import DataTable from '@/components/common/DataTable.vue';
import ProgramFeesConfigIndex from './ProgramFeesConfigIndex.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';

@Component({ components: { RemovedConfigIndexComponent, DataTable, ProgramConfigModal } })
export default class RemovedProgramFeesConfigIndex extends ProgramFeesConfigIndex {
  private configItems: ProgramFeesConfig[] = null;

  async mounted() {
    await authorizationApiProvider.getProgramFeesConfigs(true)
        .then((configs) => this.configItems = configs);
  }

  get removedRouteActions() {
    return [
     { name: RoutingAction.Delete, action: 'dynamicfulfillmentmodule/' + DELETE_PROGRAM_FEES_CONFIG, requiredPermission: 'Global-HardDelete', params: { hardDelete: true }},
    ];
  }
}

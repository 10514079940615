
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { SourceSystem, ConfigType, RoutingAction } from '@/store/dynamicfulfillment/types';
import { FETCH_CONFIG_HISTORY, FETCH_SOURCE_SYSTEM_CONFIGS, SAVE_SOURCE_SYSTEM_CONFIG } from '@/store/constants';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { humanizeString } from '@/common/utilities';
import DataTable from '@/components/common/DataTable.vue';

@Component({ components: { ConfigIndexComponent, DataTable } })
export default class SourceSystemIndex extends Vue {
  configType: ConfigType = ConfigType.SourceSystems;
  fields: any[] = [
    'id',
    {
      key: 'uiVersion',
      label: 'User Interface Version',
      formatter: (value: any, key: any, item: any) => {
        return item && item.userInterfaceSettings ? item.userInterfaceSettings.interfaceVersion : '';
      },
    },
    {
      key: 'sourceSystem',
      label: 'Source System Enum',
      formatter: (value: any, key: any, item: any) => {
        return SourceSystem[value];
      },
    },
    {
      key: 'claimDataIncludedInRequestBody',
      label: 'Claim Data Included In Request Body',
      formatter: (value: any, key: any, item: any) => {
        return humanizeString(String(item.claimDataIncludedInRequestBody));
      },
    },
    {
      key: 'isAutoAuthorized',
      label: 'Ticket Authorize When Created',
      formatter: (value: any, key: any, item: any) => {
        return humanizeString(String(item.isAutoAuthorized));
      },
    },
    {
      key: 'eventForwarding.enabled',
      label: 'Event Forwarding Enabled',
      formatter: (value: any, key: any, item: any) => {
        return item.eventForwarding?.enabled ? 'Yes' : 'No';
      },
    },
    {
      key: 'searchCriteria.criteria',
      label: 'Search Criteria',
    },
    {
      key: 'userAudit.current.name',
      sortable: true,
      label: 'Last Modified User',
    },
    {
      key: 'userAudit.current.modifiedOnUtc',
      sortable: true,
      label: 'ModifiedOn Utc',
      formatter: (value: any, key: any, item: any) => {
        return value === '0001-01-01T00:00:00' ? '' : value;
      },
    },
    {
      key: 'eventForwarding.forwardingEndpoint',
      label: 'Forwarding Endpoint',
    },
    'actions',
  ];
  criteriaFields: any[] = ['name', 'values', 'defaultValue'];

  hasSearchCriteria(data: any) {
    return data.item.searchCriteria && data.item.searchCriteria.criteria && data.item.searchCriteria.criteria.length > 0;
  }

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'SourceSystemConfig-Edit', requiredPermission: 'Configuration-SourceSystems-Edit' },
      { name: RoutingAction.Fetch, action: FETCH_SOURCE_SYSTEM_CONFIGS, requiredPermission: 'Configuration-SourceSystems-View' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-SourceSystems-View' },
      { name: RoutingAction.Revert, action: SAVE_SOURCE_SYSTEM_CONFIG, requiredPermission: 'Configuration-SourceSystems-Edit' },
    ];
  }
}


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import axios from 'axios';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import {
  POST_CLIENT_MIGRATION_MATRIX,
} from '@/store/globalmodules/constants';
import MigrationMatrixUploadForm from '@/components/MigrationMatrixUploadForm.vue';
import { Action, State } from 'vuex-class';
const namespace: string = 'globalmodule';

@Component({
  components: { ViewContent, MigrationMatrixUploadForm },
})
export default class ClientMigrationMatrixUpload extends Vue {
    private pageTitle: string = 'Upload Client Migration Matrix';

    @Action(POST_CLIENT_MIGRATION_MATRIX, { namespace }) private postClientMigrationMatrix: (file: any) => Promise<any>;

    private async upload(eventData: any) {
          await this.postClientMigrationMatrix(eventData).then( (response) => {
            if (response && response.isUploaded) {
                EventBus.$emit('s', `${eventData.file.name} uploaded successfully.`);
                return;
            }
            EventBus.$emit('e', `<h4>Upload failed.</h4>${this.formatToastMessage(response)}`);
        }).catch( (error) => {
            EventBus.$emit('e', `<h3>Upload failed.</h3>${this.formatToastMessage(error)}`);
        });
    }

    private formatToastMessage(error: any) {
        if (error && error.response && error.response.data && error.response.data.errors) {
            const errors = error.response.data.errors;
            let messages = '';
            Object.keys(error.response.data.errors).forEach( (e) => {
                messages += `<div><strong>${errors[e]}</strong><br/>`;
            });
            return messages;
        }
        if (error && error.response && error.response.data && error.response.data.message) {
            return `<div><strong>${error.response.data.message}</strong><br/>`;
        }
        return `<div><strong>Upload Failed</strong><br/>`;
    }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import { SourceSystemConfig, EventForwarding, SourceSystemCriteria } from '@/store/dynamicfulfillment/types';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { FETCH_SOURCE_SYSTEM_CONFIG, SAVE_SOURCE_SYSTEM_CONFIG } from '@/store/dynamicfulfillment/constants';
import EventBus from '@/common/event.bus';
import { Action, State } from 'vuex-class';
import DataTable from '@/components/common/DataTable.vue';
import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, DataTable, PerLineTextAreaControl } })
export default class SourceSystemConfigEdit extends Vue {
  @Prop() private id!: string;
  @Action(FETCH_SOURCE_SYSTEM_CONFIG, { namespace }) private fetchSourceSystemConfig: any;
  @Action(SAVE_SOURCE_SYSTEM_CONFIG, { namespace }) private saveSourceSystemConfig: any;
  sourceSystemConfig: SourceSystemConfig = null;
  saving: boolean = false;
  criteriaFields = ['name', 'values', 'defaultValue', 'actions'];
  selectedCriteria: SourceSystemCriteria = { name: '', values: [], defaultValue: '' };
  selectedCriteriaIndex: number = undefined;
  criteriaValid: boolean = false;

  get pageTitle(): string {
    return this.sourceSystemConfig ? this.sourceSystemConfig.sourceSystem : '';
  }

  async created() {
    this.sourceSystemConfig = await this.fetchSourceSystemConfig(this.id);
  }

  /**
   * save
   */
  async save(e: any) {
    e.preventDefault();
    if (!this.checkFormValidity()) {
      return;
    }
    this.saving = true;
    this.saveSourceSystemConfig(this.sourceSystemConfig)
      .then((response: any) => {
        EventBus.$emit('s', 'SourceSystem config updated successfully.');
        this.saving = false;
        this.$router.push({ name: 'SourceSystems' });
      })
      .catch((error: any) => {
        EventBus.$emit('e', 'SourceSystem config update failed.');
        this.saving = false;
      });
  }

  addEventForwarding(config: SourceSystemConfig) {
    Vue.set(config, 'eventForwarding', { enabled: null, forwardingEndpoint: '' });
  }

  removeEventForwarding(config: SourceSystemConfig) {
    Vue.set(config, 'eventForwarding', null);
  }

  get eventForwardingEnabledState() {
    return this.sourceSystemConfig.eventForwarding?.enabled || false;
  }

  get invalidEventForwardingEnabledFeedback() {
    return 'Select Event Forwarding Enabled setting';
  }

  get eventForwardingEndpointState() {
    return this.sourceSystemConfig.eventForwarding && this.sourceSystemConfig.eventForwarding?.forwardingEndpoint != null && !(this.sourceSystemConfig.eventForwarding?.forwardingEndpoint.trim() === '');
  }

  get invalidEventForwardingEndpointFeedback() {
    return 'Enter Event Forwarding Endpoint';
  }

  get configState() {
    if (this.sourceSystemConfig.eventForwarding) {
      return this.sourceSystemConfig.eventForwarding.enabled != null && this.eventForwardingEndpointState;
    }

    return true;
  }

  get hasCriteria() {
    return this.sourceSystemConfig.searchCriteria && this.sourceSystemConfig.searchCriteria.criteria && this.sourceSystemConfig.searchCriteria.criteria.length > 0;
  }

  checkFormValidity(): boolean {
    return this.configState;
  }

  formatter(value: string) {
    return value.replace(',', '').replace("'", '');
  }

  updateValues(values: string[]) {
    this.selectedCriteria.values = values;
  }

  addCriteria() {
    if (!this.sourceSystemConfig.searchCriteria) {
      this.$set(this.sourceSystemConfig, 'searchCriteria', { criteria: [], delimiter: '' });
    }
    this.selectedCriteria = { name: '', values: [], defaultValue: '' };
    this.$bvModal.show('criteria-modal');
  }

  editCriteria(item: SourceSystemCriteria) {
    this.selectedCriteria = JSON.parse(JSON.stringify(item));
    this.selectedCriteriaIndex = this.sourceSystemConfig.searchCriteria.criteria.findIndex((c) => c.name === this.selectedCriteria.name);
    this.$bvModal.show('criteria-modal');
  }

  removeCriteria(item: SourceSystemCriteria) {
    const index = this.sourceSystemConfig.searchCriteria.criteria.indexOf(item);
    this.sourceSystemConfig.searchCriteria.criteria.splice(index, 1);

    if (this.sourceSystemConfig.searchCriteria.criteria.length === 0) {
      this.sourceSystemConfig.searchCriteria = null;
    }
  }

  cancelCriteriaEdit() {
    this.selectedCriteria = { name: '', values: [], defaultValue: '' };
    this.selectedCriteriaIndex = undefined;
    this.$bvModal.hide('criteria-modal');
  }

  submitCriteria() {
    const newCriteria = { ...this.selectedCriteria };

    if (this.selectedCriteriaIndex !== undefined) {
      this.$set(this.sourceSystemConfig.searchCriteria.criteria, this.selectedCriteriaIndex, newCriteria);
    } else {
      if (!this.sourceSystemConfig.searchCriteria) {
        this.sourceSystemConfig.searchCriteria = { criteria: [], delimiter: '' };
      }

      this.$set(this.sourceSystemConfig.searchCriteria.criteria, this.sourceSystemConfig.searchCriteria.criteria.length, newCriteria);
    }

    this.$bvModal.hide('criteria-modal');
  }
}

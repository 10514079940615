
import { InventoryPartType, LocationInventory } from '@/store/dynamicfulfillment/types';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import DataTable from '@/components/common/DataTable.vue';
import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue'

@Component({ components: { DataTable, PerLineTextAreaControl } })
export default class LocationInventoryViewEdit extends Vue {
    @Prop() private items: LocationInventory[];
    @Prop() private isEdit: boolean;
    private isValidState: boolean = null;
    private fields: any[] = ['id', 'name', 'inventoryParts', 'actions'];
    private readOnlyFields: any[] = ['id', 'name', 'inventoryParts'];
    private textAreaKey: number = 0;

    private inventoryPartReadOnlyFields: any[] = [
    {
        key: 'partType',
        label: 'Part Type',
        formatter: (value: any, key: any, item: any) => {
            return InventoryPartType[value];
        },
    },
    'count',
    {
        key: 'deviceSkus',
        label: 'Device Skus',
        formatter: (value: string[], key: any, item: string) => {
            if (value && value.length > 1) {
                return value.join(', ');
            } else if (value && value.length === 1) {
                return value;
            }
        },
    }];

    private inventoryPartEditFields: any[] = [
    {
        key: 'partType',
        label: 'Part Type',
        formatter: (value: any, key: any, item: any) => {
            return InventoryPartType[value];
        },
    },
    'count',
    {
        key: 'deviceSkus',
        label: 'Device Skus',
        formatter: (value: string[], key: any, item: string) => {
            if (value && value.length > 1) {
                return value.join(', ');
            } else if (value && value.length === 1) {
                return value[0];
            }
        },
    },
    'actions'];

    private getFields(): any[] {
        if (this.isEdit) {
            return this.fields;
        } else {
            return this.readOnlyFields;
        }
    }

    private onChange(value: string) {
        const count = this.items.filter( (location: LocationInventory) => location.id === value).length;
        this.isValidState = (count > 1) ? false : null;
        this.$emit('setFormState', this.isValidState);
    }

    private get partTypes() {
        return Object.entries(InventoryPartType).filter((value) => isNaN(Number(value[1])) === true).map(([index, partType]) => ({ value: index, text: partType }));
    }

    private addPart(id: string) {
        const location  = this.items.find((i) => i.id === id);
        this.textAreaKey += 1;
        location.inventoryParts.unshift({partType: 0, count: 0, deviceSkus: []});
    }

    private removePart(id: string, partIndex: number) {
        const location  = this.items.find((i) => i.id === id);
        this.textAreaKey += 1;
        location.inventoryParts.splice(partIndex, 1);
    }

    private removeLocation(id: string) {
        const index = this.items.findIndex((i) => i.id === id);
        this.items.splice(index, 1);
    }

    private updateDeviceSkus(request: { id: string, partIndex: number, array: string[]}) {
        const location  = this.items.find((i) => i.id === request.id);
        location.inventoryParts[request.partIndex].deviceSkus = request.array;
    }

    private formatter(value: string) {
        return value.replace(',', '').replace('\'', '');
    }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DamageType, DynamicFulfillmentState, FulfillmentOption, FulfillmentTicket, PriceListCostData,
FulfillmentTicketStatus as FulfillmentTicketStatusEnum } from '@/store/dynamicfulfillment/types';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import { Action, State } from 'vuex-class';
import { FETCH_FULFILLMENT_OPTION_CONFIGS, UPDATE_TICKET_AUTHORIZATION_AMOUNT } from '@/store/dynamicfulfillment/constants';
import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import _ from 'lodash';
import DataTable from '@/components/common/DataTable.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable } })
export default class FulfillmentTicketAuthorizedAmountDetails extends Vue {
  @Prop() private fulfillmentTicket: FulfillmentTicket;
  @State(namespace) private profile: DynamicFulfillmentState;
  @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace }) private fetchFulfillmentOptionConfigs: any;
  @Action(UPDATE_TICKET_AUTHORIZATION_AMOUNT, { namespace }) private updateTicketAuthorizationAmount: any;

  private originalCostDatas: PriceListCostData[] = null;
  private allowedToUpdateAuthAmountForFOVendor : boolean = false;
  private editableCostDataChanged : boolean = false;
  private fields: any[] = [
    { 
      key: 'action',
      label: 'Action',
      thStyle: { width: "95px" },
    },
    {
      key: "vendorDeviceId",
      label: "Vendor Device Id",
    },
    {
      key: "deviceTypeId",
      label: "Device Type Id",
    },
    {
      key: "repairTypeId",
      label: "Repair Type Id",
    },
    {
      key: "deviceName",
      label: "Device Name",
    },
    {
      key: "serviceName",
      label: "Service Name",
    },
    {
      key: "damageType",
      label: "Damage Type",
    },
    {
      key: "partsCost",
      label: "Parts Cost",
    },
    {
      key: "laborCost",
      label: "Labor Cost",
    },
    {
      key: "subtotal",
      label: "Subtotal",
    },
    {
      key: "maxTaxValue",
      label: "Max Tax Value",
    },
    {
      key: "maxTotalAmount",
      label: "Max Total Amount",
    },
  ];
  private userAuthorizationProvider: UserAuthorizationProvider; 
  
  private get priceListCostDatas () {
    var plcds : PriceListCostData[];
    if (this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas) {
      plcds = JSON.parse(JSON.stringify(this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas));
    }
    return plcds;
  }

  private get editingAnyRows() {
    if (this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas.some(p => p.isEditing === true)) {
      return true;
    }
    return false;
  }

  private setOriginalCostDatas() {
    if (this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas) {
      this.originalCostDatas = JSON.parse(JSON.stringify(this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas));
      this.originalCostDatas.forEach(o => {
        o.isEditing = false;
      });
    }
  }

  private onEdit(item: any, index: number) {
    if (this.validationErrors(item, index))
      return;
    this.$set(item, 'isEditing', !item.isEditing);
    Vue.set(this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas, index, item);
  }

  private resetState(index?: number) {
    var originalData = JSON.parse(JSON.stringify(this.originalCostDatas));
    if (index != null)
      Vue.set(this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas, index, originalData[index]);
    else
      this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas = JSON.parse(JSON.stringify(this.originalCostDatas));
  }

  private tableRowClass() {
    return 'tableRow'
  }

  @Watch('priceListCostDatas', {immediate: false, deep: true})
  private onEditableItemsChange(newValue: PriceListCostData[], oldValue: any[]) {
    for (let i = 0; i < newValue.length; i++)
    {
      if ( (+newValue[i].partsCost !== +this.originalCostDatas[i].partsCost) ||
           (+newValue[i].laborCost !== +this.originalCostDatas[i].laborCost) ||
           (+newValue[i].subTotal !== +this.originalCostDatas[i].subTotal) ||
           (+newValue[i].maxTaxValue !== +this.originalCostDatas[i].maxTaxValue))
      {
        this.editableCostDataChanged = true;
        break;
      }
      else
        this.editableCostDataChanged = false;
    }
  }

  public async mounted() {
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    await this.fetchFulfillmentOptionConfigs();
    var fo : FulfillmentOption = this.fulfillmentTicket.acceptedOfferInfo.offer.fulfillmentOption;
    var fulfillmentOptionConfig = this.profile.fulfillmentOptionConfigs.filter(f => f.fulfillmentOption === fo)[0];
    var vendor = fulfillmentOptionConfig.vendors.filter(f => f.name === this.fulfillmentTicket.acceptedOfferInfo.offer.vendorId)[0].name;
    this.allowedToUpdateAuthAmountForFOVendor = fulfillmentOptionConfig.vendors.filter(v => v.name === vendor)[0].canUpdateTicketAuthorizationAmount;
    this.setOriginalCostDatas();
  }

  private convertDamageType (damageType: DamageType) {
    return DamageType[damageType];
  }

  private canUpdateAuthAmount() {
    if (!this.userAuthorizationProvider)
    {
      this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    }
    const features = ['Operations-FulfillmentTicket-UpdateAuthAmount'];
    const isAuthorizedForRole = this.userAuthorizationProvider.hasPermission(features);
    return isAuthorizedForRole && this.allowedToUpdateAuthAmountForFOVendor && this.isValidTicketStatus();
  }

  private isValidTicketStatus() {
    return this.fulfillmentTicket && (this.fulfillmentTicket.status.current == FulfillmentTicketStatusEnum.Booked ||
    this.fulfillmentTicket.status.current == FulfillmentTicketStatusEnum.InProgress);
  }

  private updateMaxTotalAmount(row: any) {
    if (this.isValidTicketStatus()) {
      this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas[row.index].maxTotalAmount = Number((+row.item.subTotal + +row.item.maxTaxValue).toFixed(12));
    }
  }

  private async handleSubmit(e: any) {
    e.preventDefault();
    await this.updateTicketAuthorizationAmount(
      {
        authorizationId: this.$route.params.authorizationId,
        fulfillmentTicketId: this.$route.params.fulfillmentTicketId,
        priceListCostDatas: this.fulfillmentTicket.acceptedOfferInfo.offer.priceListCostDatas
      }
    )
      .then(() => {
        EventBus.$emit(
          's',
          `Ticket authorization amount updated successfully.`,
         );
        this.$router.go(0);
      })
    .catch((error: any) => {
        this.showErrors(error);
      });
  }

  private isValidEdit(item: any, index: number) {
    
  }

  private validationErrors(item: any, index: number) {
    if (item.isEditing) {
      var validationErrors: string[] = [];

      if ((+item.partsCost + +item.laborCost) > +item.subTotal) {
        validationErrors.push('Sum of PartsCost and LaborCost cannot exceed the SubTotal')
      }
      
      if (validationErrors.length > 0) {
        this.showErrors(validationErrors);
        return true;
      }
      else return false;
    }
    
    return false;
  }

  private showErrors(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit('e', `<h4>Validation error(s) occured.</h4>${message}`);
  }

}


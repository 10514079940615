
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  DynamicFulfillmentState,
  Product,
  SourceSystemConfig,
} from '@/store/dynamicfulfillment/types';
import { humanizeString } from '@/common/utilities';
import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue';
import RangeInput from '@/components/common//RangeInput.vue';

@Component({ components: { PerLineTextAreaControl, RangeInput } })
export default class ProductsCreateEdit extends Vue {
  @Prop() private config: Product;
  @Prop() private sourceSystem: string;
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;
  @Prop() private sourceSystemConfig: SourceSystemConfig;
  private attributeDict: any = {};

  private mounted() {
    this.sourceSystemConfig.productMappingAttributes.sort(
      (a: any, b: any) => b.isRequired - a.isRequired,
    );
  }
  private formatter(value: string) {
    return value.replace(',', '').replace("'", '');
  }

  private humanizeLabel(value: string): string {
    return humanizeString(value);
  }

  private inputChanged(request: { id: string; value: string }) {
    if (!request.value || request.value.length === 0) {
      type ObjectKey = keyof typeof this.config;
      delete this.config[request.id as ObjectKey];
    } else {
      this.attributeDict[request.id] = true;
    }
    this.validateProductAttributes();
  }

  private valuesChanged(request: { id: string; array: string[] }) {
    type ObjectKey = keyof typeof this.config;
    request.array = request.array.filter(n => n);

    if (!request.array || request.array.length === 0) {
      delete this.config[request.id as ObjectKey];
    } else {
      this.config[request.id as ObjectKey] = request.array as any;
      this.attributeDict[request.id] = true;
    }
    this.validateProductAttributes();
  }

  private rangeState_Changed(request: {
    id: string;
    event: { minValue: number; maxValue: number; isValid: boolean };
  }) {
    type ObjectKey = keyof typeof this.config;

    if (!request.event.minValue && !request.event.maxValue) {
      delete this.config[request.id as ObjectKey];
      delete this.attributeDict[request.id as ObjectKey];
    } else {
      const minValue = Number(request.event.minValue);
      const maxValue = Number(request.event.maxValue);
      this.config[request.id as ObjectKey] = `${minValue}_${maxValue}` as any;

      this.attributeDict[request.id] = request.event.isValid;
    }
    this.validateProductAttributes();
  }

  private hasInvalidValues(): boolean {
    for (const key in this.attributeDict) {
      if (this.attributeDict.hasOwnProperty(key) && this.attributeDict[key] === false) {
        return true;
      }
    }

    return false;
  }

  private validateProductAttributes() {
    this.$emit('isValidState_Changed', !this.hasInvalidValues());
  }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { ConfigType, FulfillmentOption, FulfillmentOptionConfig, RoutingAction } from '@/store/dynamicfulfillment/types';
import { FETCH_FULFILLMENT_OPTION_CONFIGS, FETCH_CONFIG_HISTORY, FULFILLMENT_OPTION_CONFIG_SAVE } from '@/store/constants';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { jsonToText } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, VueJSONEditor, ConfigIndexComponent } })
export default class FulfillmentOptionConfigIndex extends Vue {
  configType: ConfigType = ConfigType.FulfillmentOptions;
  fields: any[] = [
    'id',
    'fulfillmentOptionCategory',
    {
      key: 'fulfillmentOption',
      label: 'Fulfillment Option',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
      filterable: true,
      filterByFormatted: true,
    },
    {
      key: 'vendorServiceProviders',
      label: 'Vendor - Service Networks',
      formatter: (value: any, key: any, item: any) => {
        return item.vendors;
      },
      filterable: true,
      filterByFormatted: true,
    },
    {
      key: 'fulfillmentOptionEnum',
      label: 'Fulfillment Option Enum',
    },
    {
      key: 'isEnabled',
      filterable: true,
    },
    {
      key: 'fulfillmentOptionConfig',
      label: 'Config',
    },
    {
      key: 'userAudit.current.name',
      label: 'Last Modified User',
      sortable: true,
    },
    {
      key: 'userAudit.current.modifiedOnUtc',
      sortable: true,
      label: 'ModifiedOn Utc',
      formatter: (value: any, key: any, item: any) => {
        return value === '0001-01-01T00:00:00' ? '' : value;
      },
    },
    'actions',
  ];

  vendorServiceProviderFields: any[] = [
    'name',
    {
      key: 'serviceProviders',
      label: 'Service Networks',
      formatter: (value: any, key: any, item: any) => {
        return item.serviceProviders.join('<br />');
      },
    },
  ];

  configModal: any = {
    id: '',
    title: '',
    content: '',
  };
  jsonEditorText: any = jsonToText;

  config(item: any, index: any, button: any) {
    this.configModal.id = item.id;
    this.configModal.title = `FulfillmentOption Config`;
    this.configModal.content = item;
    this.$root.$emit('bv::show::modal', this.configModal.id, button);
  }

  resetConfigModal() {
    this.configModal.title = '';
    this.configModal.content = '';
  }

  get validateVendors() {
    return (vendors: any[]) => {
      return vendors !== undefined && vendors.length > 0;
    };
  }

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'FulfillmentOptionConfig-Edit', requiredPermission: 'Configuration-FulfillmentOptions-Edit' },
      { name: RoutingAction.Create, route: 'FulfillmentOptionConfig-Create', requiredPermission: 'Configuration-FulfillmentOptions-Create' },
      { name: RoutingAction.Fetch, action: FETCH_FULFILLMENT_OPTION_CONFIGS, requiredPermission: 'Configuration-FulfillmentOptions-View' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-FulfillmentOptions-View' },
      { name: RoutingAction.Revert, action: FULFILLMENT_OPTION_CONFIG_SAVE, requiredPermission: 'Configuration-FulfillmentOptions-Edit' },
    ];
  }
}


import { ServiceArea, ServiceAreaClassification, ServiceAreaValueInputType, ServiceCenterLocationConfig } from '@/store/globalmodules/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class ServiceAreaComponent extends Vue {
    @Prop({ required: true }) private serviceArea: ServiceArea;
    @Prop({ required: true }) private serviceCenterLocation: ServiceCenterLocationConfig;
    @Prop({ required: true }) private index: number;
    @Prop ({default: false}) private isReadOnly: boolean;
    private exactValue: string = null;
    private maxLengthPostalCode: number = 10;
    private allowedClassifications: string[] = ['PostalCode'];
    private allowedInputTypes:string[] = ['Exact', 'Range'];
    private rangeStartError: string = "Range Start must be less than Range End";
    private rangeEndError: string = "Range End must be greater than Range Start";

    private get isExact() { 
        return this.serviceArea.inputType === ServiceAreaValueInputType[ServiceAreaValueInputType.Exact]
    }

    private get isRange() {
        return this.serviceArea.inputType === ServiceAreaValueInputType[ServiceAreaValueInputType.Range]
    }

    private hasDuplicate(): boolean {
        return false;
    }

    private removeServiceArea() {
        this.serviceCenterLocation.serviceArea.splice(this.index, 1);
        this.$emit('onServiceCollectionChange', { config: this.serviceCenterLocation, isValid: !this.hasDuplicate() });
    }

    private get maxLength() {
        switch(this.serviceArea.classification) {
            case ServiceAreaClassification[ServiceAreaClassification.PostalCode]:
                return this.maxLengthPostalCode;
            default:
                return this.maxLengthPostalCode;
        }
    }

    private get rangeStart() {
        return this.serviceArea.value.split('_')[0];
    }

    private set rangeStart(val: string) {
        this.serviceArea.value = `${val}_${this.rangeEnd}`
    }

    private get rangeEnd() {
        if (this.serviceArea.value && this.serviceArea.value.split('_').length > 0) {
            return this.serviceArea.value.split('_')[1];
        }
        return '';  
    }

    private set rangeEnd(val: string) {
        this.serviceArea.value = `${this.rangeStart}_${val}`
    }

    private get rangeStartState() {
        if (this.isReadOnly) return null;
        if (this.rangeStart !== 'undefined' && this.rangeStart !== '' && this.rangeEnd !== 'undefined' && this.rangeEnd !== '') {
            return this.rangeStart < this.rangeEnd;
        }            
        return true;
    }

    private get rangeEndState() {
        if (this.isReadOnly) return null;
        if (this.rangeEnd !== 'undefined' && this.rangeEnd !== '' && this.rangeStart !== 'undefined' && this.rangeStart !== '') {           
            return this.rangeEnd > this.rangeStart;
        }
        return true;
    }
}

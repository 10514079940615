
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import BrandMappingConfigIndex from '@/components/Vendors/ServicePower/BrandMappingConfigIndex.vue';

@Component({ components: { ViewContent, BrandMappingConfigIndex }})
export default class ServicePowerBrandMappingIndex extends Vue {

}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import AppConfigDetails from '@/views/configuration/AppConfigs/AppConfigDetails.vue';

@Component({ components: { ViewContent, AppConfigDetails }})
export default class AppConfigIndex extends Vue {

}

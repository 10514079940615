
import ServiceNetworkConfigIndex from '@/components/Vendors/ServiceCentral/ServiceNetworkConfigIndex.vue';
import ViewContent from '@/components/ViewContent.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({ components: {ViewContent, ServiceNetworkConfigIndex}})
export default class ServiceCentralNetworkManagementIndex extends Vue {

}

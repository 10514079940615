
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import { legacyApiProvider } from '@/common/df.legacy.api';
import PhoneNumber from '@/components/common/PhoneNumber.vue';
import LegacyClaimStatusLabel from '@/components/LegacyClaimStatusLabel.vue';
import {
  LegacyClaimData,
  LegacyRepairStatus,
  LegacyCallData,
  SourceSystemIdentifier,
} from '@/store/dynamicfulfillment/types';
import { FETCH_SOURCE_SYSTEM_CLAIM } from '@/store/dynamicfulfillment/constants';
import { copyToClipboard } from '@/common/utilities';
import { Action, State } from 'vuex-class';
import { DateTime } from 'luxon';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: { ViewContent, PhoneNumber, LegacyClaimStatusLabel },
})
export default class LegacyClaimDetails extends Vue {
  @Prop() private title: string;
  @Prop() private claimNumber!: string;
  @Prop() private claimAuthorizationNumber!: string;
  // tslint:disable-next-line:max-line-length
  @Action(FETCH_SOURCE_SYSTEM_CLAIM, { namespace }) private fetchSourceSystemClaim: (sourceSystemIdentifier: SourceSystemIdentifier) => Promise<any>;

  private claimData: LegacyClaimData[] = null;
  private selectedClaimData: LegacyClaimData = null;
  private callData: LegacyCallData[] = null;
  private sourceSystemClaimData: any = null;

  private callDataFields: any[] = [
    {
      key: 'id',
      thStyle: { width: '275px' },
    },
    { key: 'authorizationNumber', label: 'Authorization Number' },
    'program',
    { key: 'operationName', label: 'Operation Name' },
    { key: 'callDate', label: 'Call Date' },
    { key: 'details', label: 'View Error' },
  ];

  private configModal: any = {
    id: '',
    title: '',
    content: '',
  };

  public async mounted() {
    await this.refreshData(this.claimNumber, this.claimAuthorizationNumber);
  }

  @Watch('claimNumber', { immediate: false })
  public async refreshData(claimNumber: string, claimAuthNumber: string) {
    this.claimData = await legacyApiProvider.getClaimData(claimNumber);
    this.selectedClaimData = this.claimData.find(
      (c) => c.RepairData.AuthorizationNumber === this.claimAuthorizationNumber,
    );
    this.callData = await legacyApiProvider.getCallData(claimAuthNumber);
    this.getSourceSystemData();
  }

  private error(item: any, index: any, button: any) {
    this.configModal.id = item.id;
    this.configModal.title = `Call Error Message`;
    this.configModal.content = JSON.stringify(
      JSON.parse(item.Error.Message),
      null,
      2,
    );
    this.$root.$emit('bv::show::modal', this.configModal.id, button);
  }

  private request(item: any, index: any, button: any) {
    this.configModal.id = item.id;
    this.configModal.title = `Call Error Request`;
    this.configModal.content = unescape(item.Error.Request);
    this.$root.$emit('bv::show::modal', this.configModal.id, button);
  }

  private resetConfigModal() {
    this.configModal.title = '';
    this.configModal.content = '';
  }

  private copyPayload(payload: any) {
    copyToClipboard(payload);
  }

  private copyClaimData() {
    copyToClipboard(JSON.stringify(this.sourceSystemClaimData, null, 2));
  }

  private get sourceSystemIdentifier() {
    const sourceSystemIdentifier = {
      sourceSystem: '1',
      claimNumber: this.claimNumber,
    } as SourceSystemIdentifier;

    return sourceSystemIdentifier;
  }

  private getSourceSystemData() {
    const sourceSystemIdentifier = this.sourceSystemIdentifier;
    this.fetchSourceSystemClaim(sourceSystemIdentifier).then((claim) => this.sourceSystemClaimData = claim);
  }

  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}

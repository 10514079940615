
import { Component, Prop, Vue } from 'vue-property-decorator';
import axios from 'axios';
import ViewContent from '@/components/ViewContent.vue';
import { FETCH_MIGRATION_MATRIX_CLIENTS } from '@/store/globalmodules/constants';
import { operationsApiProvider } from '@/common/operations.api';
import { Action, State } from 'vuex-class';
import { GlobalModuleState } from '@/store/globalmodules/types';
const namespace: string = 'globalmodule';

@Component({
  components: { ViewContent },
})
export default class MigrationMatrixReportDownload extends Vue {
  private pageTitle: string = 'Report Download';
  private baseUrl: string = null;
  private selectedClient: string = '';
  private selectedReport: string = '';
  private readonly activeMigrationMatrix = 'ActiveMigrationMatrix';
  private readonly missingSkuData = 'MissingSkuData';
  private readonly internalMigrationMatrix = 'InternalMigrationMatrix';

  @Action(FETCH_MIGRATION_MATRIX_CLIENTS, { namespace }) private fetchMigrationMatrixClients: any;
  @State(namespace) private profile: GlobalModuleState;

  private async created() {
    await this.fetchMigrationMatrixClients();
  }

  private async download() {
    if (this.selectedReport === this.activeMigrationMatrix) {
      return await operationsApiProvider.getMigrationReport(this.selectedClient, false);
    } else if (this.selectedReport === this.internalMigrationMatrix) {
      return await operationsApiProvider.getMigrationReport(this.selectedClient, true);
    } else {
      return await operationsApiProvider.getMissingSkuDataReport(this.selectedClient);
    }
  }

  private get migrationMatrixClients() {
    return this.profile.migrationMatrixClients;
  }

  private get isActiveReportSelected() {
    return (this.selectedReport = this.activeMigrationMatrix);
  }

  private get reports() {
    const availableReports = [
      { value: this.activeMigrationMatrix, text: 'Active Migration Matrix' },
      { value: this.internalMigrationMatrix, text: 'Internal Migration Matrix' },
      { value: this.missingSkuData, text: 'Missing Sku Data' },
    ];

    return availableReports;
  }

  private get validSelections() {
    return this.selectedClient !== '' && this.selectedReport !== '';
  }
}


import Vue from 'vue';
import { State, Action } from 'vuex-class';
import ViewContent from '@/components/ViewContent.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import DataTable from '@/components/common/DataTable.vue';
import { DynamicFulfillmentState, InventoryItem, ItemSku } from '@/store/dynamicfulfillment/types';
import { FETCH_REPAIR_CATALOG_PARTS_MAPPING } from '@/store/dynamicfulfillment/constants';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, DataTable } })
export default class RepairCatalogPartsMappingIndex extends Vue {
  @Prop({ required: true, type: String }) catalogId: string;
  @State(namespace) private profile!: DynamicFulfillmentState;
  @Action(FETCH_REPAIR_CATALOG_PARTS_MAPPING, { namespace }) fetchRepairCatalogPartsMapping: (request: {catalogId: string}) => Promise<InventoryItem[]>;
  userAuthorizationProvider: UserAuthorizationProvider;
  title: string = 'Repair Catalogs Parts Mapping';
  fields: any[] = [
    {
      key: 'id',
      label: 'Id',
    },
    {
      key: 'itemType',
      label: 'ItemType',
    },
    {
      key: 'catalog',
      label: 'Catalog',
    },
    {
      key: 'description',
      label: 'Description',
    },
    {
      key: 'replacementSkus',
      label: 'ReplacementSkus',
      formatter: (value: any) => {
        if (Array.isArray(value)) {
          return value.map((skuObj: any) => skuObj.sku).join(', ');
        }
        return '';
      },
    },
  ];
  items: InventoryItem[] = [];

  async mounted() {
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    this.items = await this.fetchRepairCatalogPartsMapping({catalogId:  this.catalogId});
  }
}

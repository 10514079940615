
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import axios from 'axios';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import {
  POST_ASSURANT_MIGRATION_MATRIX,
} from '@/store/globalmodules/constants';
import MigrationMatrixUploadForm from '@/components/MigrationMatrixUploadForm.vue';
import { Action, State } from 'vuex-class';
const namespace: string = 'globalmodule';

@Component({
  components: { ViewContent, MigrationMatrixUploadForm },
})
export default class AssurantMigrationMatrixUpload extends Vue {
    private pageTitle: string = 'Upload Assurant Migration Matrix';
    @Action(POST_ASSURANT_MIGRATION_MATRIX, { namespace }) private postAssurantMigrationMatrix: (file: any) => Promise<any>;
    private async upload(eventData: any) {
        await this.postAssurantMigrationMatrix(eventData).then( (response) => {
            if (response && response.isUploaded) {
                EventBus.$emit('s', `${eventData.file.name} uploaded successfully.`);
                return;
            }
            EventBus.$emit('e', `<h4>Upload failed.</h4>${this.formatToastMessage(response)}`);
        }).catch( (error) => {
            EventBus.$emit('e', `<h3>Upload failed.</h3>${this.formatToastMessage(error.response.data.message)}`);
        });
    }

    private formatToastMessage(error: string) {
        return `<div><strong>${error}</strong><br/>`;
    }
}


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Enums, FulfillOptionVendorFlowTypeConfig, Vendor } from '@/store/dynamicfulfillment/types';

@Component
export default class VendorFlowTypeConfigComponent extends Vue {
  @Prop({ required: true }) vendor!: Vendor;

  private currentIndex: number | undefined = undefined;
  private flowTypeConfig: FulfillOptionVendorFlowTypeConfig = { flowType: null, requiresPriceList: false };

  private fields = [
    { key: 'flowType', label: 'Flow Type' },
    {
      key: 'requiresPriceList',
      label: 'Requires Price List',
      formatter: (value: boolean) => (value ? 'Yes' : 'No'),
    },
    { key: 'actions', label: 'Actions' },
  ];

  get allowedFlowTypes() {
    const existingFlowTypes = this.vendor.flowTypeConfigs.map((config) => config.flowType);
    return Object.keys(Enums.FlowTypes).filter((key) => Enums.FlowTypes[key as keyof typeof Enums.FlowTypes] !== Enums.FlowTypes.Default && !existingFlowTypes.includes(Enums.FlowTypes[key as keyof typeof Enums.FlowTypes]));
  }

  get flowTypeState() {
    return this.flowTypeConfig.flowType !== null;
  }

  editFlowType(index: number, config: FulfillOptionVendorFlowTypeConfig) {
    this.currentIndex = index;
    this.flowTypeConfig = { ...config };
    this.$bvModal.show(`bv-${this.vendor.name}-flowType-modal`);
  }

  removeFlowType(index: number) {
    this.vendor.flowTypeConfigs.splice(index, 1);
  }

  cancelFlowType() {
    this.currentIndex = undefined;
    this.flowTypeConfig = { flowType: null, requiresPriceList: false };
    this.$bvModal.hide(`bv-${this.vendor.name}-flowType-modal`);
  }

  updateFlowType() {
    if (this.currentIndex !== undefined) {
      Vue.set(this.vendor.flowTypeConfigs, this.currentIndex, { ...this.flowTypeConfig });
    } else {
      Vue.set(this.vendor.flowTypeConfigs, this.vendor.flowTypeConfigs.length, { ...this.flowTypeConfig });
    }

    this.flowTypeConfig = { flowType: null, requiresPriceList: false };
    this.currentIndex = undefined;
    this.$bvModal.hide(`bv-${this.vendor.name}-flowType-modal`);
  }
}

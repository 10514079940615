
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import VendorRoutes from '@/router/vendors.routes';
import { State } from 'vuex-class';

@Component
export default class VendorNavBar extends Vue {
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;
  private userAuthorizationProvider: UserAuthorizationProvider;

  private vendorsNavBar: any = [];

  private mounted() {
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    this.vendorsNavBar = VendorRoutes[0];
  }

  private displayRoute(item: any) {
    if (item.meta.topNavDisplay === undefined || item.meta.topNavDisplay) {
      return true;
    }
    return false;
  }

  private hasPermission(features: string[]) {
    if (!this.userAuthorizationProvider) {
      this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    }
    return this.userAuthorizationProvider.hasPermission(features);
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  DynamicFulfillmentState,
  VendorConfig,
  VendorConfigUserInterfaceSettings,
  ServiceProviderConfig,
  AncillaryServiceTypeConfig,
  AllowedAttribute,
  AncillaryServiceConfig,
  ServiceNetworkFulfillmentOptionTypeConfig,
  VendorFulfillmentOptionConfig,
  FulfillmentOption,
  ServiceProviderConfigExtended,
  Enums
} from '@/store/dynamicfulfillment/types';
import { CustomAttributeFields, CustomAttributeSchema } from '@/store/globalmodules/types';
import { FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS, FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { State, Action } from 'vuex-class';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { jsonToText } from '@/common/functions.helpers';
import DataTable from '@/components/common/DataTable.vue';
import { config } from 'vue/types/umd';
import VueFormGenerator from 'vue-form-generator';
const namespace: string = 'dynamicfulfillmentmodule';
import { humanizeString } from '@/common/utilities';
type SchemaTypes = ServiceNetworkFulfillmentOptionTypeConfig | AncillaryServiceTypeConfig;

@Component({ components: { VueJSONEditor, DataTable } })
export default class VendorConfigCreateEdit extends Vue {
  @Prop() config: VendorConfig;
  @State('dynamicfulfillmentmodule') profile!: DynamicFulfillmentState;
  @Action(FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS, { namespace }) private fetchAncillaryServiceTypeConfigs: () => Promise<AncillaryServiceTypeConfig[]>;
  @Action(FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS, { namespace }) private fetchServiceNetworkFulfillmentOptionTypeConfigs: () => Promise<ServiceNetworkFulfillmentOptionTypeConfig[]>;
  jsonEditorText: any = jsonToText;
  ancillaryServiceTypeConfigs: AncillaryServiceTypeConfig[] = [];
  serviceNetworkFulfillmentOptionTypeConfigs: ServiceNetworkFulfillmentOptionTypeConfig[] = [];
  selectedServiceProviderConfig: ServiceProviderConfig = null;
  selectedServiceProviderConfigIndex: number | undefined = undefined;
  selectedServiceNetworkFOConfig: VendorFulfillmentOptionConfig = { fulfillmentOption: null, attributes: {} };
  selectedServiceNetworkFOConfigIndex: number | undefined = undefined;
  networkConfigSchema: { [key: string]: CustomAttributeFields } = {};
  selectedServiceProviderConfigValid: boolean = false;
  attributeSchemaValid: boolean = false;
  ancillaryModalTitle: string = '';
  serviceNetworkFOTitle: string = '';
  vfgFormOptions: any = {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true,
  };

  serviceProviderFields: any[] = [
    {
      key: 'name',
      thStyle: { width: '250px' },
    },
    {
      key: 'billingVendorStrategy',
      thStyle: { width: '200px' },
    },
    {
      key: 'billingVendorId',
      thStyle: { width: '180px' },
    },
    {
      key: 'serviceCenterAdministration',
      thStyle: { width: '135px' },
    },
    {
      key: 'warrantyPeriod',
      thStyle: { width: '60px' },
    },
    {
      key: 'returnPeriod',
      thStyle: { width: '60px' },
    },
    {
      key: 'hasLoanerProgram',
      thStyle: { width: '135px' },
    },
    {
      key: 'bookedRepairLimit',
      thStyle: { width: '70px' },
    },
    {
      key: 'lookBackWindowHours',
      thStyle: { width: '70px' },
    },
    {
      key: 'inventoryFilteringEnabled',
      thStyle: { width: '150px' },
    },
    {
      key: 'minimumInventoryThreshold',
      thStyle: { width: '70px' },
    },
    {
      key: 'vendorManagedInventory',
      thStyle: { width: '150px' },
    },
    {
      key: 'billingVendorStrategy',
      thStyle: { width: '220px' },
    },
    {
      key: 'billingVendorId',
      thStyle: { width: '180px' },
    },
    {
      key: 'fulfillmentOptionConfigs',
      thStyle: { width: '140px' },
    },
    {
      key: 'actions',
    },
  ];

  ancillaryFields: any[] = [
    {
      key: 'name',
    },
    {
      key: 'billingVendorStrategy',
    },
    {
      key: 'billingVendorId',
    },
    {
      label: 'Service Types',
      key: 'allowedServices',
      formatter: (value: any, key: any, item: any) => {
        return value.map((service: any) => service.serviceType).join(', ');
      },
    },
    {
      key: 'actions',
    },
  ];

  fulfillmentOptionConfigFields: any[] = [
    {
      key: 'fulfillmentOption',
    },
    {
      key: 'attributes',
      formatter: (value: any, key: any, item: any) => {
        if (value) {
          return Object.entries(value).map(([key, value]) => `${humanizeString(key)}: ${value}`).join('<br />');
        }
      },
    },
    {
      key: 'actions',
    },
  ];

  public async beforeMount() {
    if (!this.config.vendorStatusPathConfig) {
      this.config.vendorStatusPathConfig = {
        statusPath: null,
        statusReasonPath: null,
      };
    }
    if (!this.config.invoicing) {
      this.config.invoicing = {
        allowNullCompanyId: false,
      };
    }

    this.config.serviceProviderConfigs.forEach((sp: ServiceProviderConfig) => {
      const extendedSp = sp as ServiceProviderConfigExtended;
      delete extendedSp._showDetails;
    });

    this.ancillaryServiceTypeConfigs = await this.fetchAncillaryServiceTypeConfigs();
    this.ancillaryServiceTypeConfigs.forEach((ancillaryServiceTypeConfig: AncillaryServiceTypeConfig) => this.buildSchema(ancillaryServiceTypeConfig));
    this.serviceNetworkFulfillmentOptionTypeConfigs = await this.fetchServiceNetworkFulfillmentOptionTypeConfigs();
    this.serviceNetworkFulfillmentOptionTypeConfigs.forEach((serviceNetworkFulfillmentOptionTypeConfig: ServiceNetworkFulfillmentOptionTypeConfig) => this.buildSchema(serviceNetworkFulfillmentOptionTypeConfig));
  }

  get isNew() {
    return this.config._etag === null;
  }

  get allowedFulfillmentOptions() {
    return Object.entries(FulfillmentOption)
      .filter((e: any) => !isNaN(e[0] as any))
      .map(([label, value]) => value);
  }

  get isServiceProviderConfigValid() {
    return this.selectedServiceProviderConfigValid && this.attributeSchemaValid;
  }

  get isServiceNetworkFOConfigValid() {
    return this.selectedServiceNetworkFOConfig.fulfillmentOption != null && this.attributeSchemaValid;
  }

  fulfillmentOptionLabel(option: number) {
    if (isNaN(option)) {
      return option;
    } else {
      return FulfillmentOption[option];
    }
  }

  hasAllowedAttributes(serviceType: string): boolean {
    const filteredConfigs = this.ancillaryServiceTypeConfigs.filter((item) => item.serviceType == serviceType);

    if (filteredConfigs.length === 0) {
      return false;
    }

    return filteredConfigs[0].allowedAttributes.length > 0 && this.networkConfigSchema[filteredConfigs[0].serviceType] !== undefined;
  }

  removeServiceProvider(row: { index: number; item: ServiceProviderConfig }) {
    this.remove(this.config.serviceProviderConfigs, row.item);
  }

  addServiceNetworkFO(serviceProviderConfig: ServiceProviderConfig) {
    this.selectedServiceProviderConfig = serviceProviderConfig;
    this.selectedServiceNetworkFOConfig = { fulfillmentOption: null, attributes: {} };
    this.serviceNetworkFOTitle = 'Add Service Network FulfillmentOption';
    this.$bvModal.show('service-network-fo-modal');
  }

  editServiceNetworkFO(row: { index: number; item: VendorFulfillmentOptionConfig }, parentRow: { index: number; item: ServiceProviderConfig }) {
    const nConfig = this.serviceNetworkFulfillmentOptionTypeConfigs.find((serviceNetworkFulfillmentOptionTypeConfig: ServiceNetworkFulfillmentOptionTypeConfig) => serviceNetworkFulfillmentOptionTypeConfig.id === FulfillmentOption[row.item.fulfillmentOption]);
    if (nConfig) {
      this.buildSchema(nConfig);
      row.item.attributes = JSON.parse(JSON.stringify(row.item.attributes)); //customAttributes are returned as observer instead of object. So parsing back to json.
      row.item.attributes = this.setAttributeDefaults(nConfig, row.item.attributes);
      VueFormGenerator.schema.mergeMultiObjectFields(this.networkConfigSchema[row.item.fulfillmentOption], [row.item.attributes]); //map model to schema
    }

    this.serviceNetworkFOTitle = 'Edit Service Network FulfillmentOption';
    this.selectedServiceProviderConfig = parentRow.item;
    this.selectedServiceNetworkFOConfigIndex = row.index;
    this.selectedServiceNetworkFOConfig = JSON.parse(JSON.stringify(row.item));
    this.ancillaryModalTitle = 'Edit FulfillmentOption Config';
    this.$bvModal.show('service-network-fo-modal');
  }

  serviceNetworkFOChanged(value: any) {
    const nConfig = this.serviceNetworkFulfillmentOptionTypeConfigs.find((config: ServiceNetworkFulfillmentOptionTypeConfig) => config.id === value);
    this.selectedServiceNetworkFOConfig.attributes = this.setAttributeDefaults(nConfig, this.selectedServiceNetworkFOConfig.attributes);
  }

  updateServiceNetworkFO() {
    if (this.selectedServiceNetworkFOConfigIndex !== undefined) {
      this.$set(this.selectedServiceProviderConfig.fulfillmentOptionConfigs, this.selectedServiceNetworkFOConfigIndex, this.selectedServiceNetworkFOConfig);
    } else {
      this.selectedServiceProviderConfig.fulfillmentOptionConfigs.push(this.selectedServiceNetworkFOConfig);
    }

    this.selectedServiceProviderConfig = this.newSelectedServiceProviderConfig('Ancillary');
    this.selectedServiceNetworkFOConfig = { fulfillmentOption: null, attributes: {} };
    this.selectedServiceNetworkFOConfigIndex = undefined;
    this.serviceNetworkFOTitle = undefined;
    this.$bvModal.hide('service-network-fo-modal');
  }

  removeServiceNetworkFO(row: { index: number; item: ServiceProviderConfig }, parentRow: { index: number; item: ServiceProviderConfig }) {
    this.config.serviceProviderConfigs[parentRow.index].fulfillmentOptionConfigs.splice(row.index, 1);
  }

  cancelServiceNetworkFO() {
    this.selectedServiceNetworkFOConfigIndex = undefined;
    this.attributeSchemaValid = false;
    this.serviceNetworkFOTitle = undefined;
    this.$bvModal.hide('service-network-fo-modal');
  }

  addServiceNetwork(category: string) {
    const serviceNetwork: ServiceProviderConfig = this.newSelectedServiceProviderConfig(category);

    if (category == 'Ancillary') {
      this.selectedServiceProviderConfig = serviceNetwork;
      this.ancillaryModalTitle = 'Add Ancillary Service Network';
      this.$bvModal.show('allowed-service-modal');
    } else {
      this.config.serviceProviderConfigs.push(serviceNetwork);
    }
  }

  addAllowedService() {
    let newService = { serviceType: null, attributes: {} } as AncillaryServiceConfig;

    if (this.ancillaryServiceTypeConfigs.length === 1) {
      newService.serviceType = this.ancillaryServiceTypeConfigs[0].serviceType;
      newService.attributes = this.serviceTypeChanged(newService);
    }

    this.selectedServiceProviderConfig.allowedServices.push(newService);
  }

  serviceTypeChanged(service: AncillaryServiceConfig): { [key: string]: string | number | boolean | string[] | Date} {
    const nConfig = this.ancillaryServiceTypeConfigs.find((ancillaryServiceTypeConfig: AncillaryServiceTypeConfig) => ancillaryServiceTypeConfig.id === service.serviceType);
    service.attributes = this.setAttributeDefaults(nConfig, service.attributes);

    return service.attributes;
  }

  editServiceType(row: { index: number; item: ServiceProviderConfig }) {
    for (var allowedService of row.item.allowedServices) {
      const nConfig = this.ancillaryServiceTypeConfigs.find((ancillaryServiceTypeConfig: AncillaryServiceTypeConfig) => ancillaryServiceTypeConfig.id === allowedService.serviceType);
      if (nConfig) {
        this.buildSchema(nConfig);
        allowedService.attributes = JSON.parse(JSON.stringify(allowedService.attributes)); //customAttributes are returned as observer instead of object. So parsing back to json.
        allowedService.attributes = this.setAttributeDefaults(nConfig, allowedService.attributes);
        VueFormGenerator.schema.mergeMultiObjectFields(this.networkConfigSchema[allowedService.serviceType], [allowedService.attributes]); //map model to schema
      }
    }

    this.selectedServiceProviderConfigIndex = row.index;
    this.selectedServiceProviderConfig = JSON.parse(JSON.stringify(row.item));
    this.ancillaryModalTitle = 'Edit Ancillary Service Network';
    this.$bvModal.show('allowed-service-modal');
  }

  removeService(allowedServices: { [key: string]: any }[], index: number) {
    allowedServices.splice(index, 1);
  }

  updateService() {
    if (this.selectedServiceProviderConfigIndex !== undefined) {
      this.$set(this.config.serviceProviderConfigs, this.selectedServiceProviderConfigIndex, this.selectedServiceProviderConfig);
    } else {
      this.config.serviceProviderConfigs.push(this.selectedServiceProviderConfig);
    }

    this.selectedServiceProviderConfig = this.newSelectedServiceProviderConfig('Ancillary');
    this.selectedServiceProviderConfigIndex = undefined;
    this.ancillaryModalTitle = undefined;
    this.$bvModal.hide('allowed-service-modal');
  }

  cancelService() {
    this.selectedServiceProviderConfigIndex = undefined;
    this.selectedServiceProviderConfigValid = false;
    this.attributeSchemaValid = false;
    this.ancillaryModalTitle = undefined;
    this.$bvModal.hide('allowed-service-modal');
  }

  validateServiceProviderConfig() {
    const { name, allowedServices } = this.selectedServiceProviderConfig;
    if (this.selectedServiceProviderConfig.billingVendorStrategy === Enums.BillingVendorStrategy.ServiceNetwork) {
      if (!this.selectedServiceProviderConfig.billingVendorId) {
        this.selectedServiceProviderConfigValid = false;
        return;
      }
    }
    this.selectedServiceProviderConfigValid = name && allowedServices.length > 0 && allowedServices.every((service) => service.serviceType !== null && service.serviceType !== '');
  }

  onBillingStrategyChange(item: ServiceProviderConfig) {
    if (item.billingVendorStrategy !== Enums.BillingVendorStrategy.ServiceNetwork) {
      item.billingVendorId = null;
    }
  }

  buildSchema<T extends SchemaTypes>(typeConfig: T) {
    const customAttributeFields: CustomAttributeFields = { fields: [] };
    typeConfig.allowedAttributes.forEach((attribute: AllowedAttribute) => {
      const schema: CustomAttributeSchema = {
        inputType: this.getSchemaInputType(attribute.dataType, attribute.allowMultiple, attribute.allowedValues),
        type: this.getSchemaType(attribute.dataType, attribute.allowMultiple, attribute.allowedValues),
        validator: this.getValidator(attribute.dataType, attribute.allowMultiple, attribute.isRequired),
        set: (model: any, value: any) => {
          if (attribute.allowMultiple && (!attribute.allowedValues || attribute.allowedValues.length === 0)) {
            model[attribute.key] = value.split('\n');
          } else {
            model[attribute.key] = value;
          }
        },
        get: (model: any) => {
          if (attribute.allowMultiple && (!attribute.allowedValues || attribute.allowedValues.length === 0)) {
            if (Array.isArray(model[attribute.key])) {
              return model[attribute.key].join('\n');
            } else if (attribute.allowMultiple && (!attribute.allowedValues || attribute.allowedValues.length === 0)) {
              if (!Array.isArray(model[attribute.key])) {
                let values: any[] = [];
                values.push(model[attribute.key]);
                return values.join('\n');
              }
              return model[attribute.key].join('\n');
            } else {
              return model[attribute.key];
            }
          } else if (attribute.allowMultiple && attribute.allowedValues && attribute.allowedValues.length > 0) {
            if (!Array.isArray(model[attribute.key])) {
              let values: any[] = [];
              values.push(model[attribute.key]);
              return values.join(',');
            } else {
              return model[attribute.key];
            }
          } else {
            return model[attribute.key];
          }
        },
        id: attribute.displayName,
        label: attribute.displayName,
        model: attribute.key,
        required: attribute.isRequired,
        default: attribute.defaultValue,
        hint: attribute.description,
        multi: attribute.allowMultiple,
        values:
          attribute.allowedValues && attribute.allowedValues.length > 0
            ? () => {
                return attribute.allowedValues;
              }
            : attribute.allowedValues,
        textOn: 'Yes',
        textOff: 'No',
        selectOptions:
          attribute.allowedValues && attribute.allowedValues.length > 0
            ? {
                multiple: attribute.allowMultiple,
                allowEmpty: !attribute.isRequired,
                maxHeight: 100,
              }
            : null,
        attributes: {
          trim: attribute.dataType === 'String' ? true : null,
        },
      };
      customAttributeFields.fields.push(schema);
    });

    Object.assign(this.networkConfigSchema, { [typeConfig.id]: customAttributeFields });
  }

  getSchemaType(dataType: string, allowMultiple: boolean, allowedValues: any[]) {
    if (allowedValues && allowedValues.length > 0) {
      return 'vueMultiSelect';
    } else if (allowMultiple && ((allowedValues && allowedValues.length === 0) || !allowedValues)) {
      return 'textArea';
    }

    switch (dataType) {
      case 'String':
      case 'Integer':
      case 'Decimal':
        return 'input';
      case 'Boolean':
        return 'switch';
      case 'Date':
        return 'vfj-datepicker';
      default:
        return 'input';
    }
  }

  getSchemaInputType(dataType: string, allowMultiple: boolean, allowedValues: any[]) {
    if (allowMultiple && allowedValues && allowedValues.length > 0) return null;
    switch (dataType) {
      case 'String':
        return 'text';
      case 'Integer':
        return 'number';
      case 'Decimal':
        return 'text';
      default:
        return null;
    }
  }

  getValidator(dataType: string, allowMultiple: boolean, isRequired: boolean) {
    let validators: string[] = [];
    if (isRequired) validators.push('required');
    if (allowMultiple) validators.push('uniqueValueValidator');
    switch (dataType) {
      case 'Integer':
      case 'Decimal':
        validators.push('numberValidator');
        break;
      default:
        return validators;
    }
    return validators;
  }

  onSchemaValidated(isValid: boolean, errors: any) {
    this.attributeSchemaValid = isValid;
  }

  setAttributeDefaults(nConfig: SchemaTypes, existingAttributes: any): { [key: string]: string | number | boolean | string[] | Date} {
    return nConfig.allowedAttributes.reduce((acc: any, attribute) => {
      if (attribute.key && acc[attribute.key] === undefined) {
        if (existingAttributes.hasOwnProperty(attribute.key)) {
          acc[attribute.key] = existingAttributes[attribute.key];
        } else if (attribute.defaultValue !== undefined) {
          acc[attribute.key] = attribute.defaultValue;
        } else {
          acc[attribute.key] = null;
        }
      }
      return acc;
    }, {});
  }

  private get allowedBillingVendorStrategies() {
    return Object.keys(Enums.BillingVendorStrategy);
  }

  newSelectedServiceProviderConfig(category: string): ServiceProviderConfig {
    return {
      name: null,
      serviceCenterAdministration: false,
      warrantyPeriod: null,
      loanerProgramConfig: {
        returnPeriodInDays: null,
        hasLoanerProgram: false,
      },
      bookedRepairVolumeFilterConfig: {
        lookBackWindowHours: null,
        bookedRepairLimit: null,
      },
      inventoryConfig: {
        inventoryFilteringEnabled: false,
        minimumInventoryThreshold: 1,
        vendorManagedInventory: false,
      },
      category: category,
      allowedServices: [],
      fulfillmentOptionConfigs: [],
      billingVendorStrategy: null,
      billingVendorId: null,
    };
  }

  descriptionItems(settings: VendorConfigUserInterfaceSettings) {
    return settings.descriptionItems.map((item: string) => {
      return {
        descriptionItemText: item,
      };
    });
  }

  addDescriptionItem(config: VendorConfig) {
    return config.userInterfaceSettings.descriptionItemsShadow.push({} as any);
  }

  deleteDescriptionItem(config: VendorConfig, item: any) {
    this.remove(config.userInterfaceSettings.descriptionItemsShadow, item);
  }

  remove(items: any[], item: any) {
    const index = items.indexOf(item);
    items.splice(index, 1);
  }

  userInterfaceSettingsChanged(event: any) {
    this.config.userInterfaceSettings.settings = event.text === '' ? null : JSON.parse(event.text);
  }

  @Watch('selectedServiceProviderConfig', { deep: true, immediate: false })
  selectedServiceProviderConfigChanged() {
    this.validateServiceProviderConfig();
  }

  @Watch('config', {immediate: false, deep: true})
  private config_changed() {
    this.$emit('stateUpdate');
  }
}

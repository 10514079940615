
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import BrandMappingConfigCreate from '@/components/Vendors/ServicePower/BrandMappingConfigCreate.vue';

@Component({ components: { ViewContent, BrandMappingConfigCreate } })
export default class ServicePowerBrandMappingCreate extends Vue {
  private defaultConfig: any = {
    brand: null,
    servicePowerCode: null,
  };

  private get pageTitle() {
    return 'Service Power Brand Mapping';
  }

  private mounted() {
    if (!this.defaultConfig) {
      this.$router.push({ name: 'ServicePowerBrandMapping-Index' });
    }
  }
}

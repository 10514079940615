import { ActionTree } from 'vuex';
import { GlobalModuleState, ServiceManufacturer } from './types';
import { RootState } from '../types';
import ServiceCenterApisActions from '../globalmodules/Actions/ServiceCenterApis.actions';
import MigrationMatrixApisActions from '../globalmodules/Actions/MigrationMatrixApis.actions';
import ServiceManufacturerApisActions from '../globalmodules/Actions/ServiceManufacturerApis.actions';

export const actions: ActionTree<GlobalModuleState, RootState> = {
    ...ServiceCenterApisActions,
    ...MigrationMatrixApisActions,
    ...ServiceManufacturerApisActions,
};

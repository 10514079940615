import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { operationsApiProvider } from '@/common/operations.api';
import { GlobalModuleState } from '../types';
import * as apis from '../constants';

const MigrationMatrixApisActions: ActionTree<GlobalModuleState, RootState> = {
  async [apis.POST_CLIENT_MIGRATION_MATRIX](context, requestData: { clientId: string; file: File; modifiedBy: string }) {
    return await operationsApiProvider.postClientMigrationMatrix(requestData.clientId, requestData.file, requestData.modifiedBy);
  },

  async [apis.POST_ASSURANT_MIGRATION_MATRIX](context, requestData: { clientId: string; file: File; modifiedBy: string }) {
    return await operationsApiProvider.postAssurantMigrationMatrix(requestData.clientId, requestData.file, requestData.modifiedBy);
  },

  async [apis.FETCH_MIGRATION_MATRIX_CLIENTS](context) {
    let migrationMatrixClients = context.state.migrationMatrixClients;
    if (migrationMatrixClients.length > 0) {
      return migrationMatrixClients;
    }
    migrationMatrixClients = await operationsApiProvider.getMigrationMatrixClients();
    context.commit(apis.SET_MIGRATION_MATRIX_CLIENTS, migrationMatrixClients);
    return migrationMatrixClients;
  },

  async [apis.FETCH_MIGRATION_MATRIX_DATA](context) {
    let migrationMatrixData = context.state.migrationMatrixPagingData;
    if (migrationMatrixData && migrationMatrixData.pagingToken) {
      const migrationMatrixResponse = await operationsApiProvider.getMigrationMatrixPagingData(migrationMatrixData.pagingToken);
      migrationMatrixData.migrationMatrices.push(...migrationMatrixResponse.migrationMatrices);
      migrationMatrixData.pagingToken = migrationMatrixResponse.pagingToken;
    } else if (migrationMatrixData) {
      return migrationMatrixData.migrationMatrices; // reached end of the page. Do not make the api call.
    } else {
      migrationMatrixData = await operationsApiProvider.getMigrationMatrixPagingData(null);
    }
    context.commit(apis.SET_MIGRATION_MATRIX_PAGING_DATA, migrationMatrixData);
    return migrationMatrixData.migrationMatrices;
  },
};

export default MigrationMatrixApisActions;

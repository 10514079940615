
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { FulfillmentOptionConfig, VendorConfig, CustomerLocationRepairFulfillmentOptionVendorConfig, AppointmentConfig } from '@/store/dynamicfulfillment/types';
import { default as FulfillmentOptionVendorConfig } from '@/components/configuration/FulfillmentOptionVendorConfigs/FulfillmentOptionVendorConfigCreateEdit.vue';
import { default as AppointmentConfigCreateEdit } from '@/components/configuration/AppointmentConfigCreateEdit.vue';

@Component({ components: { FulfillmentOptionVendorConfig, AppointmentConfigCreateEdit } })
export default class CustomerLocationRepairFOVendorConfigCreateEdit extends Vue {
  @Prop({ required: true })
  private index!: number;
  @Prop({ required: true })
  private fulfillmentOptionConfig: FulfillmentOptionConfig;
  @Prop({ required: true })
  private vendor: CustomerLocationRepairFulfillmentOptionVendorConfig;
  @Prop({ required: true }) private vendorConfigs: VendorConfig[];

  defaultAppointmentConfig: AppointmentConfig = {
    daysToLookOut: 0,
    autoCancelMissedAppointments: false,
    daysUntilAutoCancel: null,
  };

  public async mounted() {
    Vue.set(this.vendor, 'appointmentConfig', Object.assign({}, this.defaultAppointmentConfig, this.vendor.appointmentConfig || {}));
  }

  emitOnChange(content: { config: CustomerLocationRepairFulfillmentOptionVendorConfig | null; isValid: boolean }) {
    this.$emit('onConfigChange', content);
  }

  @Watch('vendor', { immediate: false, deep: true })
  configChange() {
    this.emitOnChange({ config: this.vendor, isValid: true });
  }

}

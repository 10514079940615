
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import { IConfig, RouteAction, RoutingAction } from '@/store/dynamicfulfillment/types';
import { State, Action, Getter } from 'vuex-class';
import { buildErrorMessage } from '@/common/functions.helpers';
import { faL } from '@fortawesome/free-solid-svg-icons';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({components: {ViewContent}})
export default class ConfigEditComponent extends Vue {
  @Prop() id: string;
  @Prop() configLabel!: string;
  @Prop() indexRoute!: string | { name: string, query?: any };
  @Prop() saveAction!: string;
  @Prop() fetchAction!: string;
  @Prop() fetchParams: {};
  @Prop() editing: boolean;
  @Prop() defaultConfig: IConfig;
  @Prop({required: false}) routeActions: RouteAction[];
  @Prop({default: false}) hideSubmit: boolean;
  @Prop({default: false}) submitForm: boolean;
  /**
   * Use this property to update the entire config. Right now this is configured to update the program config only.
   */
  @Prop() editedConfig: IConfig;
  @Prop({default: true}) isValidState: boolean;
  config: IConfig = null;
  loading: boolean = false;

  public async mounted() {
    if (this.editing) {
      await this.fetchConfig();
      this.$emit('pageLoaded', this.config);
    } else {
      this.config = this.defaultConfig;
    }
  }

  /**
   * save
   */
  public async save(e: any) {
    this.loading = true;
    try {
      if (this.editedConfig) {
        this.config = this.editedConfig;
      }
      let routeAction: RouteAction = this.routeActions? this.routeActions?.find((routeAction) => routeAction.name === RoutingAction.Create) : null;
      let saveAction = routeAction? routeAction.action: this.saveAction;
      let params = routeAction? {apiRoute: routeAction.params.apiRoute, config: this.config} : this.config;
      const savedConfig = await this.$store.dispatch(saveAction, params) as undefined | IConfig;
      if (savedConfig) {
        this.config = savedConfig;
      } else {
        await this.fetchConfig();
      }
      EventBus.$emit('s', `${this.configLabel} config ${this.editing ? 'updated' : 'created'} successfully.`);
      this.$emit('saved');
      this.$router.push(this.routeResolver(this.indexRoute));
    } catch (error) {
      const err: any = error;
      this.displayError(error);
      this.$emit('error');
    } finally {
      this.loading = false;
    }
  }

  displayError(error: any) {
    const message = buildErrorMessage(error);
    EventBus.$emit('e', `<h4>Validation error(s) occured.</h4>${message}`);
  }
  
  async fetchConfig() {
    const id = !!this.id ? this.id : this.config.id;
    let routeAction: RouteAction = this.routeActions? this.routeActions?.find((routeAction) => routeAction.name === RoutingAction.Fetch) : null;
    let fetchAction = routeAction? routeAction.action: this.fetchAction;
    let params = routeAction? {apiRoute: routeAction.params.apiRoute, id: id } : this.fetchParams ?? id;
    this.config = await this.$store.dispatch(fetchAction, params);
  }

  get pageTitle() {
    const configLabel = this.configLabel;
    return this.editing
      ? `${configLabel} - ${this.id}`
      : configLabel;
  }

  routeResolver(route: string | {}) {
    return typeof route === 'string' ? {name: route} : route;
  }

  hideSubmitButton() {
    this.hideSubmit = true;
  }

  @Watch('submitForm', {immediate: false})
  formSubmit(e: any) {
    this.save(e);
  }
}

import { GetterTree } from 'vuex';
import { GlobalModuleState } from './types';
import * as apis from './constants';
import { RootState } from '../types';

export const getters: GetterTree<GlobalModuleState, RootState> = {
    [apis.GET_MIGRATION_MATRIX_CLIENTS](state) {
        return state.migrationMatrixClients;
    },
};

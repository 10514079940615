
import Vue from 'vue';
import { State, Action } from 'vuex-class';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DynamicFulfillmentState, RepairCatalog, RoutingAction } from '@/store/dynamicfulfillment/types';
import { FETCH_REPAIR_CATALOGS } from '@/store/constants';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ConfigIndexComponent } })
export default class RepairCatalogIndex extends Vue {
  @State(namespace) private profile!: DynamicFulfillmentState;
  userAuthorizationProvider: UserAuthorizationProvider;
  title: string = 'Repair Catalogs';
  fields: any[] = [
    {
      key: 'id',
      label: 'Id',
    },
    'vendor',
    {
      key: 'serviceNetworks',
      label: 'Service Networks',
      formatter: (value: any) => {
        if (Array.isArray(value)) {
          return value.map((network: { network: string }) => network).join(', ');
        }
        return value;
      },
    },
    'actions',
  ];
  repairCatalogs: RepairCatalog[] = [];

  mounted() {
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
  }

  get routeActions() {
    return [
      { name: RoutingAction.Fetch, action: FETCH_REPAIR_CATALOGS, requiredPermission: 'Vendors-RepairCatalog-View' },
      { name: RoutingAction.Create, route: 'RepairCatalog-Create', requiredPermission: 'Vendors-RepairCatalog-Create' },
    ];
  }

  hasPermission(features: string[]) {
    return this.userAuthorizationProvider.hasPermission(features);
  }
}

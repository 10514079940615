import { RouteConfig } from 'vue-router';
import VendorsIndex from '@/views/configuration/Vendors/VendorsIndex.vue';
import RepairCatalogIndex from '@/views/configuration/Vendors/RepairCatalog/RepairCatalogIndex.vue';
import RepairCatalogItemsIndex from '@/views/configuration/Vendors/RepairCatalog/RepairCatalogItemsIndex.vue';
import RepairCatalogCreateEdit from '@/views/configuration/Vendors/RepairCatalog/RepairCatalogCreateEdit.vue';
import RepairCatalogItemsUpload from '@/views/configuration/Vendors/RepairCatalog/RepairCatalogItemsUpload.vue';
import RepairCatalogPartsMappingIndex from '@/views/configuration/Vendors/RepairCatalog/RepairCatalogPartsMappingIndex.vue';

const routes = [
    {
        path: 'repair-catalogs',
        component: VendorsIndex,
        meta: {
            displayName: 'Repair Catalogs',
            authorizedPermissions: ['Vendors-RepairCatalog-View'],
        },
        children: [
            {
                path: 'list',
                name: 'RepairCatalogs-Index',
                component: RepairCatalogIndex,
                meta: {
                    title: 'Repair Catalogs List',
                    displayName: 'Repair Catalogs List',
                    topNavDisplay: true,
                    requiresAuth: true,
                    authorizedPermissions: ['Vendors-RepairCatalog-View'],
                },
            },
            {
                path: 'create',
                name: 'RepairCatalog-Create',
                component: RepairCatalogCreateEdit,
                meta: {
                    title: 'Repair Catalog Create',
                    displayName: 'Repair Catalog Create',
                    topNavDisplay: false,
                    requiresAuth: true,
                    authorizedPermissions: ['Vendors-RepairCatalog-Create'],
                },
            },
            {
                path: ':catalogId/items/upload',
                name: 'RepairCatalog-Items-Upload',
                props: true,
                meta: {
                    title: 'Repair Catalog Items Upload',
                    topNavDisplay: false,
                    requiresAuth: true,
                    authorizedPermissions: ['Vendors-RepairCatalog-UploadItems'],
                },
                component: RepairCatalogItemsUpload,
            },
            {
                path: ':catalogId/repair-catalog-items',
                name: 'RepairCatalogItems-Index',
                component: RepairCatalogItemsIndex,
                props: true,
                meta: {
                    title: 'Repair Catalog Items',
                    displayName: 'Repair Catalog Items',
                    topNavDisplay: false,
                    requiresAuth: true,
                    authorizedPermissions: ['Vendors-RepairCatalog-View'],
                },
            },
            {
                path: ':catalogId/repair-catalog-parts-mapping',
                name: 'RepairCatalogPartsMapping-Index',
                component: RepairCatalogPartsMappingIndex,
                props: true,
                meta: {
                    title: 'Repair Catalog Parts Mapping',
                    displayName: 'Repair Catalog Parts Mapping',
                    topNavDisplay: false,
                    requiresAuth: true,
                    authorizedPermissions: ['Vendors-RepairCatalog-View'],
                },
            },
        ]
    },

] as RouteConfig[];

export default routes;
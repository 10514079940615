
import { Component, Vue, Prop } from 'vue-property-decorator';
import { copyToClipboard } from '@/common/utilities';

@Component
export default class ClipboardText extends Vue {
  @Prop({ default: null }) private text!: string;

  private get textToCopy(): string {
    return this.text ? this.text : this.$slots.default && this.$slots.default[0] ? this.$slots.default[0].text : '';
  }

  private copyToClipboard() {
    copyToClipboard(this.textToCopy);
  }
}

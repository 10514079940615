
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { humanizeString } from '@/common/utilities';

@Component
export default class FilterOnFields extends Vue {
  @Prop() private id: string;
  @Prop() private fields: any[];
  @Prop() private filterOnFields: any[];

  private filterFields: any[] = [];

  private async mounted() {
    this.filterFields = this.filterOnFields;
  }

  private get hasFilterOn() {
    if (this.fields) {
      return this.fields.some((x) => x.filterable === true);
    }
  }

  private get filterOnOptions() {
    return this.fields
      .filter((x) => x.filterable === true)
      .map((field) => ({
        text: field.label ?? humanizeString(field.key),
        value: field.key,
      }));
  }

  @Watch('filterFields', { immediate: false, deep: true })
  private filterOnFieldsHasChanged() {
    this.$emit('filterOnFieldsHasChanged', this.filterFields);
  }
}


import Vue from 'vue';
import { State, Action } from 'vuex-class';
import ViewContent from '@/components/ViewContent.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import DataTable from '@/components/common/DataTable.vue';
import { FETCH_REPAIR_CATALOG_ITEMS } from '@/store/dynamicfulfillment/constants';
import { DynamicFulfillmentState, RepairCatalogItemDefinition, RoutingAction } from '@/store/dynamicfulfillment/types';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import RepairCatalogInventoryItemModal from '@/components/Vendors/RepairCatalogInventoryItemModal.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, DataTable, RepairCatalogInventoryItemModal } })
export default class RepairCatalogItemsIndex extends Vue {
  @Prop({ required: true, type: String }) catalogId: string;
  @State(namespace) private profile!: DynamicFulfillmentState;
  @Action(FETCH_REPAIR_CATALOG_ITEMS, { namespace }) fetchRepairCatalogItems: (catalogId: string) => Promise<RepairCatalogItemDefinition[]>;
  userAuthorizationProvider: UserAuthorizationProvider;
  title: string = 'Repair Catalog Items';
  fields: any[] = [
    { key: 'manufacturer', sortable: true },
    { key: 'model', sortable: true },
    { key: 'serviceName', sortable: true },
    { key: 'serviceSku', sortable: true },
    { key: 'deviceTypeId', sortable: true },
    { key: 'repairTypeId', sortable: true },
    { key: 'color', sortable: true },
    {
      key: 'partIds',
      label: 'Parts',
      sortable: true,
      thStyle: {
        width: '250px',
      },
    },
  ];
  filteredItems: any[] = [];
  criteria: { [key: string]: any } = {
    serviceProgramId: null,
    manufacturer: null,
    model: null,
  };
  serviceProgramIds: string[] = [];
  items: any[] = [];

  private async mounted() {
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    this.items = await this.fetchRepairCatalogItems(this.catalogId);
    this.serviceProgramIds = [...new Set(this.items.map((item) => item.serviceProgramId))];
  }

  hasPermission(features: string[]) {
    if (!this.userAuthorizationProvider) {
      this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    }
    return this.userAuthorizationProvider.hasPermission(features);
  }
}

import {
    FulfillmentTicket,
    FulfillmentOption,
} from '@/store/dynamicfulfillment/types';
import { ComponentList } from '@/components/FulfillmentAuthorization/Vendors';

export class VendorDetailFactory {
    public GetFulfillmentTicketVendorDetailComponent(fulfillmentTicket: FulfillmentTicket): any {
        if (!fulfillmentTicket || (fulfillmentTicket.acceptedOfferInfo.offer.vendorId === "FixtQ" && !this.fixtQClaimDataIsAvailableInSCT(fulfillmentTicket)))
            return null;
        let vendorComponentKey = fulfillmentTicket.acceptedOfferInfo.offer.vendorId + "-" + this.getFulfillmentOptionText(fulfillmentTicket.acceptedOfferInfo.offer.fulfillmentOption);
        return ComponentList[vendorComponentKey];
    }

    private get getFulfillmentOptionText() {
        return (val: number) => {
            return FulfillmentOption[val];
        };
    }

    private fixtQClaimDataIsAvailableInSCT(fulfillmentTicket: FulfillmentTicket) {
        if (fulfillmentTicket.status.statusHistory.some((s) => s.reason === "Appointment Accepted")){
            return true;
        }
        return false;
    }
}

export const vendorDetailFactory = new VendorDetailFactory();
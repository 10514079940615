
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import { FETCH_FULFILLMENT_OPTION_CONFIG, FULFILLMENT_OPTION_CONFIG_SAVE, RESET_FULFILLMENT_OPTION_CONFIGS } from '@/store/constants';
import FulfillmentOptionConfigCreateEdit from '@/components/configuration/FulfillmentOptionConfigCreateEdit.vue';
import { CustomerLocationRepairFulfillmentOptionVendorConfig, FulfillmentOptionConfig, RepairFulfillmentOptionVendorConfig, Vendor } from '@/store/dynamicfulfillment/types';
type VendorConfigType = CustomerLocationRepairFulfillmentOptionVendorConfig | RepairFulfillmentOptionVendorConfig;
Component.registerHooks(['beforeRouteLeave']);

@Component({ components: { ConfigEditComponent, FulfillmentOptionConfigCreateEdit } })
export default class FulfillmentOptionConfigEdit extends Vue {
  @Prop() private id!: string;
  private selected: any[] = null;
  private isValidState: boolean = false;
  private isStateChanged: boolean = false;

  private get fetchAction() {
    return FETCH_FULFILLMENT_OPTION_CONFIG;
  }

  private get saveAction() {
    return FULFILLMENT_OPTION_CONFIG_SAVE;
  }

  private stateUpdated() {
    this.isStateChanged = true;
  }

  private stateSaved() {
    this.isStateChanged = false;
  }

  private onConfigChange(request: { config: FulfillmentOptionConfig | null; isValid: boolean }) {
    this.isValidState = request.isValid;
    if (!request.config) {
      return;
    }
      request.config.vendors.forEach((vendor: VendorConfigType) => {
        if (vendor.appointmentConfig?.autoCancelMissedAppointments) {
          if (vendor.appointmentConfig.daysUntilAutoCancel === 0 || vendor.appointmentConfig.daysUntilAutoCancel == null) {
            this.isValidState = false;
            return;
          }
        }
      });
  }
  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.isStateChanged) {
      this.$store.commit(RESET_FULFILLMENT_OPTION_CONFIGS);
      next();
    } else {
      next();
    }
  }
}


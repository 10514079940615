
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import {
  FulfillmentOptionConfig,
  SourceSystemConfig,
  Vendor,
  UserInterfaceSettings,
  FulfillmentOptionUserInterfaceSettings,
} from '@/store/dynamicfulfillment/types';
import {
  FETCH_FULFILLMENT_OPTION_CONFIG,
  FULFILLMENT_OPTION_CONFIG_SAVE,
} from '@/store/constants';
import FulfillmentOptionConfigCreateEdit from '@/components/configuration/FulfillmentOptionConfigCreateEdit.vue';

@Component({
  components: { ConfigEditComponent, FulfillmentOptionConfigCreateEdit },
})
export default class FulfillmentOptionConfigEdit extends Vue {
  @Prop() private id!: string;

  private defaultConfig: FulfillmentOptionConfig = {
    id: null,
    _etag: null,
    isEnabled: false,
    allowedPerils: [],
    userInterfaceSettings: {
      descriptionItems: [],
      descriptionItemsShadow: [],
      settings: {},
    },
    vendors: [],
    canForceCancel: false,
    defaultSlaDuration: '00:00:00',
    requireDeclineReason: false,
    requiresPriceList: false,
  };

  private get fetchAction() {
    return FETCH_FULFILLMENT_OPTION_CONFIG;
  }

  private get saveAction() {
    return FULFILLMENT_OPTION_CONFIG_SAVE;
  }
}

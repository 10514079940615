import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const CacheApiActions: ActionTree<DynamicFulfillmentState, RootState> = {

    async [apis.CLEAR_CACHE](context) {
      return await authorizationApiProvider.clearCache();
    },
  
    async [apis.CLEAR_CACHE_BY_KEY](context, key: string) {
      return await authorizationApiProvider.clearCacheByKey(key);
    },
};

export default CacheApiActions;
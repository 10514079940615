
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Invoice, InvoiceCompany } from '@/store/dynamicfulfillment/types';
import { FETCH_FULFILLMENTTICKET_INVOICES } from '@/store/dynamicfulfillment/constants';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { jsonToText } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';
import DataTable from '@/components/common/DataTable.vue';
import { DateTime } from 'luxon';

@Component({
  components: {
    DataTable,
    VueJSONEditor,
  },
})
export default class FulfillmentTicketInvoices extends Vue {
  @Prop() private id: string;
  @Action(FETCH_FULFILLMENTTICKET_INVOICES, { namespace })
  private fetchFulfillmentTicketInvoices: any;
  private jsonEditorText: any = jsonToText;
  private invoices: Invoice[] = null;
  private fields: any[] = [
    {
      key: 'id',
      sortable: true,
    },
    {
      key: 'orderId',
      sortable: true,
    },
    {
      key: 'invoiceNumber',
      sortable: true,
    },
    {
      key: 'invoiceDate',
      sortable: true,
    },
    {
      key: 'invoiceStatus',
      sortable: true,
    },
    {
      key: 'receivedOnUtc',
      sortable: true,
    },
    {
      key: 'vendor',
      sortable: true,
    },
    {
      key: 'currency',
      sortable: true,
    },
    {
      key: 'subtotal',
      sortable: true,
    },
    {
      key: 'tax',
      sortable: true,
    },
    {
      key: 'total',
      label: 'Total Amount',
      sortable: true,
    },
    {
      key: 'details',
    },
  ];
  private invoiceLineItemFields: any[] = [
    {
      key: 'itemId',
      sortable: true,
    },
    {
      key: 'itemName',
      sortable: true,
    },
    {
      key: 'itemDescription',
      sortable: true,
    },
    {
      key: 'sku',
      sortable: true,
    },
    {
      key: 'quantity',
      sortable: true,
    },
    {
      key: 'unitPrice',
      sortable: true,
    },
    {
      key: 'unitTax',
      sortable: true,
    },
    {
      key: 'subTotal',
      sortable: true,
    },
    {
      key: 'tax',
      sortable: true,
    },
    {
      key: 'total',
      sortable: true,
    },
  ];
  private companyFields: any[] = [
    {
      key: 'key',
      sortable: true,
    },
    {
      key: 'value',
      sortable: true,
    },
  ];
  private attributeFields: any[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'value',
      sortable: true,
    },
  ];
  private costSummaryFields: any[] = [
    {
      key: 'itemId',
      sortable: true,
    },
    {
      key: 'subtotal',
      sortable: true,
    },
    {
      key: 'total',
      sortable: true,
    },
    {
      key: 'tax',
      sortable: true,
    },
  ];
  private configModal: any = {
    id: '',
    title: '',
    content: '',
  };

  public async mounted() {
    this.invoices = await this.fetchFulfillmentTicketInvoices(this.id);
  }

  private companyToTable(company: any) {
    return Object.keys(company).map((key) => ({
      key: key,
      value: company[key],
    }));
  }

  private attributeItems(attributes: any) {
    return Object.keys(attributes).map((key) => ({
      name: key,
      value: attributes[key],
    }));
  }

  private isObject(data: any) {
    return typeof data === 'object' && data !== null;
  }

  private config(item: any, index: any, button: any, title: string) {
    this.configModal.id = item.name;
    this.configModal.title = title;
    this.configModal.content = JSON.stringify(item.value, null, 4);
    this.$root.$emit('bv::show::modal', this.configModal.id, button);
  }

  private resetConfigModal() {
    this.configModal.title = '';
    this.configModal.content = '';
  }

  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}

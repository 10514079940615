
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { UserClaims } from '@/common/auth.types';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import { State } from 'vuex-class';
import { DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import VendorsNavBar from '@/components/navigation/VendorsNavBar.vue';
const namespace: string = 'dynamicfulfillmentmodule';
@Component({ components: { VendorsNavBar } })
export default class NavBar extends Vue {
  @Prop() authenticated!: boolean;
  @State('dynamicfulfillmentmodule') profile!: DynamicFulfillmentState;
  claims: any = null;
  userAuthorizationProvider: UserAuthorizationProvider;
  configurationMenuPermissions: string[] = [
    'Configuration-Cache-View',
    'Configuration-FulfillmentOptions-View',
    'Configuration-Locales-View',
    'Configuration-PriceLists-View',
    'Configuration-Products-View',
    'Configuration-SourceSystems-View',
    'Configuration-Vendors-View',
    'Configuration-VendorTicketStatusMapping-View',
    'Configuration-Programs-View',
    'Configuration-AppConfig-View',
    'Configuration-Users-View',
    'Configuration-Roles-View',
    'Configuration-AncillaryServiceType-View',
    'Configuration-ServiceNetworkFulfillmentOptionType-View',
  ];
  vendorsMenuPermissions: string[] = [
    'Vendors-ServiceCentral-NetworkManagement-View',
    'Vendors-ServicePower-BrandMappings-View',
  ];

  mounted() {
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
  }

  get userName() {
    return !!this.profile.userClaims ? (this.profile.userClaims as UserClaims).preferred_username : '';
  }

  login() {
    this.$auth.loginRedirect('/');
  }

  async logout() {
    await this.$auth.logout();
    this.authenticated = false;
    this.$router.push({ name: 'Home' });
  }

  get environment() {
    return `${process.env.VUE_APP_REGION}-${process.env.VUE_APP_ENVIRONMENT}`;
  }

  get sourceSystems() {
    return this.profile.sourceSystemConfigs;
  }

  hasPermission(features: string[]) {
    if (!this.userAuthorizationProvider) {
      this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    }
    return this.userAuthorizationProvider.hasPermission(features);
  }
}


import { Component } from 'vue-property-decorator';
import RemovedConfigIndexComponent from '@/components/configuration/RemovedConfigIndexComponent.vue';
import RolesConfigModal from '@/components/configuration/RolesConfigModal.vue'
import RolesIndex from './RolesIndex.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { RoleConfig, RoutingAction } from '@/store/dynamicfulfillment/types';
import { DELETE_ROLE } from '@/store/dynamicfulfillment/constants';

@Component({ components: { RemovedConfigIndexComponent, RolesConfigModal } })
export default class RemovedRolesIndex extends RolesIndex {  
  private configItems: RoleConfig[]  = null;

  async mounted() {
    await authorizationApiProvider.getRoles(true)
        .then((configs) => this.configItems = configs);
  }

  get removedRouteActions() {
    return [
     { name: RoutingAction.Delete, action: 'dynamicfulfillmentmodule/' + DELETE_ROLE, requiredPermission: 'Global-HardDelete', params: { hardDelete: true }},
    ];
  }
}

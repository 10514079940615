
import Vue from 'vue';
import ViewContent from '@/components/ViewContent.vue';
import { Action, State } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { humanizeString } from '@/common/utilities';
import { buildErrorMessage } from '@/common/functions.helpers';
import { UPDATE_SOURCE_SYSTEM_CONFIG_PARTIAL } from '@/store/dynamicfulfillment/constants';
import ProductMappingAttributesEdit from '@/views/configuration/SourceSystems/ProductMappingAttributesEdit.vue';
import { DamageType, EquipmentType, Peril, ProductMappingAttribute, ClaimStatuses } from '@/store/dynamicfulfillment/types';
import DataTable from '@/components/common/DataTable.vue';
import EventBus from '@/common/event.bus';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: { ViewContent, ProductMappingAttributesEdit, DataTable },
})
export default class SourceSystemConfigDetailsItemsCreateEdit extends Vue {
  @Prop() id!: string;
  @Prop() sourceSystem!: string;
  @Prop() items: any;
  @Prop() indexRoute: {};
  @Action(UPDATE_SOURCE_SYSTEM_CONFIG_PARTIAL, { namespace })
  updateSourceSystemConfigPartial: any;
  editedConfig: any = {};
  isValid: boolean = false;
  fields: string[] = ['key', 'value', 'action'];
  keyToValueMap: any ={
    damageTypes: DamageType,
    perils: Peril,
    equipmentTypes: EquipmentType,
    claimStatuses: ClaimStatuses,
  };

  mounted() {
    if (!this.isProductMappingAttribute) {
      this.isValid = true;
    }
  }

  get pageTitle(): string {
    return `${humanizeString(this.id)}`;
  }

  get isProductMappingAttribute(): boolean {
    return this.id === 'productMappingAttributes';
  }

  humanizeString(value: string): string {
    return humanizeString(value);
  }

  get allowedConfigTypeOptions() {
    const value = this.keyToValueMap[this.id];

    if (value) {
      return Object.entries(value)
        .filter((e: any) => isNaN(e[0] as any))
        .map((e: any) => e[0]);
    }
  }

  configTypeLabel(option: number) {
    const value = this.keyToValueMap[this.id];

    if (value) {
      return value[option];
    }
  }

  async handleSubmit(e: any) {
    e.preventDefault();

    if (this.isValid) {
      await this.updateSourceSystemConfigPartial({
        id: this.sourceSystem,
        configType: this.id,
        items: this.items.value,
      })
        .then(() => {
          EventBus.$emit('s', `${this.sourceSystem} Source System ${humanizeString(this.id)} updated successfully.`);
          this.$router.push(this.indexRoute);
        })
        .catch((error: any) => {
          const message = buildErrorMessage(error);
          EventBus.$emit('e', message);
        });
    }
  }

  addItem() {
    const newProductMappingAttribute: ProductMappingAttribute = {
      name: '',
      weight: 0,
      values: [],
      type: null,
      isRequired: false,
      path: '',
    };

    if (this.isProductMappingAttribute) {
      this.items.value.push(newProductMappingAttribute);
    } else {
      this.items.value.unshift({ key: null, value: null });
    }    
    const dataTable = this.$refs.dataTable as DataTable;
    if (dataTable) {
      dataTable.pageToFirst();
    }
  }

  removeItem(item: any) {
    const index = this.items.value.findIndex((obj: any) => obj.key === item.key && obj.value === item.value);
    if (index !== -1) {
      this.items.value.splice(index, 1);
    }
  }

  productMappingAttributeChanged(response: { items: ProductMappingAttribute[]; isValid: boolean }) {
    this.isValid = response.isValid;
  }

  @Watch('items', { immediate: false, deep: true })
  config_changed() {
    this.$emit('stateUpdate');
  }
}

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, SourceSystemConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';

const SourceSystemConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_SOURCE_SYSTEM_CONFIGS](context) {
    const configs = await authorizationApiProvider.getSourceSystemConfigs();
    const userAuthorizationProvider = userAuthorizationProviderFactory(context.state);

    const eligibleSourceSystems = configs.filter((sourceSystemConfig) => userAuthorizationProvider.hasPermission([`SourceSystem-${sourceSystemConfig.sourceSystem}`]));

    context.commit(apis.SET_SOURCE_SYSTEM_CONFIGS, eligibleSourceSystems);
    return context.state.sourceSystemConfigs;
  },

  async [apis.FETCH_SOURCE_SYSTEM_CONFIG](context, sourceSystem: string) {
    const config = context.state.sourceSystemConfigs.find((s) => s.id === sourceSystem);
    if (!config) {
      await context.dispatch(apis.FETCH_SOURCE_SYSTEM_CONFIGS);
    }
    return context.state.sourceSystemConfigs.find((s: any) => s.id === sourceSystem);
  },

  async [apis.SAVE_SOURCE_SYSTEM_CONFIG](context, config: SourceSystemConfig) {
    config = await authorizationApiProvider.updateSourceSystemConfig(config);
    context.commit(apis.SET_SOURCE_SYSTEM_CONFIG, config);
  },

  async [apis.UPDATE_SOURCE_SYSTEM_CONFIG_PARTIAL](context, params: { id: string; configType: string; items: [] }): Promise<void> {
    await authorizationApiProvider.updateSourceSystemConfigPartial(params.id, params.configType, params.items);
    await authorizationApiProvider.clearCache();
    await context.dispatch(apis.FETCH_SOURCE_SYSTEM_CONFIGS);
  },
};

export default SourceSystemConfigActions;

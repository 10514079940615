import { EquipmentType } from "../dynamicfulfillment/types";
import { IHasAllowedAttributes } from "../dynamicfulfillment/types";

export interface GlobalModuleState {
  migrationMatrixClients: MigrationMatrixSettingData[];
  migrationMatrixData: MigrationMatrixData[];
  migrationMatrixPagingData: MigrationMatrixDataResponse;
  serviceCentersPage: ServiceLocationPage;
  serviceNetworkConfigs: ServiceNetworkConfig[];
  serviceManufacturers: ServiceManufacturer[];
  serviceCenterLocationConfig: ServiceCenterLocationConfig;
}

export interface IConfig {
  id: string;
  eTag: string;
  userAudit?: UserAudit;
}

export interface MigrationMatrixSettingData {
  clientId: string;
  useMigrationMatrix: boolean;
  displayName: string;
  clientName: string;
}

export interface MigrationMatrixData extends IConfig {
  deviceSku: string;
  description: string;
  clientId: string;
  effectiveStartDate: string;
  effectiveEndDate?: string | null;
  replacementItems: ReplacementItem[];
  lastModifiedBy: string;
  lastModifiedDate: string;
  fileReceivedDate: string;
}

export interface MigrationMatrixDataResponse {
  migrationMatrices: MigrationMatrixData[];
  pagingToken: string;
}

export interface ReplacementItem {
  deviceSku: string;
  description: string;
  rank: number;
}

export enum ServiceLocationMakes {
  Samsung = 1,
  LG = 2,
  Apple = 3,
}

export interface ServiceLocationContactData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
}

export interface ServiceLocationAddress {
  line1: string;
  line2: string;
  line3: string;
  region: string;
  city: string;
  postalCode: string;
  country: string;
  latitude: string;
  longitude: string;
}

export interface StoreHours {
  day: string;
  startTime: string;
  endTime: string;
  startTimeFormatted: string;
  endTimeFormatted: string;
  isValid: boolean;
}

export interface SelectOption {
  value: number;
  text: string;
}

export interface ServiceLocationSearchCriteria {
  postalCode: string;
  serviceNetwork: string;
  serviceCenterName: string;
  countryCodes: string[];
  showActiveOnly: boolean;
}

export interface ServiceLocationPage {
  serviceCenters: ServiceCenterLocationConfig[];
  pagingToken: string;
}

export interface BenefitCheckSearchCriteria {
  mobileNumber: string;
  imei: string;
}

export interface BenefitCheckData {
  carrierName: string;
  hasActiveProtectionPlan: boolean;
}

export interface BenefitCheckErrors {
  Imei: string[];
  MobileNumber: string[];
}

export interface CustomAttribute {
  key?: string;
  value: object;
}

export interface ServiceCenterLocationConfig extends IConfig {
  id: string;
  storeId: string;
  name: string;
  address: ServiceLocationAddress;
  contactInfo: ServiceLocationContactData;
  openHours: StoreHours[];
  servicedManufacturers: string[];
  isActive: boolean;
  serviceNetwork: string;
  rating: string;
  warrantyDays: number;
  customAttributes: { [key: string]: any };
  isEdit?: boolean;
  serviceArea: ServiceArea[];
  servicedEquipmentTypes: EquipmentType[];
  billingVendorId: string;
}

export interface AllowedAttribute {
  key: string;
  displayName: string;
  isRequired: boolean;
  allowMultiple: boolean;
  dataType: string;
  defaultValue: any;
  description: string;
  allowedValues: any[];
}

export interface ServiceNetworkConfig extends IHasAllowedAttributes, IConfig {
  id: string;
  isEdit?: boolean;
}

export interface UserAudit {
  historyItems: Audit[];
  current: Audit;
}

export interface Audit {
  userId: string;
  name: string;
  modifiedOnUtc: string;
  configAction: string;
}

export interface ServiceManufacturer extends IConfig {
  id: string;
  displayName: string;
  allowedValues: string[];
}

export interface CustomAttributeSchema {
  id: string;
  type: string;
  inputType: string;
  label: string;
  model: string;
  required: boolean;
  default: any;
  hint: string;
  multi: boolean;
  values: any;
  textOn: string;
  textOff: string;
  validator: any;
  set(model: any, value: any): void;
  get(model: any): void;
  attributes: any;
  selectOptions: any;
}

export interface CustomAttributeFields {
  fields: CustomAttributeSchema[];
}

export interface CustomAttributeGroup {
  legend: string;
  fields: CustomAttributeSchema[];
}

export enum ServiceAreaValueInputType {
  Exact = 0,
  Range = 1,
}

export enum ServiceAreaClassification {
  PostalCode = 0,
}

export interface ServiceArea {
  classification: string;
  inputType: string;
  value: string;
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import {
  FETCH_INVENTORY_STATE,
  SAVE_INVENTORY_STATE,
  UPDATE_INVENTORY_STATE,
} from '@/store/constants';
import InventoryStateCreateEdit from '@/components/inventory/InventoryStateCreateEdit.vue';
import { DynamicFulfillmentState, InventoryState } from '@/store/dynamicfulfillment/types';
import { Action, State } from 'vuex-class';
import { SET_INVENTORY_STATE } from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';

Component.registerHooks([
    'beforeRouteLeave',
]);

@Component({components: {ConfigEditComponent, InventoryStateCreateEdit}})
export default class InventoryStatesEdit extends Vue {
  @Prop() private id!: string;
  @State(namespace) private profile!: DynamicFulfillmentState;
  @Action(SET_INVENTORY_STATE, { namespace }) private setInventoryState: any;
  private selected: any[] = null;
  private isValidState: boolean = true;
  private isStateChanged: boolean;
  private config: InventoryState = null;

  private get fetchAction() {
    return FETCH_INVENTORY_STATE;
  }

  private get saveAction() {
    return UPDATE_INVENTORY_STATE;
  }

  private formState(value: boolean) {
    this.isValidState = (value === null);
  }

  private stateUpdated() {
    this.isStateChanged = true;
  }

  private stateSaved() {
    this.isStateChanged = false;
  }

  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.isStateChanged) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
      if (answer) {
        this.setInventoryState(this.config);
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }

  private pageLoaded(config: InventoryState) {
    this.config = JSON.parse(JSON.stringify(config));
  }
}


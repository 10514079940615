
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import RolesCreateEdit from '@/components/configuration/RolesCreateEdit.vue';
import { FETCH_ROLE, UPDATE_ROLE } from '@/store/dynamicfulfillment/constants';

@Component({ components: { ConfigEditComponent, RolesCreateEdit } })
export default class RolesEdit extends Vue {
  @Prop() id!: string;

  get fetchAction() {
    return 'dynamicfulfillmentmodule/' + FETCH_ROLE;
  }

  get saveAction() {
    return 'dynamicfulfillmentmodule/' + UPDATE_ROLE;
  }

  get indexRoute() {
    return 'Roles-Index';
  }
}


import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import ServiceNetworkCreateEdit from '@/components/ServiceCenters/ServiceNetworkCreateEdit.vue';
import {
  FETCH_SERVICENETWORK_CONFIG,
  SET_SERVICENETWORK_CONFIGS,
} from '@/store/globalmodules/constants';
import {
  ServiceNetworkConfig,
  ServiceCenterLocationConfig,
  AllowedAttribute,
} from '@/store/globalmodules/types';
import { Action } from 'vuex-class';
const namespace: string = 'globalmodule';

@Component({
  components: { ViewContent, ConfigEditComponent, ServiceNetworkCreateEdit },
})
export default class ServiceNetworkEdit extends Vue {
  @Prop() private id!: string;
  @Action(FETCH_SERVICENETWORK_CONFIG, { namespace })
  private fetchServiceNetworkConfig: any;
  private config: ServiceNetworkConfig = {
    eTag: null,
    id: null,
    allowedAttributes: [] as AllowedAttribute[],
  };
  private isValid: boolean = false;

  private get fetchAction() {
    return 'globalmodule/' + FETCH_SERVICENETWORK_CONFIG;
  }

  private get saveAction() {
    return 'globalmodule/' + SET_SERVICENETWORK_CONFIGS;
  }

  private async mounted() {
    const serviceNetworkConfig = await this.fetchServiceNetworkConfig(this.id);
    if (serviceNetworkConfig) {
      this.config = serviceNetworkConfig;
    }
  }

  private onConfigChange(request: { config: any | null; isValid: boolean }) {
    this.isValid = request.isValid;

    if (request.isValid) {
      this.config = request.config;
    }
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import DataTable from '@/components/common/DataTable.vue';

@Component({ components: { DataTable } })
export default class StatusHistoryComponent extends Vue {
  @Prop({ default: false }) private showEmpty: boolean;
  @Prop({ default: true, type: Boolean }) private showFilter: boolean;
  @Prop({ default: true, type: Boolean }) private showPagination: boolean;
  @Prop() private items: any[];
  @Prop({
    default: () => [
      'status',
      {
        key: 'reason',
        label: 'Status Reason',
      },
      'notes',
      'data',
      {
        key: 'modifiedDate',
        label: 'Status Date',
      },
      'modifiedBy',
    ],
  }) private fields: any[];
  @Prop({ default: () => ['name', 'value'] }) private dataFields: any[];

  formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}

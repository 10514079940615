
import { Component, Vue, Prop } from 'vue-property-decorator';
import Badge from '@/common/badge';

@Component
export default class Severity extends Vue {
  @Prop({required: true, type: Number}) private severity!: number;

  private get badge(): Badge {
    switch (this.severity) {
      case 1:
        return new Badge('Information', 'info');
      case 2:
        return new Badge('Warning', 'warning');
      case 3:
        return new Badge('Error', 'danger');
      case 4:
        return new Badge('Critical', 'danger');
      default:
        return new Badge(this.severity.toString(), 'secondary');
    }
  }
}

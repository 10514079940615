import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, ServiceNetworkFulfillmentOptionTypeConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const ServiceNetworkFulfillmentOptionTypeConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS](context) {
    let ancillaryServiceTypes = await authorizationApiProvider.getServiceNetworkFulfillmentOptionTypeConfigs();
    context.commit(apis.SET_ANCILLARY_SERVICE_TYPES, ancillaryServiceTypes);
    return ancillaryServiceTypes;
  },

  async [apis.FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG](context, id: string) {
    const serviceNetworkFulfillmentOptionTypeConfig = context.state.serviceNetworkFulfillmentOptionTypeConfigs.find((config: ServiceNetworkFulfillmentOptionTypeConfig) => config.id === id);
    return serviceNetworkFulfillmentOptionTypeConfig ? serviceNetworkFulfillmentOptionTypeConfig : await authorizationApiProvider.getServiceNetworkFulfillmentOptionTypeById(id);
  },

  async [apis.CREATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG](context, serviceNetworkFulfillmentOptionTypeConfig: ServiceNetworkFulfillmentOptionTypeConfig) {
    if (serviceNetworkFulfillmentOptionTypeConfig._etag == null && context.state.serviceNetworkFulfillmentOptionTypeConfigs.find((c) => c.id === serviceNetworkFulfillmentOptionTypeConfig.id)) {
      throw Error(`ServiceNetworkFulfillmentOptionTypeConfig with Id ${serviceNetworkFulfillmentOptionTypeConfig.id} already exists.`);
    }
    await authorizationApiProvider.createServiceNetworkFulfillmentOptionTypeConfig(serviceNetworkFulfillmentOptionTypeConfig);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES);
    await context.dispatch(apis.FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS);
  },

  async [apis.UPDATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG](context, serviceNetworkFulfillmentOptionTypeConfig: ServiceNetworkFulfillmentOptionTypeConfig) {
    await authorizationApiProvider.updateServiceNetworkFulfillmentOptionTypeConfig(serviceNetworkFulfillmentOptionTypeConfig);
    context.commit(apis.RESET_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES);
    await context.dispatch(apis.FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS);
  },

  async [apis.DELETE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIG](context, params: { config: ServiceNetworkFulfillmentOptionTypeConfig; hardDelete: boolean }) {
    await authorizationApiProvider.deleteServiceNetworkFulfillmentOptionTypeConfig(params.config, params.hardDelete);
    context.dispatch(apis.CLEAR_CACHE);
    context.commit(apis.FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS);
  },
};

export default ServiceNetworkFulfillmentOptionTypeConfigActions;

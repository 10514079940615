
import { Component, Vue, Prop } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import UploadComponent from '@/components/common/UploadComponent.vue';
import { DynamicFulfillmentState, FulfillmentOption, IPriceListConfig, IPriceListItem, PriceListConfig, PriceListItem } from '@/store/dynamicfulfillment/types';
import { Action, State } from 'vuex-class';
import { UPLOAD_PRICE_LIST_ITEMS, FETCH_PRICE_LIST_CONFIG } from '@/store/dynamicfulfillment/constants';
import { buildErrorMessage } from '@/common/functions.helpers';
import { validateFileType, formatFileName, uploadFile } from '@/common/file.helpers';

const namespace: string = 'dynamicfulfillmentmodule';
@Component({
    components: { ViewContent, UploadComponent },
})
export default class PriceListConfigItemsUploadComponent<T> extends Vue {
    @Prop() private config: IPriceListConfig<T>;
    @Prop() private configRoute: string;
    @Prop() private route: string;
    @Action(UPLOAD_PRICE_LIST_ITEMS, { namespace }) private uploadPriceListItems: ((request: { payload: any; configId: string }) => Promise<void>);
    @Action(FETCH_PRICE_LIST_CONFIG, { namespace }) private fetchPriceListConfig: ((request: { id: string; route: string, forceFetch: boolean }) => Promise<IPriceListConfig<T>>);
    @State(namespace) private profile!: DynamicFulfillmentState;
    private resetForm: boolean = false;
    private isValidState: boolean = true;
    private isValidFileType: boolean = true;

    public get pageTitle(): string {
        return this.config
            ? this.config.vendor  +  ((this.config.serviceProviders && this.config.serviceProviders.length > 0) ? ' / ' + this.config.serviceProviders.join(', ') + ' / ' : ' / ') + FulfillmentOption[this.config.fulfillmentOption]
            : '';
    }
    private validation(file: File) {
        if (file) {
            this.isValidFileType = validateFileType(file, ['csv', 'xlsx']);
        }

        return this.isValidFileType && this.isValidState;
    }

    private formatFileName(files: File[]) {
        return formatFileName(files[0]);
    }

    private invalidFeedBack() {
        if ( this.isValidFileType === false ) {
            return 'Invalid file. Select ".csv or .xlsx" file';
        }
        if ( this.isValidState === false) {
            return 'File contains invalid data.';
        }
    }

    private async upload(eventData: any) {        
        EventBus.$emit('i', 'Please wait. Uploading Price List Items...');
        
        const uploadFunction = async (payload: any) => {
            await this.uploadPriceListItems({payload: eventData.file, configId: this.config.id}).then(() => {
                this.uploadSuccessful();
            });
        };

        try {
            await uploadFile(eventData.file, uploadFunction);
        } catch (error: any) {
            this.isValidState = false;
            const message = buildErrorMessage(error);
            EventBus.$emit('e', message);
        }
    }

    private async uploadSuccessful() {        
        EventBus.$emit('s', 'Price list items upload submitted successfully. Processing may take a few minutes. Please refresh the price list upload status page for current upload status.');
        await this.fetchPriceListConfig({ id: this.config.id, route: this.configRoute, forceFetch: true });
        this.$router.push({ name: `${this.route + 'PriceListConfig-Index'}` });
    }

    private reset() {
        this.isValidState = true;
        this.isValidFileType = true;
    }
}

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, RoleConfig, UserConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const RoleConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_ROLES](context) {
    let roles = context.state.roles;
    if (!roles && roles.length === 0) {
      return roles;
    }
    roles = await authorizationApiProvider.getRoles();
    context.commit(apis.SET_ROLES, roles);
    return roles;
  },

  async [apis.FETCH_ROLE](context, id: string) {
    if (!context.state.roles || context.state.roles.length == 0) {
      await context.dispatch(apis.FETCH_ROLES, true);
    }
    return context.state.roles.find((c) => c.id === id);
  },

  async [apis.UPDATE_ROLE](context, config: RoleConfig) {
    await authorizationApiProvider.updateRole(config);
  },

  async [apis.CREATE_ROLE](context, config: RoleConfig) {
    if (config._etag === null && context.state.roles.find((c: any) => c.id === config.id)) {
      throw Error(`Role with Id ${config.id} already exists.`);
    }
    await authorizationApiProvider.createRole(config);
  },

  async [apis.DELETE_ROLE](context, params: { config: RoleConfig; hardDelete: boolean }) {
    if (context.state.userConfigs.length === 0) {
      await context.dispatch(apis.FETCH_USER_CONFIGS, true);
    }

    const userConfig = context.state.userConfigs.some((userConfig: UserConfig) => userConfig.roles.some((c: any) => c === params.config.id));
    if (userConfig) {
      throw Error(`${params.config.id}, is currently assigned to user(s). Remove the role from user(s) and try again.`);
    }

    await authorizationApiProvider.deleteRole(params.config, params.hardDelete).then(() => {
      context.dispatch(apis.CLEAR_CACHE);
      context.commit(apis.FETCH_ROLE);
    });
  },
};

export default RoleConfigActions;

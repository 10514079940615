
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import { DamageType, SourceSystemConfig, Peril, EquipmentType, ClaimStatuses, IConfig } from '@/store/dynamicfulfillment/types';
import { FETCH_SOURCE_SYSTEM_CONFIG, SAVE_SOURCE_SYSTEM_CONFIG } from '@/store/dynamicfulfillment/constants';
import { Action, State } from 'vuex-class';
const namespace: string = 'dynamicfulfillmentmodule';
import { humanizeString, replaceEmptyArraysWithEmptyString } from '@/common/utilities';
import FilterOnFields from '@/components/common/FilterOnFields.vue';
import DataTable from '@/components/common/DataTable.vue';

@Component({ components: { ViewContent, FilterOnFields, DataTable } })
export default class SourceSystemConfigDetails extends Vue {
  @Prop() private id!: string;
  @Action(FETCH_SOURCE_SYSTEM_CONFIG, { namespace })
  private fetchSourceSystemConfig: any;
  private sourceSystemConfig: SourceSystemConfig = null;
  private excludedProperties: string[] = ['userAudit', 'searchCriteria'];
  private currentTab: string = '';
  private currentPage = 1;
  private filter: {} = {};
  private filterOnFields: any[] = [];
  private editableTabs: string[] = ['productMappingAttributes', 'damageTypes', 'perils', 'equipmentTypes', 'claimStatuses'];
  private sortByDesc: boolean = false;

  public get pageTitle(): string {
    return this.sourceSystemConfig ? this.sourceSystemConfig.sourceSystem : '';
  }

  private get tabData(): any[] {
    let tabData: { key: string; label: string; fields?: any[]; value: any }[] = [];
    const excludedPropertiesSet = new Set(this.excludedProperties);
    if (this.sourceSystemConfig) {
      const keyToValueMap = {
        damageTypes: DamageType,
        perils: Peril,
        equipmentTypes: EquipmentType,
        claimStatuses: ClaimStatuses,
      } as any;

      for (const key in this.sourceSystemConfig) {
        if (this.sourceSystemConfig.hasOwnProperty(key) && !excludedPropertiesSet.has(key)) {
          const value = (this.sourceSystemConfig as any)[key];
          const label = humanizeString(key);
          if (typeof value != 'string' && typeof value === 'object' && value !== null) {
            if (value instanceof Array && value.length > 0) {
              tabData.push({
                key: key,
                fields: this.getObjectFields(value),
                label: label,
                value: replaceEmptyArraysWithEmptyString(value),
              });
            } else {
              const keyValuePairArray: { key: string; value: string }[] = [];
              const fields: { key: string; sortable: boolean; filterable: boolean }[] = [
                { key: 'key', sortable: true, filterable: true },
                { key: 'value', sortable: true, filterable: true },
              ];
              for (const subKey in value) {
                if (value.hasOwnProperty(subKey)) {
                  let val = value[subKey];

                  if (keyToValueMap[key]) {
                    val = keyToValueMap[key][value[subKey]];
                  }

                  keyValuePairArray.push({ key: subKey, value: val });
                }
              }

              tabData.push({
                key: key,
                fields: fields,
                label: label,
                value: keyValuePairArray,
              });
            }
          }
        }
      }
    }

    return tabData;
  }

  public async created() {
    this.sourceSystemConfig = await this.fetchSourceSystemConfig(this.id);
    this.currentTab = this.tabData[0].key;
  }

  public get currentTabData() {
    return this.tabData.find((i) => i.key === this.currentTab);
  }

  public get allowedEdit() {
    return this.editableTabs.includes(this.currentTab);
  }

  private getObjectFields(value: any[]) {
    const consolidatedProperties = value.reduce((acc, obj) => {
      Object.keys(obj).forEach((key) => {
        if (!acc.includes(key)) {
          acc.push(key);
        }
      });
      return acc;
    }, []);

    return consolidatedProperties.map((key: string) => ({ key: key, filterable: true, sortable: true }));
  }
}

  export const validateFileType = (file: File, types: string[]): boolean => {
    if (file) {
      const fileType = file.name.split('.').pop();
      return types.includes(fileType);
    }
    return false;
  };

  export const formatFileName = (file: File): string => {
    const fileName = file.name;
    return fileName.length > 50 ? `${fileName.substring(0, 50)}...` : fileName;
  };

  export const uploadFile = async (file: File, uploadFunction: (payload: any) => Promise<void>): Promise<void> => {
    const reader = new FileReader();
    const fileType = file.name.split('.').pop();

    return new Promise((resolve, reject) => {
      reader.onload = async (e: any) => {
        try {
          await uploadFunction(e.target.result);
          resolve();
        } catch (error) {
          reject(error);
        }
      };

      if (fileType === 'xlsx') {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsText(file);
      }
    });
  };
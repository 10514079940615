
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AttributeComponent from './AttributeComponent.vue';
import { IHasAllowedAttributes, AllowedAttribute } from '@/store/dynamicfulfillment/types';

@Component({ components: { AttributeComponent } })
export default class CustomAttributesCreateEditComponent extends Vue {
    @Prop({ required: true }) private config: IHasAllowedAttributes;
    @Prop({ default: 'Allowed Attributes' }) allowedAttributeLabel!: string;
    @Prop() private isNew!: boolean;
    @Prop({ required: false, type: Function }) private deleteValidationFunc: (item: AllowedAttribute) => { isValid: boolean, message?: string };
    attributeState: boolean = false;

    private created() {
        if (this.isNew) {
            this.addCustomAttribute();
        }
    }

    private customAttributesChanged(content: {
        config: IHasAllowedAttributes | null;
        isValid: boolean;
    }) {
        this.attributeState = content.isValid;
        this.validateConfig();
    }

    validateConfig() {
        this.$emit('onConfigChange', {
            config: this.config,
            isValid: this.attributeState
        });
    }

    private addCustomAttribute() {
        this.config.allowedAttributes.push({
        isRequired: false,
        allowedValues: [],
        } as AllowedAttribute);
    }
}

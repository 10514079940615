
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SeverityLevel from '@/components/SeverityLevel.vue';
import DataTable from '@/components/common/DataTable.vue';
import {
    FETCH_AUTH_TRACES,
    FETCH_COMMUNICATIONS,
} from '@/store/dynamicfulfillment/constants';
import { Action } from 'vuex-class';
import { DateTime } from 'luxon';
const namespace: string = 'dynamicfulfillmentmodule';
declare var process: any;

@Component({components: {SeverityLevel, DataTable}})
export default class Communications extends Vue {
    @Prop() private authorizationId!: string;
    @Prop() private claimId!: string;
    @Action(FETCH_COMMUNICATIONS, { namespace }) private fetchCommunications: any;
    private items: any[] = null;
    private fields = [
        {
            key: 'Action',
            label: '',
            thClass: 'view-content',
        },
        {
            key: 'sentOn',
            label: 'Sent On',
            thClass: 'view-content',
        },
        {
            key: 'status',
            label: 'Status',
            thClass: 'view-content',
        },
        {
            key: 'id',
            label: 'Id',
            thClass: 'view-content',
        },
        {
            key: 'subject',
            label: 'Subject',
            thClass: 'view-content',
        },
        {
            key: 'from',
            label: 'From',
            thClass: 'view-content',
        },
        {
            key: 'recipients',
            label: 'Recipients',
            thClass: 'view-content',
        },
        {
            key: 'deliveryStatus',
            label: 'Delivery Status',
            thClass: 'view-content',
        },
        {
            key: 'fulfillmentTicketId',
            label: 'Fulfillment Ticket Id',
            thClass: 'view-content',
        },
        {
            key: 'clientId',
            label: 'Client Id',
            thClass: 'view-content',
        },
        {
            key: 'clientName',
            label: 'Client Name',
            thClass: 'view-content',
        },
        {
            key: 'ruleId',
            label: 'Rule Id',
            thClass: 'view-content',
        },
        {
            key: 'ruleName',
            label: 'Rule Name',
            thClass: 'view-content',
        },
        {
            key: 'communicationTypeId',
            label: 'Communication Type Id',
            thClass: 'view-content',
        },
        {
            key: 'communicationTypeName',
            label: 'Communication Type Name',
            thClass: 'view-content',
        },
        {
            key: 'canResend',
            label: 'Can Resend?',
            thClass: 'view-content',
        },
    ];
    private communicationContent: string = null;

    public async mounted() {
        this.items = await this.fetchCommunications(this.authorizationId);
    }

    private canView(item: any) {
        return item.Channel === 'Email' && item.DeliveryStatus === 'Sent';
    }

    private contentUrl(data: {Id: string}) {
        return `${process.env.VUE_APP_COMMS_MANAGER_BASE_URI}/Operations/Communications/${data.Id}/Content`;
    }

    private formatDateTime(value: any) {
        return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
    }
}

import { default as SvsGiftCardReplacementsDetail } from './SvsGiftCardReplacementsDetail.vue';
import { default as ServiceCentralDirectedRepairDetail } from './ServiceCentralDirectedRepairDetail.vue';
import { default as FixtqDirectedRepairDetail } from './FixtqDirectedRepairDetail.vue';

/* 
    Notes: 
    - key is a combination of VendorId and FulfillmentOption. 
    - Example: If VendorId is ServiceCentral and FulfillmentOption is DirectedRepair 
        then the key will be "ServiceCentral-DirectedRepair".
    - After creating a new vendor FO component; import it onto this page and add it to below ComponentList.
*/
const ComponentList: { [key: string]: object } = {
    "Svs-GiftCardReplacements": SvsGiftCardReplacementsDetail,
    "ServiceCentral-DirectedRepair": ServiceCentralDirectedRepairDetail,
    "FixtQ-DirectedRepair": FixtqDirectedRepairDetail
};

export { ComponentList };
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, ProgramFeesConfig } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { filterConfigsByProgramId } from '@/common/utilities';

const ProgramFeesConfigActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.FETCH_PROGRAM_FEES_CONFIGS](context) {
    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    let programFeesConfigs = await authorizationApiProvider.getProgramFeesConfigs();

    programFeesConfigs = filterConfigsByProgramId(
      programFeesConfigs,
      (config) => [config.programId],
      () => context.state.programConfigs.map((config) => config.id),
    );

    context.commit(apis.SET_PROGRAM_FEES_CONFIGS, programFeesConfigs);
    return programFeesConfigs;
  },

  async [apis.FETCH_PROGRAM_FEES_CONFIG](context, id: string) {
    const programFeesConfigs = context.state.programFeesConfigs.find((config: ProgramFeesConfig) => config.id === id);
    return programFeesConfigs ? programFeesConfigs : await authorizationApiProvider.getProgramFeesConfig(id);
  },

  async [apis.CREATE_PROGRAM_FEES_CONFIG](context, config: ProgramFeesConfig) {
    await authorizationApiProvider.createProgramFeesConfig(config);
  },

  async [apis.EDIT_PROGRAM_FEES_CONFIG](context, config: ProgramFeesConfig) {
    await authorizationApiProvider.editProgramFeesConfig(config);
  },

  async [apis.DELETE_PROGRAM_FEES_CONFIG](context, params: { config: ProgramFeesConfig; hardDelete: boolean }) {
    await authorizationApiProvider.deleteProgramFeesConfig(params.config, params.hardDelete).then(() => {
      context.dispatch(apis.CLEAR_CACHE);
      context.commit(apis.FETCH_PROGRAM_FEES_CONFIGS);
    });
  },

  async [apis.FETCH_FEE_TYPES](context) {
    const feeTypes = await authorizationApiProvider.getFeeTypes();
    return feeTypes;
  },
};

export default ProgramFeesConfigActions;

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, FulfillmentTicket, FulfillmentTicketOrchestrationDetail, FulfillmentTicketByAuthorizationQuery, FulfillmentAuthorization } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const FulfillmentTicketApiActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.SEARCH_FULFILLMENT_TICKET_BY_BOOKED_OFFER_CONFIRMATION_ID](context, bookedOfferConfirmationId: string): Promise<FulfillmentTicket[]> {
    return await authorizationApiProvider.getFulfillmentTicketsByBookedOfferConfirmationId(bookedOfferConfirmationId);
  },

  async [apis.FETCH_FULFILLMENT_TICKET_ORCHESTRATION](context, orchestrationId: string): Promise<FulfillmentTicketOrchestrationDetail> {
    return await authorizationApiProvider.getOrchestrationInfo(orchestrationId);
  },

  async [apis.RESTART_FULFILLMENT_TICKET_ORCHESTRATION](context, fulfillmentTicketId: string) {
    return await authorizationApiProvider.restartFulfillmentTicketOrchestration(fulfillmentTicketId);
  },

  async [apis.SEARCH_FULFILLMENT_TICKET_BY_AUTH](context, query: FulfillmentTicketByAuthorizationQuery) {
    const auth = (await context.dispatch(apis.SEARCH_AUTH_BY_ID, { authorizationId: query.authorizationId, forceFetch: false })) as FulfillmentAuthorization;
    return auth.fulfillmentTickets.find((t: FulfillmentTicket) => t.id === query.fulfillmentTicketId);
  },

  async [apis.FETCH_FULFILLMENTTICKET_INVOICES](context, id: string) {
    let fulfillmentTicketInvoices = await authorizationApiProvider.fetchFulfillmentTicketInvoices(id);
    context.commit(apis.SET_FULFILLMENTTICKET_INVOICES, fulfillmentTicketInvoices);
    return fulfillmentTicketInvoices;
  },
};

export default FulfillmentTicketApiActions;

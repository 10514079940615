import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { GlobalModuleState, ServiceManufacturer } from '../types';
import * as apis from '../constants';
import { locationManagementApiProvider } from '@/common/locationManagement.api';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const ServiceManufacturerApisActions: ActionTree<GlobalModuleState, RootState> = {
  async [apis.FETCH_SERVICE_MANUFACTURER](context, manufacturerId: string) {
    return await locationManagementApiProvider.getServiceManufacturer(manufacturerId);
  },

  async [apis.UPDATE_SERVICE_MANUFACTURER](context, serviceManufacturer: ServiceManufacturer) {
    await locationManagementApiProvider.updateServiceManufacturer(serviceManufacturer);
    context.commit(apis.RESET_SERVICE_MANUFACTURERS);
    await context.dispatch(apis.FETCH_SERVICE_MANUFACTURERS);
  },

  async [apis.SAVE_SERVICE_MANUFACTURER](context, serviceManufacturer: ServiceManufacturer) {
    if (serviceManufacturer.eTag == null && context.state.serviceManufacturers.find((c) => c.id === serviceManufacturer.id)) {
      throw Error(`Service Manufacturer with Id ${serviceManufacturer.id} already exists.`);
    }
    await locationManagementApiProvider.addServiceManufacturer(serviceManufacturer);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_SERVICE_MANUFACTURERS);
    await context.dispatch(apis.FETCH_SERVICE_MANUFACTURERS);
  },
};

export default ServiceManufacturerApisActions;


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FETCH_AUTH_TRACES } from '@/store/dynamicfulfillment/constants';
import SeverityLevel from '@/components/SeverityLevel.vue';
import DataTable from '@/components/common/DataTable.vue';
import { Action } from 'vuex-class';
import { DateTime } from 'luxon';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({components: {SeverityLevel, DataTable}})
export default class FulfillmentAuthorizationTraces extends Vue {
    @Prop() private authorizationId!: string;
    @Prop() private offerSetId: string;
    @Action(FETCH_AUTH_TRACES, { namespace }) private fetchAuthTraces: any;
    private fields = [
        {
            key: 'timestamp',
            sortable: true,
            thStyle: { width: '150px' },
        },
        {
            key: 'component',
            sortable: true,
        },
        {
            key: 'severityLevel',
            sortable: true,
        },
        {
            key: 'message',
            sortable: true,
        },
        'details',
    ];
    private traces: any[] = null;
    private infoModal: any = {
          id: 'info-modal',
          title: '',
          content: '',
        };

    public async mounted() {
        this.traces = await this.fetchAuthTraces(this.authorizationId);
        if(this.offerSetId) {
            this.traces = this.traces.filter((x) => x.customDimensions.offerSetId == this.offerSetId);
        }
    }

  private info(item: any, index: any, button: any, title: string) {
        this.infoModal.title = title;
        this.infoModal.content = JSON.stringify(item, null, 4);
        this.$root.$emit('bv::show::modal', this.infoModal.id, button);
  }

  private resetInfoModal() {
        this.infoModal.title = '';
        this.infoModal.content = '';
  }

  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}
